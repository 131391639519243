import { useEffect, useRef, useState } from "react";
import { IconCirclePlus, IconTrash } from "@tabler/icons-react";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { useLocation } from "react-router-dom";
//
import { DISelectItem, DIPayload, DISelectTypes, EyeListItem, DIListItem, DIStatus, UpdateDIPayload } from "@/models/select/diagnosticImpression";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { createDI, deleteDI, getDIList, getDISelects, getDIStatus, updateDI } from "./diagnosticImpression.action";
import { getAttentionTimes } from "../AttentionOrigin/attentionOrigin.actions";
//
import { AttentionTime } from "@/models/attentionOrigin";
import { RadioButton, SectionCard, Select } from "@/components";
import { setDICounter, setSelectedDI } from "@/config/slices/counters";
import ScrollableTable from "@/components/ScrollableTable/ScrollableTable";
interface DiagnosticImpressionProps {
    isDisabledForm: boolean;
}
export default function DiagnosticImpression({ isDisabledForm }: DiagnosticImpressionProps) {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const selectRef = useRef<{ clearInput: () => void; }>();

    const currentSheet = location.pathname.split("/")[location.pathname.split("/").length - 1];
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const accountId = useAppSelector(state => state.workspace.id);
    const clinicalHistoryId = useAppSelector(state => state.patientAttention.patientStatus.clhId);
    const patientId = useAppSelector(state => state.patientAttention.appointmentSelected.patientId);

    const [selectSearch, setSelectSearch] = useDebounce<string>("", 500);
    const [statusSelect, setStatusSelect] = useState<DIStatus[]>([]);
    const [diagnosticTimes, setDiagnosticTimes] = useState<AttentionTime[]>([]);
    const [diList, setDIList] = useState<DIListItem[]>([]);
    const [diSelectItems, setDISelectItems] = useState<DISelectItem[]>([]);
    const [eyeList, setEyeList] = useState<EyeListItem[]>([]);
    const [selectedDX, setSelectedDX] = useState<UpdateDIPayload>({
        appId: appointmentId,
        clhId: clinicalHistoryId,
        eaccount: accountId,
        userId: userId as number,
        sheetOrigin: currentSheet
    });
    const [diCreatePayload, setDIPayload] = useState<DIPayload>({
        appId: appointmentId,
        userId: userId as number,
        eaccount: accountId,
        type: "cie10",
        codeId: "",
        dboId: undefined,
        evolution: undefined,
        sheet: currentSheet
    });
    const [diParams] = useState({
        appId: appointmentId,
        eaccount: accountId,
        clhId: clinicalHistoryId,
        userId: userId as number,
        cluId: patientId,
    });

    useEffect(() => {
        async function fetchData() {
            const data = await getDIStatus();
            setStatusSelect(data);
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const attentionTimesData = await getAttentionTimes();
            setDiagnosticTimes(attentionTimesData as AttentionTime[]);
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getDISelects({
                type: diCreatePayload.type,
                enabled: 1,
                search: selectSearch,
                page: 1,
                perPage: 10
            }));
            setDISelectItems(data?.diSelectResponse as DISelectItem[]);
            setEyeList(data?.eyesResponse as EyeListItem[]);
        }
        fetchData();
    }, [dispatch, diCreatePayload.type, selectSearch]);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getDIList(diParams));
            if (data) {
                dispatch(setDICounter(data.length));
                const mainOption = data.find(item => item.isMain);
                if (mainOption) {
                    setSelectedDX(prevValue => ({
                        ...prevValue,
                        cie10Code: mainOption.cie10Code,
                        evolution: mainOption.evolution,
                        evolutionTimeId: mainOption.evolutionTime?.id,
                        extDiagnosticId: mainOption.extDiagnosticId,
                        isMain: mainOption.isMain as 1 | 0,
                        relClhId: mainOption.id
                    }));
                    dispatch(setSelectedDI(!!mainOption.isMain));
                }
                setDIList(data);
            }
        }
        fetchData();
    }, [dispatch, diParams]);

    const onSetEvolutionTime = useDebouncedCallback(async (option: UpdateDIPayload) => {
        await dispatch(updateDI(option));
    }, 1000);

    const onChangeDiagnostic = (value: DISelectTypes) => {
        selectRef.current?.clearInput();
        setDIPayload({
            ...diCreatePayload,
            type: value,
            codeId: "",
            timId: undefined,
            dboId: undefined
        });
        setSelectSearch("");
    };

    const onChangeDISelect = (value: string | number): void => {
        setDIPayload({
            ...diCreatePayload,
            codeId: value
        });
    };

    const onChangeEyeSelect = (value: number): void => {
        setDIPayload({
            ...diCreatePayload,
            dboId: value
        });
    };

    const onCreateService = async () => {
        const isSuccess = await dispatch(createDI(diCreatePayload));
        if (isSuccess) {
            const data = await dispatch(getDIList({
                appId: appointmentId,
                eaccount: accountId,
                clhId: clinicalHistoryId,
                userId: userId as number,
                cluId: patientId,
            }));
            dispatch(setDICounter(data.length));
            setDIList(data as DIListItem[]);
            setDIPayload({
                ...diCreatePayload,
                codeId: "",
                timId: undefined,
                dboId: undefined,
                evolution: undefined
            });
        }
    };

    const onDeleteService = async (di: DIListItem) => {
        const isSuccess = await dispatch(deleteDI({
            appId: appointmentId,
            clhId: clinicalHistoryId,
            eaccount: accountId,
            userId: userId as number,
            relClhId: di.id as number,
            cie10Code: di.cie10Code,
            extDiagnosticId: di.extDiagnosticId as number
        }));
        if (isSuccess) {
            if (di.isMain) setSelectedDX({
                appId: appointmentId,
                clhId: clinicalHistoryId,
                eaccount: accountId,
                userId: userId as number,
                evolution: "",
                evolutionTimeId: undefined,
                sheetOrigin: currentSheet
            });
            const data = await dispatch(getDIList({
                appId: appointmentId,
                eaccount: accountId,
                clhId: clinicalHistoryId,
                userId: userId as number,
                cluId: patientId,
            }));
            dispatch(setDICounter(data.length));
            setDIList(data as DIListItem[]);
            dispatch(setSelectedDI(false));
        }
    };

    const onChangeDX = async (di: DIListItem) => {
        const options: DIListItem[] = diList.map(item => {
            if (di.id === item.id && di.clhId === item.clhId) {
                return { ...item, isMain: 1 };
            }
            return { ...item, isMain: 0 };
        });
        const selectedDI = options.find(item => item.id === di.id);

        if (selectedDI) {
            setDIList(options);

            if (selectedDI.extDiagnosticId) {
                setSelectedDX({
                    ...selectedDX,
                    relClhId: selectedDI.id,
                    extDiagnosticId: selectedDI.extDiagnosticId,
                    isMain: selectedDI.isMain,
                    evolution: "",
                    evolutionTimeId: undefined
                });
                await dispatch(updateDI({
                    appId: appointmentId,
                    clhId: clinicalHistoryId,
                    relClhId: selectedDI.id,
                    eaccount: accountId,
                    userId: userId as number,
                    extDiagnosticId: selectedDI.extDiagnosticId,
                    isMain: selectedDI.isMain,
                    sheetOrigin: currentSheet
                }));
            } else {
                setSelectedDX({
                    ...selectedDX,
                    relClhId: selectedDI.id,
                    cie10Code: selectedDI.cie10Code,
                    isMain: selectedDI.isMain,
                    evolution: "",
                    evolutionTimeId: undefined
                });
                await dispatch(updateDI({
                    appId: appointmentId,
                    clhId: clinicalHistoryId,
                    relClhId: di.id,
                    cie10Code: di.cie10Code,
                    eaccount: accountId,
                    userId: userId as number,
                    isMain: selectedDI.isMain,
                    sheetOrigin: currentSheet
                }));
            }
            dispatch(setSelectedDI(!!selectedDI.isMain));
        }
    };

    const onChangeStatusSelect = async (di: DIListItem, selectValue: string) => {
        const options = diList.map(item => {
            if (di.id === item.id && di.clhId === item.clhId) {
                return {
                    ...item, status: {
                        ...item.status,
                        tag: selectValue
                    }
                };
            }
            return item;
        });

        if (di.extDiagnosticId) {
            await dispatch(updateDI({
                appId: appointmentId,
                clhId: clinicalHistoryId,
                relClhId: di.id,
                eaccount: accountId,
                userId: userId as number,
                extDiagnosticId: di.extDiagnosticId,
                diagnosticStatus: selectValue,
                sheetOrigin: currentSheet
            }));
        } else {
            await dispatch(updateDI({
                appId: appointmentId,
                clhId: clinicalHistoryId,
                relClhId: di.id,
                cie10Code: di.cie10Code,
                eaccount: accountId,
                userId: userId as number,
                diagnosticStatus: selectValue,
                sheetOrigin: currentSheet
            }));
        }

        setDIList(options);
    };

    const renderDiagnosticHeader = () => {
        const formattedSelect = diSelectItems?.map(item => ({
            value: item.id ? item.id : item.cie10Code,
            label: item.cie10Code + " - " + item.description
        })) || [];
        const formattedEyes = eyeList?.map(item => ({
            value: item.id,
            label: item.name
        })) || [];
        const currentSelectValue = formattedSelect.find(item => item.value === diCreatePayload.codeId);

        const currentEyeValue = formattedEyes.find(item => item.value === diCreatePayload.dboId);

        return (
            <>
                <div className="d-flex align-items-center">
                    <RadioButton
                        label="IDX"
                        className="text-primary fw-bold"
                        value="cie10"
                        name="di"
                        id="cie10"
                        disabled={isDisabledForm}
                        checked={diCreatePayload.type === "cie10"}
                        onChange={({ target }) => onChangeDiagnostic(target.value as DISelectTypes)}
                    />
                    <RadioButton
                        label="IDX Ampliada"
                        className="text-primary fw-bold ms-4"
                        value="extendedDiagnostic"
                        name="di"
                        disabled={isDisabledForm}
                        id="extendedDiagnostic"
                        checked={diCreatePayload.type === "extendedDiagnostic"}
                        onChange={({ target }) => onChangeDiagnostic(target.value as DISelectTypes)}
                    />
                </div>

                <div className="w-100 d-flex align-items-end mb-3">
                    <Select
                        ref={selectRef}
                        variant="filled"
                        className="flex-fill"
                        width="100%"
                        isCleanable
                        isSearchable
                        value={currentSelectValue}
                        options={formattedSelect}
                        disabled={isDisabledForm}
                        onChange={({ option }) => onChangeDISelect(option.value)}
                        onType={(value) => setSelectSearch(value)}
                    />
                    <div>
                        <span className="text-primary fw-bold ms-5">Ojo</span>
                        <Select
                            className="ms-3"
                            style={{ width: 40 }}
                            disabled={isDisabledForm}
                            options={formattedEyes}
                            value={currentEyeValue}
                            onChange={({ option }) => onChangeEyeSelect(option.value)}
                        />
                    </div>
                    <div className="flex-fill"></div>
                    {!isDisabledForm &&
                        <IconCirclePlus
                            className="text-primary mb-1 pointer ms-3"
                            onClick={() => onCreateService()}
                        />
                    }
                </div>
            </>
        );
    };

    const renderTableRow = (di: DIListItem) => {
        const formattedStatus = statusSelect.map(item => ({
            label: item.description,
            value: item.value
        }));
        const statusValue = formattedStatus.find(item => item.value === di.status?.tag);
        const splittedUrl = location.pathname.split("/");
        const currentSheet = splittedUrl[splittedUrl.length - 1];

        return (
            <ScrollableTable.Row key={`${di.id}-${di.clhId}`}>
                <ScrollableTable.Cell className="fs-6" col={2}>
                    {di.date}
                </ScrollableTable.Cell>
                <ScrollableTable.Cell className="fs-6" col={1}>
                    {di.cie10Code}
                </ScrollableTable.Cell>
                <ScrollableTable.Cell className="fs-6" col={3}>
                    <div className="nowrap overflow-hidden text-ellipsis">
                        {di.extDiagnosticId ? (
                            <>
                                <span title={di.extDiagnosticDescription}>
                                    <span className="text-warning">A</span> / {di.extDiagnosticDescription}
                                </span>
                                <br />
                                <span className="nowrap" title={di.cie10Description}>
                                    <span className="text-secondary">10</span> / {di.cie10Description}
                                </span>
                            </>
                        ) : (
                            <span className="nowrap" title={di.cie10Description}>
                                {di.cie10Description}
                            </span>
                        )}
                    </div>
                </ScrollableTable.Cell>
                <ScrollableTable.Cell className="fs-6" col={1}>
                    {di.dbo?.name}
                </ScrollableTable.Cell>
                <ScrollableTable.Cell className="fs-6" col={1}>
                    {di.doctorInitials}
                </ScrollableTable.Cell>
                <ScrollableTable.Cell className="fs-6" col={2}>
                    <select
                        className={`form-select form-select-sm ${di.sheet !== currentSheet && "pointer"}`}
                        value={statusValue?.value}
                        disabled={di.sheet !== currentSheet || isDisabledForm}
                        onChange={({ target }) => onChangeStatusSelect(di, target.value)}
                    >
                        <option value={undefined}>Seleccionar...</option>
                        {formattedStatus.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </select>
                </ScrollableTable.Cell>
                <ScrollableTable.Cell align="center" className="fs-6" col={1}>
                    {di.clhId === clinicalHistoryId && (
                        <input
                            className="form-check-input border-primary"
                            type="radio"
                            disabled={isDisabledForm}
                            checked={di.isMain === 1}
                            name="dx"
                            onChange={() => onChangeDX(di)}
                            style={{ borderWidth: 2 }}
                        />
                    )}
                </ScrollableTable.Cell>
                <ScrollableTable.Cell className="fs-6" col={1}>
                    {di.canDelete ? (
                        <IconTrash
                            className="text-danger pointer"
                            size={18}
                            onClick={() => onDeleteService(di)}
                        />
                    ) : (
                        <div></div>
                    )}
                </ScrollableTable.Cell>
            </ScrollableTable.Row >
        );
    };

    const render = () => {
        const formattedTimes = diagnosticTimes.map(item => ({
            value: item.tim_id,
            label: item.tim_name
        }));
        const currentTimeValue = formattedTimes.find(item => item.value === selectedDX.evolutionTimeId);

        return (
            <SectionCard className="h-100">
                <h5 className="fw-bold text-secondary">Impresión diagnóstica</h5>
                <hr className="text-primary mt-2" />
                {renderDiagnosticHeader()}
                <ScrollableTable maxHeight={150}>
                    <ScrollableTable.Head>
                        <ScrollableTable.Cell className="fs-6" col={2}>
                            Fecha
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell className="fs-6 nowrap" col={1}>
                            CIE-10
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell className="fs-6 nowrap text-ellipsis overflow-hidden" col={3}>
                            Impresión diagnóstica
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell className="fs-6" col={1}>
                            Ojo
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell align="center" className="fs-6" col={1}>
                            MD
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell className="fs-6" col={2}>
                            Estado
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell className="fs-6" col={1}>
                            DX
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell className="fs-6" col={1}>
                        </ScrollableTable.Cell>
                    </ScrollableTable.Head>

                    <ScrollableTable.Body>
                        {diList.length ? diList.map((di) => renderTableRow(di)) : (
                            <ScrollableTable.Row>
                                <ScrollableTable.Cell col={12} align="center">No se encontraron registros.</ScrollableTable.Cell>
                            </ScrollableTable.Row>
                        )}
                    </ScrollableTable.Body>
                </ScrollableTable>
                <div className="d-flex align-items-center mt-3">
                    <div className="d-flex align-items-center flex-wrap w-100">
                        <span className="text-primary fw-bold me-3 nowrap">Tiempo de evolución DX</span>
                        <div className="input-group flex-nowrap w-50">
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Cantidad"
                                value={selectedDX.evolution}
                                onChange={({ target }) => {
                                    const option = { ...selectedDX, evolution: target.value };
                                    setSelectedDX(option);
                                    onSetEvolutionTime(option);
                                }}
                                style={{ maxWidth: 80, minWidth: 50 }}
                                disabled={(!selectedDX.isMain ? true : false) || isDisabledForm}
                            />
                            <Select
                                options={formattedTimes}
                                maxHeight={70}
                                inputGroup
                                value={currentTimeValue}
                                onChange={({ option }) => {
                                    const options = { ...selectedDX, evolutionTimeId: option.value };
                                    setSelectedDX(options);
                                    onSetEvolutionTime(options);
                                }}
                                style={{ width: "100%", maxWidth: 60 }}
                                disabled={(!selectedDX.isMain ? true : false) || isDisabledForm}
                            />
                        </div>
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}