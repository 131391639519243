import { Avatar, Badge, Select, Table, TextField } from "@/components";
import { TablePagination } from "@/components/TableAccordion/TableAcordion";
import routes from "@/constants/routes";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { StatusPayload } from "@/models/diagnosticAidReadings/diagnosticAidReadings";
import { Anesthesia, CommonSelect } from "@/models/sheets/orthoptic";
import { AppointmentSurgery, AttentionAppParams, AttentionAppointmentSurgeryResponse } from "@/models/surgery/patientAttention/patientAttention";
import { IconChevronRight, IconLock, IconLockOpen, IconSearch } from "@tabler/icons-react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isToday from "dayjs/plugin/isToday";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStatus } from "../../DiagnosticAidReadings/DiagnosticAidReadings/DiagnosticAidReadings.actions";
import { getAttentionAppointment, getSitesSurgery, getSurgeons, getSurgeryRooms, startSurgeryAttention } from "./patientAttentionSurgery.action";


type PreoperativeTabs =
    ""
    | "income"
    | "preparation"
    | "surgery"
    | "recovery";

const PatientAttentionSurgery = () => {
    dayjs.extend(isToday);
    dayjs.extend(customParseFormat);
    const workspace = useAppSelector(state => state.workspace);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState<PreoperativeTabs>("");
    const [appointmentList, setAppointmentList] = useState<AttentionAppointmentSurgeryResponse>();
    const [doctors, setDoctors] = useState<Anesthesia[]>([]);
    const [status, setStatus] = useState<StatusPayload[]>([]);
    const [sites, setSites] = useState<CommonSelect[]>([]);
    const [surgeryRoom, setSurgeryRoom] = useState<CommonSelect[]>([]);
    const [trigger, setTrigger] = useState(0);
    const [filters, setFilters] = useState<AttentionAppParams>({
        // eaccount: workspace.id,
        date: dayjs().format("YYYY-MM-DD"),
        status: "",
        page: 1,
        perpage: 10,
        tab: "",
        site: undefined,
        qx: undefined,
        medId: undefined,
        search: "",

    });


    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getAttentionAppointment(filters));
            if (data) {
                setAppointmentList(data as AttentionAppointmentSurgeryResponse);
            }
        }
        fetchData(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, workspace.id, workspace.profile, filters.tab, trigger]);


    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getSurgeons());
            setDoctors(data as Anesthesia[]);
            const dataSurgeryRoom = await dispatch(getSurgeryRooms());
            setSurgeryRoom(dataSurgeryRoom as CommonSelect[]);
            const dataSite = await dispatch(getSitesSurgery({
                eaccount: workspace.id,
                status: "enabled"
            }));
            setSites(dataSite as CommonSelect[]);
            const dataStatus = await dispatch(getStatus({ eaccount: workspace.id, view: "surgery_clinic_history" }));
            setStatus(dataStatus as StatusPayload[]);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [dispatch, workspace.id,]);
    const handleNextPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);
    };
    const handlePrevPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);

    };
    const handleMaxPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);

    };
    const handleMinPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);

    };

    const tabItems: { tag: PreoperativeTabs; label: string; }[] = [
        { tag: "", label: "Todos" },
        { tag: "income", label: "Ingreso" },
        { tag: "preparation", label: "Preparación" },
        { tag: "surgery", label: "Quirófano" },
        { tag: "recovery", label: "Recuperación" },
    ];

    const onChangeTab = (tab: PreoperativeTabs) => {
        setCurrentTab(tab);
        setFilters({ ...filters, tab });

    };

    const handleSelectChange = (value: number | string, name: string) => {
        setFilters({ ...filters, [name]: value, page: 1 });
        if (name !== "search" && name !== "date") {
            setTrigger(trigger + 1);
        }
    };

    const validateAttention = (appointment: AppointmentSurgery) => {
        async function fetchData() {
            const data = await dispatch(startSurgeryAttention(appointment.appId));
            if (data.success) {
                navigate(routes.workspaceSurgeryAttention,
                    { state: { tab: appointment.status.value, data: appointment, startAttentionInfo: data.results } });
            }
        }
        fetchData();
    };

    const renderFilters = () => {
        const formattedDoctors = doctors.map(doc => ({
            value: doc.id,
            label: doc.name
        }));
        const formattedSites = sites.map(site => ({
            value: site.id,
            label: site.description
        }));

        const formattedStatus = status.map(doc => ({
            value: doc.id,
            label: doc.description
        }));
        const formattedSurgeryRoom = surgeryRoom.map(doc => ({
            value: doc.id,
            label: doc.description
        }));
        return (
            <div className="d-flex align-items-end my-2">
                <TextField
                    placeholder="Escribe aquí para buscar"
                    variant="filled"
                    endIcon={
                        <IconSearch className="pointer" onClick={() => setTrigger(trigger + 1)} />
                    }
                    className="flex-grow-1 me-4"
                    name="search"
                    onChange={(e) => { handleSelectChange(e.target.value, "search"); }}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            setTrigger(trigger + 1);
                        }
                    }}
                />
                <div className="me-3" style={{ width: "12%" }}>
                    <label htmlFor="site" className="text-label">{"Sede"}</label>
                    <Select
                        isSearchable
                        placeholder="Seleccionar..."
                        emptyOptionsLabel="No hay opciones disponibles."
                        options={formattedSites}
                        name="site"
                        onChange={({ option, name }) => handleSelectChange(option.value, name)}
                    />
                </div>
                <div className="me-3" style={{ width: "12%" }}>
                    <label htmlFor="qx" className="text-label">{"Quirófano"}</label>
                    <Select
                        isSearchable
                        placeholder="Seleccionar..."
                        emptyOptionsLabel="No hay opciones disponibles."
                        options={formattedSurgeryRoom}
                        name="qx"
                        onChange={({ option, name }) => handleSelectChange(option.value, name)}
                    />
                </div>
                <div className="me-3" style={{ width: "12%" }}>
                    <label htmlFor="medId" className="text-label">{"Cirujano"}</label>
                    <Select
                        isSearchable
                        placeholder="Seleccionar..."
                        emptyOptionsLabel="No hay opciones disponibles."
                        options={formattedDoctors}
                        name="medId"
                        onChange={({ option, name }) => handleSelectChange(option.value, name)}
                    />
                </div>
                <div className="me-3">
                    <label className="text-label">Fecha</label>
                    <TextField
                        type="date"
                        value={filters.date}
                        onChange={(e) => { handleSelectChange(e.target.value, "date"); }}
                        name="appDate"
                        placeholder="DD/MM/AAAA"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                setTrigger(trigger + 1);
                            }
                        }}
                        style={{ color: "#999" }}
                    />
                </div>
                {
                    currentTab === "" &&
                    <div className="me-3" style={{ width: "12%" }}>
                        <label htmlFor="status" className="text-label">{"Estado"}</label>
                        <Select
                            isSearchable
                            placeholder="Seleccionar..."
                            emptyOptionsLabel="No hay opciones disponibles."
                            options={formattedStatus}
                            name="status"
                            onChange={({ option, name }) => handleSelectChange(option.value, name)}
                        />
                    </div>
                }

            </div>
        );
    };
    const renderTable = () => {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Paciente</th>
                        <th>Tipo de servicio</th>
                        <th className="text-center">Hora Prog.</th>
                        <th className="text-center">Hora admisión</th>
                        <th>Cirujano</th>
                        <th>Tipo cirugía</th>
                        <th>Aseguradora</th>
                        <th>Contrato</th>
                        <th className="text-center">Qx</th>
                        <th>Anestesia</th>
                        <th className="text-center">Estado</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {appointmentList?.results?.length ? appointmentList.results.map(appointment => renderRow(appointment)) : (
                        <tr >
                            <td colSpan={12} align="center" style={{ padding: "15px 0" }}>
                                No hay citas disponibles para la fecha seleccionada.
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    };



    const renderRow = (appointment: AppointmentSurgery) => {
        const getCurrentTime = (time: string) => {
            const currentTime = new Date();
            if (time) {
                const [hour, minutes, seconds] = time.split(":");
                const timeSend = new Date();
                timeSend.setHours(parseInt(hour), parseInt(minutes), parseInt(seconds));
                const diferenciaTiempo = currentTime.getTime() - timeSend.getTime();

                return Math.abs(diferenciaTiempo / (1000 * 60));
            } else {
                return 0;
            }
        };
        const currentTime = dayjs();
        const startAppointment = appointment?.admissionTime ? dayjs(appointment?.admissionTime) : null;
        let delayTime: string | null = null;

        if (appointment.status.value === "admitted" && currentTime.isAfter(startAppointment)) {
            if (startAppointment) {
                delayTime = startAppointment.fromNow(true);
            }
        }
        const validStatus: string[] = ["admitted", "income", "joined", "in_preparation", "prepared", "in_surgery", "intervened", "in_recovery", "failed"];
        const validStatusTime: string[] = ["admitted", "joined", "prepared", "intervened"];
        return (
            <tr
                key={appointment.appId}
            >
                <td width={200}>
                    <div className="d-flex align-items-center justify-content-start">
                        <Avatar src={appointment.patient.photo ? appointment.patient.photo : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"} className="me-3" />
                        <div
                            className="d-flex flex-column nowrap"
                            data-tooltip-id="my-tooltip"
                        >
                            <span className="fw-bold">{appointment.patient.fullName}</span>
                            <span className="text-muted">{appointment.patient.documentType} {appointment.patient.document}</span>
                        </div>
                    </div>
                </td>
                <td
                    width={200}
                >
                    <div className="d-flex">
                        {(appointment?.services && appointment?.services?.length > 0) && appointment?.services?.map((service, ind) => {
                            if (ind === 0) {
                                return (
                                    <div className="p-2">
                                        <Badge style={{
                                            backgroundColor: "#00b4cc20",
                                            color: "#00b4cc"
                                        }}>
                                            {service}
                                        </Badge>
                                    </div>
                                );
                            }
                            return <div></div>;
                        })}

                        {appointment?.services?.length > 1 &&
                            <div className="p-2">
                                <Badge
                                    title={appointment?.services?.join(", ")}
                                    style={{
                                        backgroundColor: "#00b4cc20",
                                        color: "#00b4cc"
                                    }}>
                                    ...
                                </Badge>
                            </div>
                        }

                    </div>

                </td>
                <td width={130}
                    className="text-center"
                ><span className="fw-bold text-center"> {appointment.scheduledTime}</span></td>
                <td>
                    <div className="d-flex flex-column justify-content-center nowrap">
                        <span className="fw-bold">{startAppointment ? startAppointment.format("hh:mm a") : "-"}</span>
                        <span className="text-muted">{delayTime && delayTime + " en espera"}</span>
                    </div>
                </td>
                <td
                    className="overflow-hidden text-ellipsis"
                    style={{ maxWidth: 130 }}
                    title={appointment.medName}
                >
                    <span className="fw-bold">{appointment.medName ?? "-"}</span>
                </td>
                <td
                    width={130}
                ><span className="fw-bold">{appointment.surgeryType ?? "-"}</span></td>

                <td
                    className="overflow-hidden text-ellipsis"
                    style={{ maxWidth: 100 }}
                    title={appointment.cclientName}
                >
                    <span className="fw-bold">{appointment.cclientName ?? "-"}</span>
                </td>
                <td
                    className="overflow-hidden text-ellipsis"
                    style={{ maxWidth: 100 }}
                    title={appointment.contractName}
                >
                    <span className="fw-bold">{appointment.contractName ?? "-"}</span>
                </td>
                <td width={100} className="text-center fw-bold"
                >{appointment.qx ?? "-"}</td>
                <td><span className="fw-bold">{appointment.anesthesia ?? "-"}</span></td>
                <td className="text-center align-items-center justify-content-center nowrap align-self-center">
                    <div className="d-flex flex-column align align-items-center">
                        <Badge style={{
                            backgroundColor: appointment.status.bgColor || "#E8E8EA",
                            color: appointment.status.fontColor || "#6E6F7C"
                        }}>
                            {appointment.status.name}
                        </Badge>
                        {(appointment.lastStatusTime && (dayjs(appointment.scheduledDate, "DD/MM/YYYY").isToday()) && (validStatusTime.includes(appointment.status.value))) &&
                            <span className="text-muted">{getCurrentTime(appointment.lastStatusTime).toFixed(0) + "m en espera"}</span>
                        }
                    </div>
                </td>
                <td>
                    {(dayjs(appointment.scheduledDate, "DD/MM/YYYY").isToday())
                        ? (validStatus.includes(appointment.status.value))
                            ? <IconLockOpen className="text-success" />
                            : <IconLock />
                        : <IconLock />}
                </td>
                <td className="text-primary pointer"

                >
                    {(dayjs(appointment.scheduledDate, "DD/MM/YYYY")?.isToday())
                        ? (validStatus.includes(appointment.status.value)) &&
                        <div className="cursor"
                            onClick={() =>
                                validateAttention(appointment)
                            }
                        >
                            <IconChevronRight />
                        </div> :
                        <div></div>
                    }
                </td>
            </tr >
        );
    };



    const render = () => {
        return (
            <section className="px-5 pt-5 d-flex flex-column w-100 h-100 overflow-auto">
                <h1 className="text-secondary fw-bold mb-2 display-5">Atención de pacientes</h1>  <div className="d-flex ">
                    <div className="">
                        <ul className="nav nav-tabs">
                            {tabItems.map(tab => (
                                <li key={tab.tag}
                                    style={{ width: "180px" }}
                                    className="nav-item pointer" onClick={() => onChangeTab(tab.tag)}>
                                    <div className={`nav-link text-center ${tab.tag === currentTab ? "active" : ""}`}>{tab.label}</div>
                                </li>
                            ))}
                        </ul>

                    </div>
                </div>
                {renderFilters()}
                <div className="d-flex flex-column flex-grow-1 pt-4">
                    <div className="table-responsive">
                        {renderTable()}
                    </div>
                    <TablePagination
                        totalPages={appointmentList?.rowTotal as number}
                        perPage={10}
                        currentPage={filters.page as number}
                        totalCount={appointmentList?.results?.length as number}
                        onNextPage={(value) => handleNextPage(value as number)}
                        onPrevPage={(value) => handlePrevPage(value as number)}
                        onMaxPage={(value) => handleMaxPage(value as number)}
                        onMinPage={(value) => handleMinPage(value as number)}
                    />
                </div>
            </section>
        );
    };
    return render();
};

export default PatientAttentionSurgery;