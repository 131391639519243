import { useEffect, useState } from "react";
import { SectionCard, Select } from "@/components";
import { CommonSelect, OrthopticPayload } from "@/models/sheets/orthoptic";
import { getStereopsisListService } from "@/services/sheets/orthoptic";

interface StereopsisParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
}

export default function         Stereopsis({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm }: StereopsisParams) {
    const [stereopsisList, setStereopsisList] = useState<CommonSelect[]>([]);

    useEffect(() => {
        async function fetchData() {
            const response = await getStereopsisListService();
            if (response.results) setStereopsisList(response.results);
        }
        fetchData();
    }, []);

    const onChangeStereopsis = (value: number) => {
        const options: typeof orthopticPayload = {
            ...orthopticPayload,
            stereopsis: {
                ...orthopticPayload.stereopsis,
                id: value
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const onChangeObservations = (value: string) => {
        const options: typeof orthopticPayload = {
            ...orthopticPayload,
            stereopsis: {
                ...orthopticPayload.stereopsis,
                observations: value
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const render = () => {
        const formattedStereopsisList = stereopsisList.map(
            item => ({ label: item.description, value: item.id })
        );
        const stereopsisListValue = formattedStereopsisList.find(
            item => item.value === orthopticPayload.stereopsis?.id
        );

        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold align-self-start">
                    Estereopsis
                </h5>
                <hr className="text-primary mt-2 mb-4" />
                <div className=" h-100">
                    <div className="">
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="me-3 text-secondary">Test</span>
                            <Select
                                disabled={isDisabledForm}
                                value={stereopsisListValue}
                                options={formattedStereopsisList}
                                onChange={({ option }) => onChangeStereopsis(option.value)}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                    <div className="mt-3 ">
                        <textarea
                            disabled={isDisabledForm}
                            className="form-control no-resize"
                            value={orthopticPayload.stereopsis?.observations}
                            onChange={({ target }) => onChangeObservations(target.value)}
                            placeholder="Observaciones..."
                            style={{height: "150px"}}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}