import {
    BackgroundMedical,
    Cie10EXTDiagnostic,
    DigestiveProblems,
    HeartProblems,
    HematologicalProblems,
    LaboratoryExams,
    LungProblems,
    MetabolicProblems,
    MusculoskeletalProblems,
    NeurologicalProblems,
    PhysicalExam,
    PreoperativeDetail,
    SuitableForSurgery,
    UrinaryProblems
} from "@/models/historyDetails/preoperative";
import { formatHistoryDetails } from "@/utils";
import "../Details.scss";

export default function PreoperativeDetails({ preoperativeDetails, forDownload }: { preoperativeDetails: PreoperativeDetail; forDownload?: boolean; }) {

    const renderBackgroundMedical = (backgroundMedical: BackgroundMedical | null) => {
        if (backgroundMedical) {
            const prevSurgeries = Object.values(backgroundMedical.previousSurgeries || {});

            const currentCondition = (
                prevSurgeries.length ||
                backgroundMedical.familyMemberProblemsAnesthesia ||
                backgroundMedical.smoker ||
                backgroundMedical.drinkAlcohol ||
                backgroundMedical.dentalProthesis ||
                backgroundMedical.contactLenses ||
                backgroundMedical.possiblePregnancy);

            const heartProblems = formatHistoryDetails(backgroundMedical.heartProblems) as HeartProblems;
            const lungProblems = formatHistoryDetails(backgroundMedical.lungProblems) as LungProblems;
            const digestiveProblems = formatHistoryDetails(backgroundMedical.digestiveProblems) as DigestiveProblems;
            const hematologicalProblems = formatHistoryDetails(backgroundMedical.hematologicalProblems) as HematologicalProblems;
            const musculoskeletalProblems = formatHistoryDetails(backgroundMedical.musculoskeletalProblems) as MusculoskeletalProblems;
            const neurologicalProblems = formatHistoryDetails(backgroundMedical.neurologicalProblems) as NeurologicalProblems;
            const metabolicProblems = formatHistoryDetails(backgroundMedical.metabolicProblems) as MetabolicProblems;
            const urinaryProblems = formatHistoryDetails(backgroundMedical.urinaryProblems) as UrinaryProblems;

            return (
                <>
                    <h4 className="text-primary fw-bold mb-2 mt-4">
                        Antecedentes médicos
                    </h4>
                    {currentCondition ? (
                        <div className="item py-1">
                            <h5>
                                <span className="fw-bold">Condición actual</span>
                            </h5>
                            <ul className="fs-5 mb-3">
                                {prevSurgeries?.length ? (
                                    <li>
                                        <span>Historial de anestesias</span>
                                        <ul>
                                            {prevSurgeries.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))}
                                        </ul>
                                    </li>
                                ) : ""}
                                {backgroundMedical.problemsAnesthetic ? (
                                    <li>{backgroundMedical.problemsAnesthetic}</li>
                                ) : ""}
                                {backgroundMedical.aspirinLastWeek ? (
                                    <li>{backgroundMedical.aspirinLastWeek}</li>
                                ) : ""}
                                {backgroundMedical.seriousIllness ? (
                                    <li>{backgroundMedical.seriousIllness}</li>
                                ) : ""}
                                {backgroundMedical.familyMemberProblemsAnesthesia ? (
                                    <li>{backgroundMedical.familyMemberProblemsAnesthesia}</li>
                                ) : ""}
                                {backgroundMedical.smoker ? (
                                    <li>{backgroundMedical.smoker}</li>
                                ) : ""}
                                {backgroundMedical.drinkAlcohol ? (
                                    <li>{backgroundMedical.drinkAlcohol}</li>
                                ) : ""}
                                {backgroundMedical.dentalProthesis ? (
                                    <li>{backgroundMedical.dentalProthesis}</li>
                                ) : ""}
                                {backgroundMedical.contactLenses ? (
                                    <li>{backgroundMedical.contactLenses}</li>
                                ) : ""}
                                {backgroundMedical.possiblePregnancy ? (
                                    <li>{backgroundMedical.possiblePregnancy}</li>
                                ) : ""}
                                {backgroundMedical.allergies ? (
                                    <li>{backgroundMedical.allergies}</li>
                                ) : ""}
                            </ul>
                        </div>
                    ) : ""}

                    {heartProblems ? (
                        <div className="item py-1">
                            <h5 className="mb-2">
                                <span className="fw-bold">Problemas cardiacos</span>
                            </h5>
                            <ul className="fs-5">
                                {heartProblems.lowBloodPressure ? (
                                    <li>{heartProblems.lowBloodPressure}</li>
                                ) : ""}
                                {heartProblems.highBloodPressure ? (
                                    <li>{heartProblems.highBloodPressure}</li>
                                ) : ""}
                                {heartProblems.myocardialInfarction ? (
                                    <li>{heartProblems.myocardialInfarction}</li>
                                ) : ""}
                                {heartProblems.murmurs ? (
                                    <li>{heartProblems.murmurs}</li>
                                ) : ""}
                                {heartProblems.chestPain ? (
                                    <li>{heartProblems.chestPain}</li>
                                ) : ""}
                                {heartProblems.arrhythmias ? (
                                    <li>{heartProblems.arrhythmias}</li>
                                ) : ""}
                                {heartProblems.ankleSwelling ? (
                                    <li>{heartProblems.ankleSwelling}</li>
                                ) : ""}
                                {heartProblems.difficultyBreathing_night ? (
                                    <li>{heartProblems.difficultyBreathing_night}</li>
                                ) : ""}
                            </ul>
                            {heartProblems.otherHeart ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {heartProblems.otherHeart}
                                </h5>
                            ) : ""}
                            {heartProblems.medicationsDoTake ? (
                                <h5>
                                    <span className="fw-bold">Comentarios: </span>
                                    {heartProblems.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}

                    {lungProblems ? (
                        <div className="item py-1">
                            <h5 className="mb-2">
                                <span className="fw-bold">Problemas pulmonares</span>
                            </h5>
                            <ul className="fs-5">
                                {lungProblems.asthma ? (
                                    <li>{lungProblems.asthma}</li>
                                ) : ""}
                                {lungProblems.bronchitis ? (
                                    <li>{lungProblems.bronchitis}</li>
                                ) : ""}
                                {lungProblems.frequent_cough ? (
                                    <li>{lungProblems.frequent_cough}</li>
                                ) : ""}
                            </ul>
                            {lungProblems.lungOther ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {lungProblems.lungOther}
                                </h5>
                            ) : ""}
                            {lungProblems.medicationsDoTake ? (
                                <h5>
                                    <span className="fw-bold">Comentarios: </span>
                                    {lungProblems.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}

                    {digestiveProblems ? (
                        <div className="item py-1">
                            <h5 className=" mb-2">
                                <span className="fw-bold">Problemas digestivo</span>
                            </h5>
                            <ul className=" fs-5">
                                {digestiveProblems.ulcer ? (
                                    <li>{digestiveProblems.ulcer}</li>
                                ) : ""}
                                {digestiveProblems.chronicDiarrhea ? (
                                    <li>{digestiveProblems.chronicDiarrhea}</li>
                                ) : ""}
                                {digestiveProblems.hepatitis ? (
                                    <li>{digestiveProblems.hepatitis}</li>
                                ) : ""}
                            </ul>
                            {digestiveProblems.digestiveOther ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {digestiveProblems.digestiveOther}
                                </h5>
                            ) : ""}
                            {digestiveProblems.medicationsDoTake ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Comentarios: </span>
                                    {digestiveProblems.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}
                    {hematologicalProblems ? (
                        <div className="item py-1">
                            <h5 className=" mb-2">
                                <span className="fw-bold">Problemas hematológicos</span>
                            </h5>
                            <ul className=" fs-5">
                                {hematologicalProblems.anemias ? (
                                    <li>{hematologicalProblems.anemias}</li>
                                ) : ""}
                                {hematologicalProblems.excessiveBleeding ? (
                                    <li>{hematologicalProblems.excessiveBleeding}</li>
                                ) : ""}
                            </ul>
                            {hematologicalProblems.hematologicalOther ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {hematologicalProblems.hematologicalOther}
                                </h5>
                            ) : ""}
                            {hematologicalProblems.medicationsDoTake ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Comentarios: </span>
                                    {hematologicalProblems.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}

                    {musculoskeletalProblems ? (
                        <div className="item py-1">
                            <h5 className=" mb-2">
                                <span className="fw-bold">Problemas musculo-esqueléticos</span>
                            </h5>
                            <ul className=" fs-5">
                                {musculoskeletalProblems.arthritis ? (
                                    <li>{musculoskeletalProblems.arthritis}</li>
                                ) : ""}
                                {musculoskeletalProblems.muscularWeakness ? (
                                    <li>{musculoskeletalProblems.muscularWeakness}</li>
                                ) : ""}
                                {musculoskeletalProblems.frequentCramps ? (
                                    <li>{musculoskeletalProblems.frequentCramps}</li>
                                ) : ""}
                            </ul>
                            {musculoskeletalProblems.musculoskeletalOther ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {musculoskeletalProblems.musculoskeletalOther}
                                </h5>
                            ) : ""}
                            {musculoskeletalProblems.medicationsDoTake ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Comentarios: </span>
                                    {musculoskeletalProblems.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}


                    {neurologicalProblems ? (
                        <div className="item py-1">
                            <h5 className="mb-2">
                                <span className="fw-bold">Problemas neurológicos</span>
                            </h5>
                            <ul className="fs-5">
                                {neurologicalProblems.epilepsies ? (
                                    <li>{neurologicalProblems.epilepsies}</li>
                                ) : ""}
                                {neurologicalProblems.strongBlowsSkull ? (
                                    <li>{neurologicalProblems.strongBlowsSkull}</li>
                                ) : ""}
                                {neurologicalProblems.lossConsciousness ? (
                                    <li>{neurologicalProblems.lossConsciousness}</li>
                                ) : ""}
                                {neurologicalProblems.faintsEasily ? (
                                    <li>{neurologicalProblems.faintsEasily}</li>
                                ) : ""}
                            </ul>
                            {neurologicalProblems.neurologicalOther ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {neurologicalProblems.neurologicalOther}
                                </h5>
                            ) : ""}
                            {neurologicalProblems.medicationsDoTake ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Comentarios: </span>
                                    {neurologicalProblems.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}

                    {metabolicProblems ? (
                        <div className="item py-1">
                            <h5 className=" mb-2">
                                <span className="fw-bold">Problemas metabólicos</span>
                            </h5>
                            <ul className=" fs-5">
                                {metabolicProblems.diabetes ? (
                                    <li>{metabolicProblems.diabetes}</li>
                                ) : ""}
                                {metabolicProblems.thyroidDiseases ? (
                                    <li>{metabolicProblems.thyroidDiseases}</li>
                                ) : ""}
                            </ul>
                            {metabolicProblems.metabolicOther ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {metabolicProblems.metabolicOther}
                                </h5>
                            ) : ""}
                            {metabolicProblems.medicationsDoTake ? (
                                <h5>
                                    <span className="fw-bold">Comentarios: </span>
                                    {metabolicProblems.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}

                    {urinaryProblems ? (
                        <div className="item py-1">
                            <h5 className="mb-2">
                                <span className="fw-bold">Problemas urinarios</span>
                            </h5>
                            <ul className=" fs-5">
                                {urinaryProblems.whichText ? (
                                    <li>{urinaryProblems.whichText}</li>
                                ) : ""}
                            </ul>
                            {urinaryProblems.medicationsDoTake ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Comentarios: </span>
                                    {urinaryProblems.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}
                </>
            );
        } else return "";
    };

    const renderLaboratoryExams = (laboratoryExams: LaboratoryExams | null) => {
        if (laboratoryExams) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold">
                        Exámenes de laboratorio
                    </h4>
                    {laboratoryExams.hemoglobin ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Hemoglobina: </span>{" "}
                            {laboratoryExams.hemoglobin}
                        </h5>
                    ) : ""}
                    {laboratoryExams.hematocrit ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Hematocrito: </span>{" "}
                            {laboratoryExams.hematocrit}
                        </h5>
                    ) : ""}
                    {laboratoryExams.leukocytes ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Leucocitos: </span>{" "}
                            {laboratoryExams.leukocytes}
                        </h5>
                    ) : ""}
                    {laboratoryExams.platelets ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Plaquetas: </span>{" "}
                            {laboratoryExams.platelets}
                        </h5>
                    ) : ""}
                    {laboratoryExams.thromboplastin ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">T. Tromboplastina: </span>{" "}
                            {laboratoryExams.thromboplastin}
                        </h5>
                    ) : ""}
                    {laboratoryExams.prothrombin ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">T. Protombina: </span>{" "}
                            {laboratoryExams.prothrombin}
                        </h5>
                    ) : ""}
                    {laboratoryExams.glycemia ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Glicemia: </span>{" "}
                            {laboratoryExams.glycemia}
                        </h5>
                    ) : ""}
                    {laboratoryExams.creatinine ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Creatinina: </span>{" "}
                            {laboratoryExams.creatinine}
                        </h5>
                    ) : ""}
                    {laboratoryExams.tsh ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">TSH: </span>{" "}
                            {laboratoryExams.tsh}
                        </h5>
                    ) : ""}
                    {laboratoryExams.thyroid ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Tiroides: </span>{" "}
                            {laboratoryExams.thyroid}
                        </h5>
                    ) : ""}
                    {laboratoryExams.ekg ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">EKG: </span>{" "}
                            {laboratoryExams.ekg}
                        </h5>
                    ) : ""}
                    {laboratoryExams.other ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Otro: </span>{" "}
                            {laboratoryExams.other}
                        </h5>
                    ) : ""}
                    {laboratoryExams.value ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Valor: </span>{" "}
                            {laboratoryExams.value}
                        </h5>
                    ) : ""}
                </div>
            );
        }
    };

    const renderPhysicalExams = (physicalExams: PhysicalExam) => {
        if (physicalExams) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Exámenes físico
                    </h4>
                    {physicalExams.cardiovascular ? (
                        <h5 className=" ">
                            <span className="fw-bold">Cardiovascular: </span>{" "}
                            {physicalExams.cardiovascular}
                        </h5>
                    ) : ""}
                    {(physicalExams.cardiovascular && physicalExams.pulmonary) ? (
                        <hr className="my-3" />
                    ) : ""}
                    {physicalExams.pulmonary ? (
                        <h5 className=" ">
                            <span className="fw-bold">Pulmonar: </span>{" "}
                            {physicalExams.pulmonary}
                        </h5>
                    ) : ""}
                    {(physicalExams.pulmonary && physicalExams.extremities) ? (
                        <hr className="my-3" />
                    ) : ""}
                    {physicalExams.extremities ? (
                        <h5 className=" ">
                            <span className="fw-bold">Extremidades: </span>{" "}
                            {physicalExams.extremities}
                        </h5>
                    ) : ""}
                    {(physicalExams.extremities && physicalExams.centralNervousSystem) ? (
                        <hr className="my-3" />
                    ) : ""}
                    {physicalExams.centralNervousSystem ? (
                        <h5 className="">
                            <span className="fw-bold">Sistema nervioso central: </span>{" "}
                            {physicalExams.centralNervousSystem}
                        </h5>
                    ) : ""}
                    {(physicalExams.centralNervousSystem && physicalExams.other) ? (
                        <hr className="my-3" />
                    ) : ""}
                    {physicalExams.other ? (
                        <h5 className=" mb-3">
                            <span className="fw-bold">Otros: </span>{" "}
                            {physicalExams.other}
                        </h5>
                    ) : ""}
                </div>
            );
        }
    };

    const renderExtDiagnostic = (extDiagnostic: Cie10EXTDiagnostic | null) => {
        if (extDiagnostic && Object.values(extDiagnostic).length) {
            const mainExt = Object.values(extDiagnostic).find(item => item.isMain === 1);

            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Impresión diagnóstica
                    </h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-secondary">
                                <th>Fecha</th>
                                <th className="text-center">CIE-10</th>
                                <th>Impresión diagnóstica</th>
                                <th className="text-center">Ojo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(extDiagnostic).map((item) => (
                                <tr key={`${item.id}-${item.clhId}`} style={{ backgroundColor: item.isMain ? "#fcfae6" : undefined }}>
                                    <td className="align-middle">{item.date}</td>
                                    <td align="center" className="align-middle">{item.cie10Code}</td>
                                    <td style={{ whiteSpace: "pre-line" }}>{item.cie10Description}</td>
                                    <td className="align-middle" align="center">{item.dbo?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {mainExt ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>
                            {mainExt.evolution} {mainExt.evolutionTime?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderSuitableForSurgery = (suitableForSurgery: SuitableForSurgery | null) => {
        if (suitableForSurgery) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Decisión especialista
                    </h4>
                    {suitableForSurgery.anestheticRisk ? (
                        <h5 className=" mb-3">
                            <span className="fw-bold">Clasificación del riesgo anestésico: </span>{" "}
                            {suitableForSurgery.anestheticRisk}
                        </h5>
                    ) : ""}
                    {suitableForSurgery.suitable ? (
                        <h5 className=" mb-3">
                            <span className="fw-bold">{suitableForSurgery.suitable}: </span>{" "}
                            {suitableForSurgery.justification || ""}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const render = () => {
        const backgroundMedical = formatHistoryDetails(preoperativeDetails.backgroundMedical);
        const extDiagnostic = formatHistoryDetails(preoperativeDetails.cie10ExtDiagnostic);
        const laboratoryExams = formatHistoryDetails(preoperativeDetails.laboratoryExams);
        const physicalExams = formatHistoryDetails(preoperativeDetails.physicalExam);
        const suitableForSurgery = formatHistoryDetails(preoperativeDetails.suitableForSurgery);

        return (
            <div className="detail-container">
                <div className={`${!forDownload ? "columns" : "column"}`}>
                    {renderBackgroundMedical(backgroundMedical)}
                    {renderLaboratoryExams(laboratoryExams)}
                    {renderPhysicalExams(physicalExams)}
                    {renderSuitableForSurgery(suitableForSurgery)}
                    {renderExtDiagnostic(extDiagnostic)}
                </div>
            </div>
        );
    };

    return render();
}