import {
    AttentionOrigin,
    Bjective,
    Cie10EXTDiagnostic,
    OptometryDetails as IOptometryDetails,
    Keratometry,
    NasopupillaryDistance,
    PupillaryDistance,
    Pupillometry,
    VisualAcuity
} from "@/models/historyDetails/optometry";
import { formatHistoryDetails } from "@/utils";
import "../Details.scss";

export default function OptometryDetails({ optometryDetails, forDownload }: { optometryDetails: IOptometryDetails; forDownload?: boolean; }) {

    const renderAttentionOrigin = (attentionOrigin: AttentionOrigin | null) => {

        if (attentionOrigin) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Origen de atención
                    </h4>
                    {attentionOrigin.attentionOrigin !== null ? (
                        <h5>
                            <span className="fw-bold">Origen de la atención: </span>{" "}
                            {attentionOrigin.attentionOrigin}
                        </h5>
                    ) : ""}
                    {attentionOrigin.abserveEvent !== null ? (
                        <h5>
                            <span className="fw-bold">Evento adverso: </span>{" "}
                            {attentionOrigin.abserveEvent === 1 ? "SÍ" : "NO"}
                        </h5>
                    ) : ""}
                    {attentionOrigin?.attendingReason && Object.values(attentionOrigin.attendingReason).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Motivo consulta: {Object.values(attentionOrigin.attendingReason).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{attentionOrigin.attendingReasonJst || ""}</h5>
                        </>
                    ) : ""}
                    {attentionOrigin?.currentIllness && Object.values(attentionOrigin.currentIllness) ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Enfermedad actual: {Object.values(attentionOrigin.currentIllness).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{attentionOrigin.currentIllnessJst || ""}</h5>
                        </>
                    ) : ""}
                    {attentionOrigin.evolutionTime ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>{" "}
                            {attentionOrigin.evolutionTime}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderVisualAcuity = (visualAcuity: VisualAcuity | null) => {
        if (visualAcuity) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Agudeza visual
                    </h4>
                    <h5>
                        {visualAcuity.optotype ? (
                            <>
                                <span className="fw-bold">Optotipo: </span>{" "}
                                {visualAcuity.optotype}
                            </>
                        ) : ""}
                        {visualAcuity.optotype && visualAcuity.classification ? " - " : ""}
                        {visualAcuity.classification ? (
                            <>
                                <span className="fw-bold">Clasificación: </span>{" "}
                                {visualAcuity.classification}
                            </>
                        ) : ""}
                    </h5>
                    {visualAcuity.noCorrection ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                AV sin corrección
                            </h5>
                            <h5>
                                {visualAcuity.noCorrection.od ? (
                                    <>
                                        <span className="fw-bold">AV OD: </span>{" "}
                                        {visualAcuity.noCorrection?.od}
                                    </>
                                ) : ""}
                                {visualAcuity.noCorrection.od && visualAcuity.noCorrection.oi ? " / " : ""}
                                {visualAcuity.noCorrection.oi ? (
                                    <>
                                        <span className="fw-bold">AV OI: </span>{" "}
                                        {visualAcuity.noCorrection?.oi}
                                    </>
                                ) : ""}
                                {visualAcuity.noCorrection.oi && visualAcuity.noCorrection.ao ? " / " : ""}
                                {visualAcuity.noCorrection.ao ? (
                                    <>
                                        <span className="fw-bold">AV AO: </span>{" "}
                                        {visualAcuity.noCorrection?.ao}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}
                    {visualAcuity.correction ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                AV con corrección
                            </h5>
                            <h5>
                                {visualAcuity.correction.od ? (
                                    <>
                                        <span className="fw-bold">AV OD: </span>{" "}
                                        {visualAcuity.correction?.od}
                                    </>
                                ) : ""}
                                {visualAcuity.correction.od && visualAcuity.correction.oi ? " / " : ""}
                                {visualAcuity.correction.oi ? (
                                    <>
                                        <span className="fw-bold">AV OI: </span>{" "}
                                        {visualAcuity.correction?.oi}
                                    </>
                                ) : ""}
                                {visualAcuity.correction.oi && visualAcuity.correction.ao ? " / " : ""}
                                {visualAcuity.correction.ao ? (
                                    <>
                                        <span className="fw-bold">AV AO: </span>{" "}
                                        {visualAcuity.correction?.ao}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}

                    {visualAcuity.ph ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Pinhole (PH)
                            </h5>
                            <h5>
                                {visualAcuity.ph.od ? (
                                    <>
                                        <span className="fw-bold">AV OD: </span>{" "}
                                        {visualAcuity.ph?.od}
                                    </>
                                ) : ""}
                                {visualAcuity.ph.od && visualAcuity.ph.od ? " / " : ""}
                                {visualAcuity.ph.oi ? (
                                    <>
                                        <span className="fw-bold">AV OI: </span>{" "}
                                        {visualAcuity.ph?.oi}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}

                    {visualAcuity.lentsInUse ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Lentes en uso
                            </h5>
                            <h5>
                                {visualAcuity.lentsInUse.lentsOd ? (
                                    <>
                                        <span className="fw-bold">Lente OD: </span>{" "}
                                        {visualAcuity.lentsInUse?.lentsOd}
                                    </>
                                ) : ""}
                                {visualAcuity.lentsInUse.lentsOd && visualAcuity.lentsInUse.lentsOi ? " / " : ""}
                                {visualAcuity.lentsInUse.lentsOi ? (
                                    <>
                                        <span className="fw-bold">Lente OI: </span>{" "}
                                        {visualAcuity.lentsInUse?.lentsOi}
                                    </>
                                ) : ""}
                            </h5>
                            <h5>
                                {visualAcuity.lentsInUse.od ? (
                                    <>
                                        <span className="fw-bold">AV OD: </span>{" "}
                                        {visualAcuity.lentsInUse?.od}
                                    </>
                                ) : ""}
                                {visualAcuity.lentsInUse.od && visualAcuity.lentsInUse.oi ? " / " : ""}
                                {visualAcuity.lentsInUse.oi ? (
                                    <>
                                        <span className="fw-bold">AV OI: </span>{" "}
                                        {visualAcuity.lentsInUse?.oi}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderPupillometry = (pupillometry: Pupillometry) => {
        if (pupillometry && (pupillometry.pupillometryOd || pupillometry.pupillometryOi)) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Pupilometría
                    </h4>
                    {pupillometry.pupillometryOd ? (
                        <h5>
                            <span className="fw-bold">Pupilometría OD: </span>{pupillometry.pupillometryOd}
                        </h5>
                    ) : ""}
                    {pupillometry.pupillometryOi ? (
                        <h5>
                            <span className="fw-bold">Pupilometría OI: </span>{pupillometry.pupillometryOi}
                        </h5>
                    ) : ""}
                    {pupillometry.pupillometryObservations ? (
                        <h5>
                            <span className="fw-bold">Observaciones: </span>{" "}
                            {pupillometry.pupillometryObservations}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderRefraction = (objective: Bjective | null, subjective: Bjective | null) => {
        if (objective || subjective) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Refracción objetiva y subjetiva
                    </h4>
                    {objective ? (
                        <>
                            <h5 className="fw-bold">Refracción objetiva</h5>
                            <h5 className="text-muted"> <b>Cicloplejia:</b> {objective.cycloplegia === 1 ? "Sí" : "No"} </h5>

                            <h5>
                                {objective.od ? (
                                    <>
                                        <span className="fw-bold">OD: </span>{" "}
                                        <span>{objective.od}</span>
                                    </>
                                ) : ""}
                                {objective.od && objective.oi ? " / " : ""}
                                {objective.oi ? (
                                    <>
                                        <span className="fw-bold">OI: </span>{" "}
                                        <span>{objective.oi}</span>{" "}
                                    </>
                                ) : ""}
                            </h5>
                            {objective.observations ? (
                                <h5 className="mb-3">
                                    <span className="fw-bold">Observaciones: </span>{" "}
                                    {objective?.observations}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}

                    {subjective ? (
                        <>
                            <h5 className="fw-bold">Refracción subjetiva</h5>
                            <h5 className="text-muted"> <b>Cicloplejia:</b> {subjective.cycloplegia === 1 ? "Sí" : "No"} </h5> 
                            <h5>
                                {subjective.od ? (
                                    <>
                                        <span className="fw-bold">OD: </span>{" "}
                                        <span>{subjective?.od}</span>
                                    </>
                                ) : ""}
                                {subjective.od && subjective.oi ? " / " : ""}
                                {subjective.oi ? (
                                    <>
                                        <span className="fw-bold">OI: </span>{" "}
                                        <span>{subjective?.oi}</span>
                                    </>
                                ) : ""}
                            </h5>
                            {subjective.observations ? (
                                <h5>
                                    <span className="fw-bold">Observaciones: </span>{" "}
                                    {subjective?.observations}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderKeratometry = (keratometry: Keratometry | null) => {
        if (keratometry) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Queratometría
                    </h4>
                    {keratometry.od ? (
                        <>
                            <h5 className=" fw-bold">
                                Queratometría OD
                            </h5>
                            <h5>
                                {keratometry.od.k1 ? (
                                    <>
                                        <span className="fw-bold">K1: </span>{" "}
                                        {keratometry.od?.k1}
                                    </>
                                ) : ""}
                                {keratometry.od.k1 && keratometry.od.k2 ? " / " : ""}
                                {keratometry.od?.k2 ? (
                                    <>
                                        <span className="fw-bold">K2: </span>{" "}
                                        {keratometry.od?.k2}
                                    </>
                                ) : ""}
                                {keratometry.od.k2 && keratometry.od.astigmatism ? " / " : ""}
                                {keratometry.od.astigmatism ? (
                                    <>
                                        <span className="fw-bold">Astigmatismo: </span>{" "}
                                        {keratometry.od?.astigmatism}D
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}

                    {keratometry.oi ? (
                        <>
                            <h5 className=" fw-bold">
                                Queratometría OI
                            </h5>
                            <h5>
                                {keratometry.oi.k1 ? (
                                    <>
                                        <span className="fw-bold">K1: </span>{" "}
                                        {keratometry.oi?.k1}
                                    </>
                                ) : ""}
                                {keratometry.oi.k1 && keratometry.oi.k2 ? " / " : ""}
                                {keratometry.oi.k2 ? (
                                    <>
                                        <span className="fw-bold">K2: </span>{" "}
                                        {keratometry.oi?.k2}
                                    </>
                                ) : ""}
                                {keratometry.oi.k2 && keratometry.oi.astigmatism ? " / " : ""}
                                {keratometry.oi.astigmatism ? (
                                    <>
                                        <span className="fw-bold">Astigmatismo: </span>{" "}
                                        {keratometry.oi?.astigmatism}D
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}

                    {keratometry.observations ? (
                        <h5>
                            <span className="fw-bold">Observaciones: </span>
                            {keratometry.observations}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderDistances = (pupillaryDistance: PupillaryDistance | null, nasopupillaryDistance: NasopupillaryDistance | null) => {
        if (pupillaryDistance || nasopupillaryDistance) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Distancia pupilar y nasopupilar
                    </h4>
                    {pupillaryDistance ? (
                        <>
                            <h5 className=" fw-bold">
                                Distancia pupilar
                            </h5>
                            <h5>
                                {pupillaryDistance.vl ? (
                                    <>
                                        <span className="fw-bold">VL: </span>{" "}
                                        {pupillaryDistance.vl}
                                    </>
                                ) : ""}
                                {pupillaryDistance.vl && pupillaryDistance.vp ? " / " : ""}
                                {pupillaryDistance.vp ? (
                                    <>
                                        <span className="fw-bold">VP: </span>{" "}
                                        {pupillaryDistance.vp}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}

                    {nasopupillaryDistance ? (
                        <>
                            <h5 className=" fw-bold">
                                Distancia nasopupilar
                            </h5>
                            <h5>
                                {nasopupillaryDistance.od ? (
                                    <>
                                        <span className="fw-bold">OD: </span>{" "}
                                        {nasopupillaryDistance.od}
                                    </>
                                ) : ""}
                                {nasopupillaryDistance.od && nasopupillaryDistance.oi ? " / " : ""}
                                {nasopupillaryDistance.oi ? (
                                    <>
                                        <span className="fw-bold">OI: </span>{" "}
                                        {nasopupillaryDistance.oi}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderComments = (comments: string | null) => {
        if (comments) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Comentarios sobre motilidad
                    </h4>
                    <h5>
                        <span className="fw-bold">Observaciones: </span>
                        <span>{comments}</span>
                    </h5>
                </div>
            );
        } else {
            return "";
        }
    };

    const renderExtDiagnostic = (extDiagnostic: Cie10EXTDiagnostic | null) => {
        if (extDiagnostic && Object.values(extDiagnostic).length) {
            const mainExt = Object.values(extDiagnostic).find(item => item.isMain === 1);

            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Impresión diagnóstica
                    </h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-secondary">
                                <th>Fecha</th>
                                <th className="text-center">CIE-10</th>
                                <th>Impresión diagnóstica</th>
                                <th className="text-center">Ojo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(extDiagnostic).map((item) => (
                                <tr key={`${item.id}-${item.clhId}`} style={{ backgroundColor: item.isMain ? "#fcfae6" : undefined }}>
                                    <td className="align-middle">{item.date}</td>
                                    <td className="align-middle" align="center">{item.cie10Code}</td>
                                    <td style={{ whiteSpace: "pre-line" }}>{item.cie10Description}</td>
                                    <td className="align-middle" align="center">{item.dbo?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {mainExt ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>
                            {mainExt.evolution} {mainExt.evolutionTime?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderAnalysisAndPlan = (analysisPlan: string | null) => {
        if (analysisPlan) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Análisis y plan
                    </h4>
                    <h5>{analysisPlan}</h5>
                </div>
            );
        } else {
            return "";
        }
    };

    const render = () => {
        const attentionOrigin = formatHistoryDetails(optometryDetails.attentionOrigin);
        const visualAcuity = formatHistoryDetails(optometryDetails.visualAcuity);
        const pupillometry = formatHistoryDetails(optometryDetails.pupillometry);
        const objective = formatHistoryDetails(optometryDetails.objective);
        const subjective = formatHistoryDetails(optometryDetails.subjective);
        const keratometry = formatHistoryDetails(optometryDetails.keratometry);
        const pupillaryDistance = formatHistoryDetails(optometryDetails.pupillaryDistance);
        const nasopupillaryDistance = formatHistoryDetails(optometryDetails.nasopupillaryDistance);
        const comments = formatHistoryDetails(optometryDetails.commentOnMotility);
        const extDiagnostic = formatHistoryDetails(optometryDetails.cie10ExtDiagnostic);
        const analysisPlan = formatHistoryDetails(optometryDetails.analysisPlan);

        return (
            <div className="detail-container">
                <div className={`${!forDownload ? "columns" : "column"}`}>
                    {renderAttentionOrigin(attentionOrigin)}
                    {renderVisualAcuity(visualAcuity)}
                    {renderPupillometry(pupillometry)}
                    {renderRefraction(objective, subjective)}
                    {renderKeratometry(keratometry)}
                    {renderDistances(pupillaryDistance, nasopupillaryDistance)}
                    {renderComments(comments)}
                    {renderExtDiagnostic(extDiagnostic)}
                    {renderAnalysisAndPlan(analysisPlan)}
                </div>
            </div>
        );
    };

    return render();
}