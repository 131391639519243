// React
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
//Css
import "@/components/Loader/Loader.scss";
import "react-day-picker/dist/style.css";
import "./History.scss";

//Const
import { listHistorySegments } from "@/constants/selects";

//Hooks
import { useAppDispatch, useAppSelector, useGetLastPathName } from "@/hooks";
import {
    getAHAdaptation,
    getAHAdaptationSection,
    getAHAnalysisPlan,
    getAHAttentionOrigin,
    getAHBackground,
    getAHDiagnosticImpression,
    getAHDownloadData,
    getAHLowVision,
    getAHLowVisionSection,
    getAHOphthalmology,
    getAHOphthalmologySection,
    getAHOptometry,
    getAHOptometrySection,
    getAHOrthoptic,
    getAHOrthopticSection,
    getAHPachymetry,
    getAHPio,
    getAHPreconsulting,
    getAHPreconsultingSection,
    getAHPreoperative,
    getAHPreoperativeSection,
    getAdxDetail,
    getAllImpressionDiagnostic
} from "./history.actions";

//Icons
import { IconDownload, IconX } from "@tabler/icons-react";

//Components
import { CustomDayPicker, Select, TextField } from "@/components";
import {
    AttentionAdaptation,
    AttentionAdaptationSection,
    AttentionAdxSection,
    AttentionAnalysisPlan,
    AttentionBackground,
    AttentionDI,
    AttentionHistoryParams,
    AttentionLowVision,
    AttentionLowVisionSection,
    AttentionOphthalmology,
    AttentionOphthalmologySection,
    AttentionOptometry,
    AttentionOptometrySection,
    AttentionOrigin,
    AttentionOrthoptic,
    AttentionOrthopticSection,
    AttentionPachymetry,
    AttentionPio,
    AttentionPreconsulting,
    AttentionPreconsultingSection,
    AttentionPreoperative,
    AttentionPreoperativeSection,
    getAllDiagnosticImpression
} from "@/models/history";
import { IconSearch } from "@tabler/icons-react";
import { DateRange } from "react-day-picker";
import { useDebouncedCallback } from "use-debounce";
import { CardFilterByDates, CardFilterBySections } from "./components";

import { DownloadInfo } from "@/models/historyDetails/download";
import { IconRefresh } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import PioChart from "./PioChart";

interface IHistory {
    completePathHistorySegments?: string;
    setShowHistory?: Dispatch<SetStateAction<boolean>>;
    showClose?: boolean;
    width?: string;
    patientIdProp?: number | null;
    patientName?: string;
    patientAge?: number | string;
    patientIdentification?: number | string;
    defaultHistory?: string;
    allData?: boolean;
}

const History = ({ completePathHistorySegments, setShowHistory, showClose = true, width, patientIdProp = null, patientName, patientAge, patientIdentification, defaultHistory, allData }: IHistory) => {
    const { lastPathname } = useGetLastPathName("/");
    const dispatch = useAppDispatch();
    const location = useLocation();
    const mcfId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const patientId = useAppSelector(state => state.patientAttention.appointmentSelected.patientId);
    const accountId = useAppSelector(state => state.workspace.id);
    const currentSheet = useAppSelector(state => state.patientAttention.patientStatus.sheet);
    const pdfRef = useRef<HTMLTableElement>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [range, setRange] = useState<DateRange | undefined>();
    const [showCardFilterBySections, setShowCardFilterBySections] = useState<boolean>(false);
    const [showCardFilterByDates, serShowCardFilterByDates] = useState<boolean>(true);
    const [attentionHistoryParams, setAttentionHistoryParams] = useState<AttentionHistoryParams>({
        patient_id: patientIdProp ?? patientId,
        prefix: currentSheet
    });
    const [chartConfig, setChartConfig] = useState<boolean>(false);
    const [downloadData, setDownloadData] = useState<DownloadInfo>({});
    const [impressionDiagnosticRes, setImpressionDiagnosticRes] = useState<getAllDiagnosticImpression[]>([]);
    const [attentionsOptometry, setAttentionsOptometry] = useState<AttentionOptometry[]>([]);
    const [attentionsOptometrySection, setAttentionsOptometrySection] = useState<AttentionOptometrySection>({});
    const [attentionsLowVision, setAttentionsLowVision] = useState<AttentionLowVision[]>([]);
    const [attentionsLowVisionSection, setAttentionsLowVisionSection] = useState<AttentionLowVisionSection>({});
    const [selectedSheet, setSelectedSheet] = useState(listHistorySegments.find(
        segment => segment.value === defaultHistory ?? (
            listHistorySegments.filter(el => el.value === lastPathname.charAt(0).toLowerCase() + lastPathname.slice(1)).length ? lastPathname.charAt(0).toLowerCase() + lastPathname.slice(1) : `${lastPathname}/${completePathHistorySegments}`
        )));

    const [attentionsAdaptation, setAttentionsAdaptation] = useState<AttentionAdaptation[]>([]);
    const [attentionsAdaptationSection, setAttentionsAdaptationSection] = useState<AttentionAdaptationSection>({});

    const [attentionsOphthalmology, setAttentionsOphthalmology] = useState<AttentionOphthalmology[]>([]);
    const [attentionsOphthalmologySection, setAttentionsAdaptatonSection] = useState<AttentionOphthalmologySection>({});

    const [attentionsOrthoptic, setAttentionsOrthoptic] = useState<AttentionOrthoptic[]>([]);
    const [attentionsOrthopticSection, setAttentionsOrthopticSection] = useState<AttentionOrthopticSection>({});

    const [attentionsAttentionOrigin, setAttentionsAttentionOrigin] = useState<AttentionOrigin[]>([]);
    const [attentionsBackground, setAttentionsBackground] = useState<AttentionBackground[]>([]);
    const [attentionsAnalysisPlan, setAttentionsAnalysisPlan] = useState<AttentionAnalysisPlan[]>([]);
    const [attentionsPachymetry, setAttentionsPachymetry] = useState<AttentionPachymetry[]>([]);
    const [attentionDi, setAttentionDI] = useState<AttentionDI[]>([]);

    const [attentionsPreoperative, setAttentionsPreoperative] = useState<AttentionPreoperative[]>([]);
    const [attentionsPreoperativeSection, setAttentionsPreoperativeSection] = useState<AttentionPreoperativeSection>({});
    const [attentionsPreconsulting, setAttentionsPreconsulting] = useState<AttentionPreconsulting[]>([]);
    const [attentionsPreconsultingSection, setAttentionsPreconsultingSection] = useState<AttentionPreconsultingSection>({});
    const [attentionsPio, setAttentionsPio] = useState<AttentionPio[]>([]);
    const [attentionAdx, setAttentionAdx] = useState<AttentionAdxSection[]>([]);


    const currentYear = new Date().getFullYear();


    useEffect(() => {
        async function fetch() {
            if (mcfId) {
                const response = await dispatch(getAHDownloadData(allData ? null : accountId, mcfId));
                if (response) setDownloadData(response);
            }
        }
        fetch();

    }, [dispatch, accountId, mcfId, allData]);


    useEffect(() => {
        getAllDataDiagnostic();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attentionHistoryParams.startDate, attentionHistoryParams.endDate]);

    useEffect(() => {
        if (selectedSheet?.value) {
            setAttentionHistoryParams(prev => ({
                ...prev,
                prefix: selectedSheet?.value
            }));
        }
    }, [selectedSheet?.value]);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);

                // --------- OPTOMETRY ----------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "optometry" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHOptometry(attentionHistoryParams));
                    if (data && data?.length > 0) {
                        data[0] = { ...data[0], isOpen: true };
                        setAttentionsOptometry(data);
                    } else {
                        setAttentionsOptometry([]);
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "optometry" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHOptometrySection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) {
                        setAttentionsOptometrySection({ ...data, correctionOpen: true });
                    } else {
                        setAttentionsOptometrySection({});
                    }
                }

                // ----------- LOW VISION ---------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "lowVision" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHLowVision(attentionHistoryParams));
                    if (data && data?.length > 0) {
                        data[0] = { ...data[0], isOpen: true };
                        setAttentionsLowVision(data);
                    } else {
                        setAttentionsLowVision([]);
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "lowVision" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHLowVisionSection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) {
                        setAttentionsLowVisionSection({ ...data, correctionOpen: true });
                    } else {
                        setAttentionsLowVisionSection({});
                    }
                }

                // ------------- ADAPTATION ---------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "adaptation" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHAdaptation(attentionHistoryParams));
                    if (data && data?.length > 0) {
                        data[0] = { ...data[0], isOpen: true };
                        setAttentionsAdaptation(data);
                    } else {
                        setAttentionsAdaptation([]);
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "adaptation" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHAdaptationSection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) {
                        setAttentionsAdaptationSection({ ...data, contactLensesOpen: true });
                    } else {
                        setAttentionsAdaptationSection({});
                    }
                }

                // ------------- OPHTHALMOLOGY ---------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "ophthalmology" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHOphthalmology(attentionHistoryParams));
                    if (data && data?.length > 0) {
                        data[0] = { ...data[0], isOpen: true };
                        setAttentionsOphthalmology(data);
                    } else {
                        setAttentionsOphthalmology([]);
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "ophthalmology" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHOphthalmologySection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) { setAttentionsAdaptatonSection({ ...data, externalOpen: true }); } else {
                        setAttentionsAdaptatonSection({});
                    }
                }

                // ------------- ORTHOPTIC ---------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "orthoptic" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHOrthoptic(attentionHistoryParams));
                    if (data && data?.length > 0) {
                        data[0] = { ...data[0], isOpen: true };
                        setAttentionsOrthoptic(data);
                    } else {
                        setAttentionsOrthoptic([]);
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "orthoptic" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHOrthopticSection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) {
                        setAttentionsOrthopticSection({ ...data, correctionOpen: true, breadthAccommodationOpen: false });
                    } else {
                        setAttentionsOrthopticSection({});
                    }
                }

                // --------------------- ATTENTION ORIGIN -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "attentionOrigin" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHAttentionOrigin(attentionHistoryParams));
                    if (data && data?.length > 0) {
                        data[0] = { ...data[0], isOpen: true };
                        setAttentionsAttentionOrigin(data);
                    } else {
                        setAttentionsAttentionOrigin([]);
                    }
                }

                // --------------------- BACKGROUND -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "background" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHBackground(attentionHistoryParams));
                    if (data && data?.length > 0) { setAttentionsBackground(data); } else {
                        setAttentionsBackground([]);
                    }
                }

                // --------------------- ANALYSIS AND PLAN -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "analysisPlan" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHAnalysisPlan(attentionHistoryParams));
                    if (data && data?.length > 0) {
                        data[0] = { ...data[0], isOpen: true };
                        setAttentionsAnalysisPlan(data);
                    } else {
                        setAttentionsAnalysisPlan([]);

                    }
                }

                // --------------------- PACHYMETRY -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "pachymetry" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHPachymetry(attentionHistoryParams));
                    if (data && data?.length > 0) { setAttentionsPachymetry(data); } else {
                        setAttentionsPachymetry([]);
                    }
                }

                // --------------------- DIAGNOSTIC IMPRESSION -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "diagnosticImpression" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHDiagnosticImpression(attentionHistoryParams));
                    if (data && data?.length > 0) {
                        data[0] = { ...data[0], isOpen: true };
                        setAttentionDI(data);
                    } else {
                        setAttentionDI([]);

                    }
                }

                // --------------------- PRECONSULTING -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "preconsulting" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHPreconsulting(attentionHistoryParams));
                    if (data && data?.length > 0) {
                        data[0] = { ...data[0], isOpen: true };
                        setAttentionsPreconsulting(data);
                    } else {
                        setAttentionsPreconsulting([]);

                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "preconsulting" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHPreconsultingSection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) { setAttentionsPreconsultingSection({ ...data, dilatedOpen: true }); } else {
                        setAttentionsPreconsultingSection({});
                    }
                }

                // --------------------- PIO -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "pio" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHPio(attentionHistoryParams));
                    if (data && data?.length > 0) { setAttentionsPio(data); } else { setAttentionsPio([]); }
                }
                // ------------- PREOPERATIVE ---------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "preoperative" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHPreoperative(attentionHistoryParams));
                    if (data && data?.length > 0) { setAttentionsPreoperative(data); } else { setAttentionsPreoperative([]); }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "preoperative" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHPreoperativeSection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) { setAttentionsPreoperativeSection(data); } else {
                        setAttentionsPreoperativeSection({});
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "diagnosticAids" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAdxDetail(attentionHistoryParams));
                    if (data && data?.length > 0) {
                        data[0] = { ...data[0], isOpen: true };
                        setAttentionAdx(data);
                    } else {
                        setAttentionAdx([]);
                    }
                }

            } finally {
                setIsLoading(false);
            }

        }
        fetchData();
    }, [dispatch, attentionHistoryParams]);

    const onChangeDate = (e?: DateRange) => {
        if (dayjs(e?.from).isSame(e?.to)) {
            setRange(undefined);
            setAttentionHistoryParams({
                ...attentionHistoryParams,
                startDate: undefined,
                endDate: undefined
            });
            return;
        }
        if (range?.from && range?.to) {
            setRange({
                from: dayjs(e?.from).isBefore(range.from) ? e?.from : e?.to,
            });
            setAttentionHistoryParams({
                ...attentionHistoryParams,
                startDate: dayjs(e?.from).isBefore(range.from) ? dayjs(e?.from).format("YYYY-MM-DD") : dayjs(e?.to).format("YYYY-MM-DD"),
                endDate: undefined
            });
        } else {
            setRange(e);
            setAttentionHistoryParams({
                ...attentionHistoryParams,
                startDate: dayjs(e?.from).format("YYYY-MM-DD"),
                endDate: dayjs(e?.to).format("YYYY-MM-DD")
            });
        }
        return range;
    };

    const onSearchIDX = useDebouncedCallback((value: string) => {
        setAttentionHistoryParams({
            ...attentionHistoryParams,
            search: value
        });
    }, 500);


    const getAllDataDiagnostic = async () => {
        const response = await dispatch(getAllImpressionDiagnostic({
            eaccount: accountId,
            patient_id: patientIdProp ?? patientId,
            startDate: attentionHistoryParams.startDate ?? "",
            endDate: attentionHistoryParams.endDate ?? "",
        }));
        if (response) {
            setImpressionDiagnosticRes(response.diagnosticImpression);
        }
    };
    const renderDownload = () => {
        return (
            <table border={0} ref={pdfRef} className="w-100">
                <thead>
                    <tr>
                        <td>
                            <div className="print-header">
                                <div className="d-flex align-items-center justify-content-between">
                                    <img src={downloadData.header?.logo} alt="cofca_logo" width={150} height={50} style={{ objectFit: "contain" }} />
                                    <h2 className="text-secondary fw-bold">
                                        {downloadData.header?.name_of_the_institution}
                                    </h2>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className="row p-3 py-2 align-items-center bg-light text-muted mt-3">
                                    <div className="col-6">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h5 className="fw-bold">Paciente:</h5>
                                                <h5 className="fw-bold">Documento:</h5>
                                            </div>
                                            <div className="col-8">
                                                <h5>{downloadData.header?.patient?.name}</h5>
                                                <h5>{downloadData.header?.patient?.doc}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <h5 className="fw-bold nowrap">Fecha de nacimiento:</h5>
                                                <h5 className="fw-bold">Género:</h5>
                                            </div>
                                            <div className="col-6">
                                                <h5>{downloadData.header?.patient?.birthdate}</h5>
                                                <h5>{downloadData.header?.patient?.gender}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 10 }}></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="printable">
                                <h3 className="text-primary mt-4">
                                    Impresión diagnóstica
                                </h3>
                                <table className="table table-bordered my-2">
                                    <thead>
                                        <tr className="text-secondary">
                                            <th>Fecha</th>
                                            <th className="text-center">CIE-10</th>
                                            <th>Impresión diagnóstica</th>
                                            <th className="text-center">Ojo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {impressionDiagnosticRes.length > 0 && impressionDiagnosticRes.map((item) => (
                                            <tr key={`${item.id}-${item.chlId}`} >
                                                <td className="align-middle">{item.date}</td>
                                                <td className="align-middle" align="center">{item.cie10}</td>
                                                <td style={{ whiteSpace: "pre-line" }}>
                                                    {item.descriptionAmp &&
                                                        <>
                                                            <span>
                                                                <b>AMP / </b>{item.descriptionAmp}
                                                            </span> <br />
                                                        </>
                                                    }
                                                    <span><b>{item.cie10} / </b>{item.descriptionCie10}</span>

                                                </td>
                                                <td className="align-middle" align="center">{item.eye}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div className="d-flex flex-column mt-5">
                                    <img width={200} src={downloadData.footer?.doctor?.signature} alt="" />
                                    <h5 className="fw-bold m-0">{downloadData.footer?.doctor?.name}</h5>
                                    <h5 className="fst-italic m-0">{downloadData.footer?.doctor?.position}</h5>
                                    <h5 className="fst-italic m-0">R.M {downloadData.footer?.doctor?.medical_record}</h5>
                                </div> */}
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>
                            <div style={{ height: 25 }}></div>
                            <div className="align-bottom text-end text-muted position-fixed bottom-0 w-100" style={{ marginBottom: 0 }}>
                                <h6 className="d-inline text-end mx-2">
                                    <span className="fw-bold">Teléfono: </span>
                                    {downloadData.footer?.appointment_phone}
                                </h6>
                                <h6 className="d-inline text-end mx-2">
                                    <span className="fw-bold">Email: </span>
                                    {downloadData.footer?.appointment_email}
                                </h6>
                                <h6 className="d-inline text-end mx-2">
                                    <span className="fw-bold">Dirección: </span>
                                    {downloadData.footer?.address}
                                </h6>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    };


    return (

        <div className="history"
            style={{ width: width ?? "30%" }}
        >
            <div>
                {showClose &&
                    <div className="pointer" onClick={() => setShowHistory && setShowHistory(false)}>
                        {location.pathname.includes("DiagnosticAids") ?
                            <>
                                <ReactTooltip
                                    anchorId="replace"
                                    place="bottom"
                                    content="Ver PDF"
                                    style={{ width: "100px", textAlign: "center", opacity: "0.8", zIndex: 50 }}
                                />
                                <IconRefresh className="text-black-50" id="replace" />
                            </>
                            :
                            <IconX />
                        }
                    </div>
                }
            </div>
            <div className="text-center">
                <div className="fs-3 text-secondary fw-bold mb-1">Historial de atención</div>
                <div className="fs-5 textGray100">¿Qué deseas ver del paciente?</div>
            </div>

            <div className="mt-4">
                <Select
                    className="textGray100"
                    isSearchable
                    showDefaultOption={false}
                    options={listHistorySegments}
                    style={{ width: "100%" }}
                    value={selectedSheet}
                    onChange={({ option }) => {
                        setAttentionHistoryParams({
                            ...attentionHistoryParams,
                            prefix: option.value,
                            search: undefined
                        });
                        setSelectedSheet(option);
                    }}
                />
            </div>

            <div className="d-flex gap-2 align-items-center justify-content-between mt-4">

                {attentionHistoryParams.prefix === "diagnosticImpression" &&
                    <div className="d-flex">
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <div className=" pointer textGray100"
                                    >
                                        <IconDownload />
                                    </div>
                                );
                            }}
                            content={() => pdfRef.current}
                        />
                    </div>
                }
                {(attentionHistoryParams.prefix === "pio" && attentionsPio.length) ? (
                    <div className="text-secondary fw-bold pointer" onClick={() => setChartConfig(true)}>
                        Ver gráfica
                    </div>
                ) : ""}

                {!(attentionHistoryParams.prefix === "background" ||
                    attentionHistoryParams.prefix === "attentionOrigin" ||
                    attentionHistoryParams.prefix === "analysisPlan" ||
                    attentionHistoryParams.prefix === "diagnosticImpression" ||
                    attentionHistoryParams.prefix === "diagnosticAids" ||
                    attentionHistoryParams.prefix === "pio")
                    ? (
                        <div className="form-check form-switch d-flex align-items-center justify-content-center p-0">
                            <small className="form-check-label textGray100 me-2">Mostrar por sección</small>
                            <input
                                checked={attentionHistoryParams.section === 1}
                                className="form-check-input m-0"
                                type="checkbox"
                                role="switch"
                                style={{ minWidth: 25, minHeight: 15 }}
                                onChange={({ target }) => {
                                    setShowCardFilterBySections(target.checked);
                                    serShowCardFilterByDates(false);
                                    setAttentionHistoryParams({
                                        ...attentionHistoryParams,
                                        startDate: undefined,
                                        endDate: undefined,
                                        section: target.checked ? 1 : 0
                                    });
                                    setRange(undefined);
                                }}
                            />
                        </div>
                    ) : <div></div>}
                <div className="d-flex align-items-center">
                    {(attentionHistoryParams.startDate || attentionHistoryParams.endDate) ? (
                        <small className="badge-wrapper badge-wrapper__primary fs-">
                            {dayjs(attentionHistoryParams.startDate).format("DD/MM/YYYY")}
                            {(attentionHistoryParams.startDate && attentionHistoryParams.endDate) ? " al " : ""}
                            {attentionHistoryParams.endDate && dayjs(attentionHistoryParams.endDate).format("DD/MM/YYYY")}
                        </small>
                    ) : ""}
                    <CustomDayPicker
                        dayPickerProps={{
                            mode: "range",
                            selected: range,
                            onSelect: (e) => {
                                if (dayjs(e?.from).isSame(e?.to)) {
                                    setRange(undefined);
                                    setAttentionHistoryParams({
                                        ...attentionHistoryParams,
                                        startDate: undefined,
                                        endDate: undefined
                                    });
                                    return;
                                }
                                if (range?.from && range?.to) {
                                    setRange({
                                        from: dayjs(e?.from).isBefore(range.from) ? e?.from : e?.to,
                                        to: undefined
                                    });
                                    setAttentionHistoryParams({
                                        ...attentionHistoryParams,
                                        startDate: dayjs(e?.from).isBefore(range.from) ? dayjs(e?.from).format("YYYY-MM-DD") : dayjs(e?.to).format("YYYY-MM-DD"),
                                        endDate: undefined
                                    });
                                } else {
                                    setRange(e);
                                    setAttentionHistoryParams({
                                        ...attentionHistoryParams,
                                        startDate: dayjs(e?.from).format("YYYY-MM-DD"),
                                        endDate: dayjs(e?.to).format("YYYY-MM-DD")
                                    });
                                }
                            },
                            className: "my-rdp dropdown-item p-0",
                            captionLayout: "dropdown-buttons",
                            fromYear: 2010,
                            toYear: currentYear
                        }}
                    />
                </div>


            </div>

            {attentionHistoryParams.prefix === "diagnosticImpression" ? (
                <TextField
                    className="mt-3"
                    placeholder="Buscar IDX..."
                    endIcon={<IconSearch />}
                    onChange={({ target }) => onSearchIDX(target.value)}
                />
            ) : ""}

            {isLoading
                ? (
                    <div className="big-loader align-self-center mt-5"></div>
                )
                : (
                    <>
                        {showCardFilterBySections &&
                            <CardFilterBySections
                                sectionsOptometry={attentionsOptometrySection}
                                sectionsLowVision={attentionsLowVisionSection}
                                sectionsAdaptation={attentionsAdaptationSection}
                                sectionsOphthalmology={attentionsOphthalmologySection}
                                sectionsOrthoptic={attentionsOrthopticSection}
                                sectionPreoperative={attentionsPreoperativeSection}
                                sectionsPreconsulting={attentionsPreconsultingSection}
                                sheet={attentionHistoryParams.prefix}
                                patientIdProp={attentionHistoryParams.patient_id}
                                patientName={patientName}
                                patientAge={patientAge}
                                allData={allData}
                                patientIdentification={patientIdentification}
                            />}

                        {(showCardFilterByDates || !showCardFilterBySections) &&
                            <CardFilterByDates
                                allData={allData}
                                attentionsOptometry={attentionsOptometry}
                                attentionsLowVision={attentionsLowVision}
                                attentionsAdaptation={attentionsAdaptation}
                                attentionsOphthalmology={attentionsOphthalmology}
                                attentionsOrthoptic={attentionsOrthoptic}
                                attentionOrigin={attentionsAttentionOrigin}
                                attentionBackground={attentionsBackground}
                                attentionAnalysisPlan={attentionsAnalysisPlan}
                                attentionPachymetry={attentionsPachymetry}
                                attentionDI={attentionDi}
                                attentionPreoperative={attentionsPreoperative}
                                attentionPreconsulting={attentionsPreconsulting}
                                attentionAdx={attentionAdx}
                                attentionPio={attentionsPio}
                                sheet={attentionHistoryParams.prefix}
                                patientIdProp={attentionHistoryParams.patient_id}
                                patientName={patientName}
                                patientAge={patientAge}
                                patientIdentification={patientIdentification}
                            />}
                    </>
                )}

            <PioChart
                fullScreen
                isOpen={chartConfig}
                onClose={() => {
                    setChartConfig(false);
                }}
                data={attentionsPio}
                attentionHistoryParams={attentionHistoryParams}
                onChangeDate={onChangeDate}
                range={range}
            />

            <div style={{ display: "none" }}>
                {renderDownload()}
            </div>
        </div>
    );
};

export default History;