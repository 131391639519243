import { IRadio } from "./radio";
import "./Radio.scss";

export function RadioButton({
    className = "",
    label,
    labelPosition = "right",
    id = "",
    ...rest
}: IRadio) {

    return (
        <div className={`radio ${className}`}>
            {labelPosition === "left" && (
                <label htmlFor={id} className="form-check-label me-3">{label}</label>
            )}
            <input className="form-check-input border-primary" style={{ borderWidth: 2 }} type="radio" id={id} {...rest} />
            {labelPosition === "right" && (
                <label htmlFor={id} className="ms-2">{label}</label>
            )}
        </div>
    );
}