import { Fragment, useEffect, useState } from "react";
import { IconCirclePlus, IconEdit, IconTrash } from "@tabler/icons-react";
//
import { DigestiveProblems, General, HeartProblems, HematologicalProblems, LungProblems, MetabolicProblems, MusculoskeletalProblems, NeurologicalProblems, PreoperativeSegment, Surgery } from "@/models/sheets/preoperative";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { finishLoading, startLoading } from "@/config/slices";
import { deleteSurgery, getPreoperativeEvaluation, savePreoperativeEvaluation } from "../preoperative.actions";
import { CommonSelect } from "@/models/sheets/orthoptic";
import { getDentalProthesisService } from "@/services/sheets/preoperative";
//
import { Button, Checkbox, SectionCard, Select } from "@/components";
import PreviousSurgery from "./PreviousSurgeryModal/PreviousSurgery";
//
import "@/features/Workspace/PatientAttention/Consultation/Oftalmology/TextConfig/TextConfig.scss";
import ScrollableTable from "@/components/ScrollableTable/ScrollableTable";

interface IProblems<T> {
    isChecked: boolean;
    label: string;
    tag: keyof T;
}

interface SurgeryModalConfig {
    isOpen: boolean;
    data?: Surgery;
}

export default function Antecedents({ preoperativePayload, setPreoperativePayload, onSubmit, isDisabledForm }: PreoperativeSegment) {
    const dispatch = useAppDispatch();

    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const accountId = useAppSelector(state => state.workspace.id);

    const [dentalProthesis, setDentalProthesis] = useState<CommonSelect[]>([]);
    const [surgeryModalConfig, setSurgeryModalConfig] = useState<SurgeryModalConfig>({
        isOpen: false
    });
    const [isLoading, setIsLoading] = useState(false);

    const [heartProblems, setHeartProblems] = useState<IProblems<HeartProblems>[]>([
        { isChecked: false, label: "Presión arterial alta", tag: "highBloodPressure" },
        { isChecked: false, label: "Presión arterial baja", tag: "lowBloodPressure" },
        { isChecked: false, label: "Infarto del miocardio", tag: "myocardialInfarction" },
        { isChecked: false, label: "Soplos cardiacos", tag: "murmurs" },
        { isChecked: false, label: "Dolor de pecho", tag: "chestPain" },
        { isChecked: false, label: "Ritmo cardiaco irregular (Arritmias)", tag: "arrhythmias" },
        { isChecked: false, label: "Hinchazón de tobillos", tag: "ankleSwelling" },
        { isChecked: false, label: "Dificultad para respirar en la noche", tag: "difficultyBreathing_night" },
        { isChecked: false, label: "Otro", tag: "other" }
    ]);
    const [lungsProblems, setLungsProblems] = useState<IProblems<LungProblems>[]>([
        { isChecked: false, label: "Asma", tag: "asthma" },
        { isChecked: false, label: "Bronquitis", tag: "bronchitis" },
        { isChecked: false, label: "Tos frecuente", tag: "frequent_cough" },
        { isChecked: false, label: "Otro", tag: "other" }
    ]);
    const [muscleProblems, setMuscleProblems] = useState<IProblems<MusculoskeletalProblems>[]>([
        { isChecked: false, label: "Artritis", tag: "arthritis" },
        { isChecked: false, label: "Debilidad muscular", tag: "muscularWeakness" },
        { isChecked: false, label: "Calambres o espasmos frecuentes", tag: "frequentCramps" },
        { isChecked: false, label: "Otro", tag: "other" }
    ]);
    const [digestiveProblems, setDigestiveProblems] = useState<IProblems<DigestiveProblems>[]>([
        { isChecked: false, label: "Úlcera o gastritis", tag: "ulcer" },
        { isChecked: false, label: "Diarrea crónica", tag: "chronicDiarrhea" },
        { isChecked: false, label: "Hepatitis", tag: "hepatitis" },
        { isChecked: false, label: "Otro", tag: "other" }
    ]);
    const [neurologicProblems, setNeurologicProblems] = useState<IProblems<NeurologicalProblems>[]>([
        { isChecked: false, label: "Epilepsias o convulsiones", tag: "epilepsies" },
        { isChecked: false, label: "Golpes fuertes en el cráneo", tag: "strongBlowsSkull" },
        { isChecked: false, label: "Pérdida del conocimiento", tag: "lossConsciousness" },
        { isChecked: false, label: "Se desmaya fácilmente", tag: "faintsEasily" },
        { isChecked: false, label: "Otro", tag: "other" }
    ]);
    const [bloodProblems, setBloodProblems] = useState<IProblems<HematologicalProblems>[]>([
        { isChecked: false, label: "Anemias", tag: "anemias" },
        { isChecked: false, label: "Sangrado excesivo", tag: "excessiveBleeding" },
        { isChecked: false, label: "Otro", tag: "other" },
    ]);
    const [metabolicProblems, setMetabolicProblems] = useState<IProblems<MetabolicProblems>[]>([
        { isChecked: false, label: "Diabetes", tag: "diabetes" },
        { isChecked: false, label: "Enfermedades de tiroides", tag: "thyroidDiseases" },
        { isChecked: false, label: "Otro", tag: "other" }
    ]);

    useEffect(() => {
        if (preoperativePayload.heartProblems) {
            setHeartProblems(prev => prev.map(item => ({
                ...item,
                isChecked: preoperativePayload.heartProblems?.[item.tag] as boolean
            })));
        }
    }, [preoperativePayload.heartProblems]);

    useEffect(() => {
        if (preoperativePayload.lungProblems) {
            setLungsProblems(prev => prev.map(item => ({
                ...item,
                isChecked: preoperativePayload.lungProblems?.[item.tag] as boolean
            })));
        }
    }, [preoperativePayload.lungProblems]);

    useEffect(() => {
        if (preoperativePayload.musculoskeletalProblems) {
            setMuscleProblems(prev => prev.map(item => ({
                ...item,
                isChecked: preoperativePayload.musculoskeletalProblems?.[item.tag] as boolean
            })));
        }
    }, [preoperativePayload.musculoskeletalProblems]);

    useEffect(() => {
        if (preoperativePayload.digestiveProblems) {
            setDigestiveProblems(prev => prev.map(item => ({
                ...item,
                isChecked: preoperativePayload.digestiveProblems?.[item.tag] as boolean
            })));
        }
    }, [preoperativePayload.digestiveProblems]);

    useEffect(() => {
        if (preoperativePayload.neurologicalProblems) {
            setNeurologicProblems(prev => prev.map(item => ({
                ...item,
                isChecked: preoperativePayload.neurologicalProblems?.[item.tag] as boolean
            })));
        }
    }, [preoperativePayload.neurologicalProblems]);

    useEffect(() => {
        if (preoperativePayload.hematologicalProblems) {
            setBloodProblems(prev => prev.map(item => ({
                ...item,
                isChecked: preoperativePayload.hematologicalProblems?.[item.tag] as boolean
            })));
        }
    }, [preoperativePayload.hematologicalProblems]);

    useEffect(() => {
        if (preoperativePayload.metabolicProblems) {
            setMetabolicProblems(prev => prev.map(item => ({
                ...item,
                isChecked: preoperativePayload.metabolicProblems?.[item.tag] as boolean
            })));
        }
    }, [preoperativePayload.metabolicProblems]);

    useEffect(() => {
        async function fetchData() {
            const response = await getDentalProthesisService();
            if (response.results) setDentalProthesis(response.results as CommonSelect[]);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (preoperativePayload.previousSurgeries?.surgeries?.length as number < 1) {
            setPreoperativePayload(prev => ({
                ...prev,
                previousSurgeries: {
                    ...prev.previousSurgeries,
                    problem: false,
                    problemText: ""
                }
            }));
        }

    }, [preoperativePayload.previousSurgeries?.surgeries?.length, setPreoperativePayload]);

    const onCloseModal = () => setSurgeryModalConfig({
        isOpen: false,
        data: undefined
    });

    const onAddSurgery = async (surgery: Surgery) => {
        try {
            setIsLoading(true);
            let options: typeof preoperativePayload;
            if (surgery.id) {
                options = {
                    ...preoperativePayload,
                    previousSurgeries: {
                        ...preoperativePayload.previousSurgeries,
                        surgeries: preoperativePayload.previousSurgeries?.surgeries?.map(item => {
                            if (item.id === surgery.id) {
                                return { ...item, ...surgery };
                            }
                            return item;
                        })
                    },
                    tag: "background"
                };
            } else {
                options = {
                    ...preoperativePayload,
                    previousSurgeries: {
                        ...preoperativePayload.previousSurgeries,
                        surgeries: [...preoperativePayload?.previousSurgeries?.surgeries || [], surgery]
                    },
                    tag: "background"
                };
            }
            const data = await dispatch(savePreoperativeEvaluation(options));
            if (data) {
                setPreoperativePayload(state => ({ ...state, ...data }));
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onCheckAnesthesiaProblems = (isChecked: boolean) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            previousSurgeries: {
                ...preoperativePayload.previousSurgeries,
                problem: isChecked,
                problemText: isChecked ? preoperativePayload.previousSurgeries?.problemText : ""
            }
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const onChangeAnesthesiaProblems = (value: string) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            previousSurgeries: {
                ...preoperativePayload.previousSurgeries,
                problemText: value
            }
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const onDeleteSurgery = async (surg: Surgery) => {
        if (surg.id) {
            const isSuccess = await dispatch(deleteSurgery({
                appId: appointmentId,
                eaccount: accountId,
                userId: userId as number,
                surgeryId: surg.id as number,
                tag: ""
            }));
            if (isSuccess) {
                dispatch(startLoading());
                const data = await dispatch(getPreoperativeEvaluation({
                    appId: appointmentId,
                    eaccount: accountId,
                    userId: userId as number,
                    tag: ""
                }));
                if (data) setPreoperativePayload({ ...preoperativePayload, ...data });
                dispatch(finishLoading());
            }
        }
    };

    const onChangeGeneral = (segment: keyof General, value: boolean) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            general: {
                ...preoperativePayload.general,
                [segment]: {
                    ...preoperativePayload.general?.[segment],
                    check: value,
                    id: null,
                    observations: ""
                }
            }
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const onChangeGeneralObservation = (segment: keyof General, value: string) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            general: {
                ...preoperativePayload.general,
                [segment]: {
                    ...preoperativePayload.general?.[segment],
                    observations: value
                }
            }
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const renderAnesthesia = () => {
        return (
            <SectionCard className="h-100">
                <h5 className="fw-bold text-secondary">¿Ha sido anestesiado anteriormente?</h5>
                <hr className="text-primary mt-2 mb-0" />
                <div className="select-option-item justify-content-between">
                    <span>¿Qué cirugías previas y qué tipo de anestesia se le administró?</span>
                    <Button
                        disabled={isDisabledForm}
                        variant="text"
                        endIcon={<IconCirclePlus />}
                        onClick={() => setSurgeryModalConfig({
                            data: { index: new Date().getTime().toString() + 1 },
                            isOpen: true
                        })}
                    >
                        Añadir
                    </Button>
                </div>

                <ScrollableTable className="my-3" maxHeight={150}>
                    <ScrollableTable.Head>
                        <ScrollableTable.Cell col={2}>
                            Año
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell col={4}>
                            Cirugía practicada
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell col={4}>
                            Anestesia
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell col={2}>

                        </ScrollableTable.Cell>
                    </ScrollableTable.Head>

                    <ScrollableTable.Body>
                        {preoperativePayload.previousSurgeries?.surgeries?.length ? preoperativePayload.previousSurgeries.surgeries.map(surg => (
                            <ScrollableTable.Row>
                                <ScrollableTable.Cell col={2}>
                                    {surg.year}
                                </ScrollableTable.Cell>
                                <ScrollableTable.Cell col={4}>
                                    {surg.surgery}
                                </ScrollableTable.Cell>
                                <ScrollableTable.Cell col={4}>
                                    {surg.anesthesia_description}
                                </ScrollableTable.Cell>
                                <ScrollableTable.Cell col={2} align="right">
                                    <IconEdit
                                        className="hoverable pointer me-3" size={18}
                                        onClick={() => setSurgeryModalConfig({
                                            isOpen: true,
                                            data: surg
                                        })}
                                    />
                                    <IconTrash className="hoverable pointer" size={18} onClick={() => onDeleteSurgery(surg)} />
                                </ScrollableTable.Cell>
                            </ScrollableTable.Row>
                        )) : (
                            <ScrollableTable.Row>
                                <ScrollableTable.Cell col={12} align="center">No hay cirugías previas</ScrollableTable.Cell>
                            </ScrollableTable.Row>
                        )}
                    </ScrollableTable.Body>
                </ScrollableTable >

                <Checkbox
                    label="¿Ha presentado problemas con algún anestésico?"
                    id="anesthesiaProblems"
                    checked={preoperativePayload.previousSurgeries?.problem}
                    onChange={() => onCheckAnesthesiaProblems(!preoperativePayload.previousSurgeries?.problem)}
                    disabled={!preoperativePayload.previousSurgeries?.surgeries?.length || isDisabledForm}
                />
                <textarea
                    className="form-control mt-2 no-resize"
                    placeholder="El paciente comunica estar tomando"
                    value={preoperativePayload.previousSurgeries?.problemText}
                    onChange={({ target }) => onChangeAnesthesiaProblems(target.value)}
                    disabled={!preoperativePayload.previousSurgeries?.problem || isDisabledForm}
                />
            </SectionCard >
        );
    };

    const renderGenerals = () => {
        const formattedDentalProthesis = dentalProthesis.map(
            item => ({ label: item.description, value: item.id })
        );
        const dentalProthesisValue = formattedDentalProthesis.find(
            item => item.value === preoperativePayload.general?.dentalProthesis?.id
        );

        return (
            <SectionCard className="h-100">
                <h5 className="fw-bold text-secondary">General</h5>
                <hr className="text-primary mt-2" />

                <div className="row mb-3">
                    <div className="col-lg-5">
                        <Checkbox
                            disabled={isDisabledForm}
                            label="Alergias"
                            id="allergies"
                            onChange={({ target }) => onChangeGeneral("allergies", target.checked)}
                            checked={preoperativePayload.general?.allergies?.check}
                        />
                    </div>
                    <div className="col-lg-7">
                        {preoperativePayload.general?.allergies?.check ? (
                            <textarea
                                disabled={isDisabledForm}
                                className="form-control no-resize"
                                value={preoperativePayload.general.allergies.observations}
                                onChange={({ target }) => onChangeGeneralObservation("allergies", target.value)}
                            />
                        ) : null}
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-5">
                        <Checkbox
                            disabled={isDisabledForm}
                            label="¿Ha padecido alguna enfermedad grave?"
                            id="seriousIllness"
                            onChange={({ target }) => onChangeGeneral("seriousIllness", target.checked)}
                            checked={preoperativePayload.general?.seriousIllness?.check}
                        />
                    </div>
                    <div className="col-lg-7">
                        {preoperativePayload.general?.seriousIllness?.check ? (
                            <textarea
                                disabled={isDisabledForm}
                                className="form-control no-resize"
                                value={preoperativePayload.general.seriousIllness?.observations}
                                onChange={({ target }) => onChangeGeneralObservation("seriousIllness", target.value)}
                            />
                        ) : null}
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-5">
                        <Checkbox
                            disabled={isDisabledForm}
                            label="Toma aspirina"
                            id="aspirinLastWeek"
                            onChange={({ target }) => onChangeGeneral("aspirinLastWeek", target.checked)}
                            checked={preoperativePayload.general?.aspirinLastWeek?.check}
                        />
                    </div>
                    <div className="col-lg-7">
                        {preoperativePayload.general?.aspirinLastWeek?.check ? (
                            <textarea
                                disabled={isDisabledForm}
                                className="form-control no-resize"
                                value={preoperativePayload.general.aspirinLastWeek.observations}
                                onChange={({ target }) => onChangeGeneralObservation("aspirinLastWeek", target.value)}
                            />
                        ) : null}
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-5">
                        <Checkbox
                            disabled={isDisabledForm}
                            label="Prótesis dental"
                            id="dentalProthesis"
                            onChange={({ target }) => onChangeGeneral("dentalProthesis", target.checked)}
                            checked={preoperativePayload.general?.dentalProthesis?.check}
                        />
                    </div>
                    <div className="col-lg-7">
                        {preoperativePayload.general?.dentalProthesis?.check ? (
                            <Select
                                disabled={isDisabledForm}
                                style={{ width: "100%" }}
                                value={dentalProthesisValue}
                                onChange={({ option }) => {
                                    setPreoperativePayload({
                                        ...preoperativePayload,
                                        general: {
                                            ...preoperativePayload.general,
                                            dentalProthesis: {
                                                ...preoperativePayload.general?.dentalProthesis,
                                                id: option.value
                                            }
                                        }
                                    });
                                    onSubmit();
                                }}
                                options={formattedDentalProthesis}
                            />
                        ) : null}
                    </div>
                </div>
            </SectionCard>
        );
    };

    const renderHeartProblems = () => {
        return (
            <SectionCard>
                <h5 className="text-secondary fw-bold">
                    Problemas cardiacos
                </h5>
                <hr className="text-primary mt-2" />
                {heartProblems.map(pr => (
                    <div key={pr.tag} className="d-flex align-items-center w-100">
                        <Checkbox
                            disabled={isDisabledForm}
                            className="my-2"
                            key={pr.tag}
                            id={pr.tag}
                            label={pr.label}
                            checked={pr.isChecked}
                            onChange={({ target }) => {
                                const options = heartProblems.map(item => {
                                    if (item.tag === pr.tag) {
                                        return { ...item, isChecked: target.checked };
                                    }
                                    return item;
                                });
                                const formattedToPayload = options.reduce((acc, el) => {
                                    return { ...acc, [el.tag]: el.isChecked };
                                }, {});
                                setHeartProblems(options);
                                setPreoperativePayload({
                                    ...preoperativePayload,
                                    heartProblems: {
                                        ...preoperativePayload.heartProblems,
                                        ...formattedToPayload
                                    }
                                });
                                onSubmit();
                            }}
                        />
                        {(pr.tag === "other" && preoperativePayload.heartProblems?.other) && (
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control form-control-sm ms-4"
                                value={preoperativePayload.heartProblems?.otherText}
                                onChange={({ target }) => {
                                    const options: typeof preoperativePayload = {
                                        ...preoperativePayload,
                                        heartProblems: {
                                            ...preoperativePayload.heartProblems,
                                            otherText: target.value
                                        }
                                    };
                                    setPreoperativePayload(options);
                                    onSubmit();
                                }}
                            />
                        )}
                    </div>
                ))}
                {Object.values(preoperativePayload.heartProblems || {}).some(pr => (typeof pr === "boolean" && pr === true)) && (
                    <textarea
                        disabled={isDisabledForm}
                        className="form-control no-resize mt-2"
                        placeholder="El paciente comunica estar tomando"
                        value={preoperativePayload.heartProblems?.medicationsDoTake}
                        onChange={({ target }) => {
                            const options: typeof preoperativePayload = {
                                ...preoperativePayload,
                                heartProblems: {
                                    ...preoperativePayload.heartProblems,
                                    medicationsDoTake: target.value
                                }
                            };
                            setPreoperativePayload(options);
                            onSubmit();
                        }}
                    />
                )}
            </SectionCard>
        );
    };

    const renderMuscleProblems = () => {
        return (
            <SectionCard className="mt-4">
                <h5 className="text-secondary fw-bold">
                    Problemas musculo-esqueléticos
                </h5>
                <hr className="text-primary mt-2" />
                {muscleProblems.map(pr => (
                    <div key={pr.tag} className="d-flex align-items-center w-100">
                        <Checkbox
                            disabled={isDisabledForm}
                            className="my-2"
                            key={pr.tag}
                            id={pr.tag}
                            label={pr.label}
                            checked={pr.isChecked}
                            onChange={({ target }) => {
                                const options = muscleProblems.map(item => {
                                    if (item.tag === pr.tag) {
                                        return { ...item, isChecked: target.checked };
                                    }
                                    return item;
                                });
                                const formattedToPayload = options.reduce((acc, el) => {
                                    return { ...acc, [el.tag]: el.isChecked };
                                }, {});
                                setMuscleProblems(options);
                                setPreoperativePayload({
                                    ...preoperativePayload,
                                    musculoskeletalProblems: {
                                        ...preoperativePayload.musculoskeletalProblems,
                                        ...formattedToPayload
                                    }
                                });
                                onSubmit();
                            }}
                        />
                        {(pr.tag === "other" && preoperativePayload.musculoskeletalProblems?.other) && (
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control form-control-sm ms-4"
                                value={preoperativePayload.musculoskeletalProblems?.otherText}
                                onChange={({ target }) => {
                                    const options: typeof preoperativePayload = {
                                        ...preoperativePayload,
                                        musculoskeletalProblems: {
                                            ...preoperativePayload.musculoskeletalProblems,
                                            otherText: target.value
                                        }
                                    };
                                    setPreoperativePayload(options);
                                    onSubmit();
                                }}
                            />
                        )}
                    </div>
                ))}
                {Object.values(preoperativePayload.musculoskeletalProblems || {}).some(pr => (typeof pr === "boolean" && pr === true)) && (
                    <textarea
                        disabled={isDisabledForm}
                        className="form-control no-resize mt-2"
                        placeholder="El paciente comunica estar tomando"
                        value={preoperativePayload.musculoskeletalProblems?.medicationsDoTake}
                        onChange={({ target }) => {
                            const options: typeof preoperativePayload = {
                                ...preoperativePayload,
                                musculoskeletalProblems: {
                                    ...preoperativePayload.musculoskeletalProblems,
                                    medicationsDoTake: target.value
                                }
                            };
                            setPreoperativePayload(options);
                            onSubmit();
                        }}
                    />
                )}
            </SectionCard>
        );
    };

    const renderLungsProblems = () => {
        return (
            <SectionCard>
                <h5 className="text-secondary fw-bold">
                    Problemas pulmonares
                </h5>
                <hr className="text-primary mt-2" />
                {lungsProblems.map(pr => (
                    <div key={pr.tag} className="d-flex align-items-center w-100">
                        <Checkbox
                            disabled={isDisabledForm}
                            className="my-2"
                            key={pr.tag}
                            id={pr.tag}
                            label={pr.label}
                            checked={pr.isChecked}
                            onChange={({ target }) => {
                                const options = lungsProblems.map(item => {
                                    if (item.tag === pr.tag) {
                                        return { ...item, isChecked: target.checked };
                                    }
                                    return item;
                                });
                                const formattedToPayload = options.reduce((acc, el) => {
                                    return { ...acc, [el.tag]: el.isChecked };
                                }, {});
                                setLungsProblems(options);
                                setPreoperativePayload({
                                    ...preoperativePayload,
                                    lungProblems: {
                                        ...preoperativePayload.lungProblems,
                                        ...formattedToPayload
                                    }
                                });
                                onSubmit();
                            }}
                        />
                        {(pr.tag === "other" && preoperativePayload.lungProblems?.other) && (
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control form-control-sm ms-4"
                                value={preoperativePayload.lungProblems?.otherText}
                                onChange={({ target }) => {
                                    const options: typeof preoperativePayload = {
                                        ...preoperativePayload,
                                        lungProblems: {
                                            ...preoperativePayload.lungProblems,
                                            otherText: target.value
                                        }
                                    };
                                    setPreoperativePayload(options);
                                    onSubmit();
                                }}
                            />
                        )}
                    </div>
                ))}
                {Object.values(preoperativePayload.lungProblems || {}).some(pr => (typeof pr === "boolean" && pr === true)) && (
                    <textarea
                        disabled={isDisabledForm}
                        className="form-control no-resize mt-2"
                        placeholder="El paciente comunica estar tomando"
                        value={preoperativePayload.lungProblems?.medicationsDoTake}
                        onChange={({ target }) => {
                            const options: typeof preoperativePayload = {
                                ...preoperativePayload,
                                lungProblems: {
                                    ...preoperativePayload.lungProblems,
                                    medicationsDoTake: target.value
                                }
                            };
                            setPreoperativePayload(options);
                            onSubmit();
                        }}
                    />
                )}
            </SectionCard>
        );
    };

    const renderDigestiveProblems = () => {
        return (
            <SectionCard className="mt-4">
                <h5 className="text-secondary fw-bold">
                    Problemas Digestivos
                </h5>
                <hr className="text-primary mt-2" />
                {digestiveProblems.map(pr => (
                    <div key={pr.tag} className="d-flex align-items-center w-100">
                        <Checkbox
                            disabled={isDisabledForm}
                            className="my-2"
                            key={pr.tag}
                            id={pr.tag}
                            label={pr.label}
                            checked={pr.isChecked}
                            onChange={({ target }) => {
                                const options = digestiveProblems.map(item => {
                                    if (item.tag === pr.tag) {
                                        return { ...item, isChecked: target.checked };
                                    }
                                    return item;
                                });
                                const formattedToPayload = options.reduce((acc, el) => {
                                    return { ...acc, [el.tag]: el.isChecked };
                                }, {});
                                setDigestiveProblems(options);
                                setPreoperativePayload({
                                    ...preoperativePayload,
                                    digestiveProblems: {
                                        ...preoperativePayload.digestiveProblems,
                                        ...formattedToPayload
                                    }
                                });
                                onSubmit();
                            }}
                        />
                        {(pr.tag === "other" && preoperativePayload.digestiveProblems?.other) && (
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control form-control-sm ms-4"
                                value={preoperativePayload.digestiveProblems?.otherText}
                                onChange={({ target }) => {
                                    const options: typeof preoperativePayload = {
                                        ...preoperativePayload,
                                        digestiveProblems: {
                                            ...preoperativePayload.digestiveProblems,
                                            otherText: target.value
                                        }
                                    };
                                    setPreoperativePayload(options);
                                    onSubmit();
                                }}
                            />
                        )}
                    </div>
                ))}
                {Object.values(preoperativePayload.digestiveProblems || {}).some(pr => (typeof pr === "boolean" && pr === true)) && (
                    <textarea
                        disabled={isDisabledForm}
                        className="form-control no-resize mt-2"
                        placeholder="El paciente comunica estar tomando"
                        value={preoperativePayload.digestiveProblems?.medicationsDoTake}
                        onChange={({ target }) => {
                            const options: typeof preoperativePayload = {
                                ...preoperativePayload,
                                digestiveProblems: {
                                    ...preoperativePayload.digestiveProblems,
                                    medicationsDoTake: target.value
                                }
                            };
                            setPreoperativePayload(options);
                            onSubmit();
                        }}
                    />
                )}
            </SectionCard>
        );
    };

    const renderNeurologicProblems = () => {
        return (
            <SectionCard>
                <h5 className="text-secondary fw-bold">
                    Problemas neurológicos
                </h5>
                <hr className="text-primary mt-2" />
                {neurologicProblems.map(pr => (
                    <div key={pr.tag} className="d-flex align-items-center w-100">
                        <Checkbox
                            disabled={isDisabledForm}
                            className="my-2"
                            key={pr.tag}
                            id={pr.tag}
                            label={pr.label}
                            checked={pr.isChecked}
                            onChange={({ target }) => {
                                const options = neurologicProblems.map(item => {
                                    if (item.tag === pr.tag) {
                                        return { ...item, isChecked: target.checked };
                                    }
                                    return item;
                                });
                                const formattedToPayload = options.reduce((acc, el) => {
                                    return { ...acc, [el.tag]: el.isChecked };
                                }, {});
                                setNeurologicProblems(options);
                                setPreoperativePayload({
                                    ...preoperativePayload,
                                    neurologicalProblems: {
                                        ...preoperativePayload.neurologicalProblems,
                                        ...formattedToPayload
                                    }
                                });
                                onSubmit();
                            }}
                        />
                        {(pr.tag === "other" && preoperativePayload.neurologicalProblems?.other) && (
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control form-control-sm ms-4"
                                value={preoperativePayload.neurologicalProblems?.otherText}
                                onChange={({ target }) => {
                                    const options: typeof preoperativePayload = {
                                        ...preoperativePayload,
                                        neurologicalProblems: {
                                            ...preoperativePayload.neurologicalProblems,
                                            otherText: target.value
                                        }
                                    };
                                    setPreoperativePayload(options);
                                    onSubmit();
                                }}
                            />
                        )}
                    </div>
                ))}
                {Object.values(preoperativePayload.neurologicalProblems || {}).some(pr => (typeof pr === "boolean" && pr === true)) && (
                    <textarea
                        disabled={isDisabledForm}
                        className="form-control no-resize mt-2"
                        placeholder="El paciente comunica estar tomando"
                        value={preoperativePayload.neurologicalProblems?.medicationsDoTake}
                        onChange={({ target }) => {
                            const options: typeof preoperativePayload = {
                                ...preoperativePayload,
                                neurologicalProblems: {
                                    ...preoperativePayload.neurologicalProblems,
                                    medicationsDoTake: target.value
                                }
                            };
                            setPreoperativePayload(options);
                            onSubmit();
                        }}
                    />
                )}
            </SectionCard>
        );
    };

    const renderHematologicalProblems = () => {
        return (
            <SectionCard className="mt-4">
                <h5 className="text-secondary fw-bold">
                    Problemas hematológicos
                </h5>
                <hr className="text-primary mt-2" />
                {bloodProblems.map(pr => (
                    <div key={pr.tag} className="d-flex align-items-center w-100">
                        <Checkbox
                            disabled={isDisabledForm}
                            className="my-2"
                            key={pr.tag}
                            id={pr.tag}
                            label={pr.label}
                            checked={pr.isChecked}
                            onChange={({ target }) => {
                                const options = bloodProblems.map(item => {
                                    if (item.tag === pr.tag) {
                                        return { ...item, isChecked: target.checked };
                                    }
                                    return item;
                                });
                                const formattedToPayload = options.reduce((acc, el) => {
                                    return { ...acc, [el.tag]: el.isChecked };
                                }, {});
                                setBloodProblems(options);
                                setPreoperativePayload({
                                    ...preoperativePayload,
                                    hematologicalProblems: {
                                        ...preoperativePayload.hematologicalProblems,
                                        ...formattedToPayload
                                    }
                                });
                                onSubmit();
                            }}
                        />
                        {(pr.tag === "other" && preoperativePayload.hematologicalProblems?.other) && (
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control form-control-sm ms-4"
                                value={preoperativePayload.hematologicalProblems?.otherText}
                                onChange={({ target }) => {
                                    const options: typeof preoperativePayload = {
                                        ...preoperativePayload,
                                        hematologicalProblems: {
                                            ...preoperativePayload.hematologicalProblems,
                                            otherText: target.value
                                        }
                                    };
                                    setPreoperativePayload(options);
                                    onSubmit();
                                }}
                            />
                        )}
                    </div>
                ))}
                {Object.values(preoperativePayload.hematologicalProblems || {}).some(pr => (typeof pr === "boolean" && pr === true)) && (
                    <textarea
                        disabled={isDisabledForm}
                        className="form-control no-resize mt-2"
                        placeholder="El paciente comunica estar tomando"
                        value={preoperativePayload.hematologicalProblems?.medicationsDoTake}
                        onChange={({ target }) => {
                            const options: typeof preoperativePayload = {
                                ...preoperativePayload,
                                hematologicalProblems: {
                                    ...preoperativePayload.hematologicalProblems,
                                    medicationsDoTake: target.value
                                }
                            };
                            setPreoperativePayload(options);
                            onSubmit();
                        }}
                    />
                )}
            </SectionCard>
        );
    };

    const renderMetabolicProblems = () => {
        return (
            <SectionCard className="mt-4">
                <h5 className="text-secondary fw-bold">
                    Problemas metabólicos
                </h5>
                <hr className="text-primary mt-2" />
                {metabolicProblems.map(pr => (
                    <div key={pr.tag} className="d-flex align-items-center w-100">
                        <Checkbox
                            disabled={isDisabledForm}
                            className="my-2"
                            key={pr.tag}
                            id={pr.tag}
                            checked={pr.isChecked}
                            label={pr.label}
                            onChange={({ target }) => {
                                const options = metabolicProblems.map(item => {
                                    if (item.tag === pr.tag) {
                                        return { ...item, isChecked: target.checked };
                                    }
                                    return item;
                                });
                                const formattedToPayload = options.reduce((acc, el) => {
                                    return { ...acc, [el.tag]: el.isChecked };
                                }, {});
                                setMetabolicProblems(options);
                                setPreoperativePayload({
                                    ...preoperativePayload,
                                    metabolicProblems: {
                                        ...preoperativePayload.metabolicProblems,
                                        ...formattedToPayload
                                    }
                                });
                                onSubmit();
                            }}
                        />
                        {(pr.tag === "other" && preoperativePayload.metabolicProblems?.other) && (
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control form-control-sm ms-4 my-2"
                                value={preoperativePayload.metabolicProblems?.otherText}
                                onChange={({ target }) => {
                                    const options: typeof preoperativePayload = {
                                        ...preoperativePayload,
                                        metabolicProblems: {
                                            ...preoperativePayload.metabolicProblems,
                                            otherText: target.value
                                        }
                                    };
                                    setPreoperativePayload(options);
                                    onSubmit();
                                }}
                            />
                        )}
                    </div>
                ))}
                {metabolicProblems.some(pr => pr.isChecked) && (
                    <textarea
                        disabled={isDisabledForm}
                        className="form-control no-resize mt-2"
                        placeholder="El paciente comunica estar tomando"
                        value={preoperativePayload.metabolicProblems?.medicationsDoTake}
                        onChange={({ target }) => {
                            const options: typeof preoperativePayload = {
                                ...preoperativePayload,
                                metabolicProblems: {
                                    ...preoperativePayload.metabolicProblems,
                                    medicationsDoTake: target.value
                                }
                            };
                            setPreoperativePayload(options);
                            onSubmit();
                        }}
                    />
                )}
            </SectionCard>
        );
    };

    const render = () => {
        return (
            <Fragment>
                <div className="row gy-3">
                    <div className="col-lg-6">
                        {renderAnesthesia()}
                    </div>
                    <div className="col-lg-6">
                        {renderGenerals()}
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-4 h-100">
                        {renderHeartProblems()}
                        {renderMuscleProblems()}
                    </div>
                    <div className="col-lg-4 ">
                        {renderLungsProblems()}
                        {renderDigestiveProblems()}
                        <SectionCard className="mt-4" style={{ height: 193 }}>
                            <h5 className="text-secondary fw-bold">
                                Problemas urinarios
                            </h5>
                            <hr className="text-primary mt-2" />
                            <textarea
                                disabled={isDisabledForm}
                                className="form-control no-resize mt-2"
                                placeholder="¿Cuáles?"
                                value={preoperativePayload.urinaryProblems?.whichText}
                                onChange={({ target }) => {
                                    const options: typeof preoperativePayload = {
                                        ...preoperativePayload,
                                        urinaryProblems: {
                                            ...preoperativePayload.urinaryProblems,
                                            whichText: target.value
                                        }
                                    };
                                    setPreoperativePayload(options);
                                    onSubmit();
                                }}
                            />
                            <textarea
                                disabled={isDisabledForm}
                                className="form-control no-resize mt-2"
                                placeholder="El paciente comunica estar tomando"
                                value={preoperativePayload.urinaryProblems?.medicationsDoTake}
                                onChange={({ target }) => {
                                    const options: typeof preoperativePayload = {
                                        ...preoperativePayload,
                                        urinaryProblems: {
                                            ...preoperativePayload.urinaryProblems,
                                            medicationsDoTake: target.value
                                        }
                                    };
                                    setPreoperativePayload(options);
                                    onSubmit();
                                }}
                            />
                        </SectionCard>
                    </div>
                    <div className="col-lg-4">
                        {renderNeurologicProblems()}
                        {renderHematologicalProblems()}
                        {renderMetabolicProblems()}
                    </div>
                </div>
                <PreviousSurgery
                    isDisabledForm={isDisabledForm}
                    isOpen={surgeryModalConfig.isOpen}
                    onClose={onCloseModal}
                    data={surgeryModalConfig.data}
                    width={400}
                    className="mt-0"
                    positionModal="center"
                    onSuccess={(data) => onAddSurgery(data as Surgery)}
                    isLoading={isLoading}
                />
            </Fragment>
        );
    };

    return render();
}