import { useEffect, useState } from "react";
import { IconChevronDown, IconChevronRight, IconEye } from "@tabler/icons-react";
//
import { TableAccordion } from "@/components/TableAccordion/TableAcordion";
import {
    AstigmatismSection,
    AttentionAdaptationSection,
    AttentionLowVisionSection,
    AttentionOphthalmologySection,
    AttentionOptometrySection,
    AttentionOrthopticSection,
    AttentionPreoperative,
    AttentionPreoperativeSection,
    AttentionPreconsultingSection,
    CenteredElement,
    EyeDetail,
    PreconsultingSection,
} from "@/models/history";
import Details from "./Details";

interface CardFilter {
    sectionsOptometry: AttentionOptometrySection;
    sectionsLowVision: AttentionLowVisionSection;
    sectionsAdaptation: AttentionAdaptationSection;
    sectionsOphthalmology: AttentionOphthalmologySection;
    sectionsOrthoptic: AttentionOrthopticSection;
    sectionPreoperative: AttentionPreoperativeSection;
    sectionsPreconsulting: AttentionPreconsultingSection;
    sheet: string;
    patientIdProp?: number;
    patientName?: string;
    patientAge?: number | string;
    patientIdentification?: number | string;
    allData?: boolean;
}

interface DetailParams {
    sheet?: string;
    mcfId?: number;
}

export const CardFilterBySections = ({
    sectionsOptometry,
    sectionsLowVision,
    sectionsAdaptation,
    sectionsOphthalmology,
    sectionsOrthoptic,
    sectionPreoperative,
    sectionsPreconsulting,
    sheet,
    patientIdProp,
    patientName,
    patientAge,
    patientIdentification,
    allData
}: CardFilter) => {
    const [detailModalConfig, setDetailModalConfig] = useState<{ isOpen: boolean; data: DetailParams; }>({
        isOpen: false,
        data: { sheet, mcfId: undefined }
    });


    const [attentionsOptometryState, setAttentionsOptometryState] = useState<AttentionOptometrySection>({});
    const [attentionsLowVisonState, setAttentionsLowVisonState] = useState<AttentionLowVisionSection>({});
    const [attentionsAdaptationState, setAttentionsAdaptationState] = useState<AttentionAdaptationSection>({});
    const [attentionsOphthalmologyState, setAttentionsOphthalmologyState] = useState<AttentionOphthalmologySection>({});
    const [attentionsPreoperativeState, setAttentionsPreoperativeState] = useState<AttentionPreoperativeSection>({});
    const [attentionsPreconsultState, setAttentionsPreconsultState] = useState<AttentionPreconsultingSection>({});
    const [attentionsOrthopticState, setAttentionsOrthopticState] = useState<AttentionOrthopticSection>({});

    useEffect(() => {
        if (sectionsOptometry) setAttentionsOptometryState(sectionsOptometry);
        if (sectionsLowVision) setAttentionsLowVisonState(sectionsLowVision);
        if (sectionsAdaptation) setAttentionsAdaptationState(sectionsAdaptation);
        if (sectionsOphthalmology) setAttentionsOphthalmologyState(sectionsOphthalmology);
        if (sectionsOrthoptic) setAttentionsOrthopticState(sectionsOrthoptic);
        if (sectionsPreconsulting) setAttentionsPreconsultState(sectionsPreconsulting);
        if (sectionPreoperative) setAttentionsPreoperativeState(sectionPreoperative);
    }, [
        sectionsOptometry,
        sectionsLowVision,
        sectionsAdaptation,
        sectionsOphthalmology,
        sectionsOrthoptic,
        sectionPreoperative,
        sectionsPreconsulting
    ]);

    const onCloseDetails = () => {
        setDetailModalConfig({
            data: { sheet, mcfId: undefined },
            isOpen: false
        });
    };

    const onOpenDetails = (segment: DetailParams) => {
        setDetailModalConfig({
            isOpen: true,
            data: segment
        });
    };

    const renderRow = (item: AstigmatismSection) => {
        return (
            <tr key={item.id}>
                <td width={100} className="px-4 fw-bold">{item.date}</td>
                {item.supportsVsnTifloInUse ? (
                    <td colSpan={2} className="px-2">{item.supportsVsnTifloInUse}</td>
                ) : (
                    <>
                        <td width={300} className="px-2">{item.oi}</td>
                        <td width={200} className="px-2">{item.od}</td>
                    </>
                )}
                <td>
                    <IconEye className="hoverable pointer" onClick={() => onOpenDetails({ sheet, mcfId: item.mcfId })} />
                </td>
            </tr>
        );
    };

    const renderOrthopticRow = (item: EyeDetail) => {
        return (
            <tr key={item.id}>
                <td width={100} className="px-4 fw-bold">{item.date}</td>
                <td width={300} className="px-2">{item.oi}</td>
                <td width={200} className="px-2">{item.od}</td>
                <td>
                    <IconEye className="hoverable pointer" onClick={() => onOpenDetails({ sheet, mcfId: item.mcfId })} />
                </td>
            </tr>
        );
    };

    const renderRowAdaptation = (item: CenteredElement) => {
        return (
            <tr key={item.id}>
                <td className="px-4 fw-bold nowrap">{item.date}</td>
                {item.contactLenses ? <td colSpan={2} className="px-4 fw-bold">{item.contactLenses}</td> : null}
                {item.centered ? <td colSpan={2} className="px-4 fw-bold">{item.centered}</td> : null}
                {item.motion ? <td colSpan={2} className="px-4 fw-bold">{item.motion}</td> : null}
                {item.tolerance ? <td colSpan={2} className="px-4 fw-bold">{item.tolerance}</td> : null}
                {(item.oi || item.od) ? (
                    <>
                        <td>{item.od}</td>
                        <td>{item.oi}</td>
                    </>

                ) : null}
                <td>
                    <IconEye className="hoverable pointer" onClick={() => onOpenDetails({ sheet, mcfId: item.mcfId })} />
                </td>
            </tr>
        );
    };

    const renderRowPreoperative = (item: AttentionPreoperative) => {
        return (
            <tr key={item.id}>
                <td className="px-4 fw-bold nowrap">{item.date}</td>
                {item.suitable ? <td colSpan={2} className="px-4 fw-bold">{item.suitable}</td> : null}
                <td>
                    <IconEye className="hoverable pointer" onClick={() => onOpenDetails({ sheet, mcfId: item.mcfId })} />
                </td>
            </tr>
        );
    };

    const renderTableOptometry = (title: string, section: string, optometrySection: AstigmatismSection[], isOpen?: boolean) => {

        return (
            <TableAccordion className="tableStyle d-block overflow-auto">
                <div className="d-flex p-3 px-2">
                    <div className={"col-7"}>
                        {!isOpen && (
                            <IconChevronRight
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current: typeof attentionsOptometryState = {
                                        ...attentionsOptometryState,
                                        [section]: true
                                    };
                                    setAttentionsOptometryState(current);
                                }}
                            />
                        )}

                        {isOpen &&
                            <IconChevronDown
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current: typeof attentionsOptometryState = {
                                        ...attentionsOptometryState,
                                        [section]: false
                                    };
                                    setAttentionsOptometryState(current);
                                }}
                            />
                        }

                        <span className="ms-2 mt-1 fw-bold textAguaMarine">{title}</span>
                    </div>

                </div>

                {isOpen &&
                    <thead>
                        <tr>
                            <th className="col-4 px-4 textTableAccordion fs13" >
                                Fechas
                            </th>

                            <th className="col-4 px-2 textTableAccordion fs13" >
                                OI
                            </th>

                            <th className="col-4 px-2 textTableAccordion fs13" >
                                OD
                            </th>

                        </tr>
                    </thead>
                }

                {isOpen &&
                    <tbody className="transitionBody">
                        {optometrySection.map((item) => renderRow(item))}
                    </tbody>
                }

            </TableAccordion>
        );
    };

    const renderTableLowVision = (title: string, section: string, lowVisionSection: AstigmatismSection[], isOpen?: boolean) => {
        return (
            <TableAccordion className="tableStyle d-block overflow-auto">
                <div className="d-flex p-3 px-2">
                    <div className={"col-7 nowrap overflow-hidden text-ellipsis"}>
                        {!isOpen && (
                            <IconChevronRight
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current: typeof attentionsLowVisonState = {
                                        ...attentionsLowVisonState,
                                        [section]: true
                                    };
                                    setAttentionsLowVisonState(current);
                                }}
                            />
                        )}

                        {isOpen &&
                            <IconChevronDown
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current: typeof attentionsLowVisonState = {
                                        ...attentionsLowVisonState,
                                        [section]: false
                                    };
                                    setAttentionsLowVisonState(current);
                                }}
                            />
                        }

                        <span className="ms-2 mt-1 fw-bold textAguaMarine nowrap">{title}</span>
                    </div>

                </div>

                {isOpen &&
                    <thead>
                        <tr>
                            <th className="col-4 px-4 textTableAccordion fs13" >
                                Fechas
                            </th>

                            {section !== "supportsVsnTifloInUseOpen" ? (
                                <>
                                    <th className="col-4 px-2 textTableAccordion fs13" >
                                        OI
                                    </th>

                                    <th className="col-4 px-2 textTableAccordion fs13" >
                                        OD
                                    </th>
                                </>
                            ) : (
                                <th colSpan={2}></th>
                            )}

                        </tr>
                    </thead>
                }

                {isOpen &&
                    <tbody className="transitionBody">
                        {lowVisionSection.map((item) => renderRow(item))}
                    </tbody>
                }

            </TableAccordion>
        );
    };

    const renderTableAdaptation = (title: string, section: string, adaptationSection: CenteredElement[], isOpen?: boolean) => {
        return (
            <TableAccordion className="tableStyle d-block overflow-auto">
                <div className="d-flex p-3 px-2">
                    <div className={"col-7"}>
                        {!isOpen && (
                            <IconChevronRight
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current: typeof attentionsAdaptationState = {
                                        ...attentionsAdaptationState,
                                        [section]: true
                                    };
                                    setAttentionsAdaptationState(current);
                                }}
                            />
                        )}

                        {isOpen &&
                            <IconChevronDown
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current: typeof attentionsAdaptationState = {
                                        ...attentionsAdaptationState,
                                        [section]: false
                                    };
                                    setAttentionsAdaptationState(current);
                                }}
                            />
                        }

                        <span className="ms-2 mt-1 fw-bold textAguaMarine">{title}</span>
                    </div>

                </div>

                {section === "glassesToOrderOpen" && attentionsAdaptationState.glassesToOrderOpen ? (
                    <thead>
                        <tr>
                            <th className="col-4 px-4 textTableAccordion fs13">Fechas</th>
                            <th className="col-4 px-2 textTableAccordion fs13">OD</th>
                            <th className="col-4 px-2 textTableAccordion fs13">OI</th>
                        </tr>
                    </thead>
                ) : ""}

                {isOpen &&
                    <tbody className="transitionBody">
                        {adaptationSection.map((item) => renderRowAdaptation(item))}
                    </tbody>
                }

            </TableAccordion>
        );
    };

    const renderRowOphthalmology = (item: EyeDetail) => {
        return (
            <tr key={item.id}>
                <td width={100} className="px-4 fw-bold">{item.date}</td>
                <td width={300} className="px-2">{item.oi}</td>
                <td width={200} className="px-2">{item.od}</td>
                <td>
                    <IconEye className="hoverable pointer" onClick={() => onOpenDetails({ sheet, mcfId: item.mcfId })} />
                </td>
            </tr>
        );
    };

    const renderTableOphthalmology = (title: string, section: string, ophthalmologySection: EyeDetail[], isOpen?: boolean) => {
        return (
            <TableAccordion className="tableStyle d-block overflow-auto">
                <div className="d-flex p-3 px-2">
                    <div className={"col-7"}>
                        {!isOpen && (
                            <IconChevronRight
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current: typeof attentionsOphthalmologyState = {
                                        ...attentionsOphthalmologyState,
                                        [section]: true
                                    };
                                    setAttentionsOphthalmologyState(current);
                                }}
                            />
                        )}

                        {isOpen &&
                            <IconChevronDown
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current: typeof attentionsOphthalmologyState = {
                                        ...attentionsOphthalmologyState,
                                        [section]: false
                                    };
                                    setAttentionsOphthalmologyState(current);
                                }}
                            />
                        }

                        <span className="ms-2 mt-1 fw-bold textAguaMarine">{title}</span>
                    </div>

                </div>

                {isOpen &&
                    <thead>
                        <tr>
                            <th className="col-4 px-4 textTableAccordion fs13" >
                                Fechas
                            </th>

                            <th className="col-4 px-2 textTableAccordion fs13" >
                                OI
                            </th>

                            <th className="col-4 px-2 textTableAccordion fs13" >
                                OD
                            </th>

                        </tr>
                    </thead>
                }

                {isOpen &&
                    <tbody className="transitionBody">
                        {ophthalmologySection.map((item) => renderRowOphthalmology(item))}
                    </tbody>
                }

            </TableAccordion>
        );
    };

    const renderRowPreconsult = (item: PreconsultingSection) => {
        return (
            <tr key={item.id}>
                <td className="px-4 fw-bold nowrap">{item.date}</td>
                {item.dilated ? <td colSpan={2} className="px-4 fw-bold">{item.dilated}</td> : null}
                {(item.bloodPressureDiastolic || item.bloodPressureDiastolic) ? <td colSpan={2} className="px-4 fw-bold">{item.bloodPressureDiastolic} / {item.bloodPressureDiastolic}</td> : null}
                {item.heartRate ? <td colSpan={2} className="px-4 fw-bold">{item.heartRate}</td> : null}
                {item.breathingFrequency ? <td colSpan={2} className="px-4 fw-bold">{item.breathingFrequency}</td> : null}
                {item.bodyWeight ? <td colSpan={2} className="px-4 fw-bold">{item.bodyWeight}</td> : null}
                <td>
                    <IconEye className="hoverable pointer" onClick={() => onOpenDetails({ sheet, mcfId: item.mcfId })} />
                </td>
            </tr>
        );
    };

    const renderTablePreconsult = (title: string, section: string, preconsultingSection: PreconsultingSection[], isOpen?: boolean) => {
        return (
            <div className="tableStyle overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <div className="p-3">
                    {!isOpen ? (
                        <IconChevronRight
                            height={18}
                            color="#6E6F7C"
                            className="pointer"
                            onClick={() => {
                                const current: typeof attentionsPreconsultState = {
                                    ...attentionsPreconsultState,
                                    [section]: true
                                };
                                setAttentionsPreconsultState(current);
                            }}
                        />
                    ) : (
                        <IconChevronDown
                            height={18}
                            color="#6E6F7C"
                            className="pointer"
                            onClick={() => {
                                const current: typeof attentionsPreconsultState = {
                                    ...attentionsPreconsultState,
                                    [section]: false
                                };
                                setAttentionsPreconsultState(current);
                            }}
                        />
                    )}

                    <span className="ms-2 mt-1 fw-bold textAguaMarine">{title}</span>
                </div>
                {isOpen &&
                    <TableAccordion className="mt-3 overflow-auto">
                        <thead>
                            <tr>
                                <th colSpan={3} className="px-4 fs13 text-secondary" >
                                    Fechas
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {preconsultingSection.map(el => renderRowPreconsult(el))}
                        </tbody>
                    </TableAccordion>
                }
            </div>
        );
    };

    const renderTablePreoperative = (title: string, section: string, preoperativeSection: AttentionPreoperative[], isOpen?: boolean) => {
        return (
            <TableAccordion className="tableStyle d-block overflow-auto">
                <div className="d-flex p-3 px-2">
                    <div className={"col-7"}>
                        {!isOpen && (
                            <IconChevronRight
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current: typeof attentionsPreoperativeState = {
                                        ...attentionsPreoperativeState,
                                        [section]: true
                                    };
                                    setAttentionsPreoperativeState(current);
                                }}
                            />
                        )}

                        {isOpen &&
                            <IconChevronDown
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current: typeof attentionsPreoperativeState = {
                                        ...attentionsPreoperativeState,
                                        [section]: false
                                    };
                                    setAttentionsPreoperativeState(current);
                                }}
                            />
                        }

                        <span className="ms-2 mt-1 fw-bold textAguaMarine">{title}</span>
                    </div>

                </div>

                {isOpen &&
                    <tbody className="transitionBody">
                        {preoperativeSection.map((item) => renderRowPreoperative(item))}
                    </tbody>
                }

            </TableAccordion>
        );
    };

    const renderTableOrthoptic = (title: string, section: string, orthopticSection: EyeDetail[], isOpen?: boolean) => {
        return (
            <div className="tableStyle overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <div className="d-flex p-3 px-2">
                    <div className="nowrap">
                        {!isOpen && (
                            <IconChevronRight
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current: typeof attentionsOrthopticState = {
                                        ...attentionsOrthopticState,
                                        [section]: true
                                    };
                                    setAttentionsOrthopticState(current);
                                }}
                            />
                        )}

                        {isOpen &&
                            <IconChevronDown
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current: typeof attentionsOrthopticState = {
                                        ...attentionsOrthopticState,
                                        [section]: false
                                    };
                                    setAttentionsOrthopticState(current);
                                }}
                            />
                        }

                        <span className="ms-2 mt-1 fw-bold textAguaMarine nowrap">{title}</span>
                    </div>

                </div>
                <div className="overflow-auto">
                    <TableAccordion>
                        {isOpen &&
                            <thead>
                                <tr>
                                    <th className="px-4 textTableAccordion fs13" >
                                        Fechas
                                    </th>

                                    <th className="px-2 textTableAccordion fs13" >
                                        OI
                                    </th>

                                    <th className="px-2 textTableAccordion fs13" >
                                        OD
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                        }

                        {isOpen &&
                            <tbody className="transitionBody">
                                {orthopticSection.map((item) => renderOrthopticRow(item))}
                            </tbody>
                        }
                    </TableAccordion>
                </div>
            </div>
        );
    };

    const render = () => {
        return (
            <section className="mt-4">
                {(sheet === "optometry") && (!attentionsOptometryState.isEmpty ? (
                    <>
                        {attentionsOptometryState.correction && attentionsOptometryState.correction?.length > 0 && renderTableOptometry(
                            "AV con corrección",
                            "correctionOpen",
                            attentionsOptometryState.correction,
                            attentionsOptometryState.correctionOpen)
                        }
                        {attentionsOptometryState.noCorrection && attentionsOptometryState.noCorrection?.length > 0 && renderTableOptometry(
                            "AV sin corrección",
                            "noCorrectionOpen",
                            attentionsOptometryState.noCorrection,
                            attentionsOptometryState.noCorrectionOpen)
                        }
                        {attentionsOptometryState.subjective && attentionsOptometryState.subjective?.length > 0 && renderTableOptometry(
                            "R. subjetiva",
                            "subjectiveOpen",
                            attentionsOptometryState.subjective,
                            attentionsOptometryState.subjectiveOpen)
                        }
                        {attentionsOptometryState.astigmatism && attentionsOptometryState.astigmatism?.length > 0 && renderTableOptometry(
                            "Astigmatismo",
                            "astigmatismOpen",
                            attentionsOptometryState.astigmatism,
                            attentionsOptometryState.astigmatismOpen)
                        }
                    </>
                ) : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)}

                {(sheet === "lowVision") && (!attentionsLowVisonState.isEmpty ? (
                    <>
                        {attentionsLowVisonState.correction?.length ? renderTableLowVision(
                            "AV con corrección",
                            "correctionOpen",
                            attentionsLowVisonState.correction,
                            attentionsLowVisonState.correctionOpen) : ""
                        }
                        {attentionsLowVisonState.noCorrection?.length ? renderTableLowVision(
                            "AV sin corrección",
                            "noCorrectionOpen",
                            attentionsLowVisonState.noCorrection,
                            attentionsLowVisonState.noCorrectionOpen) : ""
                        }
                        {attentionsLowVisonState.subjective?.length ? renderTableLowVision(
                            "R. subjetiva",
                            "subjectiveOpen",
                            attentionsLowVisonState.subjective,
                            attentionsLowVisonState.subjectiveOpen) : ""
                        }
                        {attentionsLowVisonState.supportsVsnTifloInUse?.length ? renderTableLowVision(
                            "Ayuda VSN - TIFLO en uso",
                            "supportsVsnTifloInUseOpen",
                            attentionsLowVisonState.supportsVsnTifloInUse,
                            attentionsLowVisonState.supportsVsnTifloInUseOpen) : ""
                        }
                    </>
                ) : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)}

                {(sheet === "adaptation") && (!attentionsAdaptationState.isEmpty ? (
                    <>
                        {attentionsAdaptationState.contactLenses?.length ? renderTableAdaptation(
                            "LC de prueba",
                            "contactLensesOpen",
                            attentionsAdaptationState.contactLenses,
                            attentionsAdaptationState.contactLensesOpen) : ""
                        }
                        {attentionsAdaptationState.tolerance?.length ? renderTableAdaptation(
                            "Tolerancia / Confort",
                            "toleranceOpen",
                            attentionsAdaptationState.tolerance,
                            attentionsAdaptationState.toleranceOpen) : ""
                        }
                        {attentionsAdaptationState.centered?.length ? renderTableAdaptation(
                            "Centrado",
                            "centeredOpen",
                            attentionsAdaptationState.centered,
                            attentionsAdaptationState.centeredOpen) : ""
                        }
                        {attentionsAdaptationState.motion?.length ? renderTableAdaptation(
                            "Movimiento",
                            "motionOpen",
                            attentionsAdaptationState.motion,
                            attentionsAdaptationState.motionOpen) : ""
                        }
                        {attentionsAdaptationState.glassesToOrder?.length ? renderTableAdaptation(
                            "Lentes a pedir",
                            "glassesToOrderOpen",
                            attentionsAdaptationState.glassesToOrder,
                            attentionsAdaptationState.glassesToOrderOpen) : ""
                        }
                    </>
                ) : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)}

                {(sheet === "ophthalmology") && (!attentionsOphthalmologyState.isEmpty ? (
                    <>
                        {attentionsOphthalmologyState.external?.length ? renderTableOphthalmology(
                            "Externo",
                            "externalOpen",
                            attentionsOphthalmologyState.external,
                            attentionsOphthalmologyState.externalOpen) : ""
                        }
                        {attentionsOphthalmologyState.ppl?.length ? renderTableOphthalmology(
                            "P/P/L",
                            "pplOpen",
                            attentionsOphthalmologyState.ppl,
                            attentionsOphthalmologyState.pplOpen) : ""
                        }
                        {attentionsOphthalmologyState.screraConjunctiva?.length ? renderTableOphthalmology(
                            "Cojuntiva / Esclera",
                            "screraConjunctivaOpen",
                            attentionsOphthalmologyState.screraConjunctiva,
                            attentionsOphthalmologyState.screraConjunctivaOpen) : ""
                        }
                        {attentionsOphthalmologyState.cornea?.length ? renderTableOphthalmology(
                            "Córnea",
                            "corneaOpen",
                            attentionsOphthalmologyState.cornea,
                            attentionsOphthalmologyState.corneaOpen) : ""
                        }
                        {attentionsOphthalmologyState.previousChamber?.length ? renderTableOphthalmology(
                            "Cámara anterior",
                            "previousChamberOpen",
                            attentionsOphthalmologyState.previousChamber,
                            attentionsOphthalmologyState.previousChamberOpen) : ""
                        }
                        {attentionsOphthalmologyState.iris?.length ? renderTableOphthalmology(
                            "Iris",
                            "irisOpen",
                            attentionsOphthalmologyState.iris,
                            attentionsOphthalmologyState.irisOpen) : ""
                        }
                        {attentionsOphthalmologyState.crystalline?.length ? renderTableOphthalmology(
                            "Cristalino",
                            "crystallineOpen",
                            attentionsOphthalmologyState.crystalline,
                            attentionsOphthalmologyState.crystallineOpen) : ""
                        }
                        {attentionsOphthalmologyState.retinaVitreous?.length ? renderTableOphthalmology(
                            "Retina/Vítreo",
                            "retinaVitreousOpen",
                            attentionsOphthalmologyState.retinaVitreous,
                            attentionsOphthalmologyState.retinaVitreousOpen) : ""
                        }
                        {attentionsOphthalmologyState.opticNerve?.length ? renderTableOphthalmology(
                            "Nervio óptico",
                            "opticNerveOpen",
                            attentionsOphthalmologyState.opticNerve,
                            attentionsOphthalmologyState.opticNerveOpen) : ""
                        }
                    </>
                ) : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)}

                {(sheet === "preoperative") && (!attentionsPreoperativeState.isEmpty ? (
                    <>
                        {attentionsPreoperativeState.suitable?.length ? renderTablePreoperative(
                            "Apto para cirugía",
                            "suitableOpen",
                            attentionsPreoperativeState.suitable,
                            attentionsPreoperativeState.suitableOpen) : ""
                        }
                    </>
                ) : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)}


                {(sheet === "preconsulting") && (!attentionsPreconsultState.isEmpty ? (
                    <>
                        {attentionsPreconsultState.dilated?.length ? renderTablePreconsult(
                            "Dilatado",
                            "dilatedOpen",
                            attentionsPreconsultState.dilated,
                            attentionsPreconsultState.dilatedOpen
                        ) : ""}
                        {attentionsPreconsultState.bloodPressureDiastolic?.length ? renderTablePreconsult(
                            "Tensión arterial",
                            "bloodPressureDiastolicOpen",
                            attentionsPreconsultState.bloodPressureDiastolic,
                            attentionsPreconsultState.bloodPressureDiastolicOpen
                        ) : ""}
                        {attentionsPreconsultState.heartRate?.length ? renderTablePreconsult(
                            "F. cardiaca",
                            "heartRateOpen",
                            attentionsPreconsultState.heartRate,
                            attentionsPreconsultState.heartRateOpen
                        ) : ""}
                        {attentionsPreconsultState.breathingFrequency?.length ? renderTablePreconsult(
                            "F. respiratoria",
                            "breathingFrequencyOpen",
                            attentionsPreconsultState.breathingFrequency,
                            attentionsPreconsultState.breathingFrequencyOpen
                        ) : ""}
                        {attentionsPreconsultState.bodyWeight?.length ? renderTablePreconsult(
                            "Peso corporal",
                            "bodyWeightOpen",
                            attentionsPreconsultState.bodyWeight,
                            attentionsPreconsultState.bodyWeightOpen
                        ) : ""}
                    </>
                ) : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)}

                {sheet === "orthoptic" && (!attentionsOrthopticState.isEmpty ? (
                    <>
                        {attentionsOrthopticState.breadthAccommodation?.length ? renderTableOrthoptic(
                            "Amplitud de acomodación",
                            "breadthAccommodationOpen",
                            attentionsOrthopticState?.breadthAccommodation,
                            attentionsOrthopticState.breadthAccommodationOpen) : ""}

                        {attentionsOrthopticState.correction?.length ? renderTableOrthoptic(
                            "AV con corrección",
                            "correctionOpen",
                            attentionsOrthopticState.correction,
                            attentionsOrthopticState.correctionOpen) : ""}

                        {attentionsOrthopticState.noCorrection?.length ? renderTableOrthoptic(
                            "AV sin corrección",
                            "noCorrectionOpen",
                            attentionsOrthopticState.noCorrection,
                            attentionsOrthopticState.noCorrectionOpen) : ""}

                        {attentionsOrthopticState.functionalOptometryFinalFormul?.length ? renderTableOrthoptic(
                            "F.F.F Optometría",
                            "functionalOptometryFinalFormulaOpen",
                            attentionsOrthopticState.functionalOptometryFinalFormul,
                            attentionsOrthopticState.functionalOptometryFinalFormulaOpen) : ""}

                        {attentionsOrthopticState.accommodationFlexibility?.length ? renderTableOrthoptic(
                            "Flexibilidad de acomodación",
                            "accommodationFlexibilityOpen",
                            attentionsOrthopticState.accommodationFlexibility,
                            attentionsOrthopticState.accommodationFlexibilityOpen) : ""}
                    </>
                ) : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)}
                {detailModalConfig.isOpen && (
                    <Details
                        data={{ sheet, mcfId: Number(detailModalConfig.data.mcfId) }}
                        allData={allData}
                        isOpen={detailModalConfig.isOpen}
                        onClose={onCloseDetails}
                        patientIdProp={patientIdProp}
                        patientName={patientName}
                        patientAge={patientAge}
                        patientIdentification={patientIdentification}
                    />
                )}
            </section>
        );
    };

    return render();
};