import React from "react";

interface IIconEye {
    height?: number;
    width?: number;
    className?: string;
    onClick?: React.MouseEventHandler<SVGSVGElement>
}

export const IconEye = ({ height = 13, width = 13, className, onClick }: IIconEye) => {
    return (

        <svg onClick={onClick} className={className} width={width} height={height} viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3906 5.66016C16.4453 5.79688 16.5 5.98828 16.5 6.125C16.5 6.26172 16.4453 6.48047 16.3906 6.61719C14.9141 9.98047 11.9609 12.25 8.625 12.25C5.26172 12.25 2.30859 9.98047 0.832031 6.61719C0.777344 6.48047 0.75 6.26172 0.75 6.125C0.75 5.98828 0.777344 5.79688 0.832031 5.66016C2.30859 2.29688 5.26172 0 8.625 0C11.9609 0 14.9141 2.29688 16.3906 5.66016ZM8.625 10.9375C11.332 10.9375 13.8203 9.10547 15.1602 6.125C13.8477 3.17188 11.332 1.3125 8.625 1.3125C5.89062 1.3125 3.40234 3.17188 2.0625 6.15234C3.375 9.10547 5.89062 10.9375 8.625 10.9375ZM8.625 2.625C10.5391 2.625 12.125 4.21094 12.125 6.15234C12.125 8.06641 10.5391 9.625 8.625 9.625C6.68359 9.625 5.125 8.06641 5.125 6.125C5.125 4.21094 6.68359 2.625 8.625 2.625ZM8.625 8.3125C9.82812 8.3125 10.8125 7.35547 10.8125 6.15234C10.8125 4.92188 9.82812 3.9375 8.625 3.9375C8.59766 3.9375 8.57031 3.96484 8.54297 3.96484C8.59766 4.10156 8.625 4.23828 8.625 4.375C8.625 5.35938 7.83203 6.125 6.875 6.125C6.71094 6.125 6.57422 6.125 6.4375 6.07031C6.4375 6.09766 6.4375 6.125 6.4375 6.125C6.4375 7.35547 7.39453 8.3125 8.625 8.3125Z" fill="#8B8C96" />
        </svg>

    );
};



