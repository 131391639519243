import { useEffect, useState } from "react";
//
import { OptotypeItem } from "@/models/select/optotype";
import { VisualAcuityParams } from "../VisualAcuity/VisualAcuity";
import { getOptotypes } from "@/services";
//
import { SectionCard, Select } from "@/components";

export default function Optotype({ externalPayload, setExternalPayload, onSubmit }: VisualAcuityParams) {
    const [optotypes, setOptotypes] = useState<OptotypeItem[]>([]);

    useEffect(() => {
        async function fetch() {
            const optotypesResponse = await getOptotypes();
            setOptotypes(optotypesResponse.results);
        }
        fetch();
    }, []);

    const optotypesFormatted = optotypes?.map(item => ({ label: item.name, value: item.id }));

    const render = () => {
        const optotypeValue = optotypesFormatted.find(opt => opt.value === externalPayload.visualAcuity?.optotype);

        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold">Optotipo</h5>
                <hr className="text-primary mt-2" />
                <Select
                    options={optotypesFormatted}
                    onChange={({ option }) => {
                        setExternalPayload({
                            ...externalPayload,
                            visualAcuity: {
                                ...externalPayload.visualAcuity,
                                optotype: +option.value
                            }
                        });
                        onSubmit();
                    }}
                    value={optotypeValue}
                />
                <span className="text-label mt-2">Clasificación</span>
                <input
                    type="text"
                    className="form-control"
                    value={externalPayload.visualAcuity?.clasification}
                    onChange={({ target }) => {
                        setExternalPayload({
                            ...externalPayload,
                            visualAcuity: {
                                ...externalPayload.visualAcuity,
                                clasification: target.value
                            }
                        });
                        onSubmit();
                    }}
                />
            </SectionCard>
        );
    };

    return render();
}