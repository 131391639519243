import { useEffect, useState } from "react";
import {
    IconCheck,
    IconCirclePlus,
    IconEdit,
    IconSearch,
    IconX
} from "@tabler/icons-react";
import {
    Badge,
    Button,
    Checkbox,
    Modal,
    RadioButton,
    SectionCard,
    Select,
    Table,
    TablePagination,
    TextField
} from "@/components";
//
import { fireWarningAlert } from "@/utils";
import { getAttentionTimes } from "../../AttentionOrigin/attentionOrigin.actions";
import {
    DrugRouteAdm,
    ExternalMedicinePayload,
    NewMedicinePayload,
    OrderMeasure,
    ReconciliationMedicine,
    ReconciliationMedicineParams
} from "@/models/pre-consultation/medicine";
import { createMedicine, getDrugRouteAdm, getExternalMedicine, getOneMedicine, getOrderMeasure, getReconciliationMedicine, saveExternalMedicine } from "../preConsultation.actions";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { AttentionTime } from "@/models/attentionOrigin";

interface MedicineSelects {
    times: AttentionTime[],
    orderMeasure: OrderMeasure[],
    drugRouteAdministsration: DrugRouteAdm[];
}
interface MedicineProps {
    isDisabledForm: boolean;
}
export function Medicine({ isDisabledForm }: MedicineProps) {
    const dispatch = useAppDispatch();

    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);

    const [createModal, setCreateModal] = useState<{ isOpen: boolean; state: string; }>({
        isOpen: false,
        state: ""
    });
    const [disabledSustance, setDisabledSubstance] = useState<boolean>(true);
    const [externalMedicine, setExternalMedicine] = useState<NewMedicinePayload[]>([]);
    const [medicineSelects, setMedicineSelects] = useState<MedicineSelects>({
        times: [],
        drugRouteAdministsration: [],
        orderMeasure: []
    });

    const [newMedicinePayload, setNewMedicinePayload] = useState<NewMedicinePayload>({
        appId: appointmentId,
        userId: userId as number,
        eaccount: accountId,
        name: "",
        chemicalSubstance: ""
    });
    const [externalMedicinePayload, setExternalMedicinePayload] = useState<ExternalMedicinePayload>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number
    });
    const [recMedicine, setRecMedicine] = useState<{ data: ReconciliationMedicine[], rowTotal: number; }>({
        data: [],
        rowTotal: 0
    });
    const [filters, setFilters] = useState<ReconciliationMedicineParams>({
        userId: userId as number,
        appId: appointmentId,
        eaccount: accountId,
        page: 1,
        perpage: 10,
        search: "",
        status: "currentUse"
    });
    const [selectedMedication, setSelectedMedication] = useState<ReconciliationMedicine | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getReconciliationMedicine(filters));
            if (data.results) setRecMedicine({
                data: data.results,
                rowTotal: data.rowTotal
            });
        }
        fetchData();
    }, [dispatch, filters]);

    useEffect(() => {
        async function fetchData() {
            const data = await getAttentionTimes();
            if (data) setMedicineSelects((prevState) => ({ ...prevState, times: data }));
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const data = await getOrderMeasure();
            if (data) setMedicineSelects((prevState) => ({ ...prevState, orderMeasure: data }));
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const data = await getDrugRouteAdm();
            if (data) setMedicineSelects((prevState) => ({ ...prevState, drugRouteAdministsration: data }));
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getExternalMedicine());
            setExternalMedicine(data);
        }
        fetchData();
    }, [dispatch]);

    const onOpenModal = (state: "create" | "edit") => setCreateModal({ isOpen: true, state });
    const onCloseModal = () => {
        setCreateModal({
            isOpen: false,
            state: ""
        });
        setDisabledSubstance(true);
        setNewMedicinePayload({
            ...newMedicinePayload,
            chemicalSubstance: "",
            name: "",
            id: undefined
        });
        setExternalMedicinePayload({
            appId: appointmentId,
            eaccount: accountId,
            userId: userId as number
        });
    };

    const onChangeMedicine = (medicineId: number) => {
        const selectedMedicine = externalMedicine.find(item => item.id === medicineId);

        if (selectedMedicine) {
            setNewMedicinePayload({
                ...newMedicinePayload,
                id: selectedMedicine.id,
                chemicalSubstance: selectedMedicine.chemicalSubstance,
                name: selectedMedicine.name
            });
            setExternalMedicinePayload({
                ...externalMedicinePayload,
                exmId: selectedMedicine.id
            });
        } else {
            setNewMedicinePayload({
                ...newMedicinePayload,
                id: undefined,
                chemicalSubstance: "",
                name: ""
            });
        }
    };

    const validateNewMedicine = (): boolean => {
        if (newMedicinePayload.chemicalSubstance && newMedicinePayload.name) {
            return true;
        } else {
            return false;
        }
    };

    const onCreateNewMedicine = async () => {
        try {
            setIsLoading(true);
            if (validateNewMedicine()) {
                const responseId = await dispatch(createMedicine(newMedicinePayload));

                if (responseId) {
                    setDisabledSubstance(true);
                    const data = await dispatch(getExternalMedicine());
                    setExternalMedicine(data);

                    if (data) {
                        const medicine = data.find(item => item.id === responseId);
                        if (medicine) {
                            setNewMedicinePayload(medicine as NewMedicinePayload);

                            setExternalMedicinePayload({
                                ...externalMedicinePayload,
                                exmId: medicine?.id
                            });
                        }
                    }
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onSubmitForm = async () => {
        try {
            setIsLoading(true);
            const {
                exmId,
                quantity,
                measurementUnit,
                every,
                time,
                until,
                dra,
                undefinedDate
            } = externalMedicinePayload;
            if (exmId && quantity && measurementUnit && every && time && (undefinedDate ? true : until) && dra) {
                const isSuccess = await dispatch(saveExternalMedicine({
                    ...externalMedicinePayload,
                    every: Number(externalMedicinePayload.every),
                    quantity: Number(externalMedicinePayload.quantity)
                }));
                if (isSuccess) {
                    const data = await dispatch(getReconciliationMedicine(filters));
                    if (data.results) {
                        setRecMedicine({
                            data: data.results,
                            rowTotal: data.rowTotal
                        });
                        setSelectedMedication(data.results.find(item => item.id === selectedMedication?.id));
                    }
                    onCloseModal();
                }
            } else {
                fireWarningAlert("Asegúrese de completar todos los campos", "Intenta de nuevo");
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onSearchMedicine = (value: string) => {
        setFilters({
            ...filters,
            search: value,
            page: 1
        });
    };

    const onOpenEditModal = async () => {
        if (selectedMedication) {
            const data = await dispatch(getOneMedicine(selectedMedication.id));

            if (data) {
                setExternalMedicinePayload({
                    ...externalMedicinePayload,
                    dra: data.dra,
                    every: data.every,
                    measurementUnit: data.measurementUnit,
                    id: data.id,
                    medStatus: data.medStatus,
                    observation: data.observation,
                    quantity: data.quantity,
                    time: data.time,
                    undefinedDate: data.undefinedDate,
                    until: data.until,
                    exmId: data.exmId
                });
                const selMedicine = externalMedicine.find(item => item.id === data.exmId);
                if (selMedicine) setNewMedicinePayload({
                    ...newMedicinePayload,
                    ...selMedicine
                });
                onOpenModal("edit");
            }
        }
    };

    const renderHeader = () => {
        return (
            <div className="d-flex align-items-center">
                <TextField
                    className="w-50"
                    variant="filled"
                    placeholder="Escribe aquí para buscar"
                    endIcon={<IconSearch />}
                    value={filters.search}
                    onChange={({ target }) => onSearchMedicine(target.value)}
                />
                <div className="flex-fill"></div>
                <RadioButton
                    className={`py-2 px-3 fs-5 rounded ${filters.status === "currentUse" ? "bg-primary bg-opacity-10" : ""}`}
                    label="Vigente"
                    name="status"
                    id="currentUse"
                    value="currentUse"
                    checked={filters.status === "currentUse"}
                    onChange={({ target }) => setFilters({
                        ...filters,
                        status: target.value as "currentUse",
                        page: 1
                    })}
                />
                <RadioButton
                    className={`py-2 px-3 fs-5 rounded ${filters.status === "suspended" ? "bg-primary bg-opacity-10" : ""}`}
                    label="Suspendido"
                    name="status"
                    id="suspended"
                    value="suspended"
                    checked={filters.status === "suspended"}
                    onChange={({ target }) => setFilters({
                        ...filters,
                        status: target.value as "suspended",
                        page: 1
                    })}
                />
            </div>
        );
    };

    const renderRow = (item: ReconciliationMedicine) => {
        return (
            <tr
                key={item.id}
                className="pointer"
                onClick={() => setSelectedMedication(item)}
                style={{ background: item.editable ? "#EFF8E4" : "auto" }}
            >
                <td className="nowrap">{item.date}</td>
                <td title={item.medicine}>
                    <div className="text-ellipsis overflow-hidden" style={{ maxWidth: 160 }}>
                        {item.medicine}
                    </div>
                </td>
                <td className="nowrap">{item.dose}</td>
                <td>{item.frequency}</td>
                <td className="nowrap">{item.to}</td>
                <td>{item.via}</td>
                <td title={item.observaciones}>
                    <div className="text-ellipsis overflow-hidden" style={{ maxWidth: 160 }}>
                        {item.observaciones}
                    </div>
                </td>
                <td align="center">
                    <Badge color="secondary" className="px-4">{item.estado}</Badge>
                </td>
            </tr>
        );
    };

    const renderTable = () => {
        return (
            <Table className="mt-4">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Medicamento</th>
                        <th>Dosis</th>
                        <th>Frecuencia</th>
                        <th>Hasta</th>
                        <th>Vía</th>
                        <th>Observación</th>
                        <th><div className="text-center">Estado</div></th>
                    </tr>
                </thead>
                <tbody>
                    {recMedicine.data.map((item) => renderRow(item))}
                </tbody>
            </Table>
        );
    };

    const renderModal = () => {
        const formattedExternalMedicine = externalMedicine.map(item => ({ label: item.name, value: item.id || "" }));
        const formattedTimes = medicineSelects?.times?.map(item => ({ label: item.tim_name, value: item.tim_id }));
        const formattedOrderMeasure = medicineSelects?.orderMeasure?.map(item => (
            { label: item.label, value: item.id }
        ));
        const formattedDrugRouteAdm = medicineSelects?.drugRouteAdministsration?.map(item => (
            { label: item.name, value: item.id }
        ));

        const orderMeasureValue = formattedOrderMeasure.find(item => item.value === externalMedicinePayload.measurementUnit);
        const timeValue = formattedTimes.find(item => item.value === externalMedicinePayload.time);
        const drugRouteAdmValue = formattedDrugRouteAdm.find(item => item.value === externalMedicinePayload.dra);

        return (
            <Modal
                isOpen={createModal.isOpen}
                onClose={onCloseModal}
                width={500}
            >
                <h2 className="fw-bold text-secondary">{createModal.state === "create" ? "Crear" : "Editar"} medicamento</h2>

                <div className="d-flex align-items-end">
                    <div className="flex-grow-1 mt-3">
                        <label className="text-label">Nombre medicamento</label>
                        {disabledSustance ? (
                            <Select
                                options={formattedExternalMedicine}
                                value={formattedExternalMedicine.find(item => item.value === newMedicinePayload.id)}
                                onChange={({ option }) => onChangeMedicine(option.value)}
                                isSearchable
                            />
                        ) : (
                            <TextField
                                type="text"
                                className="w-100"
                                placeholder="Escribe..."
                                value={newMedicinePayload.name}
                                onChange={({ target }) => setNewMedicinePayload({ ...newMedicinePayload, name: target.value })}
                            />
                        )}
                    </div>
                    {disabledSustance && (
                        <IconCirclePlus
                            className="mb-1 ms-2 pointer text-primary hoverable"
                            onClick={() => {
                                setDisabledSubstance(false);
                                setNewMedicinePayload({
                                    ...newMedicinePayload,
                                    chemicalSubstance: ""
                                });
                                setExternalMedicinePayload({
                                    ...externalMedicinePayload,
                                    exmId: undefined
                                });
                            }}
                        />
                    )}
                </div>
                <div className="d-flex align-items-end">
                    <div className="flex-grow-1 mt-3">
                        <label className="text-label">Sustancia química</label>
                        <TextField
                            type="text"
                            placeholder={!disabledSustance ? "Escribe..." : ""}
                            value={newMedicinePayload.chemicalSubstance}
                            onChange={({ target }) => setNewMedicinePayload(
                                { ...newMedicinePayload, chemicalSubstance: target.value }
                            )}
                            disabled={disabledSustance}
                        />
                    </div>
                </div>
                {!(disabledSustance || isLoading) && (
                    <div className="d-flex justify-content-end mt-2">
                        <IconCheck
                            className={`mb-1 ${validateNewMedicine() ? "text-success pointer" : "text-muted"}`}
                            onClick={onCreateNewMedicine}
                        />
                        <IconX
                            className="mb-1 ms-1 pointer text-danger"
                            onClick={() => {
                                setDisabledSubstance(true);
                                setNewMedicinePayload({
                                    ...newMedicinePayload,
                                    chemicalSubstance: "",
                                    name: "",
                                    id: undefined
                                });
                            }}
                        />
                    </div>
                )}
                {isLoading && (
                    <div className="small-loader align-self-end mt-2 me-5"></div>
                )}
                <div className="row mt-3">
                    <div className="col">
                        <label className="text-label">Cantidad</label>
                        <TextField
                            type="number"
                            placeholder="Escribe..."
                            value={externalMedicinePayload.quantity}
                            onChange={({ target }) => setExternalMedicinePayload({
                                ...externalMedicinePayload,
                                quantity: target.value
                            })}
                            onKeyDown={(event) => event.key === "-" && event.preventDefault()}
                            disabled={!disabledSustance}
                        />
                    </div>
                    <div className="col">
                        <label className="text-label">Unidad</label>
                        <Select
                            options={formattedOrderMeasure}
                            value={orderMeasureValue}
                            onChange={({ option }) => setExternalMedicinePayload({
                                ...externalMedicinePayload,
                                measurementUnit: option.value
                            })}
                            isSearchable
                            width={153}
                            disabled={!disabledSustance}
                        />
                    </div>
                    <div className="col">
                        <label className="text-label">Cada</label>
                        <TextField
                            value={externalMedicinePayload.every}
                            onChange={({ target }) => setExternalMedicinePayload({
                                ...externalMedicinePayload,
                                every: target.value
                            })}
                            placeholder="Escribe..."
                            onKeyDown={(event) => event.key === "-" && event.preventDefault()}
                            disabled={!disabledSustance}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label className="text-label">Periodo</label>
                        <Select
                            value={timeValue}
                            options={formattedTimes}
                            onChange={({ option }) => setExternalMedicinePayload({
                                ...externalMedicinePayload,
                                time: option.value
                            })}
                            width={135}
                            disabled={!disabledSustance}
                        />
                    </div>
                    <div className="col">
                        <div className="d-flex align-items-end justify-content-between">
                            <label className="text-label">Hasta</label>
                            <Checkbox
                                label="Indefinido"
                                style={{ marginRight: -7 }}
                                id="undefinedDate"
                                checked={externalMedicinePayload.undefinedDate ? true : false}
                                onChange={({ target }) =>
                                    setExternalMedicinePayload({
                                        ...externalMedicinePayload,
                                        undefinedDate: target.checked ? 1 : 0,
                                        until: ""
                                    })
                                }
                                disabled={!disabledSustance}
                            />
                        </div>
                        <TextField
                            type="date"
                            style={{ width: 150 }}
                            value={externalMedicinePayload.until}
                            onChange={({ target }) => setExternalMedicinePayload({
                                ...externalMedicinePayload,
                                until: target.value
                            })}
                            disabled={(externalMedicinePayload.undefinedDate ? true : false || !disabledSustance)}
                        />
                    </div>
                    <div className="col">
                        <label className="text-label">Vía</label>
                        <Select
                            value={drugRouteAdmValue}
                            options={formattedDrugRouteAdm}
                            onChange={({ option }) => setExternalMedicinePayload({
                                ...externalMedicinePayload,
                                dra: option.value
                            })}
                            width={135}
                            disabled={!disabledSustance}
                        />
                    </div>
                </div>
                <div className="mt-3 w-100">
                    <label className="text-label">Observaciones</label>
                    <textarea
                        value={externalMedicinePayload.observation}
                        onChange={({ target }) => setExternalMedicinePayload({
                            ...externalMedicinePayload,
                            observation: target.value
                        })}
                        className="form-control no-resize"
                        rows={5}
                        disabled={!disabledSustance}
                    />
                </div>
                <Button
                    variant="primary"
                    className="align-self-end mt-3"
                    onClick={onSubmitForm}
                    isLoading={isLoading}
                >
                    Aceptar
                </Button>
            </Modal >
        );
    };

    const render = () => {
        return (
            <div className="mt-3">
                <SectionCard>
                    {renderHeader()}
                    <div className="table-responsive">
                        {renderTable()}
                    </div>
                    <TablePagination
                        currentPage={filters.page}
                        perPage={10}
                        totalCount={recMedicine.rowTotal}
                        totalPages={recMedicine.rowTotal}
                        onMaxPage={(value) => setFilters({ ...filters, page: value as number })}
                        onMinPage={(value) => setFilters({ ...filters, page: value as number })}
                        onNextPage={(value) => setFilters({ ...filters, page: value as number })}
                        onPrevPage={(value) => setFilters({ ...filters, page: value as number })}
                    />
                </SectionCard>
                <div className="d-flex align-items-center mt-3">
                    <Button
                        variant="text"
                        endIcon={<IconCirclePlus />}
                        disabled={isDisabledForm}
                        onClick={() => onOpenModal("create")}
                    >
                        Añadir
                    </Button>
                    {selectedMedication?.editable ? (
                        <Button
                            variant="text"
                            endIcon={<IconEdit />}
                            onClick={onOpenEditModal}
                        >
                            Editar
                        </Button>
                    ) : null}
                </div>
                <SectionCard className="mt-1">
                    {selectedMedication ? (
                        <div className="row">
                            <div className="col-2">
                                <div className="text-primary fw-bold mb-3">Medicamento:</div>
                                <div className="text-primary fw-bold">Observación:</div>
                            </div>
                            <div className="col">
                                <div className="mb-3">{selectedMedication.medicine}</div>
                                <div>{selectedMedication.observaciones}</div>
                            </div>
                        </div>
                    ) : (
                        <h5 className="text-muted text-center">
                            Seleccione una conciliación
                        </h5>
                    )}
                </SectionCard>
                {renderModal()}
            </div>
        );
    };

    return render();
}