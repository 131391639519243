import { useRef } from "react";
import { DebouncedState } from "use-debounce";
//
import { SectionCard } from "@/components";
import { OrthopticPayload } from "@/models/sheets/orthoptic";

interface ChromaticVisionParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: DebouncedState<() => void>;
    isDisabledForm: boolean;
}

export default function ChromaticVision({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm }: ChromaticVisionParams) {
    const diskRef = useRef<HTMLInputElement>(null);
    const correctRef = useRef<HTMLInputElement>(null);

    const onChangeIshihara = (label: "disk" | "correct" | "observations", value: string) => {
        const options = {
            ...orthopticPayload,
            chromaticVision: {
                ...orthopticPayload.chromaticVision,
                ishihara: {
                    ...orthopticPayload.chromaticVision?.ishihara,
                    [label]: value === "" ? null : value
                }
            }
        };
        setOrthopticPayload(options);
        if (Number(options.chromaticVision.ishihara.correct) <= Number(options.chromaticVision.ishihara.disk)) {
            diskRef.current?.classList.remove("border-danger");
            correctRef.current?.classList.remove("border-danger");
            onSubmit();
        } else {
            diskRef.current?.classList.add("border-danger");
            correctRef.current?.classList.add("border-danger");
            onSubmit.cancel();
        }
    };

    const onChangeFarnsworthTest = (eye: "od" | "oi", value: string) => {
        const options = {
            ...orthopticPayload,
            chromaticVision: {
                ...orthopticPayload.chromaticVision,
                farnsworthTest: {
                    ...orthopticPayload.chromaticVision?.farnsworthTest,
                    [eye]: value
                }
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const render = () => {
        const disk = Number(orthopticPayload.chromaticVision?.ishihara?.disk);
        const correct = Number(orthopticPayload.chromaticVision?.ishihara?.correct);

        let score: string | undefined = undefined;
        if (disk && correct && disk > correct) {
            score = ((correct / disk) * 100).toFixed(2) + "%";
        } else {
            score = "";
        }

        return (
            <SectionCard>
                <h5 className="text-label fw-bold">Visión cromática</h5>
                <hr className="text-primary mt-2" />
                <div className=" gy-3 gx-5">
                    <div className="">
                        <h5 className="text-label fw-bold fw-bold">Ishihara</h5>
                        <div className="d-flex align-items-center mt-3">
                            <div className="d-flex align-items-center justify-content-center me-3 flex-fill">
                                <span className="me-2 text-secondary">Platos</span>
                                <input
                                    disabled={isDisabledForm}
                                    ref={diskRef}
                                    type="number"
                                    className="form-control"
                                    value={orthopticPayload.chromaticVision?.ishihara?.disk}
                                    onChange={({ target }) => onChangeIshihara("disk", target.value)}
                                    onKeyDown={(event) => (event.key === "-" || event.key === ".") && event.preventDefault()}
                                />
                            </div>
                            <div className="d-flex align-items-center justify-content-center me-3 flex-fill">
                                <span className="me-2 text-secondary">Correcto</span>
                                <input
                                    disabled={isDisabledForm}
                                    ref={correctRef}
                                    type="number"
                                    className="form-control"
                                    value={orthopticPayload.chromaticVision?.ishihara?.correct}
                                    onChange={({ target }) => onChangeIshihara("correct", target.value)}
                                    onKeyDown={(event) => (event.key === "-" || event.key === ".") && event.preventDefault()}
                                />
                            </div>
                            <div className="d-flex align-items-center justify-content-center flex-fill">
                                <span className="me-2 text-secondary">Puntaje</span>
                                <input
                                    disabled={isDisabledForm}
                                    type="text"
                                    className="form-control"
                                    value={score}
                                    readOnly
                                />
                            </div>
                        </div>
                        <input
                            type="text"
                            disabled={isDisabledForm}
                            className="form-control mt-3"
                            placeholder="Observaciones..."
                            value={orthopticPayload.chromaticVision?.ishihara?.observations}
                            onChange={({ target }) => onChangeIshihara("observations", target.value)}
                        />
                    </div>
                    <div className="pt-2">
                        <h5 className="text-label fw-bold">Farnsworth test</h5>
                        <div className="d-flex align-items-center justify-content-center flex-fill mt-3 p-2 bg-od" style={{borderRadius: "0.5rem 0.5rem 0rem 0rem"}}>
                            <span className="text-secondary" style={{ width: 30 }}>OD</span>
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control"
                                value={orthopticPayload.chromaticVision?.farnsworthTest?.od}
                                onChange={({ target }) => onChangeFarnsworthTest("od", target.value)}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-fill bg-oi p-2 " style={{borderRadius: "0rem 0rem 0.5rem 0.5rem"}}>
                            <span className="text-secondary" style={{ width: 30 }}>OI</span>
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control"
                                value={orthopticPayload.chromaticVision?.farnsworthTest?.oi}
                                onChange={({ target }) => onChangeFarnsworthTest("oi", target.value)}
                            />
                        </div>
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}