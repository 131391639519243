import React from "react";
import "./SectionCard.scss";

interface SectionCardInterface extends React.HTMLAttributes<HTMLElement> {
    cardAidStyle?: boolean;
    height?: string;
    backgroundColor?: string;
}

export function SectionCard({
    children,
    className,
    height,
    cardAidStyle = false,
    backgroundColor,
    ...rest
}: SectionCardInterface) {

    return (
        <div className={`${cardAidStyle ? "section-card-aids" : "section-card"}  ${className}`} {...rest}
            style={{ height: height ?? "auto", backgroundColor: backgroundColor ?? "" }}
        >
            {children}
        </div>
    );
}