import { useEffect, useState } from "react";
import { Button, Modal } from "@/components";
import { IModal } from "@/components/Modal/modal";
import { CommonSelect, ODSections, OISections, SelectData } from "@/models/sheets/orthoptic";
import { getVersionsList } from "@/services";
import "../Versions.scss";

interface ISelectModal extends IModal<SelectData> {
    onSelectOd: (eyeData: { segment: ODSections; value?: number | null; }) => void;
    onSelectOi: (eyeData: { segment: OISections; value?: number | null; }) => void;
    onCleanSelect: (eye: "od" | "oi", segment: string) => void;
}

export default function SelectModal({ data, onSelectOd, onSelectOi, onCleanSelect, ...rest }: ISelectModal) {
    const [versionsList, setVersionList] = useState<CommonSelect[]>([]);
    const [temp, setTemp] = useState<{ segment: string; value?: number | null; }>();

    useEffect(() => {
        async function fetchData() {
            const response = await getVersionsList();
            if (response.results) setVersionList(response.results);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (data) {
            setTemp({
                segment: data.section,
                value: data.value
            });
        }
    }, [data]);

    const onChangeSection = (value: number) => {
        if (data?.eye === "od") {
            setTemp({ segment: data.section as ODSections, value });
        }
        if (data?.eye === "oi") {
            setTemp({ segment: data.section as OISections, value });
        }
    };

    const onSubmitSection = () => {
        if (data?.eye === "od") {
            onSelectOd({ segment: temp?.segment as ODSections, value: temp?.value });
        }

        if (data?.eye === "oi") {
            onSelectOi({ segment: temp?.segment as OISections, value: temp?.value });
        }
        rest.onClose();
    };

    const onCleanSegmentSelect = () => {
        setTemp({
            segment: data?.section as string,
            value: null
        });
        onCleanSelect(data?.eye as "od" | "oi", data?.section as string);
    };

    const render = () => {

        return (
            <Modal {...rest} positionModal="center" width={410}>
                <div className="d-flex align-items-center">
                    {data?.eye === "od" && (
                        <h4 className="text-secondary fw-bold">
                            Ojo derecho
                        </h4>
                    )}
                    {data?.eye === "oi" && (
                        <h4 className="text-secondary fw-bold">
                            Ojo izquierdo
                        </h4>
                    )}
                    <h4 className="mx-2">-</h4>
                    <h4 className="text-secondary fw-bold">
                        {data?.section.toUpperCase()}
                    </h4>
                </div>
                <div className="mt-3 align-self-center">
                    {versionsList.map(ver => (
                        <>
                            <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id={`radio-${ver.id}`}
                                value={ver.id}
                                autoComplete="off"
                                checked={temp?.value === ver.id}
                                onChange={({ target }) => onChangeSection(+target.value)}
                            />
                            <label className="btn btn-outline-secondary radio-button m-2" htmlFor={`radio-${ver.id}`}>
                                {ver.description}
                            </label>
                        </>
                    ))}
                </div>
                <div className="d-flex align-items-center justify-content-end mt-4">
                    <Button
                        onClick={() => onCleanSegmentSelect()}
                        className="align-self-end me-3"
                    >
                        Limpiar
                    </Button>
                    <Button
                        variant="primary"
                        className="align-self-end"
                        onClick={() => onSubmitSection()}
                    >
                        Aceptar
                    </Button>
                </div>
            </Modal >
        );
    };

    return render();
}