import { useEffect, useState } from "react";
import { SectionCard, Select } from "@/components";
import { CommonSelect, OrthopticPayload } from "@/models/sheets/orthoptic";
import { getFixationService } from "@/services";

interface FixationParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
}

export default function Fixation({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm }: FixationParams) {
    const [fixation, setFixation] = useState<CommonSelect[]>([]);

    useEffect(() => {
        async function fetchData() {
            const data = await getFixationService();
            setFixation(data.results);
        }
        fetchData();
    }, []);

    const onChangeFixation = (fixation: "fixIdOd" | "fixIdOi" | "fixObservations", value: string) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            fixation: {
                ...orthopticPayload.fixation,
                [fixation]: value
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const render = () => {
        const fixationFormatted = fixation.map(item => ({ label: item.description, value: item.id }));
        const fixationOdValue = fixationFormatted.find(item => item.value === orthopticPayload.fixation?.fixIdOd);
        const fixationOiValue = fixationFormatted.find(item => item.value === orthopticPayload.fixation?.fixIdOi);

        return (
            <SectionCard className="h-100">
                <h5 className="text-label fw-bold">Fijación</h5>
                <hr className="text-primary mt-2 mb-2" />
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex align-items-center mt-3 p-2 bg-od"
                            style={{ borderRadius: "0.5rem 0.5rem 0rem 0rem" }}
                        >
                            <span style={{ width: 30 }} className="text-secondary">OD</span>
                            <Select
                                disabled={isDisabledForm}
                                width="100%"
                                style={{ width: "100%" }}
                                options={fixationFormatted}
                                value={fixationOdValue}
                                onChange={({ option }) => onChangeFixation("fixIdOd", option.value)}
                            />
                        </div>
                        <div className="d-flex align-items-center p-2 bg-oi" style={{ borderRadius: "0rem 0rem 0.5rem 0.5rem" }}>
                            <span style={{ width: 30 }} className="text-secondary">OI</span>
                            <Select
                                disabled={isDisabledForm}
                                width="100%"
                                style={{ width: "100%" }}
                                options={fixationFormatted}
                                value={fixationOiValue}
                                onChange={({ option }) => onChangeFixation("fixIdOi", option.value)}
                            />
                        </div>
                    </div>
                    <div className="d-flex col-12 mt-3 ">
                        <textarea
                            disabled={isDisabledForm}
                            placeholder="Observaciones..."
                            style={{ height: 70 }}
                            className="form-control no-resize align-self-end"
                            value={orthopticPayload.fixation?.fixObservations}
                            onChange={({ target }) => onChangeFixation("fixObservations", target.value)}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}