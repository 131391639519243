import { Fragment, useRef, MouseEvent, useEffect } from "react";
import { createPortal } from "react-dom";
//
import { IModal } from "./modal";
//
import "./Modal.scss";
import { IconClose } from "@/assets/icons";

export function Modal({
    children,
    isOpen,
    onClose,
    width,
    drawer,
    positionModal,
    drawerTitle,
    className,
    fullScreen,
    modalTitle,
    height
}: IModal) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const modalId = document.getElementById("modal")!;
    const modalRef = useRef(null);

    useEffect(() => {
        const portals = document.getElementsByClassName("modal-wrapper").length;
        if (isOpen || portals > 0) {
            document.body.style.overflow = "hidden";
        }

        return function () {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    useEffect(() => {
        function close(event: KeyboardEvent) {
            if (event.key === "Escape") onClose();
        }
        document.addEventListener("keydown", close);

        return function () {
            document.removeEventListener("keydown", close);
        };
    }, [onClose]);

    const handleCloseModal = (event: MouseEvent) => {
        if (modalRef.current === event.target) {
            onClose();
        }
    };

    const renderContent = () => {
        return (
            <Fragment>
                {isOpen && (
                    <div
                        className={`modal-wrapper ${positionModal === "center" ? "align-items-center pt-0" : ""}`}
                        ref={modalRef}
                        onClick={handleCloseModal}
                    >
                        <div className={`${!drawer ? "modal-container mt-0" : `modal-drawer__${drawer}`} ${fullScreen ? "modal-fullScreen" : ""} ${className}`} style={{ width: width, height: height }}>
                            <div className={`w-100 d-flex flex-column ${drawer ? "px-5 py-4" : ""} ${(drawer || fullScreen) && "h-100"}`}>
                                <div className="d-flex align-items-start">
                                    {modalTitle &&
                                        <h1 className="text-label text-start px-2 fw-bold">
                                            {modalTitle}
                                        </h1>
                                    }
                                    <div onClick={() => onClose()} className="close-button pointer">
                                        <IconClose />
                                    </div>
                                    {drawerTitle &&
                                        <h1 className="text-label text-start px-2 fw-bold">
                                            Información lectura
                                        </h1>
                                    }
                                </div>
                                {(drawerTitle || modalTitle) && <hr className="w-100" />}
                                {children}
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    };

    return createPortal(renderContent(), modalId);
}