import {
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
//
import { ProvocativeChart as IProvocativeChart, ProvocativeEvidencePayload } from "@/models/sheets/oftalmology";
//
// import { Button } from "@/components";
import { useAppDispatch } from "@/hooks";
import { PressureCurveBody } from "@/models/sheets/diagnosticAids";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



export default function ProvocativeChart({ data, sheet }: { data: ProvocativeEvidencePayload[] | PressureCurveBody[], sheet?: string; }) {
    const dispatch = useAppDispatch();

    const [chartResult, setChartResult] = useState<IProvocativeChart>({
        evidenceOd: [],
        evidenceOi: []
    });

    const transformToDesiredFormatADX = (data: PressureCurveBody[]) => {
        const transformedData: IProvocativeChart = {
            evidenceOd: [],
            evidenceOi: [],
        };

        data.forEach(item => {
            if (!transformedData.evidenceOd) {
                transformedData.evidenceOd = [];
            }
            if (!transformedData.evidenceOi) {
                transformedData.evidenceOi = [];
            }

            transformedData.evidenceOd.push({
                order: item?.order ?? 0,
                provocativeEvidence: item?.od ?? 0,
                time: item.time ?? ""
            });

            transformedData.evidenceOi.push({
                order: item.order ?? 0,
                provocativeEvidence: item.oi ?? 0,
                time: item.time ?? "",
            });
        });


        return transformedData;
    };
    const transformToDesiredFormat = (data: ProvocativeEvidencePayload[]) => {
        const transformedData: IProvocativeChart = {
            evidenceOd: [],
            evidenceOi: [],
        };

        data.forEach(item => {
            const { provocativeEvidence } = item;

            if (!transformedData.evidenceOd) {
                transformedData.evidenceOd = [];
            }
            if (!transformedData.evidenceOi) {
                transformedData.evidenceOi = [];
            }

            transformedData.evidenceOd.push({
                order: provocativeEvidence?.order ?? 0,
                provocativeEvidence: provocativeEvidence?.evidenceOd ?? 0,
                time: provocativeEvidence.time ?? ""
            });

            transformedData.evidenceOi.push({
                order: provocativeEvidence.order ?? 0,
                provocativeEvidence: provocativeEvidence.evidenceOi ?? 0,
                time: provocativeEvidence.time ?? "",
            });
        });

        return transformedData;
    };
    useEffect(() => {
        async function fetchData() {
            if (data) {
                if (sheet === "ADX") {
                    if (data) setChartResult(transformToDesiredFormatADX(data as PressureCurveBody[]));

                } else {
                    if (data) setChartResult(transformToDesiredFormat(data as ProvocativeEvidencePayload[]));
                }
            }
        }
        fetchData();

        return () => {
            setChartResult({
                evidenceOd: [],
                evidenceOi: []
            });
        };
    }, [dispatch, data, sheet]);


    const render = () => {
        const options: ChartOptions<"line"> = {
            scales: {
                y: {
                    title: {
                        display: true,
                        text: "PIO",
                        color: "#003F80"
                    }
                },
                x: {
                    title: {
                        display: true,
                        text: "Hora",
                        color: "#003F80"
                    }
                }
            },
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    align: "start",
                    labels: {
                        usePointStyle: true,
                    },
                    title: {
                        display: true,
                        position: "start",
                    },
                },
            },
        };

        const chartData = {
            labels: chartResult.evidenceOd?.map(el => el.time),
            datasets: [
                {
                    label: "OD",
                    data: chartResult.evidenceOd?.map(el => el.provocativeEvidence),
                    borderColor: "#A3E4EC",
                    backgroundColor: "#A3E4EC",
                },
                {
                    label: "OI",
                    data: chartResult.evidenceOi?.map(el => el.provocativeEvidence),
                    borderColor: "#5C84AD",
                    backgroundColor: "#5C84AD",
                }
            ],
        };

        const plugin = {
            id: "a",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            beforeInit(chart: any) {
                const originalFit = chart.legend.fit;
                chart.legend.fit = function fit() {
                    originalFit.bind(chart.legend)();
                    this.height += 15;
                };
            }
        };

        return (
            <div style={{ border: "10px solid #ccc3", padding: "10px", borderRadius: "10px" }}>
                <Line options={options} data={chartData} plugins={[plugin]} />
            </div>
        );
    };

    return render();
}