import { useEffect, useRef, useState } from "react";
import { IconChevronDown, IconDownload } from "@tabler/icons-react";
import ReactToPrint from "react-to-print";
//
import { IModal } from "@/components/Modal/modal";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { getAHAdaptationDetail, getAHBackgroundDetail, getAHDIDetails, getAHLowVisionDetail, getAHOphthalmologyDetail, getAHOptometryDetail, getAHOrthopticDetail, getAttentionHistoryList, getAHPreoperativeDetail, getAHPreconsultingDetail, getAHDownloadData } from "../history.actions";
import { LowVisionDetail } from "@/models/historyDetails/lowVision";
import { OptometryDetails as IOptometryDetails } from "@/models/historyDetails/optometry";
import { OphthalMologyDetails } from "@/models/historyDetails/ophthalmology";
import { OrthopticDetails as IOrthopticDetails } from "@/models/historyDetails/orthoptic";
//
import { Button, Dropdown, DropdownItem, DropdownMenu, Modal } from "@/components";
import OptometryDetails from "./Details/OptometryDetails";
import AdaptationDetails from "./Details/AdaptationDetails";
import PreoperativeDetails from "./Details/PreoperativeDetails";
import OphthalmologyDetails from "./Details/OphthalmologyDetails";
import PreconsultDetails from "./Details/PreconsultDetails";
import AntecedentsDetails from "./Details/AntecedentsDetails";
import OrthopticDetails from "./Details/OrthopticDetails";
import LowVisionDetails from "./Details/LowVisionDetails";
//
import { PreoperativeDetail } from "@/models/historyDetails/preoperative";
import { BackgroundDetail } from "@/models/historyDetails/background";
import DiagnosticImpressionDetails from "./Details/DiagnosticImpressionDetails";
import { DiagnosticImpression } from "@/models/historyDetails/diagnosticImpression";
import { AdaptationDetail } from "@/models/historyDetails/adaptation";
import { AttentionHistoryList } from "@/models/history";
import { PreconsultingDetail } from "@/models/historyDetails/preconsulting";
import { DownloadInfo } from "@/models/historyDetails/download";

interface IDetails extends IModal<{ sheet: string; mcfId: number; diId?: number, origin?: string; }> {
    onChangeDetail?: (mcfId: number) => void; patientIdProp?: number; patientName?: string; allData?: boolean; patientAge?: number | string; patientIdentification?: number | string;
}

export default function Details({ data, onChangeDetail, patientIdProp, patientName, patientAge, allData, patientIdentification, ...rest }: IDetails) {
    const dispatch = useAppDispatch();
    const pdfRef = useRef<HTMLTableElement>(null);

    const accountId = useAppSelector(state => state.workspace.id);
    const patient = useAppSelector(state => state.patientAttention.appointmentSelected);

    const [attentionHistoryList, setAttentionHistoryList] = useState<AttentionHistoryList[]>([]);
    const [optometryDetails, setOptometryDetails] = useState<IOptometryDetails>({});
    const [lowVisionDetails, setLowVisionDetails] = useState<LowVisionDetail>({});
    const [ophthalmologyDetails, setOphthalmologyDetails] = useState<OphthalMologyDetails>({});
    const [adaptationDetail, setAdaptationDetail] = useState<AdaptationDetail>({});
    const [orthopticDetails, setOrthopticDetails] = useState<IOrthopticDetails>({});
    const [backgroundDetail, setBackgroundDetail] = useState<BackgroundDetail>({});
    const [diDetail, setDiDetail] = useState<DiagnosticImpression>({});
    const [preoperativeDetails, setPreoperativeDetails] = useState<PreoperativeDetail>({});

    const [selectedDetail, setSelectedDetail] = useState<AttentionHistoryList>({});
    const [preconsultingDetail, setPreconsultingDetail] = useState<PreconsultingDetail>({});

    const [downloadData, setDownloadData] = useState<DownloadInfo>({});
    const [isDocumentReady, setIsDocumentReady] = useState<boolean>(false);

    useEffect(() => {
        if (!isDocumentReady) {
            setTimeout(() => {
                setIsDocumentReady(true);
            }, 5000);
        }
    }, [isDocumentReady]);

    useEffect(() => {
        async function fetch() {
            if (selectedDetail.mcfId) {
                const response = await dispatch(getAHDownloadData(selectedDetail.eaccount ?? null, selectedDetail.mcfId));
                if (response) setDownloadData(response);
            }
        }
        fetch();
    }, [dispatch, accountId, selectedDetail.mcfId, selectedDetail.eaccount]);

    useEffect(() => {
        async function fetch() {
            if (data?.sheet === "lowVision") {
                const response = await dispatch(getAHLowVisionDetail({
                    prefix: data.sheet,
                    mcfId: data.mcfId
                }));
                if (response) setLowVisionDetails(response);
            }
        }
        fetch();
    }, [data?.mcfId, accountId, data?.sheet, dispatch]);

    useEffect(() => {
        async function fetch() {
            if (data?.sheet === "optometry") {
                const response = await dispatch(getAHOptometryDetail({
                    prefix: data.sheet,
                    mcfId: data.mcfId
                }));
                if (response) setOptometryDetails(response);
            }
        }
        fetch();
    }, [data?.mcfId, accountId, data?.sheet, dispatch]);

    useEffect(() => {
        async function fetch() {
            if (data?.sheet === "ophthalmology") {
                const response = await dispatch(getAHOphthalmologyDetail({
                    prefix: data.sheet,
                    mcfId: data.mcfId
                }));
                if (response) setOphthalmologyDetails(response);
            }
        }
        fetch();

    }, [data?.mcfId, accountId, data?.sheet, dispatch]);

    useEffect(() => {
        async function fetch() {
            if (data?.sheet === "orthoptic") {
                const response = await dispatch(getAHOrthopticDetail({
                    prefix: data.sheet,
                    mcfId: data.mcfId
                }));
                if (response) setOrthopticDetails(response);
            }
        }
        fetch();

    }, [data?.mcfId, accountId, data?.sheet, dispatch]);

    useEffect(() => {
        async function fetch() {
            if (data?.sheet === "background") {
                const response = await dispatch(getAHBackgroundDetail({
                    prefix: data.origin ? data.origin : "verifica la tavuel",
                    eaccount: accountId,
                    mcfId: data.mcfId
                }));
                if (response) setBackgroundDetail(response);
            }
        }
        fetch();

    }, [data?.mcfId, accountId, data?.sheet, dispatch, data?.origin]);

    useEffect(() => {
        async function fetch() {
            if (data?.sheet === "diagnosticImpression") {
                const response = await dispatch(getAHDIDetails({
                    prefix: data.sheet,
                    mcfId: data.mcfId
                }));
                if (response) {
                    const diSelected = response.diagnosticImpression?.find(
                        el => el.id === data.diId
                    );
                    if (diSelected) setDiDetail(diSelected);
                }
            }
        }
        fetch();

    }, [data?.mcfId, accountId, data?.sheet, data?.diId, dispatch]);

    useEffect(() => {
        async function fetch() {
            if (data?.sheet === "preoperative") {
                const response = await dispatch(getAHPreoperativeDetail({
                    prefix: data.sheet,
                    mcfId: data.mcfId
                }));
                if (response) setPreoperativeDetails(response);
            }
        }
        fetch();
    }, [data?.mcfId, accountId, data?.sheet, dispatch]);

    useEffect(() => {
        async function fetch() {
            if (data?.sheet === "adaptation") {
                const response = await dispatch(getAHAdaptationDetail({
                    prefix: data.sheet,
                    mcfId: data.mcfId
                }));
                if (response) setAdaptationDetail(response);
            }
        }
        fetch();

    }, [data?.mcfId, accountId, data?.sheet, dispatch]);

    useEffect(() => {
        async function fetch() {
            if (data?.sheet === "preconsulting") {
                const response = await dispatch(getAHPreconsultingDetail({
                    prefix: data.sheet,
                    eaccount: accountId,
                    mcfId: data.mcfId
                }));
                if (response) setPreconsultingDetail(response);
            }
        }
        fetch();

    }, [data?.mcfId, accountId, data?.sheet, dispatch]);

    useEffect(() => {
        async function fetch() {
            if (data?.sheet === "preoperative") {
                const response = await dispatch(getAHPreoperativeDetail({
                    prefix: data.sheet,
                    mcfId: data.mcfId
                }));
                if (response) setPreoperativeDetails(response);
            }
        }
        fetch();
    }, [accountId, data?.sheet, data?.mcfId, dispatch]);

    useEffect(() => {
        async function fetch() {
            const response = await dispatch(getAttentionHistoryList({
                prefix: data?.sheet as string,
                patient_id: patientIdProp ?? patient.patientId
            }));
            if (response) {
                const selected = response.find(item => item.mcfId === data?.mcfId);
                setAttentionHistoryList(response);
                if (selected) setSelectedDetail(selected);
            }
        }
        fetch();
    }, [accountId, data?.sheet, data?.mcfId, patientIdProp, patient.patientId, dispatch, allData]);

    const renderHeader = () => {
        return (
            <div className="d-flex align-items-start mt-3">
                <div className="flex-grow-1">
                    <h5 className="text-secondary"><span className="fw-bold">Paciente:</span> {patientName ?? patient.patientName} - {patientIdentification ?? patient.patientIdentification}</h5>
                    <h5 className="text-secondary">{patientAge ?? patient?.age} años</h5>
                </div>
                {data?.sheet !== "diagnosticImpression" ? (
                    <>
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <Button
                                        variant="outline"
                                        size="large"
                                        endIcon={<IconDownload />}
                                        style={{ width: 150, minHeight: 40 }}
                                        className="fs-4 me-4"
                                        disabled={!isDocumentReady}
                                    >
                                        Descargar
                                    </Button>
                                );
                            }}
                            content={() => pdfRef.current}
                        />

                        <Dropdown className="dropdown text-muted">
                            <div className="d-flex align-items-center pointer text-muted" data-bs-toggle="dropdown">
                                <div className="d-flex flex-column">
                                    <span className="fw-bold">{selectedDetail.createdAt}</span>
                                    <span>{selectedDetail.medName}</span>
                                </div>
                                <IconChevronDown />
                            </div>
                            <DropdownMenu className="overflow-auto" style={{ maxHeight: 300 }}>
                                {attentionHistoryList.map(item => (
                                    <DropdownItem
                                        className={`${item.mcfId === selectedDetail.mcfId ? "active text-white" : "text-muted"}`}
                                        onClick={() => {
                                            setSelectedDetail(item);
                                            onChangeDetail?.(item.mcfId as number);
                                            setIsDocumentReady(false);
                                        }}
                                    >
                                        <div className="d-flex flex-column">
                                            <span className="fw-bold">{item.createdAt}</span>
                                            <span>{item.medName}</span>
                                        </div>
                                    </DropdownItem>

                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </>
                ) : ""}
            </div>
        );
    };

    const renderDownload = () => {
        return (
            <table border={0} ref={pdfRef} className="w-100">
                <thead>
                    <tr>
                        <td>
                            <div className="print-header">
                                <div className="d-flex align-items-center justify-content-between">
                                    <img src={downloadData.header?.logo} alt="cofca_logo" width={150} height={50} style={{ objectFit: "contain" }} />
                                    <h2 className="text-secondary fw-bold">
                                        {downloadData.header?.name_of_the_institution}
                                    </h2>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className="row p-3 py-2 align-items-center bg-light text-muted mt-3">
                                    <div className="col-6">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h5 className="fw-bold">Paciente:</h5>
                                                <h5 className="fw-bold">Documento:</h5>
                                            </div>
                                            <div className="col-8">
                                                <h5>{downloadData.header?.patient?.name}</h5>
                                                <h5>{downloadData.header?.patient?.doc}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <h5 className="fw-bold nowrap">Fecha de nacimiento:</h5>
                                                <h5 className="fw-bold">Género:</h5>
                                            </div>
                                            <div className="col-6">
                                                <h5>{downloadData.header?.patient?.birthdate}</h5>
                                                <h5>{downloadData.header?.patient?.gender}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 10 }}></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="printable">
                                <div className="d-flex justify-content-between text-secondary w-100 bg-primary-lighter p-3" style={{ marginTop: -5 }}>
                                    <h5 className="fw-bold m-0">
                                        {downloadData.body?.datetime_it_was_made}
                                    </h5>
                                    <h5 className="fw-bold m-0">
                                        Evento N. {downloadData.body?.event_number} - {downloadData.body?.schedule_name}
                                    </h5>
                                    <h5 className="fw-bold m-0">
                                        Edad: {downloadData.body?.patient_age}
                                    </h5>
                                </div>
                                <div><h4 className="fw-bold m-0 text-secondary mt-4">
                                    {data?.sheet === "optometry" && "Optometría"}
                                    {data?.sheet === "adaptation" && "Adaptación LC"}
                                    {data?.sheet === "preoperative" && "Valoración preoperatoria"}
                                    {data?.sheet === "ophthalmology" && "Oftalmología"}
                                    {data?.sheet === "preconsulting" && "Preconsulta"}
                                    {data?.sheet === "orthoptic" && "Ortóptica"}
                                    {data?.sheet === "lowVision" && "Baja visión"}
                                </h4></div>
                                {data?.sheet === "optometry" && <OptometryDetails forDownload optometryDetails={optometryDetails} />}
                                {data?.sheet === "adaptation" && <AdaptationDetails forDownload adaptationDetail={adaptationDetail} />}
                                {data?.sheet === "preoperative" && <PreoperativeDetails forDownload preoperativeDetails={preoperativeDetails} />}
                                {data?.sheet === "ophthalmology" && <OphthalmologyDetails forDownload ophthalmologyDetails={ophthalmologyDetails} />}
                                {data?.sheet === "preconsulting" && <PreconsultDetails preconsultingDetail={preconsultingDetail} />}
                                {data?.sheet === "background" && <AntecedentsDetails forDownload backgroundDetail={backgroundDetail} />}
                                {data?.sheet === "orthoptic" && <OrthopticDetails forDownload orthopticDetails={orthopticDetails} />}
                                {data?.sheet === "lowVision" && <LowVisionDetails forDownload lowVisionDetails={lowVisionDetails} />}
                                {data?.sheet === "diagnosticImpression" && <DiagnosticImpressionDetails forDownload diDetail={diDetail} />}

                                <div className="d-flex flex-column mt-5">
                                    <img width={200} src={downloadData.footer?.doctor?.signature} alt="" />
                                    <h5 className="fw-bold m-0">{downloadData.footer?.doctor?.name}</h5>
                                    <h5 className="fst-italic m-0">{downloadData.footer?.doctor?.position}</h5>
                                    <h5 className="fst-italic m-0">R.M {downloadData.footer?.doctor?.medical_record}</h5>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>
                            <div style={{ height: 25 }}></div>
                            <div className="align-bottom text-end text-muted position-fixed bottom-0 w-100" style={{ marginBottom: 0 }}>
                                <h6 className="d-inline text-end mx-2">
                                    <span className="fw-bold">Teléfono: </span>
                                    {downloadData.footer?.appointment_phone}
                                </h6>
                                <h6 className="d-inline text-end mx-2">
                                    <span className="fw-bold">Email: </span>
                                    {downloadData.footer?.appointment_email}
                                </h6>
                                <h6 className="d-inline text-end mx-2">
                                    <span className="fw-bold">Dirección: </span>
                                    {downloadData.footer?.address}
                                </h6>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    };

    const render = () => {
        return (
            <>

                <Modal fullScreen={data?.sheet !== "diagnosticImpression"} className="px-5 py-4" {...rest}>
                    <h1 className="text-secondary fw-bold">
                        {data?.sheet === "ophthalmology" && "Oftalmología"}
                        {data?.sheet === "optometry" && "Optometría"}
                        {data?.sheet === "adaptation" && "Adaptación LC"}
                        {data?.sheet === "preoperative" && "Valoración preoperatoria"}
                        {data?.sheet === "preconsulting" && "Preconsulta"}
                        {data?.sheet === "background" && "Antecedentes"}
                        {data?.sheet === "orthoptic" && "Ortóptica"}
                        {data?.sheet === "lowVision" && "Baja visión"}
                        {data?.sheet === "diagnosticImpression" && "Impresión diagnóstica"}
                    </h1>
                    {renderHeader()}
                    {data?.sheet === "optometry" && <OptometryDetails optometryDetails={optometryDetails} />}
                    {data?.sheet === "adaptation" && <AdaptationDetails adaptationDetail={adaptationDetail} />}
                    {data?.sheet === "preoperative" && <PreoperativeDetails preoperativeDetails={preoperativeDetails} />}
                    {data?.sheet === "ophthalmology" && <OphthalmologyDetails ophthalmologyDetails={ophthalmologyDetails} />}
                    {data?.sheet === "preconsulting" && <PreconsultDetails preconsultingDetail={preconsultingDetail} />}
                    {data?.sheet === "background" && <AntecedentsDetails backgroundDetail={backgroundDetail} />}
                    {data?.sheet === "orthoptic" && <OrthopticDetails orthopticDetails={orthopticDetails} />}
                    {data?.sheet === "lowVision" && <LowVisionDetails lowVisionDetails={lowVisionDetails} />}
                    {data?.sheet === "diagnosticImpression" && <DiagnosticImpressionDetails diDetail={diDetail} />}
                    <div className="w-100 position-absolute" style={{ visibility: "hidden" }}>
                        {renderDownload()}
                    </div>
                </Modal>
            </>
        );
    };

    return render();
}