import { useEffect, useState } from "react";
//
import { Button, Checkbox, SectionCard, TextField } from "@/components";
import * as recordActions from "../preConsultation.actions";
//
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { PatientRecord } from "@/models";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";

interface PreconsultationOptions {
    checked: boolean;
    label: string;
    value: string;
    idName: keyof PatientRecord;
    idNotes: keyof PatientRecord;
}

export function Records() {
    const dispatch = useAppDispatch();
    const account = useAppSelector(state => state.workspace.id);
    const appointment = useAppSelector(state => state.patientAttention.appointmentSelected);
    const userId = useAppSelector(state => state.auth.user_data.id);

    const [accordions, setAccordions] = useState({
        generals: true,
        oculars: true,
        familiars: true
    });
    const [records, setRecords] = useState<PatientRecord>({
        appId: appointment.app_id,
        eaccount: account,
        userId: userId as number,
        cluId: appointment.patientId,
        arterialHypertension: 0,
        diabetesGeneral: 0,
        asthmaGeneral: 0,
        cancerGeneral: 0,
        coronaryHeartDiseaseGeneral: 0,
        tuberculosisGeneral: 0,
        rheumatoidArthritisGeneral: 0,
        copdGeneral: 0,
        previousSurgeriesGeneral: 0,
        allergiesGeneral: 0,
        useMedicationsGeneral: 0,
        othersGeneral: "",
        notesGeneral: "",
        arterialHypertensionNotes: "",
        diabetesGeneralNotes: "",
        asthmaGeneralNotes: "",
        cancerGeneralNotes: "",
        coronaryHeartDiseaseGeneralNotes: "",
        tuberculosisGeneralNotes: "",
        rheumatoidArthritisGeneralNotes: "",
        copdGeneralNotes: "",
        previousSurgeriesGeneralNotes: "",
        allergiesGeneralNotes: "",
        useMedicationsGeneralNotes: "",
        othersGeneralNotes: "",
        glaucomaOcular: 0,
        ropOcular: 0,
        dmreOcular: 0,
        glassesOcular: 0,
        dryEyeOcular: 0,
        diabeticRetinoPathyOcular: 0,
        uveitisOcular: 0,
        contactLensesOcular: 0,
        traumasOcular: 0,
        surgeriesOcular: 0,
        alertsOcular: 0,
        othersOcular: 0,
        eyeNotesOcular: "",
        glaucomaOcularNotes: "",
        ropOcularNotes: "",
        dmreOcularNotes: "",
        glassesOcularNotes: "",
        dryEyeOcularNotes: "",
        diabeticRetinoPathyOcularNotes: "",
        uveitisOcularNotes: "",
        contactLensesOcularNotes: "",
        traumasOcularNotes: "",
        surgeriesOcularNotes: "",
        alertsOcularNotes: "",
        othersOcularNotes: "",
        ahtFamiliar: 0,
        diabetesFamiliar: 0,
        asthmaFamiliar: 0,
        coronaryHeartDiseaseFamiliar: 0,
        collagenDiseaseFamiliar: 0,
        glaucomaFamiliar: 0,
        keratoconusFamiliar: 0,
        othersFamiliar: 0,
        familyNotesFamiliar: "",
        ahtFamiliarNotes: "",
        diabetesFamiliarNotes: "",
        asthmaFamiliarNotes: "",
        coronaryHeartDiseaseFamiliarNotes: "",
        collagenDiseaseFamiliarNotes: "",
        glaucomaFamiliarNotes: "",
        keratoconusFamiliarNotes: "",
        othersFamiliarNotes: ""
    });

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(recordActions.getPatientRecords({
                eaccount: account,
                appId: appointment.app_id,
                userId: userId as number,
                cluId: appointment.patientId
            }));
            if (data) {
                setRecords((prevStatus) => ({
                    ...prevStatus,
                    ...data as PatientRecord
                }));
            }
        }
        fetchData();
    }, [dispatch, account, appointment.app_id, userId, appointment.patientId]);

    const handleUpdateOptionValue = (itemId: keyof PatientRecord, value: string) => {
        const options = {
            ...records,
            [itemId]: value
        };
        setRecords(options as PatientRecord);
    };

    const handleUpdateOption = (itemName: keyof PatientRecord, itemNotes: keyof PatientRecord, isChecked: boolean) => {
        const options = {
            ...records,
            [itemName]: isChecked ? 1 : 0,
            [itemNotes]: isChecked ? records[itemNotes] : ""
        };
        setRecords(options as PatientRecord);
    };

    const handleSubmitRecords = async () => {
        await dispatch(recordActions.updatePatientRecord(records));
    };

    const renderCheckbox = (item: PreconsultationOptions) => {
        return (
            <div className="d-flex align-items-center mb-4" style={{ height: "25px" }}>
                <div style={{ width: 220 }}>
                    <Checkbox
                        className="nowrap"
                        label={item.label}
                        type="checkbox"
                        name={item.idName}
                        id={item.idName}
                        checked={item.checked}
                        onChange={(event) =>
                            handleUpdateOption(
                                item.idName as keyof PatientRecord,
                                item.idNotes as keyof PatientRecord,
                                event.target.checked
                            )}
                    />
                </div>
                {item.checked && (
                    <TextField
                        className="w-100 ms-3"
                        variant="plain"
                        type="text"
                        placeholder="Comentarios..."
                        value={item.value}
                        onChange={(event) =>
                            handleUpdateOptionValue(item.idNotes as keyof PatientRecord, event.target.value)}
                    />
                )}
            </div>
        );
    };

    const renderGeneralOptions = () => {
        return (
            <SectionCard className={`mt-4 p-4 ${accordions.generals ? "section-card-shrink" : ""}`}>
                <div
                    className="d-flex justify-content-between text-secondary mb-3 pointer"
                    onClick={() => setAccordions({ ...accordions, generals: !accordions.generals })}
                >
                    <h5 className="fw-bold">Generales</h5>
                    {accordions.generals
                        ? <IconChevronRight
                            className="pointer"
                            onClick={() => setAccordions({ ...accordions, generals: false })}
                        />
                        : <IconChevronDown
                            className="pointer"
                            onClick={() => setAccordions({ ...accordions, generals: true })}
                        />}
                </div>
                <hr />
                <div className="row">
                    <div className="col-6">
                        {renderCheckbox({
                            checked: records.arterialHypertension ? true : false,
                            label: "Hipertensión arterial",
                            value: records.arterialHypertensionNotes,
                            idName: "arterialHypertension",
                            idNotes: "arterialHypertensionNotes"
                        })}
                        {renderCheckbox({
                            checked: records.diabetesGeneral ? true : false,
                            label: "Diabetes",
                            value: records.diabetesGeneralNotes,
                            idName: "diabetesGeneral",
                            idNotes: "diabetesGeneralNotes"
                        })}
                        {renderCheckbox({
                            checked: records.asthmaGeneral ? true : false,
                            label: "Asma",
                            value: records.asthmaGeneralNotes,
                            idName: "asthmaGeneral",
                            idNotes: "asthmaGeneralNotes"
                        })}
                        {renderCheckbox({
                            checked: records.cancerGeneral ? true : false,
                            label: "Cáncer",
                            value: records.cancerGeneralNotes,
                            idName: "cancerGeneral",
                            idNotes: "cancerGeneralNotes"
                        })}
                        {renderCheckbox({
                            checked: records.coronaryHeartDiseaseGeneral ? true : false,
                            label: "Enfermedad coronaria",
                            value: records.coronaryHeartDiseaseGeneralNotes,
                            idName: "coronaryHeartDiseaseGeneral",
                            idNotes: "coronaryHeartDiseaseGeneralNotes"
                        })}
                        {renderCheckbox({
                            checked: records.tuberculosisGeneral ? true : false,
                            label: "Tuberculosis",
                            value: records.tuberculosisGeneralNotes,
                            idName: "tuberculosisGeneral",
                            idNotes: "tuberculosisGeneralNotes"
                        })}
                    </div>
                    <div className="col-6">
                        {renderCheckbox({
                            checked: records.rheumatoidArthritisGeneral ? true : false,
                            label: "Artritis Reumatoidea",
                            value: records.rheumatoidArthritisGeneralNotes,
                            idName: "rheumatoidArthritisGeneral",
                            idNotes: "rheumatoidArthritisGeneralNotes"
                        })}
                        {renderCheckbox({
                            checked: records.copdGeneral ? true : false,
                            label: "EPOC",
                            value: records.copdGeneralNotes,
                            idName: "copdGeneral",
                            idNotes: "copdGeneralNotes"
                        })}
                        {renderCheckbox({
                            checked: records.previousSurgeriesGeneral ? true : false,
                            label: "Cirugías previas",
                            value: records.previousSurgeriesGeneralNotes,
                            idName: "previousSurgeriesGeneral",
                            idNotes: "previousSurgeriesGeneralNotes"
                        })}
                        {renderCheckbox({
                            checked: records.allergiesGeneral ? true : false,
                            label: "Alergias",
                            value: records.allergiesGeneralNotes,
                            idName: "allergiesGeneral",
                            idNotes: "allergiesGeneralNotes"
                        })}
                        {renderCheckbox({
                            checked: records.useMedicationsGeneral ? true : false,
                            label: "¿Usa medicamentos?",
                            value: records.useMedicationsGeneralNotes,
                            idName: "useMedicationsGeneral",
                            idNotes: "useMedicationsGeneralNotes"
                        })}
                        {renderCheckbox({
                            checked: records.othersGeneral ? true : false,
                            label: "Otros",
                            value: records.othersGeneralNotes,
                            idName: "othersGeneral",
                            idNotes: "othersGeneralNotes"
                        })}
                    </div>
                </div>
                <div>
                    <label htmlFor="" className="text-label">Nota</label>
                    <textarea
                        className="form-control no-resize"
                        onChange={(event) => handleUpdateOptionValue("notesGeneral", event.target.value)}
                        value={records?.notesGeneral}
                        placeholder="Escribir..."
                    />
                </div>
                <Button variant="primary" className="mt-4 align-self-end" onClick={handleSubmitRecords}>
                    Guardar cambios
                </Button>
            </SectionCard>
        );
    };

    const renderOcularOptions = () => {
        return (
            <SectionCard className={`mt-4 p-4 ${accordions.oculars ? "section-card-shrink" : ""}`}>
                <div
                    className="d-flex justify-content-between text-secondary mb-3 pointer"
                    onClick={() => setAccordions({ ...accordions, oculars: !accordions.oculars })}
                >
                    <h5 className="fw-bold">Oculares</h5>
                    {accordions.oculars
                        ? <IconChevronRight
                            className="pointer"
                            onClick={() => setAccordions({ ...accordions, oculars: false })}
                        />
                        : <IconChevronDown
                            className="pointer"
                            onClick={() => setAccordions({ ...accordions, oculars: true })}
                        />}
                </div>
                <hr />
                <div className="row">
                    <div className="col-6">
                        {renderCheckbox({
                            checked: records.glaucomaOcular ? true : false,
                            label: "Glaucoma",
                            value: records.glaucomaOcularNotes,
                            idName: "glaucomaOcular",
                            idNotes: "glaucomaOcularNotes"
                        })}
                        {renderCheckbox({
                            checked: records.ropOcular ? true : false,
                            label: "ROP",
                            value: records.ropOcularNotes,
                            idName: "ropOcular",
                            idNotes: "ropOcularNotes"
                        })}
                        {renderCheckbox({
                            checked: records.dmreOcular ? true : false,
                            label: "DMRE",
                            value: records.dmreOcularNotes,
                            idName: "dmreOcular",
                            idNotes: "dmreOcularNotes"
                        })}
                        {renderCheckbox({
                            checked: records.glassesOcular ? true : false,
                            label: "Gafas",
                            value: records.glassesOcularNotes,
                            idName: "glassesOcular",
                            idNotes: "glassesOcularNotes"
                        })}
                        {renderCheckbox({
                            checked: records.dryEyeOcular ? true : false,
                            label: "Ojo seco",
                            value: records.dryEyeOcularNotes,
                            idName: "dryEyeOcular",
                            idNotes: "dryEyeOcularNotes"
                        })}
                        {renderCheckbox({
                            checked: records.diabeticRetinoPathyOcular ? true : false,
                            label: "Retinopatía diabética",
                            value: records.diabeticRetinoPathyOcularNotes,
                            idName: "diabeticRetinoPathyOcular",
                            idNotes: "diabeticRetinoPathyOcularNotes"
                        })}
                    </div>
                    <div className="col-6">
                        {renderCheckbox({
                            checked: records.uveitisOcular ? true : false,
                            label: "Uveitis",
                            value: records.uveitisOcularNotes,
                            idName: "uveitisOcular",
                            idNotes: "uveitisOcularNotes"
                        })}
                        {renderCheckbox({
                            checked: records.contactLensesOcular ? true : false,
                            label: "Lentes de contacto",
                            value: records.contactLensesOcularNotes,
                            idName: "contactLensesOcular",
                            idNotes: "contactLensesOcularNotes"
                        })}
                        {renderCheckbox({
                            checked: records.traumasOcular ? true : false,
                            label: "Traumas",
                            value: records.traumasOcularNotes,
                            idName: "traumasOcular",
                            idNotes: "traumasOcularNotes"
                        })}
                        {renderCheckbox({
                            checked: records.surgeriesOcular ? true : false,
                            label: "Cirugía",
                            value: records.surgeriesOcularNotes,
                            idName: "surgeriesOcular",
                            idNotes: "surgeriesOcularNotes"
                        })}
                        {renderCheckbox({
                            checked: records.alertsOcular ? true : false,
                            label: "Alertas",
                            value: records.alertsOcularNotes,
                            idName: "alertsOcular",
                            idNotes: "alertsOcularNotes"
                        })}
                        {renderCheckbox({
                            checked: records.othersOcular ? true : false,
                            label: "Otros",
                            value: records.othersOcularNotes,
                            idName: "othersOcular",
                            idNotes: "othersOcularNotes"
                        })}
                    </div>
                </div>
                <div>
                    <label htmlFor="" className="text-label">Nota</label>
                    <textarea
                        className="form-control no-resize"
                        onChange={(event) => handleUpdateOptionValue("eyeNotesOcular", event.target.value)}
                        value={records?.eyeNotesOcular}
                        placeholder="Escribir..."
                    />
                </div>
                <Button variant="primary" className="mt-4 align-self-end" onClick={handleSubmitRecords}>
                    Guardar cambios
                </Button>
            </SectionCard>
        );
    };

    const renderFamiliarOptions = () => {
        return (
            <SectionCard className={`mt-4 p-4 ${accordions.familiars ? "section-card-shrink" : ""}`}>
                <div
                    className="d-flex justify-content-between text-secondary mb-3 pointer"
                    onClick={() => setAccordions({ ...accordions, familiars: !accordions.familiars })}
                >
                    <h5 className="fw-bold">Familiares</h5>
                    {accordions.familiars
                        ? <IconChevronRight
                            className="pointer"
                            onClick={() => setAccordions({ ...accordions, familiars: false })}
                        />
                        : <IconChevronDown
                            className="pointer"
                            onClick={() => setAccordions({ ...accordions, familiars: true })}
                        />}
                </div>
                <hr />
                <div className="row">
                    <div className="col-6">
                        {renderCheckbox({
                            checked: records.ahtFamiliar ? true : false,
                            label: "HTA",
                            value: records.ahtFamiliarNotes,
                            idName: "ahtFamiliar",
                            idNotes: "ahtFamiliarNotes"
                        })}
                        {renderCheckbox({
                            checked: records.diabetesFamiliar ? true : false,
                            label: "Diabetes",
                            value: records.diabetesFamiliarNotes,
                            idName: "diabetesFamiliar",
                            idNotes: "diabetesFamiliarNotes"
                        })}
                        {renderCheckbox({
                            checked: records.asthmaFamiliar ? true : false,
                            label: "Asma",
                            value: records.asthmaFamiliarNotes,
                            idName: "asthmaFamiliar",
                            idNotes: "asthmaFamiliarNotes"
                        })}
                        {renderCheckbox({
                            checked: records.coronaryHeartDiseaseFamiliar ? true : false,
                            label: "Enfermedad coronaria",
                            value: records.coronaryHeartDiseaseFamiliarNotes,
                            idName: "coronaryHeartDiseaseFamiliar",
                            idNotes: "coronaryHeartDiseaseFamiliarNotes"
                        })}
                    </div>
                    <div className="col-6">
                        {renderCheckbox({
                            checked: records.collagenDiseaseFamiliar ? true : false,
                            label: "Enfermedad colágeno",
                            value: records.collagenDiseaseFamiliarNotes,
                            idName: "collagenDiseaseFamiliar",
                            idNotes: "collagenDiseaseFamiliarNotes"
                        })}
                        {renderCheckbox({
                            checked: records.glaucomaFamiliar ? true : false,
                            label: "Glaucoma",
                            value: records.glaucomaFamiliarNotes,
                            idName: "glaucomaFamiliar",
                            idNotes: "glaucomaFamiliarNotes"
                        })}
                        {renderCheckbox({
                            checked: records.keratoconusFamiliar ? true : false,
                            label: "Queratocono",
                            value: records.keratoconusFamiliarNotes,
                            idName: "keratoconusFamiliar",
                            idNotes: "keratoconusFamiliarNotes"
                        })}
                        {renderCheckbox({
                            checked: records.othersFamiliar ? true : false,
                            label: "Otros",
                            value: records.othersFamiliarNotes,
                            idName: "othersFamiliar",
                            idNotes: "othersFamiliarNotes"
                        })}
                    </div>
                </div>
                <div>
                    <label htmlFor="" className="text-label">Nota</label>
                    <textarea
                        className="form-control no-resize"
                        onChange={(event) => handleUpdateOptionValue("familyNotesFamiliar", event.target.value)}
                        value={records?.familyNotesFamiliar}
                        placeholder="Escribir..."
                    />
                </div>
                <Button variant="primary" className="mt-4 align-self-end" onClick={handleSubmitRecords}>
                    Guardar cambios
                </Button>
            </SectionCard>
        );
    };

    const render = () => {
        return (
            <>
                {renderGeneralOptions()}
                {renderOcularOptions()}
                {renderFamiliarOptions()}
            </>
        );
    };

    return render();
}