import {
    Adaptation,
    AdaptationDetail,
    AttentionOrigin,
    Cie10EXTDiagnostic,
    GlassesToOrder,
    Overfraction,
    ScleralLenses
} from "@/models/historyDetails/adaptation";
import { formatHistoryDetails } from "@/utils";
//
import "../../../Adaptation/Adaptation.scss";
import "../Details.scss";
import CentralClearance from "../../../Adaptation/CentralClearance/CentralClearance";
import { ClearenceCentral } from "@/models/sheets/adaptation";

export default function AdaptationDetails({ adaptationDetail, forDownload }: { adaptationDetail: AdaptationDetail; forDownload?: boolean; }) {

    const renderAttentionOrigin = (attentionOrigin: AttentionOrigin | null) => {

        if (attentionOrigin) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Origen de atención
                    </h4>
                    {attentionOrigin.attentionOrigin !== null ? (
                        <h5>
                            <span className="fw-bold">Origen de la atención: </span>{" "}
                            {attentionOrigin.attentionOrigin}
                        </h5>
                    ) : ""}
                    {attentionOrigin.abserveEvent !== null ? (
                        <h5>
                            <span className="fw-bold">Evento adverso: </span>{" "}
                            {attentionOrigin.abserveEvent === 1 ? "Sí" : "No"}
                        </h5>
                    ) : ""}
                    {(Object.values(attentionOrigin.attendingReason || {}).length || attentionOrigin.attendingReasonJst) ? (
                        <div>
                            {Object.values(attentionOrigin.attendingReason || {}).length ? (
                                <h5 className="fw-bold">
                                    <span>Motivo consulta: </span>
                                    {Object.values(attentionOrigin.attendingReason || {}).join(" - ")}
                                </h5>
                            ) : ""}
                            {attentionOrigin.attendingReasonJst ? (
                                <h5 style={{ whiteSpace: "pre-line" }}>
                                    {attentionOrigin.attendingReasonJst}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}
                    {(Object.values(attentionOrigin.currentIllness || {}).length || attentionOrigin.currentIllnessJst) ? (
                        <div className="mt-3">
                            {Object.values(attentionOrigin.currentIllness || {}).length ? (
                                <h5 className="fw-bold">
                                    <span>Enfermedad actual: </span>
                                    {Object.values(attentionOrigin.currentIllness || {}).join(" - ")}
                                </h5>
                            ) : ""}
                            {attentionOrigin.currentIllnessJst ? (
                                <h5 style={{ whiteSpace: "pre-line" }}>
                                    {attentionOrigin.currentIllnessJst}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}
                    {attentionOrigin.evolutionTime !== null ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>{" "}
                            {attentionOrigin.evolutionTime}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderContactLenses = (contactLenses: string | null) => {
        if (contactLenses) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Lentes de contacto prueba
                    </h4>
                    <h5 className="text-muted mb-3">
                        <span className="fw-bold">{contactLenses}</span>
                    </h5>
                </div>
            );
        } else return "";
    };

    const renderOverfraction = (overfraction: Overfraction | null) => {
        if (overfraction) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Sobre-refracción
                    </h4>
                    <h5 className="text-muted">
                        {overfraction.od ? (
                            <>
                                <span className="fw-bold">OD: </span>
                                {overfraction.od}
                            </>
                        ) : ""}
                        {(overfraction.od && overfraction.oi) ? " - " : ""}
                        {overfraction.oi ? (
                            <>
                                <span className="fw-bold">OI: </span>
                                {overfraction.oi}
                            </>
                        ) : ""}
                    </h5>
                </div>
            );
        } else return "";
    };

    const renderAdaptation = (adaptation: Adaptation | null) => {
        if (adaptation) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Adaptación
                    </h4>
                    {adaptation.tolerance ? (
                        <h5 className="text-muted mb-3">
                            <span className="fw-bold">Tolerancia: </span>
                            {adaptation.tolerance}
                        </h5>
                    ) : ""}
                    {adaptation.centered ? (
                        <h5 className="text-muted mb-3">
                            <span className="fw-bold">Centrado: </span>
                            {adaptation.centered}
                        </h5>
                    ) : ""}
                    {adaptation.motion ? (
                        <h5 className="text-muted mb-3">
                            <span className="fw-bold">Movimiento: </span>
                            {adaptation.motion}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderGlassesToOrder = (glassesToOrder: GlassesToOrder) => {
        if (glassesToOrder) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Lentes a pedir
                    </h4>
                    <h5 className="text-muted">
                        {glassesToOrder.od ? (
                            <>
                                <span className="fw-bold">OD: </span>
                                {glassesToOrder.od}
                            </>
                        ) : ""}
                        {(glassesToOrder.od && glassesToOrder.oi) ? " - " : ""}
                        {glassesToOrder.oi ? (
                            <>
                                <span className="fw-bold">OI: </span>
                                {glassesToOrder.oi}
                            </>
                        ) : ""}
                    </h5>
                    {glassesToOrder.lensType ? (
                        <h5 className="text-muted mb-4">
                            <span className="fw-bold">Tipo de lente: </span>
                            {glassesToOrder.lensType}
                        </h5>
                    ) : ""}
                    {glassesToOrder.recommendationsForUse ? (
                        <h5 className="text-muted mb-4">
                            <span className="fw-bold">Recomendaciones de uso: </span>
                            {glassesToOrder.recommendationsForUse}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderScleralLenses = (scleralLenses: ScleralLenses) => {
        if (scleralLenses) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Lentes esclerales
                    </h4>
                    {scleralLenses.type ? (
                        <h5>
                            <span className="fw-bold">Lentes esclerales: </span>
                            {scleralLenses.type}
                        </h5>
                    ) : ""}
                    <div className="row">
                        {scleralLenses.od ? (
                            <div className="col-6">
                                <h5 className="text-muted fw-bold">
                                    Lente OD
                                </h5>
                                {scleralLenses.od.design ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Diseño: </span>
                                        {scleralLenses.od.design}
                                    </h5>
                                ) : ""}
                                {scleralLenses.od.power ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Power: </span>{" "}
                                        {scleralLenses.od.power}
                                    </h5>
                                ) : ""}
                                {scleralLenses.od.sag ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">SAG: </span>{" "}
                                        {scleralLenses.od.sag}
                                    </h5>
                                ) : ""}
                                {scleralLenses.od.diameter ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Diámetro: </span>{" "}
                                        {scleralLenses.od.diameter}
                                    </h5>
                                ) : ""}
                                {scleralLenses.od.visionShoot ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">AV: </span>{" "}
                                        {scleralLenses.od.visionShoot}
                                    </h5>
                                ) : ""}
                                {scleralLenses.od.clearance ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Clearance: </span>{" "}
                                        {scleralLenses.od.clearance}
                                    </h5>
                                ) : ""}
                                {scleralLenses.od.others ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Otros: </span>{" "}
                                        {scleralLenses.od.others}
                                    </h5>
                                ) : ""}
                            </div>
                        ) : ""}

                        {scleralLenses.oi ? (
                            <div className="col-6">
                                <h5 className="text-muted fw-bold">
                                    Lente OI
                                </h5>
                                {scleralLenses.oi.design ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Diseño: </span>
                                        {scleralLenses.oi.design}
                                    </h5>
                                ) : ""}
                                {scleralLenses.oi.power ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Power: </span>{" "}
                                        {scleralLenses.oi.power}
                                    </h5>
                                ) : ""}
                                {scleralLenses.oi.sag ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">SAG: </span>{" "}
                                        {scleralLenses.oi.sag}
                                    </h5>
                                ) : ""}
                                {scleralLenses.oi.diameter ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Diámetro: </span>{" "}
                                        {scleralLenses.oi.diameter}
                                    </h5>
                                ) : ""}
                                {scleralLenses.oi.visionShoot ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">AV: </span>{" "}
                                        {scleralLenses.oi.visionShoot}
                                    </h5>
                                ) : ""}
                                {scleralLenses.oi.clearance ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Clearance: </span>{" "}
                                        {scleralLenses.oi.clearance}
                                    </h5>
                                ) : ""}
                                {scleralLenses.oi.others ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Otros: </span>{" "}
                                        {scleralLenses.oi.others}
                                    </h5>
                                ) : ""}
                            </div>
                        ) : ""}
                    </div>
                </div>
            );
        } else return "";
    };

    const renderCentralClearence = (centralClearance: ClearenceCentral[] | null) => {
        if (centralClearance) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Clearance central
                    </h4>
                    <CentralClearance
                        adaptationPayload={{ clearenceCentral: Object.values(centralClearance) }}
                        readOnly
                    />
                </div>
            );
        } else return "";
    };

    const renderExtDiagnostic = (extDiagnostic: Cie10EXTDiagnostic | null) => {
        if (extDiagnostic && Object.values(extDiagnostic).length) {
            const mainExt = Object.values(extDiagnostic).find(item => item.isMain === 1);

            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Impresión diagnóstica
                    </h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-secondary">
                                <th>Fecha</th>
                                <th className="text-center">CIE-10</th>
                                <th>Impresión diagnóstica</th>
                                <th className="text-center">Ojo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(extDiagnostic).map((item) => (
                                <tr key={`${item.id}-${item.clhId}`} style={{ backgroundColor: item.isMain ? "#fcfae6" : undefined }}>
                                    <td className="align-middle">{item.date}</td>
                                    <td align="center" className="align-middle">{item.cie10Code}</td>
                                    <td style={{ whiteSpace: "pre-line" }}>{item.cie10Description}</td>
                                    <td className="align-middle" align="center">{item.dbo?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {mainExt ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>
                            {mainExt.evolution} {mainExt.evolutionTime?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderAnalysisAndPlan = (analysisPlan: string | null) => {
        if (analysisPlan) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Análisis y plan
                    </h4>
                    <h5>{analysisPlan}</h5>
                </div>
            );
        } else {
            return "";
        }
    };

    const render = () => {
        const attentionOrigin = formatHistoryDetails(adaptationDetail.attentionOrigin);
        const contactLenses = formatHistoryDetails(adaptationDetail.contactLenses);
        const overfraction = formatHistoryDetails(adaptationDetail.overfraction);
        const adaptation = formatHistoryDetails(adaptationDetail.adaptation);
        const glassesToOrder = formatHistoryDetails(adaptationDetail.glassesToOrder);
        const scleralLenses = formatHistoryDetails(adaptationDetail.scleralLenses);
        const centralClearance = formatHistoryDetails(adaptationDetail.centralClearance);

        const extDiagnostic = formatHistoryDetails(adaptationDetail.cie10ExtDiagnostic);
        const analysisPlan = formatHistoryDetails(adaptationDetail.analysisPlan);

        return (
            <div className="detail-container">
                <div className={`${!forDownload ? "columns" : "column"}`}>
                    {renderAttentionOrigin(attentionOrigin)}
                    {renderContactLenses(contactLenses)}
                    {renderOverfraction(overfraction)}
                    {renderAdaptation(adaptation)}
                    {renderGlassesToOrder(glassesToOrder)}
                    {renderScleralLenses(scleralLenses)}
                    {renderCentralClearence(centralClearance)}
                    {/* <div className="item">
                        <h4 className="text-primary fw-bold mb-3">
                            Clearance central
                        </h4>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column align-items-center">
                                <h5 className="text-muted fw-bold">OD</h5>
                                <div className="me-3 overflow-hidden eye">
                                    <div className="containerElement">
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <h5 className="text-muted fw-bold">OI</h5>
                                <div className="me-3 overflow-hidden eye">
                                    <div className="containerElement">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 className="text-muted fw-bold mb-3">Observaciones OD: </h5>
                        <ol>
                            <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aperiam, nam?</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, alias cumque.</li>
                            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ducimus laborum quam doloribus?</li>
                        </ol>

                        <h5 className="text-muted fw-bold">Observaciones OI: </h5>
                        <ol>
                            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. At, ex consequatur.</li>
                            <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore a quam sunt!</li>
                            <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aperiam, nam?</li>
                        </ol>
                    </div> */}

                    {renderExtDiagnostic(extDiagnostic)}
                    {renderAnalysisAndPlan(analysisPlan)}
                </div>
            </div>
        );
    };

    return render();
}