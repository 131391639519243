import { IconClose } from "@/assets/icons";
import { Button, Modal } from "@/components";
import { IModal } from "@/components/Modal/modal";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { BodyCenterList, File } from "@/models/surgery/patientAttention/patientAttention";
import { generateId } from "@/utils";
import { IconCameraRotate, IconCapture, IconChecklist, IconPhotoCancel } from "@tabler/icons-react";
import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { changeEvidence, deleteEvidence } from "../patientAttentionSurgery.action";



export default function EvidenceModal({ onSuccess, appId, stage, data, setReloadDataEvidence, reloadDataEvidence, details, ...rest }: IModal & { details?: boolean, reloadDataEvidence?: number, stage?: string; data?: BodyCenterList; setReloadDataEvidence?: (data: number) => void; }) {

    const dispatch = useAppDispatch();

    const [files, setFiles] = useState<File[]>([]);
    const [isTakingPicture, setIsTakingPicture] = useState(false);

    useEffect(() => {
        if (data?.files && data?.files.length > 0) {
            setFiles(data?.files);
        }
    }, [data?.files, rest.isOpen, reloadDataEvidence]);

    const deleteEvidenceFunction = async (id: number) => {
        const response = await dispatch(deleteEvidence({ filId: id }));
        if (response.success) {
            setReloadDataEvidence && setReloadDataEvidence((reloadDataEvidence ?? 0) + 1);
        }
    };




    return (
        <Modal {...rest} width="45%" positionModal="center" onClose={() => {
            rest.onClose();
            setIsTakingPicture(false);
        }}>
            <div className="p-4">
                <h1 className="text-secondary fw-bold text-start ">Evidencias</h1>
                <h3 className="text-muted text-start">{data?.articleName}</h3>
                <div className="px-3 d-flex flex-column">
                    <div>
                        {!details &&
                            <Button variant="primary"
                                onClick={() => setIsTakingPicture(true)}
                            >
                                Tomar foto
                            </Button>
                        }
                    </div>
                    <span className="text-secondary fw-bold mt-2">Evidencias subidas</span>
                    {!isTakingPicture &&
                        <div className="my-4 evidenceContainer d-flex gap-2" key={reloadDataEvidence}>
                            {(files && files?.length > 0) ? files.map((file, ind) => {

                                const srcValue = file.url !== null ? file.url : file.base64 !== null ? file.base64 : undefined;
                                return <div key={ind} className="position-relative">
                                    <img src={srcValue} alt={file.name} height={170} width={170} />
                                    {
                                        !details &&
                                        <div className="closeButtonEvidenceImage pointer"
                                            onClick={() => deleteEvidenceFunction(file.id || 0)}
                                        >
                                            <IconClose />
                                        </div>

                                    }
                                </div>;
                            })
                                : <div className="text-center d-flex justify-content-center w-100">
                                    <span className="text-muted text-center">
                                        <IconPhotoCancel />   No tienes ninguna evidencia subida
                                    </span>
                                </div>
                            }
                        </div>
                    }

                </div>
                {isTakingPicture &&
                    <CustomWebcam data={data} reloadDataEvidence={reloadDataEvidence || 0} setIsTakingAPicture={setIsTakingPicture} files={files} setFiles={setFiles} setReloadDataEvidence={setReloadDataEvidence} />
                }



            </div>
        </Modal>
    );
}


const CustomWebcam = ({ data, setIsTakingAPicture, files, setFiles, setReloadDataEvidence, reloadDataEvidence }: { data?: BodyCenterList, setIsTakingAPicture: (data: boolean) => void, files: File[], setFiles: (data: File[]) => void, setReloadDataEvidence?: (data: number) => void, reloadDataEvidence: number; }) => {
    const dispatch = useAppDispatch();

    const eaccount = useAppSelector(state => state.workspace).id;
    const userId = useAppSelector(state => state.auth).user_data.id;
    const webcamRef = useRef<Webcam>(null);
    const [imgSrc, setImgSrc] = useState<string | null>(null);

    const capture = useCallback(() => {
        if (webcamRef.current !== null) {
            const imageSrc = webcamRef.current.getScreenshot();
            setImgSrc(imageSrc);
        }
    }, [webcamRef]);

    const retake = () => {
        setImgSrc(null);
    };

    const addEvidence = async () => {
        const tempEvidenceList = [...files];
        const response = await dispatch(changeEvidence({
            cssId: data && data.cssId,
            eaccount,
            userId,
            files: [
                {
                    base64: imgSrc && imgSrc.replace(/^data:image\/[a-z]+;base64,/, ""),
                    url: null,
                    typeDoc: "png",
                    fileName: `evidence_{${files.length + 1}}`,
                    idTemp: generateId(),
                }
            ]
        })
        );

        if (response) {
            setReloadDataEvidence && setReloadDataEvidence(reloadDataEvidence + 1);
            setFiles(tempEvidenceList);
        }


        setIsTakingAPicture(false);
    };
    return (
        <div className="container d-flex flex-column justify-content-center align-items-center">
            {imgSrc ? (
                <div className="my-5">
                    <img src={imgSrc} alt="webcam" />
                </div>
            ) : (
                <div className="d-flex justify">
                    <Webcam height={300} width={300} ref={webcamRef}
                        screenshotFormat="image/png"
                    />
                </div>
            )}
            <div >
                {imgSrc ? (
                    <div className="d-flex justify-content-center mt-1">
                        <Button variant="outline-danger" className=" mx-4" onClick={retake}>
                            <IconCameraRotate />
                        </Button>
                        <Button variant="outline" onClick={addEvidence}>
                            <IconChecklist />
                        </Button>
                    </div>
                ) : (
                    <Button variant="outline" onClick={capture}>
                        <IconCapture />
                    </Button>
                )}
            </div>
        </div>
    );
};

