import dayjs from "dayjs";
//
import { Badge, Button, Modal } from "../../../../../components";
import { IModal } from "../../../../../components/Modal/modal";
import { DayService, ServiceData } from "../../../../../models/appointment";

export default function DayServices({ data, onSuccess, ...rest }: IModal<ServiceData>) {

    const renderDayService = (item: DayService) => {
        return (
            <div key={item.appId} className="services-list__item">
                <div className="mb-1 d-flex align-items-center justify-content-between">
                    <span className="text-secondary fw-bold">{item.cupCode} - {item.description}</span>
                    <Badge size="small" style={{
                        background: item.statusBgcolor,
                        color: item.statusFontColor
                    }}>
                        {item.statusName}
                    </Badge>
                </div>
                <div className="row">
                    <div className="col-5">
                        <div className="fw-bold">Hora</div>
                        <div className="fw-bold">Médico</div>
                        <div className="fw-bold">Sede</div>
                    </div>
                    <div className="col">
                        <div>{dayjs(item.datetime).format("hh:mm a")}</div>
                        <div>{item.firstName} {item.secondName} {item.lastName} {item.secondSurname}</div>
                        <div>{item.site}</div>
                    </div>
                </div>
            </div>
        );
    };

    const renderModalContent = () => {
        return (
            <div className="services-list mt-3" style={{ maxHeight: 300 }}>
                {data?.dayServices?.map(item => renderDayService(item))}
            </div>
        );
    };

    const render = () => {
        return (
            <Modal {...rest}>
                <h2 className="text-primary fw-bold align-self-center">Servicios del día</h2>
                <h6 className="text-secondary fw-bold mb-2 align-self-center">
                    {data?.appointment.patientIdentification} - {data?.appointment.patientName}
                </h6>
                {renderModalContent()}
                <Button
                    variant="primary"
                    className="mt-4 align-self-end"
                    onClick={rest.onClose}
                >
                    Aceptar
                </Button>
            </Modal>
        );
    };

    return render();
}