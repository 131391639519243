import { useEffect, useState } from "react";
import {
    IconExclamationMark,
    IconCircleCheck,
    IconX,
    IconChartHistogram,
    IconChevronLeft,
    IconClipboardHeart
} from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
//
import { Avatar, Button, Modal } from "@/components";
//
// import { Records } from "./Tabs/Records";
import { getPatientHeader } from "./preConsultation.actions";
import { fireWarningAlert } from "@/utils";
import { createComment, deleteComment, getComments } from "../Comments/comments.actions";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
//
import Comments from "../Comments/Comments";
import { PatientHeader } from "@/models/preconsultation";
import { Comment } from "@/models/comment";
import { Dilatation, Medicine, Screening, VitalSigns } from "./Tabs";
import ConsultationControls from "../Controls/Controls";
import "./PreConsultation.scss";
import History from "../History/History";
import { Tooltip } from "react-tooltip";
import Antecedents from "../Header/Antecedents/AntedecentsModal";

export default function PreConsultation() {
    const userId = useAppSelector(state => state.auth.user_data.id);
    const dispatch = useAppDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();

    const appointment = useAppSelector(state => state.patientAttention.appointmentSelected);
    const workspace = useAppSelector(state => state.workspace);
    const isDisabledForm = false;
    dayjs.extend(relativeTime);

    const [isAnalysisOpen, setIsAnalysisOpen] = useState<boolean>(false);
    const [patientHeader, setPatientHeader] = useState<PatientHeader>();
    const [currentTab, setCurrentTab] = useState<string>(state.tab as string || "dilatation");
    const tabs: { tab: string; label: string; }[] = [
        { tab: "dilatation", label: "Dilatación" },
        { tab: "vitalSigns", label: "Signos Vitales" },
        { tab: "eyescreening", label: "Tamizaje" },
        { tab: "medicines", label: "Conciliación medicamentosa" },
    ];
    const [isCommentsDrawerOpen, setIsCommentsDrawerOpen] = useState<boolean>(false);
    const [comments, setComments] = useState<Comment[]>([]);
    const [antecedentsConfig, setAntecedentsConfig] = useState({
        isOpen: false
    });

    useEffect(() => {
        async function fetchData() {
            if (appointment?.patientId) {
                const data = await dispatch(getComments({
                    entity: "userclient",
                    id: appointment.patientId
                }));
                setComments(data as Comment[]);
            }
        }
        fetchData();
    }, [dispatch, appointment?.patientId]);
    useEffect(() => {
        async function fetchData() {
            if (appointment.app_id && workspace.id) {
                const data = await dispatch(getPatientHeader({
                    appId: appointment.app_id,
                    eaccount: workspace.id
                }));
                setPatientHeader(data);
            }
        }
        fetchData();
    }, [appointment.app_id, workspace.id, dispatch]);

    const handleCloseCommentsDrawer = (): void => setIsCommentsDrawerOpen(false);
    const handleOpenCommentsDrawer = (): void => setIsCommentsDrawerOpen(true);

    const handleCloseAntecedentsModal = (): void => setAntecedentsConfig({
        isOpen: false
    });
    const handleOpenAntecedentsModal = (): void => setAntecedentsConfig({
        isOpen: true
    });

    const handleGoBack = (): void => navigate(-1);

    const handleChangeTab = (tab: string): void => {
        setCurrentTab(tab);
    };

    const handleAnalysis = () => {
        if (patientHeader?.analysisPlanJst) {
            setIsAnalysisOpen(true);
        } else {
            fireWarningAlert("El médico no ha ingresado la información.", "Análisis y plan");
        }
    };

    const handleReloadHeader = async () => {
        if (appointment.app_id && workspace.id) {
            const data = await dispatch(getPatientHeader({
                appId: appointment.app_id,
                eaccount: workspace.id
            }));
            setPatientHeader(data);
        }
    };

    const handleSubmitComment = async (text: string): Promise<void> => {
        await dispatch(createComment({
            comment: text,
            entity: "userclient",
            userId: userId as number,
            id: appointment?.patientId as number,
        }));
        const data = await dispatch(getComments({
            entity: "userclient",
            id: appointment?.patientId
        }));
        setComments(data as Comment[]);
    };

    const handleDeleteComment = async (id: string): Promise<void> => {
        await dispatch(deleteComment(id));
        const data = await dispatch(getComments({
            entity: "userclient",
            id: appointment?.patientId
        }));
        setComments(data as Comment[]);
    };

    const renderAnalysisModal = () => {
        return (
            <Modal
                width={600}
                isOpen={isAnalysisOpen}
                onClose={() => setIsAnalysisOpen(false)}
            >
                <h2 className="text-primary fw-bold align-self-center">
                    Análisis y plan
                </h2>
                <hr className="text-muted" />
                {patientHeader?.analysisPlanJst}
                <Button onClick={() => setIsAnalysisOpen(false)} variant="primary" className="align-self-end mt-3">
                    Aceptar
                </Button>
            </Modal>
        );
    };

    const renderHeader = () => {
        return (
            <div className="d-flex mt-4 px-4">
                <div className="d-flex align-items-center flex-row w-100 card px-3 py-1">
                    {!(workspace.profile?.prefijo.toLowerCase() === "nurse" && appointment.status === "attended") &&
                        <div className="align-self-center text-secondary pointer" onClick={handleGoBack}>
                            <IconChevronLeft />
                        </div>
                    }
                    <div className="d-flex align-items-center ms-3">
                        <Avatar src={appointment.photoUrl ?? "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"} />
                        <div className="ms-3">
                            <small className="text-muted">Paciente</small>
                            <div className="fw-bold text-secondary">{patientHeader?.cluFirstName} {patientHeader?.cluSecondName} {patientHeader?.cluLastName}</div>
                        </div>
                    </div>
                    <div className="ms-4">
                        <small className="text-muted">Identificación</small>
                        <div className="fw-bold text-secondary">{patientHeader?.docTypeDescription} {patientHeader?.cluDocument}</div>
                    </div>
                    <div className="ms-4">
                        <small className="text-muted">Edad</small>
                        <div className="fw-bold text-secondary">{dayjs(patientHeader?.cluBirthDate).fromNow(true)}</div>
                    </div>
                    <div className="ms-4">
                        <small className="text-muted">Aseguradora</small>
                        <div className="fw-bold text-secondary">{patientHeader?.cclientName}</div>
                    </div>
                    <div className="ms-4">
                        <small className="text-muted">Contrato</small>
                        <div className="fw-bold text-secondary">{patientHeader?.contractName}</div>
                    </div>
                    <div className="flex-fill"></div>
                    <div
                        className="pointer mx-2 text-primary align-self-center"
                        onClick={handleOpenAntecedentsModal}
                        data-tooltip-id="allergies-tooltip"
                        data-tooltip-content={"Antecedentes"}
                    >
                        <IconClipboardHeart />
                    </div>
                    <div
                        className="d-flex align-items-center">
                        {patientHeader?.alertsOcularNotes && (
                            <div
                                className="dynamic-icon dynamic-icon__warning ms-3"
                                data-tooltip-content={patientHeader.alertsOcularNotes}
                                data-tooltip-id="allergies-tooltip"
                            >
                                <IconX />
                            </div>
                        )}
                        {patientHeader?.allergiesGeneralNotes && (
                            <div
                                className="dynamic-icon dynamic-icon__danger ms-3"
                                data-tooltip-content={patientHeader.allergiesGeneralNotes}
                                data-tooltip-id="allergies-tooltip"
                            >
                                <IconExclamationMark />
                            </div>
                        )}
                        <div className="text-primary pointer ms-3" onClick={handleAnalysis}>
                            <IconChartHistogram size={20} />
                        </div>
                        <div className="message-icon ms-3" onClick={handleOpenCommentsDrawer}>
                            {comments?.length}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const render = () => {
        return (
            <div className="preconsultation">
                <div className="preconsultation-main">
                    <div className="d-flex flex-column overflow-hidden flex-grow-1">
                        {renderHeader()}
                        <div className="preconsultation-tabs mt-3 px-4">
                            {tabs.map(tab => (
                                <div
                                    key={tab.tab}
                                    className={`preconsultation-tab ${currentTab === tab.tab && "active"}`}
                                    onClick={() => handleChangeTab(tab.tab)}
                                >
                                    <IconCircleCheck />
                                    <span>{tab.label}</span>
                                </div>
                            ))}
                        </div>
                        <div className="flex-grow-1 overflow-auto pb-4 px-4">
                            {currentTab === "dilatation" && <Dilatation isDisabledForm={isDisabledForm} />}
                            {/* {currentTab === "records" && <Records />} */}
                            {currentTab === "vitalSigns" && <VitalSigns isDisabledForm={isDisabledForm} />}
                            {currentTab === "eyescreening" && <Screening isDisabledForm={isDisabledForm} />}
                            {currentTab === "medicines" && <Medicine isDisabledForm={isDisabledForm} />}
                        </div>
                    </div>
                    {workspace.profile?.prefijo.toLowerCase() === "nurse" && (
                        <ConsultationControls isDisabledForm={isDisabledForm} />
                    )}
                </div>
                <History showClose={false} completePathHistorySegments={"preconsulting"} />
                <Antecedents
                    isOpen={antecedentsConfig.isOpen}
                    onClose={handleCloseAntecedentsModal}
                    onSuccess={handleReloadHeader}
                    isDisabledForm={isDisabledForm}
                />
                <Comments
                    data={comments}
                    isOpen={isCommentsDrawerOpen}
                    onClose={handleCloseCommentsDrawer}
                    width={400}
                    drawer="right"
                    onCreateComment={(value) => handleSubmitComment(value)}
                    onDeleteComment={(id) => handleDeleteComment(id)}
                />
                {renderAnalysisModal()}
                <Tooltip id="allergies-tooltip" />
            </div>
        );
    };

    return render();
}