import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useLocation, useNavigate } from "react-router-dom";
//
import { SectionCard } from "@/components";
import AttentionOrigin from "../AttentionOrigin/AttentionOrigin";
import ConsultationControls from "../Controls/Controls";
import DiagnosticImpression from "../DiagnosticImpression/DiagnosticImpression";
import ConsultationHeader from "../Header/Header";
import { AttentionOriginFields } from "@/models/attentionOrigin";
import { useAppDispatch, useAppSelector } from "@/hooks";
//
import AnalysisAndPlan from "../AttentionOrigin/AnalysisAndPlan";
import {
    OptometryPayload,
} from "@/models/sheets/optometry";
// import VisualAcuity from "../shared/VisualAcuity/VisualAcuity";
import {
    getOptometry,
    updateOptometry
} from "./optometry.actions";
import "../Consultation.scss";
import History from "../History/History";
import Keratometry from "../shared/Keratometry/Keratometry";
import SubjectiveRefraction from "../shared/SubjectiveRefraction/SubjectiveRefraction";
import ObjectiveRefraction from "../shared/ObjectiveRefraction/ObjectiveRefraction";
import Distances from "../shared/Distances/Distances";
import Optotype from "../shared/Optotype/Optotype";
import AvNoCorrection from "../shared/AvNoCorrection/AvNoCorrection";
import AvPh from "../shared/AvPh/AvPh";
import AvLensesInUse from "../shared/AvLensesInUse/AvLensesInUse";
import AvWithCorrection from "../shared/AvWithCorrection/AvWithCorrection";

export default function Optometry() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const currentSheet = useAppSelector(state => state.patientAttention.patientStatus.sheet);
    const mcfId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const isDisabledForm = false;

    const [showHistory, setShowHistory] = useState(false);
    const [attentionOriginOptions, setAttentionOriginOptions] = useState<AttentionOriginFields>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number,
        generalFields: {
            attentionOriginId: undefined,
            abserveEvent: undefined,
            evolutionTime: undefined,
            evolutionTimeUnitId: undefined
        },
        reasonForConsulting: { predefinedTxts: [], justification: "" },
        currentDisease: { predefinedTxts: [], justification: "" },
        analysisPlan: ""
    });

    const [optometryPayload, setOptometryPayload] = useState<OptometryPayload>({
        mcfId: mcfId,
        eaccount: accountId,
        userId: userId as number,
        appId: appointmentId,
    });

    useEffect(() => {
        const splittedPath = location.pathname.split("/");
        const sheet = splittedPath[splittedPath.length - 1];

        if (sheet !== currentSheet) {
            navigate(-1);
        }
    }, [currentSheet, navigate, location.pathname]);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getOptometry({
                appId: appointmentId,
                eaccount: accountId,
                mcfId: mcfId,
                userId: userId as number
            }));
            if (data) {
                setOptometryPayload({
                    ...data,
                    mcfId: mcfId,
                    eaccount: accountId,
                    userId: userId as number,
                    appId: appointmentId,
                });
            }
        }
        fetchData();
    }, [dispatch, appointmentId, accountId, mcfId, userId]);

    const onSubmitOptometry = useDebouncedCallback(() => {
        if (optometryPayload) {
            const options = { ...optometryPayload, id: optometryPayload.id };

            setOptometryPayload(options);
            dispatch(updateOptometry(options));
        }
    }, 300);

    const onChangeNewPupilometry = (prefix: "photopic" | "mesotopic", eye: "od" | "oi", value: number | string) => {
        const options: typeof optometryPayload = {
            ...optometryPayload,
            pupillometry: {
                ...optometryPayload.pupillometry,
                [prefix]: {
                    ...optometryPayload.pupillometry?.[prefix],
                    [eye]: value
                }
            }
        };
        setOptometryPayload(options);
        onSubmitOptometry();
    };

    const render = () => {

        return (
            <section className="d-flex flex-column h-100 overflow-hidden">
                <ConsultationHeader isDisabledForm={isDisabledForm} currentSection="Optometría" showHistory={showHistory} setShowHistory={(state) => setShowHistory(state)} />
                <div className="d-flex overflow-hidden h-100">
                    <section className="consultation-section">
                        <div className="row g-3">
                            <div className="col-12">
                                <AttentionOrigin
                                    isDisabledForm={isDisabledForm}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                />
                            </div>
                            <div className="col-12">
                                <div className="row g-3">
                                    <div className="col-lg-2">
                                        <Optotype
                                            isDisabledForm={isDisabledForm}
                                            externalPayload={optometryPayload}
                                            setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                            onSubmit={() => onSubmitOptometry()}
                                        />
                                    </div>
                                    <div className="col-lg-5">
                                        <AvNoCorrection
                                            isDisabledForm={isDisabledForm}
                                            externalPayload={optometryPayload}
                                            setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                            onSubmit={() => onSubmitOptometry()}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <AvPh
                                            isDisabledForm={isDisabledForm}
                                            externalPayload={optometryPayload}
                                            setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                            onSubmit={() => onSubmitOptometry()}
                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <SectionCard className="h-100">
                                            <h5 className="text-secondary fw-bold">Pupilometría</h5>
                                            <hr className="text-primary mt-2" />
                                            <table className="mt-3">
                                                <thead>
                                                    <tr>
                                                        <td></td>
                                                        <td align="center" className="pt-1 text-secondary bg-od" style={{ borderTopLeftRadius: "0.5rem" }}>OD</td>
                                                        <td align="center" className="pt-1 text-secondary bg-oi" style={{ borderTopRightRadius: "0.5rem" }}>OI</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-secondary nowrap">Fotópica (mm)</td>
                                                        <td className="bg-od p-2">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={optometryPayload.pupillometry?.photopic?.od}
                                                                onChange={(event) => {
                                                                    const value = event.target.value.split(".");
                                                                    if (value[1] && value[1].length > 2) {
                                                                        event.preventDefault();
                                                                    } else {
                                                                        onChangeNewPupilometry("photopic", "od", event.target.value);
                                                                    }
                                                                }}
                                                                disabled={isDisabledForm}
                                                            />
                                                        </td>
                                                        <td className="bg-oi p-2">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={optometryPayload.pupillometry?.photopic?.oi}
                                                                onChange={(event) => {
                                                                    const value = event.target.value.split(".");
                                                                    if (value[1] && value[1].length > 2) {
                                                                        event.preventDefault();
                                                                    } else {
                                                                        onChangeNewPupilometry("photopic", "oi", event.target.value);
                                                                    }
                                                                }}
                                                                disabled={isDisabledForm}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-secondary nowrap">Mesópica (mm)</td>
                                                        <td className="bg-od p-2" style={{ borderBottomLeftRadius: "0.5rem" }}>
                                                            <input
                                                                disabled={isDisabledForm}
                                                                type="number"
                                                                className="form-control"
                                                                value={optometryPayload.pupillometry?.mesotopic?.od}
                                                                onChange={(event) => {
                                                                    const value = event.target.value.split(".");
                                                                    if (value[1] && value[1].length > 2) {
                                                                        event.preventDefault();
                                                                    } else {
                                                                        onChangeNewPupilometry("mesotopic", "od", event.target.value);
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="bg-oi p-2" style={{ borderBottomRightRadius: "0.5rem" }}>
                                                            <input
                                                                disabled={isDisabledForm}
                                                                type="number"
                                                                className="form-control"
                                                                value={optometryPayload.pupillometry?.mesotopic?.oi}
                                                                onChange={(event) => {
                                                                    const value = event.target.value.split(".");
                                                                    if (value[1] && value[1].length > 2) {
                                                                        event.preventDefault();
                                                                    } else {
                                                                        onChangeNewPupilometry("mesotopic", "oi", event.target.value);
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </SectionCard>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <AvLensesInUse
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={optometryPayload}
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                            <div className="col-6">
                                <ObjectiveRefraction
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={optometryPayload}
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                            <div className="col-6">
                                <Keratometry
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={optometryPayload}
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                            <div className="col-7">
                                <SubjectiveRefraction
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={optometryPayload}
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                            <div className="col-5">
                                <AvWithCorrection
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={optometryPayload}
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                            <div className="col-4">
                                <Distances
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={optometryPayload}
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                            <div className="col-8">
                                <SectionCard className="h-100">
                                    <h5 className="text-secondary fw-bold">Comentarios sobre motilidad</h5>
                                    <hr className="text-primary mt-2" />
                                    <textarea
                                        disabled={isDisabledForm}
                                        className="form-control h-100 no-resize"
                                        value={optometryPayload.commentOnMotility}
                                        onChange={({ target }) => {
                                            setOptometryPayload({ ...optometryPayload, commentOnMotility: target.value });
                                            onSubmitOptometry();
                                        }}
                                        placeholder="Observaciones..."
                                    />
                                </SectionCard>
                            </div>
                            <div className="col-6">
                                <DiagnosticImpression isDisabledForm={isDisabledForm} />
                            </div>
                            <div className="col-6">
                                <AnalysisAndPlan
                                    isDisabledForm={isDisabledForm}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                    height={215}
                                />
                            </div>
                        </div>
                    </section>
                    <History showClose={false} />
                </div>
                <ConsultationControls isDisabledForm={isDisabledForm} />
            </section>
        );
    };

    return render();
}