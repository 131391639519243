import { SectionCard } from "@/components";
import { useAppDispatch } from "@/hooks";
import { Surgery as ISurgery, Patient } from "@/models/surgery/patientAttention/headerSurgeryAttention";
import { AdmissionNotes, ITabs, IncomeNotes, PreparationNotes, RecoveryNotes, SurgeryNotes } from "@/models/surgery/patientAttention/patientAttention";
import { IconCircleCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import ConsultationControls from "../Consultation/Controls/Controls";
import History from "../Consultation/History/History";
import "./PatientAttentionSurgery.scss";
import SurgeryHeader from "./headerSurgery/HeaderSurgeryComponeny";
import { getNurseryNotes, updateNurseryNotes } from "./patientAttentionSurgery.action";
import Admission from "./stages/Admission";
import Income from "./stages/Income";
import Preparation from "./stages/Preparation";
import Recovery from "./stages/Recovery";
import Surgery from "./stages/Surgery";



const AttentionSurgery = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const numId = location.state.startAttentionInfo.numId;
    const [services, setServices] = useState<{ services: ISurgery[], patientInfo: Patient; status: string; }>(
        {
            services: [],
            patientInfo: { age: "", document: "", documentType: "", fullName: "", id: 0, photo: "", },
            status: ""
        });
    const [admissionNotes, setAdmissionNotes] = useState<AdmissionNotes>();

    const [preparationNotes, setPreparationNotes] = useState<PreparationNotes>();
    const [surgeryNotes, setSurgeryNotes] = useState<SurgeryNotes>();
    const [recoveryNotes, setRecoveryNotes] = useState<RecoveryNotes>();
    const onSubmitNotes = useDebouncedCallback((section: string, payload: IncomeNotes | PreparationNotes | SurgeryNotes | RecoveryNotes) => {
        dispatch(updateNurseryNotes({
            [section]: payload
        }));
    }, 2000);
    const [tabs, setTabs] = useState<ITabs[]>([
        { label: "Admisión", value: "admission", isDone: false, isActive: true, render: <Admission data={admissionNotes} setAdmissionNotes={setAdmissionNotes} onSubmitNotes={onSubmitNotes} /> },
        { label: "Ingreso", value: "income", isDone: false, isActive: false, render: <Income onSubmitNotes={onSubmitNotes} /> },
        { label: "Preparación", value: "preparation", isDone: false, isActive: false, render: <Preparation data={preparationNotes} setPreparationNotes={setPreparationNotes} onSubmitNotes={onSubmitNotes} /> },
        { label: "Cirugía", value: "surgery", isDone: false, isActive: false, render: <Surgery data={surgeryNotes} setSurgeryNotes={setSurgeryNotes} onSubmitNotes={onSubmitNotes} /> },
        { label: "Recuperación y egreso", value: "recovery", isDone: false, isActive: false, render: <Recovery data={recoveryNotes} setRecoveryNotes={setRecoveryNotes} onSubmitNotes={onSubmitNotes} /> },
    ]);
    const handleChangeTab = (stage: string, tabsUpdate?: ITabs[]): void => {
        if (tabsUpdate) {
            const statusTabChanged = tabsUpdate.map(tab => tab.value === stage ? { ...tab, isActive: true } : { ...tab, isActive: false });
            setTabs(statusTabChanged);
        } else {
            const statusTabChanged = tabs.map(tab => tab.value === stage ? { ...tab, isActive: true } : { ...tab, isActive: false });
            setTabs(statusTabChanged);
            validateChangingTab(stage);
        }
    };

    const renderSurgeries = () => {

        return (
            <SectionCard className="w-100" cardAidStyle >
                <div className="d-flex align-items-center justify-content-between row">
                    <div className="col-8">
                        <h5 className="fw-bold text-secondary">Cirugía:</h5>
                    </div>
                    <div className="col-4">
                        <h5 className="fw-bold text-secondary">Cirujano:</h5>
                    </div>
                </div>
                {services?.services.length > 0 &&
                    services?.services.map((service, ind) => {
                        return (

                            <div className="d-flex align-items-center justify-content-between row" key={ind}>
                                <div className="col-8">
                                    <h5 className="fw-bold text-muted">{service.name}</h5>
                                </div>
                                <div className="col-4">
                                    <h5 className="fw-bold text-muted">{service.surgeon}</h5>
                                </div>
                                <hr className="lineCards" />
                            </div>
                        );
                    })
                }

            </SectionCard>
        );
    };

    const renderStages = () => {

        return (
            <div className="attentionSurgeryControls-tabs mt-3">
                {tabs.map(tab => (
                    <div
                        key={tab.value}
                        className={`attentionSurgeryControls-tab ${tab.isActive && "active"}`}
                        onClick={() => handleChangeTab(tab.value)}
                    >
                        <IconCircleCheck />
                        <span>{tab.label}</span>
                    </div>
                ))}
            </div>

        );
    };

    useEffect(() => {
        validateChangingTab(services.status);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [services.status,]);

    const validateChangingTab = (status: string) => {
        if (status === "admission") {
            getNurseryNotesFunction("admission");
        } else if (status === "income") {
            getNurseryNotesFunction("income");

        } else if (status === "in_preparation" || status === "preparation") {
            getNurseryNotesFunction("preparation");

        } else if (status === "in_surgery" || status === "surgery") {
            getNurseryNotesFunction("surgery");

        } else if (status === "in_recovery" || status === "recovery") {
            getNurseryNotesFunction("recovery");

        }
    };

    const getNurseryNotesFunction = (stage: string) => {
        async function fetchData() {
            const data = await dispatch(getNurseryNotes({ tab: stage, numId }));
            if (stage === "admission") {
                data.admission && setAdmissionNotes(data.admission);
                const statusTabChanged = tabs.map(tab => tab.value === stage ? { ...tab, render: <Admission data={data.admission} setAdmissionNotes={setAdmissionNotes} onSubmitNotes={onSubmitNotes} /> }
                    : { ...tab });
                setTabs(statusTabChanged);
                handleChangeTab("admission", statusTabChanged);
            } else if (stage === "income") {
                const statusTabChanged = tabs.map(tab => tab.value === stage ? { ...tab, render: <Income data={data.income} onSubmitNotes={onSubmitNotes} /> }
                    : { ...tab });
                setTabs(statusTabChanged);
                handleChangeTab("income", statusTabChanged);
            } else if (stage === "preparation") {
                data.preparation && setPreparationNotes(data.preparation);
                const statusTabChanged = tabs.map(tab => tab.value === stage ? { ...tab, render: <Preparation data={data.preparation} setPreparationNotes={setPreparationNotes} onSubmitNotes={onSubmitNotes} /> }
                    : { ...tab });
                setTabs(statusTabChanged);
                handleChangeTab("preparation", statusTabChanged);
            } else if (stage === "surgery") {
                data.surgery && setSurgeryNotes(data.surgery);
                const statusTabChanged = tabs.map(tab => tab.value === stage ? { ...tab, render: <Surgery data={data.surgery} setSurgeryNotes={setSurgeryNotes} onSubmitNotes={onSubmitNotes} /> }
                    : { ...tab });
                setTabs(statusTabChanged);
                handleChangeTab("surgery", statusTabChanged);
            } else if (stage === "recovery") {
                data.recovery && setRecoveryNotes(data.recovery);
                const statusTabChanged = tabs.map(tab => tab.value === stage ? { ...tab, render: <Recovery data={data.recovery} setRecoveryNotes={setRecoveryNotes} onSubmitNotes={onSubmitNotes} /> }
                    : { ...tab });
                setTabs(statusTabChanged);
                handleChangeTab("recovery", statusTabChanged);
            }
        }
        fetchData();
    };


    return (
        <div className="w-100 h-100 d-flex justify-content-between surgeryBody">
            <div className=" w-100 h-100">
                {
                    location.state.data.appId &&
                    <SurgeryHeader
                        appId={location.state.data.appId}
                        setServices={setServices}
                        showHistory={false}
                        currentSection="" />
                }
                <div className="d-flex  w-100 h-100 justify-content-between">
                    <div className="w-100">
                        <div className="px-1 widthBody py-3 w-100">
                            <div className="mx-4">
                                {renderSurgeries()}
                            </div>
                            <div className="attentionSurgeryControls mx-4">
                                {renderStages()}
                            </div>
                            <div className="bodyHeight ">
                                {tabs.find(tab => tab.isActive)?.render}
                            </div>
                        </div>
                        <ConsultationControls
                            stage={tabs.find(tab => tab.isActive)}
                            tab={"surgery"} appId={location.state.data.appId} statusSurgery={tabs.find(tab => tab.isActive)?.value} />
                    </div>
                    <History showClose={false} patientIdProp={location.state.data.patient.id}
                        patientAge={services.patientInfo.age}
                        patientName={services.patientInfo.fullName}
                        patientIdentification={services.patientInfo.document}
                        defaultHistory="ophthalmology" allData />
                </div>

            </div>
        </div>
    );
};

export default AttentionSurgery; 