import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconSend } from "@tabler/icons-react";
//
import { fireCautionAlert, fireWarningAlert } from "@/utils";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { finishAppointment, finishAppointmentDiagnosticAids } from "./controls.action";
import routes from "@/constants/routes";
import { getCanAttentionEnd } from "../consultation.actions";
//
import { Button } from "@/components";
import { PreoperativePayload } from "@/models/sheets/preoperative";
//
import "./Controls.scss";
import UnexpectedSituation from "./UnexpectedSituation/UnexpectedSituation";
import { translateSurgeryAttention } from "../../Surgery/patientAttentionSurgery.action";
import ConsumptionSheet from "../../Surgery/consumptionSheet/ConsumptionSheet";
import { ITabs } from "@/models/surgery/patientAttention/patientAttention";

export default function ConsultationControls({ preoperativePayload, isDisabledForm, tab, showButtonReport, appId, statusSurgery, stage }: {
    tab?: string, showButtonReport?: boolean, preoperativePayload?: PreoperativePayload; isDisabledForm?: boolean; appId?: number; statusSurgery?: string; stage?: ITabs;
}) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const workspace = useAppSelector(state => state.workspace);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const userToken = useAppSelector(state => state.auth.access_token);
    const currentAppointment = useAppSelector(state => state.patientAttention.appointmentSelected);
    const clhId = useAppSelector(state => state.patientAttention.patientStatus.clhId);
    const mcfId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);

    const [isUnexpectedModal, setIsUnexpectedModal] = useState<boolean>(false);
    const [consumptionSheetModal, setConsumptionSheetModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const goToOrderingModule = async () => {
        const canAttentionEnd = await dispatch(getCanAttentionEnd(mcfId));

        if (!canAttentionEnd.results) {
            fireWarningAlert(canAttentionEnd.message, "Intenta de nuevo");
            return;
        }

        const { REACT_APP_URL_REDIRECT_BIOWEL } = process.env;
        window.open(`${REACT_APP_URL_REDIRECT_BIOWEL}/redirect/1/${userToken}/${clhId}/${currentAppointment.app_id}`, "_blank");
    };

    const onOpenUnexpectedModal = () => setIsUnexpectedModal(true);
    const onCloseUnexpectedModal = () => setIsUnexpectedModal(false);
    const handleOpenConsumptionSheetModal = () => setConsumptionSheetModal(true);
    const handleCloseConsumptionSheetModal = () => setConsumptionSheetModal(false);

    const onFinishAppointment = async () => {
        try {
            setIsLoading(true);
            if ((workspace.profile?.prefijo.toLowerCase()) === "nurse" || (currentAppointment.status === "failed")) {
                location.pathname.includes("DiagnosticAids") ?
                    navigate(routes.workspaceDiagnosticAids)
                    : navigate(routes.workspaceConsultation);
            } else if (currentAppointment.status !== "attended") {
                fireCautionAlert(`Se finalizará la consulta del paciente ${currentAppointment.patientName}`, undefined, async () => {
                    let isSuccess;
                    location.pathname.includes("DiagnosticAids") ?
                        isSuccess = await dispatch(finishAppointmentDiagnosticAids({
                            appId: currentAppointment.app_id,
                            eaccount: workspace.id,
                            userId: userId as number,
                            cluId: currentAppointment.patientId
                        }))
                        :
                        isSuccess = await dispatch(finishAppointment({
                            appId: currentAppointment.app_id,
                            eaccount: workspace.id,
                            userId: userId as number,
                            cluId: currentAppointment.patientId
                        }));
                    if (isSuccess) {
                        location.pathname.includes("DiagnosticAids") ?
                            navigate(routes.workspaceDiagnosticAids)
                            : navigate(routes.workspaceConsultation);
                    }
                });
            } else {
                let isSuccess;
                location.pathname.includes("DiagnosticAids") ?
                    isSuccess = await dispatch(finishAppointmentDiagnosticAids({
                        appId: currentAppointment.app_id,
                        eaccount: workspace.id,
                        userId: userId as number,
                        cluId: currentAppointment.patientId
                    }))
                    :
                    isSuccess = await dispatch(finishAppointment({
                        appId: currentAppointment.app_id,
                        eaccount: workspace.id,
                        userId: userId as number,
                        cluId: currentAppointment.patientId
                    }));
                if (isSuccess) {
                    location.pathname.includes("DiagnosticAids") ?
                        navigate(routes.workspaceDiagnosticAids)
                        : navigate(routes.workspaceConsultation);
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    const translateAttentionFunction = () => {
        async function fetchData() {
            if (appId) {
                const data = await dispatch(translateSurgeryAttention(appId));
                if (data.success) {
                    navigate(routes.workspaceSurgery);
                }
            }
        }
        fetchData();
    };



    const render = () => {
        return (
            <div className="consultation-controls">
                <div className="flex-grow-1">
                    {tab === "surgery" &&
                        <Button
                            variant="outline"
                            className="me-3"
                            onClick={handleOpenConsumptionSheetModal}
                            isLoading={isLoading}
                        >
                            Registrar consumo
                        </Button>
                    }
                    <Button
                        variant="outline-danger"
                        onClick={onOpenUnexpectedModal}
                    >
                        Situación inesperada
                    </Button>

                </div>

                {tab !== "surgery" &&
                    <Button
                        variant="outline"
                        className="me-3"
                        onClick={onFinishAppointment}
                        isLoading={isLoading}
                    >
                        {(tab && showButtonReport && currentAppointment.status === "attended") ? (tab === "adx" && showButtonReport) ? "Actualizar reporte" : "finalizar" :
                            currentAppointment.status === "attended" || currentAppointment.status === "failed" ?
                                "Regresar" : "Finalizar"}

                    </Button>

                }
                {!location.pathname.includes("DiagnosticAids") && !isDisabledForm && !(workspace.profile?.prefijo.toLowerCase() === "nurse") && (
                    <Button
                        variant="primary"
                        endIcon={<IconSend />}
                        onClick={() => goToOrderingModule()}
                    >
                        Ir a ordenamiento
                    </Button>
                )}
                {tab === "surgery" &&
                    <Button
                        variant="outline"
                        className="me-3"
                        onClick={() => translateAttentionFunction()}
                        isLoading={isLoading}
                    >
                        {statusSurgery === "income" && "Trasladar a preparación"}
                        {statusSurgery === "preparation" && "Trasladar a cirugía"}
                        {statusSurgery === "surgery" && "Trasladar a recuperación"}
                        {statusSurgery === "recovery" && "Paciente egresa"}
                    </Button>
                }
                <UnexpectedSituation
                    isOpen={isUnexpectedModal}
                    onClose={onCloseUnexpectedModal}
                    isDisabledForm={isDisabledForm}
                    appId={appId}
                />
                <ConsumptionSheet
                    isOpen={consumptionSheetModal}
                    onClose={handleCloseConsumptionSheetModal}
                    appId={appId}
                    stage={stage}
                />
            </div>
        );
    };

    return render();
}