export const listHistorySegments = [
    { label: "Análisis y plan", value: "analysisPlan" },
    { label: "Oftalmología", value: "ophthalmology" },
    { label: "Optometría", value: "optometry" },
    { label: "Ortóptica", value: "orthoptic" },
    { label: "Baja visión", value: "lowVision" },
    { label: "Adaptación LC", value: "adaptation" },
    { label: "V. Preoperatoria", value: "preoperative" },
    { label: "Preconsulta", value: "preconsulting" },
    { label: "Antecedentes", value: "background" },
    { label: "Origen de la atención", value: "attentionOrigin" },
    { label: "Impresión diagnóstica", value: "diagnosticImpression" },
    { label: "Paquimetría", value: "pachymetry" },
    { label: "Presión intraocular", value: "pio" },
    { label: "Ayudas diagnósticas", value: "diagnosticAids" }
].sort((a, b) => a.label.localeCompare(b.label));