import { useEffect, useState } from "react";
import { useAppSelector } from "@/hooks";
//
import { CommonSelect, OrthopticPayload, SightTest as ISightTest, Versions } from "@/models/sheets/orthoptic";
import SightTest from "../../../Orthoptic/SightTest/SightTest";
import Version from "../../../Orthoptic/Versions/Version/Version";
import {
    AccommodationFlexibility,
    AttentionOrigin,
    Bielshowschky,
    BreadthAccommodation,
    ChromaticVision,
    Cie10EXTDiagnostic,
    Fixation,
    FunctionalOptometryFinalFormula,
    FusionalBreadths,
    OrthopticDetails as IOrthopticDetails,
    KappaAngle,
    Laterality,
    NextConvergencePoint,
    SensoryCorrespondence,
    Stereopsis,
    VisualAcuity
} from "@/models/historyDetails/orthoptic";
import { formatHistoryDetails } from "@/utils";
import { getVersionsList } from "@/services";
//
import "../Details.scss";

export default function OrthopticDetails({ orthopticDetails, forDownload }: { orthopticDetails: IOrthopticDetails; forDownload?: boolean; }) {
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);

    const [orthopticPayload, setOrthopticPayload] = useState<OrthopticPayload>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number
    });
    const [versionsState, setVersionsState] = useState<CommonSelect[]>([]);

    useEffect(() => {
        async function fetchData() {
            const data = await getVersionsList();
            if (data.results) setVersionsState(data.results);
        }
        fetchData();
    }, []);

    const renderAttentionOrigin = (attentionOrigin: AttentionOrigin | null) => {

        if (attentionOrigin) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Origen de atención
                    </h4>
                    {attentionOrigin.attentionOrigin !== null ? (
                        <h5>
                            <span className="fw-bold">Origen de la atención: </span>{" "}
                            {attentionOrigin.attentionOrigin}
                        </h5>
                    ) : ""}
                    {attentionOrigin.abserveEvent !== null ? (
                        <h5>
                            <span className="fw-bold">Evento adverso: </span>{" "}
                            {attentionOrigin.abserveEvent === 1 ? "Sí" : "No"}
                        </h5>
                    ) : ""}
                    {(Object.values(attentionOrigin.attendingReason || {}).length || attentionOrigin.attendingReasonJst) ? (
                        <div className="mt-3">
                            {Object.values(attentionOrigin.attendingReason || {}).length ? (
                                <h5 className="fw-bold">
                                    <span>Motivo consulta: </span>
                                    {Object.values(attentionOrigin.attendingReason || {}).join(" - ")}
                                </h5>
                            ) : ""}
                            {attentionOrigin.attendingReasonJst ? (
                                <h5 style={{ whiteSpace: "pre-line" }}>
                                    {attentionOrigin.attendingReasonJst}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}
                    {(Object.values(attentionOrigin.currentIllness || {}).length || attentionOrigin.currentIllnessJst) ? (
                        <div className="mt-3">
                            {Object.values(attentionOrigin.currentIllness || {}).length ? (
                                <h5 className="fw-bold">
                                    <span>Enfermedad actual: </span>
                                    {Object.values(attentionOrigin.currentIllness || {}).join(" - ")}
                                </h5>
                            ) : ""}
                            {attentionOrigin.currentIllnessJst ? (
                                <h5 style={{ whiteSpace: "pre-line" }}>
                                    {attentionOrigin.currentIllnessJst}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}
                    {attentionOrigin.evolutionTime ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>
                            {attentionOrigin.evolutionTime}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderVisualAcuity = (visualAcuity: VisualAcuity | null) => {
        if (visualAcuity) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Agudeza visual
                    </h4>
                    <h5>
                        {visualAcuity.optotype ? (
                            <>
                                <span className="fw-bold">Optotipo: </span>{" "}
                                {visualAcuity.optotype}
                            </>
                        ) : ""}
                        {visualAcuity.optotype && visualAcuity.classification ? " - " : ""}
                        {visualAcuity.classification ? (
                            <>
                                <span className="fw-bold">Clasificación: </span>{" "}
                                {visualAcuity.classification}
                            </>
                        ) : ""}
                    </h5>
                    {visualAcuity.noCorrection ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                AV sin corrección
                            </h5>
                            <h5>
                                {visualAcuity.noCorrection.od ? (
                                    <>
                                        <span className="fw-bold">AV OD: </span>{" "}
                                        {visualAcuity.noCorrection?.od}
                                    </>
                                ) : ""}
                                {visualAcuity.noCorrection.od && visualAcuity.noCorrection.oi ? " / " : ""}
                                {visualAcuity.noCorrection.oi ? (
                                    <>
                                        <span className="fw-bold">AV OI: </span>{" "}
                                        {visualAcuity.noCorrection?.oi}
                                    </>
                                ) : ""}
                                {visualAcuity.noCorrection.oi && visualAcuity.noCorrection.ao ? " / " : ""}
                                {visualAcuity.noCorrection.ao ? (
                                    <>
                                        <span className="fw-bold">AV AO: </span>{" "}
                                        {visualAcuity.noCorrection?.ao}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}
                    {visualAcuity.correction ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                AV con corrección
                            </h5>
                            <h5>
                                {visualAcuity.correction.od ? (
                                    <>
                                        <span className="fw-bold">AV OD: </span>{" "}
                                        {visualAcuity.correction?.od}
                                    </>
                                ) : ""}
                                {visualAcuity.correction.od && visualAcuity.correction.oi ? " / " : ""}
                                {visualAcuity.correction.oi ? (
                                    <>
                                        <span className="fw-bold">AV OI: </span>{" "}
                                        {visualAcuity.correction?.oi}
                                    </>
                                ) : ""}
                                {visualAcuity.correction.oi && visualAcuity.correction.ao ? " / " : ""}
                                {visualAcuity.correction.ao ? (
                                    <>
                                        <span className="fw-bold">AV AO: </span>{" "}
                                        {visualAcuity.correction?.ao}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}

                    {visualAcuity.ph ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Pinhole (PH)
                            </h5>
                            <h5>
                                {visualAcuity.ph.od ? (
                                    <>
                                        <span className="fw-bold">AV OD: </span>{" "}
                                        {visualAcuity.ph?.od}
                                    </>
                                ) : ""}
                                {visualAcuity.ph.od && visualAcuity.ph.od ? " / " : ""}
                                {visualAcuity.ph.oi ? (
                                    <>
                                        <span className="fw-bold">AV OI: </span>{" "}
                                        {visualAcuity.ph?.oi}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}

                    {visualAcuity.lentsInUse ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Lentes en uso
                            </h5>
                            <h5>
                                {visualAcuity.lentsInUse.lentsOd ? (
                                    <>
                                        <span className="fw-bold">Lente OD: </span>{" "}
                                        {visualAcuity.lentsInUse?.lentsOd}
                                    </>
                                ) : ""}
                                {visualAcuity.lentsInUse.lentsOd && visualAcuity.lentsInUse.lentsOi ? " / " : ""}
                                {visualAcuity.lentsInUse.lentsOi ? (
                                    <>
                                        <span className="fw-bold">Lente OI: </span>{" "}
                                        {visualAcuity.lentsInUse?.lentsOi}
                                    </>
                                ) : ""}
                            </h5>
                            <h5>
                                {visualAcuity.lentsInUse.od ? (
                                    <>
                                        <span className="fw-bold">AV OD: </span>{" "}
                                        {visualAcuity.lentsInUse?.od}
                                    </>
                                ) : ""}
                                {visualAcuity.lentsInUse.od && visualAcuity.lentsInUse.oi ? " / " : ""}
                                {visualAcuity.lentsInUse.oi ? (
                                    <>
                                        <span className="fw-bold">AV OI: </span>{" "}
                                        {visualAcuity.lentsInUse?.oi}
                                    </>
                                ) : ""}
                            </h5>
                            <h5>
                                <span className="fw-bold">Observaciones: </span>
                                {visualAcuity.lentsInUse.observations}
                            </h5>
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderChromaticVision = (chromaticVision: ChromaticVision | null) => {
        if (chromaticVision) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Visión cromática
                    </h4>
                    {chromaticVision.ishihara ? (
                        <>
                            {chromaticVision.ishihara.text ? (
                                <h5>
                                    <span className="fw-bold">Ishihara: </span>{" "}
                                    {chromaticVision.ishihara.text}
                                </h5>
                            ) : ""}
                            {chromaticVision.ishihara.observations ? (
                                <h5>
                                    <span className="fw-bold">Observaciones: </span>
                                    {chromaticVision.ishihara.observations}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}
                    {chromaticVision.farnsworthTest ? (
                        <>
                            <h5 className="mt-3 fw-bold">
                                Farnsworth test
                            </h5>
                            {chromaticVision.farnsworthTest.od ? (
                                <h5>
                                    <span className="fw-bold">OD: </span>{" "}
                                    {chromaticVision.farnsworthTest.od}
                                </h5>
                            ) : ""}
                            {chromaticVision.farnsworthTest.oi ? (
                                <h5>
                                    <span className="fw-bold">OI: </span>{" "}
                                    {chromaticVision.farnsworthTest.oi}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderFFF = (fff: FunctionalOptometryFinalFormula | null) => {
        if (fff) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Fórmula final de optometría funcional
                    </h4>
                    {fff.od ? (
                        <h5>
                            <span className="fw-bold">FFFO OD: </span>{" "}
                            {fff.od}
                        </h5>
                    ) : ""}
                    {fff.oi ? (
                        <h5>
                            <span className="fw-bold">FFFO OI: </span>{" "}
                            {fff.oi}
                        </h5>
                    ) : ""}
                    {fff.observations ? (
                        <h5>
                            <span className="fw-bold">Observaciones: </span>{" "}
                            {fff.observations}
                        </h5>
                    ) : ""}
                </div>
            );
        }
    };

    const renderLaterallity = (laterallity: Laterality) => {
        if (laterallity) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Lateralidad
                    </h4>
                    {laterallity.eylId ? (
                        <h5>
                            <span className="fw-bold">Ojo dominante: </span>
                            {laterallity.eylId}
                        </h5>
                    ) : ""}
                    {laterallity.halId ? (
                        <h5>
                            <span className="fw-bold">Mano dominante: </span>
                            {laterallity.halId}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderKappaAngle = (kappaAngle: KappaAngle) => {
        if (kappaAngle) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Ángulo Kappa
                    </h4>
                    {kappaAngle.kapIdOd ? (
                        <h5>
                            <span className="fw-bold">Ángulo Kappa OD: </span>
                            {kappaAngle.kapIdOd}
                        </h5>
                    ) : ""}
                    {kappaAngle.kapIdOi ? (
                        <h5>
                            <span className="fw-bold">Ángulo Kappa OI: </span>
                            {kappaAngle.kapIdOi}
                        </h5>
                    ) : ""}
                </div>
            );
        }
    };

    const renderFixation = (fixation: Fixation) => {
        if (fixation) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Fijación
                    </h4>
                    <h5>
                        {fixation.fixIdOd ? (
                            <>
                                <span className="fw-bold">OD: </span>
                                {fixation.fixIdOd}
                            </>
                        ) : ""}
                        {(fixation.fixIdOd && fixation.fixIdOi) ? " - " : ""}
                        {fixation.fixIdOi ? (
                            <>
                                <span className="fw-bold">OI: </span>
                                {fixation.fixIdOi}
                            </>
                        ) : ""}
                    </h5>
                    {fixation.fixObservations ? (
                        <h5>
                            <span className="fw-bold">Observaciones: </span>{" "}
                            {fixation.fixObservations}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderKrimskyTest = (krimskyTest: ISightTest) => {
        if (krimskyTest) {
            return (
                <div className={`${forDownload ? "col-12 page-break" : "col-6"}`}>
                    <div className="d-inline mb-5">
                        <h4 className="text-primary fw-bold mb-3">
                            Test de Krimsky
                        </h4>
                        <h5>{krimskyTest.correction ? "Con corrección" : "Sin corrección"}</h5>
                        <div className="d-flex">
                            <SightTest
                                type="vl"
                                onGetResult={() => null}
                                currentData={krimskyTest?.vl}
                                isPrism
                                readOnly={true}
                            />
                            <div className="mx-3"></div>
                            <SightTest
                                type="vp"
                                onGetResult={() => null}
                                currentData={krimskyTest?.vp}
                                isPrism
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            );
        } else return "";
    };

    const renderPrismaCoverTest = (prismaCoverTest: ISightTest) => {
        if (prismaCoverTest) {
            return (
                <div className={`${forDownload ? "col-12 page-break" : "col-6"}`}>
                    <div className="d-inline mb-5">
                        <h4 className="text-primary fw-bold mb-3">
                            Prisma Cover Test
                        </h4>
                        <h5>{prismaCoverTest.correction ? "Con corrección" : "Sin corrección"}</h5>
                        <div className="d-flex">
                            <SightTest
                                type="vl"
                                onGetResult={() => null}
                                currentData={prismaCoverTest?.vl}
                                isPrism
                                readOnly={true}
                            />
                            <div className="mx-3"></div>
                            <SightTest
                                type="vp"
                                onGetResult={() => null}
                                currentData={prismaCoverTest?.vp}
                                isPrism
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            );
        } else return "";
    };

    const renderWhiteTest = (whiteTest: ISightTest) => {
        if (whiteTest) {
            return (
                <div className={`${forDownload ? "col-12 page-break" : "col-6"}`}>
                    <div className="d-inline mb-5">
                        <h4 className="text-primary fw-bold mb-3">
                            White test
                        </h4>
                        <h5>{whiteTest.correction ? "Con corrección" : "Sin corrección"}</h5>
                        <div className="d-flex">
                            <SightTest
                                type="vl"
                                onGetResult={() => null}
                                currentData={whiteTest?.vl}
                                isPrism
                                readOnly={true}
                            />
                            <div className="mx-3"></div>
                            <SightTest
                                type="vp"
                                onGetResult={() => null}
                                currentData={whiteTest?.vp}
                                isPrism
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            );
        } else return "";
    };

    const renderHirschberg = (hirschbergReflect: ISightTest) => {
        if (hirschbergReflect) {
            return (
                <div className={`${forDownload ? "col-12 page-break" : "col-6"}`}>
                    <div className="d-inline mb-5">
                        <h4 className="text-primary fw-bold mb-3">
                            Reflejo de Hirschberg
                        </h4>
                        <h5>{hirschbergReflect.correction ? "Con corrección" : "Sin corrección"}</h5>
                        <div className="d-flex">
                            <SightTest
                                type="vl"
                                onGetResult={() => null}
                                currentData={hirschbergReflect?.vl}
                                readOnly={true}
                            />
                            <div className="mx-3"></div>
                            <SightTest
                                type="vp"
                                onGetResult={() => null}
                                currentData={hirschbergReflect?.vp}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            );
        } else return "";
    };

    const renderVersions = (versions: Versions) => {
        if (versions) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Versiones
                    </h4>
                    <Version
                        orthopticPayload={{
                            ...orthopticPayload,
                            versions: versions
                        }}
                        setOrthopticPayload={setOrthopticPayload}
                        onSubmit={() => null}
                        versions={versionsState}
                        isDetail
                        readOnly
                    />
                </div>
            );
        } else return "";
    };

    const renderFusionalBreadth = (fusionalBreadths: FusionalBreadths | null) => {
        if (fusionalBreadths) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Amplitudes fusionales
                    </h4>
                    {fusionalBreadths.or ? (
                        <>
                            <h5 className=" fw-bold">
                                Objeto real
                            </h5>
                            {fusionalBreadths.or.vl?.divergent ? (
                                <h5>
                                    <span className="fw-bold">Divergencia VL:</span>{" "}
                                    {fusionalBreadths.or.vl?.divergent}
                                </h5>
                            ) : ""}
                            {fusionalBreadths.or.vl?.convergent ? (
                                <h5>
                                    <span className="fw-bold">Convergencia VL:</span>{" "}
                                    {fusionalBreadths.or.vl.convergent}
                                </h5>
                            ) : ""}
                            {fusionalBreadths.or.vp?.divergent ? (
                                <h5>
                                    <span className="fw-bold">Divergencia VP:</span>{" "}
                                    {fusionalBreadths.or.vp.divergent}
                                </h5>
                            ) : ""}
                            {fusionalBreadths.or.vp?.convergent ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Convergencia VP:</span>{" "}
                                    {fusionalBreadths.or.vp.convergent}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}

                    {fusionalBreadths.luz ? (
                        <>
                            <h5 className=" fw-bold">
                                Luz
                            </h5>
                            {fusionalBreadths.luz.vl?.divergent ? (
                                <h5>
                                    <span className="fw-bold">Divergencia VL:</span>{" "}
                                    {fusionalBreadths.luz.vl.divergent}
                                </h5>
                            ) : ""}
                            {fusionalBreadths.luz.vl?.convergent ? (
                                <h5>
                                    <span className="fw-bold">Convergencia VL:</span>{" "}
                                    {fusionalBreadths.luz.vl.convergent}
                                </h5>
                            ) : ""}
                            {fusionalBreadths.luz.vp?.divergent ? (
                                <h5>
                                    <span className="fw-bold">Divergencia VP:</span>{" "}
                                    {fusionalBreadths.luz.vp.divergent}
                                </h5>
                            ) : ""}
                            {fusionalBreadths.luz.vp?.convergent ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Convergencia VP:</span>{" "}
                                    {fusionalBreadths.luz.vp.convergent}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}
                    {fusionalBreadths.observations ? (
                        <h5>
                            <span className="fw-bold">Observaciones: </span>
                            {fusionalBreadths.observations}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderBielschowsky = (bielschowskyTest: Bielshowschky) => {
        if (bielschowskyTest) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Test de Bielschowsky
                    </h4>
                    {bielschowskyTest.vl?.right ? (
                        <h5>
                            <span className="fw-bold">VL Derecha:</span>{" "}
                            {bielschowskyTest.vl.right}
                        </h5>
                    ) : ""}
                    {bielschowskyTest.vl?.left ? (
                        <h5>
                            <span className="fw-bold">VL Izquierda:</span>{" "}
                            {bielschowskyTest.vl.left}
                        </h5>
                    ) : ""}
                    {bielschowskyTest.vp?.right ? (
                        <h5>
                            <span className="fw-bold">VP Derecha:</span>{" "}
                            {bielschowskyTest.vp.right}
                        </h5>
                    ) : ""}
                    {bielschowskyTest.vp?.left ? (
                        <h5>
                            <span className="fw-bold">VP Izquierda:</span>{" "}
                            {bielschowskyTest.vp.left}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderBreadthAccomodation = (breadthAccomodation: BreadthAccommodation) => {
        if (breadthAccomodation) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Amplitud de acomodación
                    </h4>
                    <h5>
                        {breadthAccomodation.balId ? (
                            <>
                                <span className="fw-bold">Nivel: </span>
                                {breadthAccomodation.balId}
                            </>
                        ) : ""}
                        {(breadthAccomodation.balId && breadthAccomodation.od) ? " - " : ""}
                        {breadthAccomodation.od ? (
                            <>
                                <span className="fw-bold">OD: </span>
                                {breadthAccomodation.od}
                            </>
                        ) : ""}
                        {(breadthAccomodation.od && breadthAccomodation.oi) ? " - " : ""}
                        {breadthAccomodation.oi ? (
                            <>
                                <span className="fw-bold">OI: </span>
                                {breadthAccomodation.oi}
                            </>
                        ) : ""}
                        {(breadthAccomodation.oi && breadthAccomodation.distance) ? " - " : ""}
                        {breadthAccomodation.distance ? (
                            <>
                                <span className="fw-bold">Distancia: </span>
                                {breadthAccomodation.distance}
                            </>
                        ) : ""}
                    </h5>
                </div>
            );
        } else return "";
    };

    const renderSensoryCorrespondence = (sensoryCorrespondence: SensoryCorrespondence) => {
        if (sensoryCorrespondence) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Correspondencia sensorial
                    </h4>
                    <h5>
                        {sensoryCorrespondence.vl ? (
                            <>
                                {sensoryCorrespondence.vl.fr ? (
                                    <h5>
                                        <span className="fw-bold">VL FR: </span>
                                        {sensoryCorrespondence.vl.fr}
                                    </h5>
                                ) : ""}
                                {sensoryCorrespondence.vl.bag ? (
                                    <h5>
                                        <span className="fw-bold">VL BAG: </span>
                                        {sensoryCorrespondence.vl.bag}
                                    </h5>
                                ) : ""}
                                {sensoryCorrespondence.vl.lw ? (
                                    <h5>
                                        <span className="fw-bold">VL LW: </span>
                                        {sensoryCorrespondence.vl.lw}
                                    </h5>
                                ) : ""}
                                {sensoryCorrespondence.vl.amd ? (
                                    <h5>
                                        <span className="fw-bold">VL AMB: </span>
                                        {sensoryCorrespondence.vl.amd}
                                    </h5>
                                ) : ""}
                            </>
                        ) : ""}
                    </h5>
                    {(sensoryCorrespondence.vl && sensoryCorrespondence.vp) ? <div className="my-3"></div> : ""}
                    <h5>
                        {sensoryCorrespondence.vp ? (
                            <>
                                {sensoryCorrespondence.vp.fr ? (
                                    <h5>
                                        <span className="fw-bold">VP FR: </span>
                                        {sensoryCorrespondence.vp.fr}
                                    </h5>
                                ) : ""}
                                {sensoryCorrespondence.vp.bag ? (
                                    <h5>
                                        <span className="fw-bold">VP BAG: </span>
                                        {sensoryCorrespondence.vp.bag}
                                    </h5>
                                ) : ""}
                                {sensoryCorrespondence.vp.lw ? (
                                    <h5>
                                        <span className="fw-bold">VP LW: </span>
                                        {sensoryCorrespondence.vp.lw}
                                    </h5>
                                ) : ""}
                                {sensoryCorrespondence.vp.amd ? (
                                    <h5>
                                        <span className="fw-bold">VP AMB: </span>
                                        {sensoryCorrespondence.vp.amd}
                                    </h5>
                                ) : ""}
                            </>
                        ) : ""}
                    </h5>
                    {sensoryCorrespondence.observations ? (
                        <h5>
                            <span className="fw-bold">Observaciones: </span>
                            {sensoryCorrespondence.observations}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderAccommodationFlexibility = (accommodationFlexibility: AccommodationFlexibility) => {
        if (accommodationFlexibility) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Flexibilidad de acomodación
                    </h4>
                    <h5>
                        {accommodationFlexibility.od ? (
                            <>
                                <span className="fw-bold">OD: </span>
                                {accommodationFlexibility.od}
                            </>
                        ) : ""}
                        {(accommodationFlexibility.od && accommodationFlexibility.oi) ? " - " : ""}
                        {accommodationFlexibility.oi ? (
                            <>
                                <span className="fw-bold">OI: </span>
                                {accommodationFlexibility.oi}
                            </>
                        ) : ""}
                        {(accommodationFlexibility.oi && accommodationFlexibility.cymId) ? " - " : ""}
                        {accommodationFlexibility.cymId ? (
                            <>
                                <span className="fw-bold">Ciclos/min: </span>
                                {accommodationFlexibility.cymId}
                            </>
                        ) : ""}
                    </h5>
                </div>
            );
        } else return "";
    };

    const renderNextConvergencePoint = (nextConvergencePoint: NextConvergencePoint) => {
        if (nextConvergencePoint) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Punto próximo de convergencia
                    </h4>
                    <h5>
                        {nextConvergencePoint.realObjectMethod ? (
                            <>
                                <span className="fw-bold">Objeto real: </span>
                                {nextConvergencePoint.realObjectMethod}
                            </>
                        ) : ""}
                        {(nextConvergencePoint.realObjectMethod && nextConvergencePoint.lightMethod) ? " - " : ""}
                        {nextConvergencePoint.lightMethod ? (
                            <>
                                <span className="fw-bold">Luz: </span>
                                {nextConvergencePoint.lightMethod}
                            </>
                        ) : ""}
                        {(nextConvergencePoint.lightMethod && nextConvergencePoint.redFilterMethod) ? " - " : ""}
                        {nextConvergencePoint.redFilterMethod ? (
                            <>
                                <span className="fw-bold">Filtro rojo: </span>
                                {nextConvergencePoint.redFilterMethod}
                            </>
                        ) : ""}
                    </h5>
                </div>
            );
        } else return "";
    };

    const renderStereopsis = (stereopsis: Stereopsis) => {
        if (stereopsis) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Estereopsis
                    </h4>
                    {stereopsis.test ? (
                        <h5>
                            <span className="fw-bold">Test: </span>
                            {stereopsis.test}
                        </h5>
                    ) : ""}
                    {stereopsis.observations ? (
                        <h5>
                            <span className="fw-bold">Observaciones: </span>
                            {stereopsis.observations}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderExtDiagnostic = (extDiagnostic: Cie10EXTDiagnostic | null) => {
        if (extDiagnostic && Object.values(extDiagnostic).length) {
            const mainExt = Object.values(extDiagnostic).find(item => item.isMain === 1);

            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Impresión diagnóstica
                    </h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-secondary">
                                <th>Fecha</th>
                                <th className="text-center">CIE-10</th>
                                <th>Impresión diagnóstica</th>
                                <th className="text-center">Ojo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(extDiagnostic).map((item) => (
                                <tr key={`${item.id}-${item.clhId}`} style={{ backgroundColor: item.isMain ? "#fcfae6" : undefined }}>
                                    <td className="align-middle">{item.date}</td>
                                    <td align="center" className="align-middle">{item.cie10Code}</td>
                                    <td style={{ whiteSpace: "pre-line" }}>{item.cie10Description}</td>
                                    <td className="align-middle" align="center">{item.dbo?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {mainExt ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>
                            {mainExt.evolution} {mainExt.evolutionTime?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderAnalysisAndPlan = (analysisPlan: string | null) => {
        if (analysisPlan) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Análisis y plan
                    </h4>
                    <h5>{analysisPlan}</h5>
                </div>
            );
        } else {
            return "";
        }
    };

    const render = () => {
        const attentionOrigin = formatHistoryDetails(orthopticDetails.attentionOrigin);
        const visualAcuity = formatHistoryDetails(orthopticDetails.visualAcuity);

        const chromaticVision = formatHistoryDetails(orthopticDetails.chromaticVision);
        const functionalOptometryFinalFormula = formatHistoryDetails(orthopticDetails.functionalOptometryFinalFormula);
        const laterality = formatHistoryDetails(orthopticDetails.laterality);
        const kappaAngle = formatHistoryDetails(orthopticDetails.kappaAngle);
        const fixation = formatHistoryDetails(orthopticDetails.fixation);

        const krimskyTest = formatHistoryDetails(orthopticDetails.krimsky);
        const prismaCoverTest = formatHistoryDetails(orthopticDetails.prismaCoverTest);
        const whiteTest = formatHistoryDetails(orthopticDetails.whiteTest);
        const hirschberg = formatHistoryDetails(orthopticDetails.hirschberg);
        const versions = formatHistoryDetails(orthopticDetails.versions);

        const fusionalBreadths = formatHistoryDetails(orthopticDetails.fusionalBreadths);
        const bielschowsky = formatHistoryDetails(orthopticDetails.bielshowschky);
        const breadthAccommodation = formatHistoryDetails(orthopticDetails.breadthAccommodation);
        const accommodationFlexibility = formatHistoryDetails(orthopticDetails.accommodationFlexibility);
        const sensoryCorrespondence = formatHistoryDetails(orthopticDetails.sensoryCorrespondence);
        const nextConvergencePoint = formatHistoryDetails(orthopticDetails.nextConvergencePoint);
        const stereopsis = formatHistoryDetails(orthopticDetails.stereopsis);
        const extDiagnostic = formatHistoryDetails(orthopticDetails.cie10ExtDiagnostic);
        const analysisPlan = formatHistoryDetails(orthopticDetails.analysisPlan);

        return (
            <div className={`row ${!forDownload ? "overflow-auto" : "overflow-hidden"}`}>
                <div className="detail-container">
                    <div className={`${!forDownload ? "columns" : "column"}`}>
                        {renderAttentionOrigin(attentionOrigin)}
                        {renderVisualAcuity(visualAcuity)}
                        {renderChromaticVision(chromaticVision)}
                        {renderFFF(functionalOptometryFinalFormula)}
                        {renderLaterallity(laterality)}
                        {renderKappaAngle(kappaAngle)}
                        {renderFixation(fixation)}
                    </div>
                </div>

                <div className="row mt-3 g-4">
                    {renderKrimskyTest(krimskyTest)}
                    {renderPrismaCoverTest(prismaCoverTest)}
                    {renderWhiteTest(whiteTest)}
                    {renderHirschberg(hirschberg)}
                </div>

                <div className="detail-container mt-3">
                    <div className={`${!forDownload ? "columns" : "column"}`}>
                        {renderVersions(versions)}
                        {renderFusionalBreadth(fusionalBreadths)}
                        {renderBielschowsky(bielschowsky)}
                        {renderBreadthAccomodation(breadthAccommodation)}
                        {renderAccommodationFlexibility(accommodationFlexibility)}
                        {renderSensoryCorrespondence(sensoryCorrespondence)}
                        {renderNextConvergencePoint(nextConvergencePoint)}
                        {renderStereopsis(stereopsis)}
                        {renderExtDiagnostic(extDiagnostic)}
                        {renderAnalysisAndPlan(analysisPlan)}
                    </div>

                </div>
            </div>
        );
    };

    return render();
}