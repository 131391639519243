import { Checkbox, SectionCard, Select } from "@/components";
import { useAppDispatch } from "@/hooks";
import { GeneralSelects, ObjectiveRefractionEye } from "@/models/shared/objectiveRefraction";
import { LowVisionPayload } from "@/models/sheets/lowVision";
import { OptometryPayload } from "@/models/sheets/optometry";
import { useEffect, useState } from "react";
import { getSelects } from "./ObjectiveRefraction.actions";
import { OptotypeItem } from "@/models/select/optotype";
import { Vision } from "@/models/select/vision";
import { IdentifiedLetter } from "@/models/select/indentifiedLetter";

interface SubjectiveRefractionParams {
    externalPayload: OptometryPayload | LowVisionPayload;
    setExternalPayload: (visualPayload: OptometryPayload | LowVisionPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
}

const ObjectiveRefraction = ({ externalPayload, setExternalPayload, onSubmit, isDisabledForm }: SubjectiveRefractionParams) => {
    const dispatch = useAppDispatch();
    const [generalSelects, setGeneralSelects] = useState<GeneralSelects>({
        optotypes: [],
        farVision: [],
        nearVision: [],
        idLetters: [],
        lensAdd: [],
        lensType: []
    });
    const formattedAddSelect = generalSelects.lensAdd.map(sel => ({
        label: sel.value,
        value: sel.id
    }));

    const odAddValue = formattedAddSelect.find(sel => sel.value === externalPayload.objectiveRefraction?.od?.add);
    const oiAddValue = formattedAddSelect.find(sel => sel.value === externalPayload.objectiveRefraction?.oi?.add);
    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getSelects());
            setGeneralSelects({
                optotypes: data?.optotypesResponse as OptotypeItem[],
                farVision: data?.farVisionResponse as Vision[],
                nearVision: data?.nearVisionResponse as Vision[],
                idLetters: data?.idLettersResponse as IdentifiedLetter[],
                lensAdd: data?.lensAddResponse as Vision[],
                lensType: data?.lensTypeResponse as Vision[]
            });
        }
        fetchData();
    }, [dispatch]);

    const onChangeORefractionValue = (eye: "od" | "oi", label: keyof ObjectiveRefractionEye, value: string | number) => {
        const options: OptometryPayload = {
            ...externalPayload,
            objectiveRefraction: {
                ...externalPayload.objectiveRefraction,
                [eye]: {
                    ...externalPayload.objectiveRefraction?.[eye],
                    [label]: value
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const onChangeRefractionCheck = (refraction: "objectiveRefraction" | "subjectiveRefraction", checked: boolean) => {
        const options: OptometryPayload = {
            ...externalPayload,
            [refraction]: {
                ...externalPayload[refraction],
                cycloplegia: checked ? 1 : 0
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const onChangeRefractionObservation = (refraction: "objectiveRefraction" | "subjectiveRefraction", value: string) => {
        const options: OptometryPayload = {
            ...externalPayload,
            [refraction]: {
                ...externalPayload[refraction],
                observations: value
            }
        };
        setExternalPayload(options);
        onSubmit();
    };
    return (
        <SectionCard>
            <div className="d-flex justify-content-between align-items-center"> 
                <h5 className="text-secondary fw-bold">Refracción objetiva</h5>
                <div className="py-1 ps-3  bg-primary-lighter rounded fw-bold pointer" style={{ width: 150 }}>
                    <Checkbox
                        label="Cicloplejia"
                        className="text-primary"
                        checked={externalPayload.objectiveRefraction?.cycloplegia ? true : false}
                        onChange={({ target }) => onChangeRefractionCheck("objectiveRefraction", target.checked)}
                    />
                </div>
            </div>
            <hr className="text-primary mt-2" />
            <table>
                <thead>
                    <tr>
                        <td className="p-2"></td>
                        <td className="text-secondary" align="center">Esfera</td>
                        <td className="text-secondary" align="center">Cilindro</td>
                        <td className="text-secondary" align="center">Eje°</td>
                        <td className="text-secondary" align="center">ADD</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="py-1 ps-2 text-secondary bg-od" style={{ borderTopLeftRadius: "0.5rem" }} width={30}>OD</td>
                        <td className="p-2 bg-od" width={150}>
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={externalPayload.objectiveRefraction?.od?.sphere}
                                onChange={(event) => {
                                    const values = event.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        event.preventDefault();
                                    } else {
                                        onChangeORefractionValue("od", "sphere", event.target.value);
                                    }
                                }}
                            />
                        </td>
                        <td className="p-2 bg-od" width={150}>
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={externalPayload.objectiveRefraction?.od?.cylinder}
                                onChange={(event) => {
                                    const values = event.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        event.preventDefault();
                                    } else {
                                        onChangeORefractionValue("od", "cylinder", event.target.value);
                                    }
                                }}
                            />
                        </td>
                        <td className="p-2 bg-od" width={150}>
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={externalPayload.objectiveRefraction?.od?.axis}
                                onChange={({ target }) => onChangeORefractionValue("od", "axis", target.value)}
                                onKeyDown={(event) => event.key === "." && event.preventDefault()}
                            />
                        </td>
                        <td className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }} width={150}>
                            <Select
                                disabled={isDisabledForm}
                                options={formattedAddSelect}
                                value={odAddValue}
                                onChange={({ option }) => onChangeORefractionValue("od", "add", option.value)}
                                style={{ width: "100%" }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="py-1 ps-2 text-secondary bg-oi" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                        <td className="p-2 bg-oi" width={150}>
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={externalPayload.objectiveRefraction?.oi?.sphere}
                                onChange={(event) => {
                                    const values = event.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        event.preventDefault();
                                    } else {
                                        onChangeORefractionValue("oi", "sphere", event.target.value);
                                    }
                                }}
                            />
                        </td>
                        <td className="p-2 bg-oi" width={150}>
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={externalPayload.objectiveRefraction?.oi?.cylinder}
                                onChange={(event) => {
                                    const values = event.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        event.preventDefault();
                                    } else {
                                        onChangeORefractionValue("oi", "cylinder", event.target.value);
                                    }
                                }}
                            />
                        </td>
                        <td className="p-2 bg-oi" width={150}>
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={externalPayload.objectiveRefraction?.oi?.axis}
                                onChange={({ target }) => onChangeORefractionValue("oi", "axis", target.value)}
                                onKeyDown={(event) => event.key === "." && event.preventDefault()}
                            />
                        </td>
                        <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }} width={150}>
                            <Select
                                disabled={isDisabledForm}
                                options={formattedAddSelect}
                                value={oiAddValue}
                                onChange={({ option }) => onChangeORefractionValue("oi", "add", option.value)}
                                style={{ width: "100%" }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2" colSpan={6}>
                            <textarea
                                className="form-control no-resize"
                                style={{ height: 40 }}
                                value={externalPayload.objectiveRefraction?.observations}
                                onChange={({ target }) => onChangeRefractionObservation("objectiveRefraction", target.value)}
                                placeholder="Observaciones..."
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </SectionCard>
    );
};

export default ObjectiveRefraction;