import { useState } from "react";
//
import { AttentionOriginFields } from "@/models/attentionOrigin";
import TextConfigModal from "./TextConfig/TextConfigNoIns";
//
import { SectionCard } from "@/components";
import { saveAttentionOrigin } from "./attentionOrigin.actions";
import { useDebouncedCallback } from "use-debounce";
import { useAppDispatch } from "@/hooks";

interface AnalysisAndPlanProps {
    attentionOriginOptions: AttentionOriginFields,
    setAttentionOriginOptions: (attentionOriginOptions: AttentionOriginFields) => void;
    height: number | string;
    isDisabledForm: boolean;
}

export default function AnalysisAndPlan({ attentionOriginOptions, setAttentionOriginOptions, height, isDisabledForm }: AnalysisAndPlanProps) {
    const dispatch = useAppDispatch();

    const [noInsTextModal, setNoInsTextModal] = useState<{ isOpen: boolean; current: string | null; }>({
        isOpen: false,
        current: null
    });

    const saveAttentionOriginDebounced = useDebouncedCallback((options: AttentionOriginFields) => {
        dispatch(saveAttentionOrigin(options));
    }, 2000);

    const handleOpenNoInsTextModal = (name: string): void => setNoInsTextModal({
        current: name, isOpen: true
    });
    const handleCloseNoInsTextModal = (): void => setNoInsTextModal({
        current: null, isOpen: false
    });

    const handleChangeText = (text: string) => {
        const options = {
            ...attentionOriginOptions,
            analysisPlan: text
        };
        setAttentionOriginOptions(options);
        saveAttentionOriginDebounced(options);
    };

    const handleAttatchText = (text: string) => {
        const options = {
            ...attentionOriginOptions,
            analysisPlan: attentionOriginOptions.analysisPlan.length
                ? `${attentionOriginOptions.analysisPlan}\n${text}`
                : text
        };
        setAttentionOriginOptions(options);
        saveAttentionOriginDebounced(options);
        handleCloseNoInsTextModal();
    };

    const render = () => {
        return (
            <SectionCard className="h-100">
                <div className="row g-3">
                    <div className="col-12">
                        <h5 className="text-secondary fw-bold fs-5 mb-3">Análisis y plan</h5>
                        <hr className="text-primary mb-3 mt-2" />
                        <textarea
                            className="form-control no-resize w-100"
                            style={{ height }}
                            disabled={isDisabledForm}
                            onContextMenu={(event) => {
                                event.preventDefault(); 
                                !isDisabledForm && handleOpenNoInsTextModal("analysisAndPlan");
                            }}
                            onChange={({ target }) => handleChangeText(target.value)}
                            value={attentionOriginOptions.analysisPlan}
                        />
                    </div>
                </div>
                <TextConfigModal
                    isOpen={noInsTextModal.isOpen}
                    current={noInsTextModal.current as string}
                    onClose={handleCloseNoInsTextModal}
                    width={800}
                    onSelectItem={(_, item) => handleAttatchText(item)}
                />
            </SectionCard>
        );
    };

    return render();
}