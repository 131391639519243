import { useEffect, useState } from "react";
import { AttentionOriginFields } from "@/models/attentionOrigin";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { useLocation, useNavigate } from "react-router-dom";
//
import ConsultationControls from "../Controls/Controls";
import AttentionOrigin from "../AttentionOrigin/AttentionOrigin";
import DiagnosticImpression from "../DiagnosticImpression/DiagnosticImpression";
import ConsultationHeader from "../Header/Header";
import { SectionCard } from "@/components";
import "../Consultation.scss";
// import VisualAcuity from "../shared/VisualAcuity/VisualAcuity";
import AnalysisAndPlan from "../AttentionOrigin/AnalysisAndPlan";
import History from "../History/History";
import { useDebouncedCallback } from "use-debounce";
import { getLowVision, updateLowVision } from "./lowVision.actions";
import { LowVisionPayload } from "@/models/sheets/lowVision";
import Keratometry from "../shared/Keratometry/Keratometry";
import ObjectiveRefraction from "../shared/ObjectiveRefraction/ObjectiveRefraction";
import SubjectiveRefraction from "../shared/SubjectiveRefraction/SubjectiveRefraction";
import Distances from "../shared/Distances/Distances";
import { AVGlassesOnUse, AVPH, AVWithCorrection, AVWithoutCorrection, Optotipe } from "../shared/VisualAcuity/VisualAcuity";

export default function LowVision() {
    const location = useLocation();
    const navigate = useNavigate();

    const currentSheet = useAppSelector(state => state.patientAttention.patientStatus.sheet);
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const mcfId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const isDisabledForm = false;
    const dispatch = useAppDispatch();

    const [attentionOriginOptions, setAttentionOriginOptions] = useState<AttentionOriginFields>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number,
        generalFields: {
            attentionOriginId: undefined,
            abserveEvent: undefined,
            evolutionTime: undefined,
            evolutionTimeUnitId: undefined
        },
        reasonForConsulting: { predefinedTxts: [], justification: "" },
        currentDisease: { predefinedTxts: [], justification: "" },
        analysisPlan: ""
    });

    const [visualPayload, setVisualPayload] = useState<LowVisionPayload>({
        mcfId: mcfId,
        eaccount: accountId,
        userId: userId as number,
        appId: appointmentId,
    });

    useEffect(() => {
        const splittedPath = location.pathname.split("/");
        const sheet = splittedPath[splittedPath.length - 1];

        if (sheet !== currentSheet) {
            navigate(-1);
        }
    }, [currentSheet, navigate, location.pathname]);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getLowVision({
                appId: appointmentId,
                eaccount: accountId,
                mcfId: mcfId,
                userId: userId as number
            }));
            if (data) {
                setVisualPayload({
                    ...data,
                    mcfId: mcfId,
                    eaccount: accountId,
                    userId: userId as number,
                    appId: appointmentId,
                });
            }
        }
        fetchData();
    }, [dispatch, appointmentId, accountId, mcfId, userId]);

    const onSubmitLowVision = useDebouncedCallback(() => {
        if (visualPayload) {
            setVisualPayload(visualPayload);
            dispatch(updateLowVision(visualPayload));
        }
    }, 2000);
    const render = () => {
        return (
            <div className="d-flex flex-column h-100 overflow-hidden">
                <ConsultationHeader isDisabledForm={isDisabledForm} currentSection="Baja visión" showHistory={false} />
                <div className="d-flex overflow-hidden h-100">
                    <section className="consultation-section">
                        <div className="row g-3">
                            <div className="col-12">
                                <AttentionOrigin
                                    isDisabledForm={isDisabledForm}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                />
                            </div>
                            <div className="col-2">
                                <Optotipe
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />

                            </div>
                            <div className="col-8">
                                <AVWithoutCorrection
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />

                            </div>
                            <div className="col-2">
                                <AVPH
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>
                            <div className="col-12">
                                <AVGlassesOnUse
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>
                            <div className="col-6">
                                <ObjectiveRefraction
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>
                            <div className="col-6">
                                <Keratometry
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>
                            <div className="col-6">
                                <SubjectiveRefraction
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>
                            <div className="col-6">
                                <AVWithCorrection
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>

                            <div className="col-4">
                                <Distances
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>

                            <div className="col-8">
                                <SectionCard className="h-100">
                                    <h5 className="text-secondary fw-bold">Ayudas VSN - Tiflo en uso</h5>
                                    <hr className="text-primary mt-2" />
                                    <textarea className="form-control h-100 no-resize"
                                        disabled={isDisabledForm}
                                        value={visualPayload.supportsVsnTifloInUse}
                                        onChange={({ target }) => {
                                            setVisualPayload({ ...visualPayload, supportsVsnTifloInUse: target.value });
                                            onSubmitLowVision();
                                        }}
                                    />
                                </SectionCard>
                            </div>

                            <div className="col-6">
                                <DiagnosticImpression isDisabledForm={isDisabledForm} />
                            </div>
                            <div className="col-6">
                                <AnalysisAndPlan
                                    isDisabledForm={isDisabledForm}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                    height={260}
                                />
                            </div>
                        </div>
                    </section>
                    <History showClose={false} />
                </div>
                <ConsultationControls isDisabledForm={isDisabledForm} />
            </div>
        );
    };

    return render();
}