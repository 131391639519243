import { Modal } from "@/components";
import { IModal } from "@/components/Modal/modal";
import ScrollableTable from "@/components/ScrollableTable/ScrollableTable";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { BodyCenterList, ConsumptionCentersListByInsurance } from "@/models/surgery/patientAttention/patientAttention";
import { IconPhoto } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { getConsumptionCentersSheet } from "../patientAttentionSurgery.action";
import EvidenceModal from "./EvidenceModal";

type PreoperativeTabs =
    ""
    | "income"
    | "preparation"
    | "surgery"
    | "recovery";


export default function GeneralConsumptionSheet({ onSuccess, appId, stage, ...rest }: IModal & { stage?: string; }) {

    const dispatch = useAppDispatch();
    const [currentTab, setCurrentTab] = useState<PreoperativeTabs>("");
    const [consumptionListSheet, setConsumptionListSheet] = useState<ConsumptionCentersListByInsurance[]>([]);

    const eaccount = useAppSelector(state => state.workspace).id;

    const tabItems: { tag: PreoperativeTabs; label: string; }[] = [
        { tag: "income", label: "Ingreso" },
        { tag: "preparation", label: "Preparación" },
        { tag: "surgery", label: "Quirófano" },
        { tag: "recovery", label: "Recuperación" },
    ];

    useEffect(() => {
        if (stage === "income") {
            setCurrentTab("income");
        } else if (stage === "in_preparation" || stage === "preparation") {
            setCurrentTab("preparation");

        } else if (stage === "in_surgery" || stage === "surgery") {
            setCurrentTab("surgery");

        } else if (stage === "in_recovery" || stage === "recovery") {
            setCurrentTab("recovery");
        }
    }, [stage, rest.isOpen]);


    useEffect(() => {
        async function fetchData() {
            if (appId && currentTab !== "") {
                const dataSheet = await dispatch(getConsumptionCentersSheet({ appId, stagePrefix: currentTab, eaccount }));
                if (dataSheet) {
                    setConsumptionListSheet(dataSheet);
                }
            }
        }
        fetchData();
    }, [appId, eaccount, dispatch, rest?.isOpen, currentTab]);

    const onChangeTab = (tab: PreoperativeTabs) => {
        setCurrentTab(tab);
    };
    const renderTable = () => {
        return (

            <ScrollableTable maxHeight={600} >
                <ScrollableTable.Head>
                    <ScrollableTable.Cell className="fs-6" col={1}>
                        Código
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6 nowrap" col={3}>
                        Articulo
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6 nowrap text-ellipsis overflow-hidden" col={1}>
                        Lote
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell align="center" className="fs-6 text-ellipsis overflow-hidden" col={2}>
                        Fecha de vencimiento
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell align="center" className="fs-6" col={2}>
                        Cant. teórica
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell align="center" className="fs-6" col={2}>
                        Cant. corregida
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6" >
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6" >
                    </ScrollableTable.Cell>
                </ScrollableTable.Head>

                <ScrollableTable.Body>
                    {consumptionListSheet?.length > 0 ? consumptionListSheet?.map((article, ind) => {
                        return (
                            <div key={ind}>
                                <ScrollableTable.Row >
                                    <ScrollableTable.Cell col={12} align="center" className="subTitleTable fw-bold">
                                        {article.supName}
                                    </ScrollableTable.Cell>
                                </ScrollableTable.Row>
                                {article?.articles.length > 0 && article?.articles.map((supply, inde) => {
                                    return (
                                        <RenderRow key={inde} row={supply} showImage={article.supPrefix === "specials"} />
                                    );
                                })
                                }
                            </div>
                        );
                    }) :
                        <ScrollableTable.Row >
                            <ScrollableTable.Cell col={12} align="center" className=" justify-content-center fw-bold py-4">
                                <span>
                                    No hay insumos disponibles
                                </span>
                            </ScrollableTable.Cell>
                        </ScrollableTable.Row>
                    }
                </ScrollableTable.Body>
            </ScrollableTable>

        );
    };



    return (
        <Modal {...rest} width="70%" positionModal="center" >
            <h1 className="text-secondary fw-bold text-start px-4">Hoja de consumo general</h1>
            <div className="p-4">
                <ul className="nav nav-tabs">
                    {tabItems.map(tab => (
                        <li key={tab.tag}
                            style={{ width: "180px" }}
                            className="nav-item pointer" onClick={() => onChangeTab(tab.tag)}>
                            <div className={`nav-link text-center ${tab.tag === currentTab ? "active" : ""}`}>{tab.label}</div>
                        </li>
                    ))}
                </ul>
                <div className="d-flex flex-column flex-grow-1 pt-4">
                    <div className="table-responsive">
                        {renderTable()}
                    </div>
                </div>
            </div>
        </Modal>
    );
}


const RenderRow = ({ row, showImage, }:
    { row: BodyCenterList, showImage?: boolean; }) => {

    const [imagesModal, setImagesModal] = useState<{ isOpen: boolean, elem?: BodyCenterList; }>({
        isOpen: false
    });
    const evidenceMandatory = (row.evidence === 1 && (row?.files?.length === 0 || row.files === null)) ? "#ffa73320" : "";
    return (
        <>
            <EvidenceModal
                details
                data={row}
                isOpen={imagesModal.isOpen}
                onClose={() => {
                    setImagesModal((state) => ({ ...state, isOpen: false }));
                }} />
            <ScrollableTable.Row key={row.articleId + row.cssId}
                style={{ backgroundColor: evidenceMandatory }}
            >
                <ScrollableTable.Cell col={1}
                > <span className="text-muted" >{row.articleBarCode ?? "-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={3}><span className="text-muted">{row.articleName ?? "-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={1}><span className="text-muted">{row.lotName ?? "-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={2} className="justify-content-center"><span className="text-muted">{row.expirationDate ?? "-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={2} className="justify-content-center"><span className="text-muted ">{"-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={2} className="d-flex justify-content-center">
                    <span className="text-muted text-center">{row.quantity}</span>
                </ScrollableTable.Cell>
                <ScrollableTable.Cell ><span className=" text-primary">{row?.files?.length > 0 &&


                    <div id="evidence" onClick={() => {
                        setImagesModal((state) => ({ ...state, isOpen: true }));
                    }} className="pointer">
                        <span className=" text-primary"><IconPhoto /></span>
                    </div>




                }</span></ScrollableTable.Cell>
            </ScrollableTable.Row >
        </>
    );
};
