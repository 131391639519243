import { ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";
//
import { AttentionOrigin, Cie10EXTDiagnostic, OphthalMologyDetails, PhysicalEyes, ProvocativeEvidence, TraditionalTonometry } from "@/models/historyDetails/ophthalmology";
import { formatHistoryDetails } from "@/utils";
//
import "../Details.scss";

export default function OphthalmologyDetails({ ophthalmologyDetails, forDownload }: { ophthalmologyDetails: OphthalMologyDetails; forDownload?: boolean; }) {

    const options: ChartOptions<"line"> = {
        scales: {
            y: {
                title: {
                    display: true,
                    text: "PIO",
                    color: "#003F80",
                }
            },
            x: {
                title: {
                    display: true,
                    text: "Hora",
                    color: "#003F80",

                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: true,
                align: "center",
                fullSize: false,
                labels: {
                    usePointStyle: true,
                    boxHeight: 8,
                },
                title: {
                    display: true,
                    position: "start",
                },
            },
        },
    };


    const plugin = {
        id: "a",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        beforeInit(chart: any) {
            const originalFit = chart.legend.fit;

            // override the fit function
            chart.legend.fit = function fit() {
                originalFit.bind(chart.legend)();
            };
        }
    };

    const renderAttentionOrigin = (attentionOrigin: AttentionOrigin | null) => {

        if (attentionOrigin) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Origen de atención
                    </h4>
                    {attentionOrigin.attentionOrigin !== null ? (
                        <h5>
                            <span className="fw-bold">Origen de la atención: </span>{" "}
                            {attentionOrigin.attentionOrigin}
                        </h5>
                    ) : ""}
                    {attentionOrigin.abserveEvent !== null ? (
                        <h5>
                            <span className="fw-bold">Evento adverso: </span>{" "}
                            {attentionOrigin.abserveEvent === 1 ? "SÍ" : "NO"}
                        </h5>
                    ) : ""}
                    {attentionOrigin?.attendingReason && Object.values(attentionOrigin.attendingReason).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Motivo consulta: {Object.values(attentionOrigin.attendingReason).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{attentionOrigin.attendingReasonJst || ""}</h5>
                        </>
                    ) : ""}
                    {attentionOrigin?.currentIllness && Object.values(attentionOrigin.currentIllness) ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Enfermedad actual: {Object.values(attentionOrigin.currentIllness).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{attentionOrigin.currentIllnessJst || ""}</h5>
                        </>
                    ) : ""}
                    {attentionOrigin.evolutionTime ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>{" "}
                            {attentionOrigin.evolutionTime}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderPhysicalExams = () => {
        const external = formatHistoryDetails(ophthalmologyDetails.external) as PhysicalEyes;
        const ppl = formatHistoryDetails(ophthalmologyDetails.ppl) as PhysicalEyes;
        const screraConjunctiva = formatHistoryDetails(ophthalmologyDetails.screraConjunctiva) as PhysicalEyes;
        const cornea = formatHistoryDetails(ophthalmologyDetails.cornea) as PhysicalEyes;
        const previousChamber = formatHistoryDetails(ophthalmologyDetails.previousChamber) as PhysicalEyes;
        const iris = formatHistoryDetails(ophthalmologyDetails.iris) as PhysicalEyes;
        const crystalline = formatHistoryDetails(ophthalmologyDetails.crystalline) as PhysicalEyes;
        const retinaVitreous = formatHistoryDetails(ophthalmologyDetails.retinaVitreous) as PhysicalEyes;
        const opticNerve = formatHistoryDetails(ophthalmologyDetails.opticNerve) as PhysicalEyes;

        if (external || ppl || screraConjunctiva || cornea || previousChamber || iris || crystalline || retinaVitreous || opticNerve) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Examen físico
                    </h4>
                    {external?.od?.findings && Object.values(external.od.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Externo OD: {Object.values(external.od.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{external.od.justification}</h5>
                        </>
                    ) : ""}
                    {external?.oi?.findings && Object.values(external.oi?.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Externo OI: {Object.values(external.oi.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{external.oi.justification}</h5>
                        </>
                    ) : ""}

                    {ppl?.od?.findings && Object.values(ppl.od.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                <span className="">P/P/L OD:</span> {Object.values(ppl.od?.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{ppl.od.justification}</h5>
                        </>
                    ) : ""}
                    {ppl?.oi?.findings && Object.values(ppl.oi.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                P/P/L OI: {Object.values(ppl.oi.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{ppl.oi.justification}</h5>
                        </>
                    ) : ""}

                    {screraConjunctiva?.od?.findings && Object.values(screraConjunctiva.od?.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Conjuntiva/Esclera OD: {Object.values(screraConjunctiva.od.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{screraConjunctiva.od.justification}</h5>
                        </>
                    ) : ""}
                    {screraConjunctiva?.oi?.findings && screraConjunctiva.oi ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Conjuntiva/Esclera OI: {Object.values(screraConjunctiva.oi.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{screraConjunctiva.oi.justification}</h5>
                        </>
                    ) : ""}

                    {cornea?.od?.findings && Object.values(cornea.od.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Córnea OD: {Object.values(cornea.od.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{cornea.od.justification}</h5>
                        </>
                    ) : ""}
                    {cornea?.oi?.findings && Object.values(cornea.oi?.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Córnea OI: {Object.values(cornea.oi.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{cornea.oi.justification}</h5>
                        </>
                    ) : ""}

                    {previousChamber?.od?.findings && Object.values(previousChamber.od?.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Cámara anterior OD: {Object.values(previousChamber.od?.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{previousChamber.od.justification}</h5>
                        </>
                    ) : ""}
                    {previousChamber?.oi?.findings && Object.values(previousChamber.oi.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Cámara anterior OI: {Object.values(previousChamber.oi.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{previousChamber.oi.justification}</h5>
                        </>
                    ) : ""}

                    {iris?.od?.findings && Object.values(iris.od.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Iris OD: {Object.values(iris.od.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{iris.od.justification}</h5>
                        </>
                    ) : ""}
                    {iris?.oi?.findings && Object.values(iris.oi.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Iris OI: {Object.values(iris.oi.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{iris.oi.justification}</h5>
                        </>
                    ) : ""}

                    {crystalline?.od?.findings && Object.values(crystalline.od.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Cristalino OD: {Object.values(crystalline.od.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{crystalline.od.justification}</h5>
                        </>
                    ) : ""}
                    {crystalline?.oi?.findings && Object.values(crystalline.oi.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Cristalino OI: {Object.values(crystalline.oi.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{crystalline.oi.justification}</h5>
                        </>
                    ) : ""}

                    {retinaVitreous?.od?.findings && Object.values(retinaVitreous.od?.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Retina/Vítreo OD: {Object.values(retinaVitreous.od?.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{retinaVitreous.od.justification}</h5>
                        </>
                    ) : ""}
                    {retinaVitreous?.oi?.findings && Object.values(retinaVitreous.oi.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Retina/Vítreo OI: {Object.values(retinaVitreous.oi.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{retinaVitreous.oi.justification}</h5>
                        </>
                    ) : ""}

                    {opticNerve?.od?.findings && Object.values(opticNerve.od.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Nervio óptico OD: {Object.values(opticNerve.od.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{opticNerve.od.justification}</h5>
                        </>
                    ) : ""}
                    {opticNerve?.oi?.findings && Object.values(opticNerve.oi?.findings).length ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Nervio óptico OI: {Object.values(opticNerve.oi?.findings).join(" - ")}
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{opticNerve.oi.justification}</h5>
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderIntraocularPresion = (traditionalTonometry: TraditionalTonometry | null) => {

        if (traditionalTonometry) {
            return (
                <div className="item">
                    <h4 className="fw-bold text-primary">Presión intraocular</h4>
                    <h4 className="mb-3">Tonometría tradicional</h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-secondary">
                                <th>Detalle</th>
                                <th>OD</th>
                                <th>OI</th>
                            </tr>
                        </thead>
                        <tbody className="text-muted">
                            {traditionalTonometry.pachymetry ? (
                                <tr>
                                    <td className="fw-bold">Paquimetría</td>
                                    <td>{traditionalTonometry.pachymetry.od}</td>
                                    <td>{traditionalTonometry.pachymetry.oi}</td>
                                </tr>
                            ) : ""}

                            {traditionalTonometry.correction ? (
                                <tr>
                                    <td className="fw-bold">Corrección</td>
                                    <td>{traditionalTonometry.correction.od}</td>
                                    <td>{traditionalTonometry.correction.oi}</td>
                                </tr>
                            ) : ""}
                            {traditionalTonometry.pio ? (
                                <tr>
                                    <td className="fw-bold">PIO</td>
                                    <td>{traditionalTonometry.pio.od}</td>
                                    <td>{traditionalTonometry.pio.oi}</td>
                                </tr>
                            ) : ""}
                            {traditionalTonometry.pioPachymetryAdjust ? (
                                <tr>
                                    <td className="fw-bold">PIO Ajustado a paquimetría</td>
                                    <td>{traditionalTonometry.pioPachymetryAdjust.od}</td>
                                    <td>{traditionalTonometry.pioPachymetryAdjust.oi}</td>
                                </tr>
                            ) : ""}
                            <tr>
                                <td colSpan={3}>
                                    <span className="fw-bold">Método: </span>{traditionalTonometry.method}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        } else return "";
    };

    const renderProvocativeEvidence = (provocativeEvidence: ProvocativeEvidence[] | null) => {

        if (provocativeEvidence && provocativeEvidence.length) {
            const chartData = {
                labels: provocativeEvidence.map(p => p.time),
                datasets: [
                    {
                        label: "OD",
                        data: provocativeEvidence.map(p => p.od),
                        borderColor: "#A3E4EC",
                        backgroundColor: "#A3E4EC",
                    },
                    {
                        label: "OI",
                        data: provocativeEvidence.map(p => p.oi),
                        borderColor: "#5C84AD",
                        backgroundColor: "#5C84AD",
                    }
                ],
            };

            return (
                <div className="item">
                    <h4 className="mb-3">Tonometría con pruebas provocativas</h4>
                    <div className="row">
                        <div className="col">
                            <table className="table table-bordered h-75">
                                <thead>
                                    <tr className="text-secondary">
                                        <th>Hora</th>
                                        <th>OD</th>
                                        <th>OI</th>
                                    </tr>
                                </thead>
                                <tbody className="text-muted">
                                    {provocativeEvidence.map(pe => (
                                        <tr key={pe.order}>
                                            <td>{pe.time}</td>
                                            <td>{pe.od}</td>
                                            <td>{pe.oi}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="col" style={{ marginTop: -20 }}>
                            <Line options={options} data={chartData} plugins={[plugin]} />
                        </div>
                    </div>
                </div>
            );
        } else {
            return "";
        }
    };

    const renderExtDiagnostic = (extDiagnostic: Cie10EXTDiagnostic | null) => {

        if (extDiagnostic && Object.values(extDiagnostic).length) {
            const mainExt = Object.values(extDiagnostic).find(item => item.isMain === 1);

            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Impresión diagnóstica
                    </h4>
                    <table className="table table-bordered w-100">
                        <thead>
                            <tr className="text-secondary">
                                <th>Fecha</th>
                                <th className="text-center">CIE-10</th>
                                <th>Impresión diagnóstica</th>
                                <th className="text-center">Ojo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(extDiagnostic).map((item) => (
                                <tr key={`${item.id}-${item.clhId}`} style={{ backgroundColor: item.isMain ? "#fcfae6" : undefined }}>
                                    <td className="align-middle">{item.date}</td>
                                    <td align="center" className="align-middle">{item.cie10Code}</td>
                                    <td style={{ whiteSpace: "pre-line" }}>{item.cie10Description}</td>
                                    <td className="align-middle" align="center">{item.dbo?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {mainExt ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>
                            {mainExt.evolution} {mainExt.evolutionTime?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderAnalysisAndPlan = (analysisPlan: string | null) => {
        if (analysisPlan) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Análisis y plan
                    </h4>
                    <h5>{analysisPlan}</h5>
                </div>
            );
        } else {
            return "";
        }
    };

    const render = () => {
        const attentionOrigin = formatHistoryDetails(ophthalmologyDetails.attentionOrigin);
        const traditionalTonometry = formatHistoryDetails(ophthalmologyDetails.traditionalTonometry);
        const provocativeEvidence = formatHistoryDetails(ophthalmologyDetails.provocativeEvidence);
        const cie10ExtDiagnostic = formatHistoryDetails(ophthalmologyDetails.cie10ExtDiagnostic);
        const analysisPlan = formatHistoryDetails(ophthalmologyDetails.analysisPlan);

        return (
            <div className="detail-container">
                <div className={`${!forDownload ? "columns" : "column"}`}>
                    {renderAttentionOrigin(attentionOrigin)}
                    {renderPhysicalExams()}
                    {renderIntraocularPresion(traditionalTonometry)}
                    {renderProvocativeEvidence(Object.values(provocativeEvidence || {}))}
                    {renderExtDiagnostic(cie10ExtDiagnostic)}
                    {renderAnalysisAndPlan(analysisPlan)}
                </div>

            </div >
        );
    };

    return render();
}