import {
    IconChevronLeft,
    IconClipboardHeart,
    IconExclamationMark,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
//
import {
    Avatar, Badge,
} from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { Comment } from "@/models/comment";
import { HeaderSurgery, Patient, Surgery } from "@/models/surgery/patientAttention/headerSurgeryAttention";
import { IconClipboardData, IconX } from "@tabler/icons-react";
import Comments from "../../Consultation/Comments/Comments";
import { createComment, deleteComment, getComments } from "../../Consultation/Comments/comments.actions";
import Antecedents from "../../Consultation/Header/Antecedents/AntedecentsModal";
import "../../Consultation/Header/Header.scss";
import GeneralConsumptionSheet from "../consumptionSheet/GeneralConsumptionSheet";
import { getHeaderSurgery } from "../patientAttentionSurgery.action";

export default function SurgeryHeader(
    { currentSection, isDisabledForm, patientId, appId, setServices }:
        {
            currentSection: string;
            showHistory: boolean;
            setShowHistory?: (state: boolean) => void;
            isDisabledForm?: boolean;
            patientId?: number;
            appId?: number | null;
            setServices?: (state: { services: Surgery[], patientInfo: Patient, status: string; }) => void;
        }
) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    dayjs.extend(relativeTime);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const appointment = useAppSelector(state => state.patientAttention.appointmentSelected);
    const eaccount = useAppSelector(state => state.workspace.id);

    const [patientHeader, setPatientHeader] = useState<HeaderSurgery>();
    const [comments, setComments] = useState<Comment[]>([]);
    const [isCommentsDrawerOpen, setIsCommentsDrawerOpen] = useState<boolean>(false);
    const [consumptionSheetGeneralModal, setConsumptionSheetGeneralModal] = useState({
        isOpen: false
    });
    const [patientInfoModal, setPatientInfoModal] = useState({
        isOpen: false,
    });
    const [antecedentsConfig, setAntecedentsConfig] = useState({
        isOpen: false
    });

    useEffect(() => {
        async function fetchData() {
            if (patientId ?? appointment?.patientId) {
                const data = await dispatch(getComments({
                    entity: "userclient",
                    id: patientId ?? appointment?.patientId
                }));
                setComments(data as Comment[]);
            }
        }
        fetchData();
    }, [dispatch, patientId, appointment?.patientId]);

    useEffect(() => {
        async function fetchData() {
            if ((appId)) {
                const data = await dispatch(getHeaderSurgery(appId));
                if (data) {
                    setPatientHeader(data);
                    (data.surgeries && setServices) && setServices({ patientInfo: data.patient, services: data.surgeries, status: data.status.value });
                }
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [appId, appointment?.app_id, eaccount, dispatch]);

    const handleCloseCommentsDrawer = (): void => setIsCommentsDrawerOpen(false);
    const handleOpenCommentsDrawer = (): void => setIsCommentsDrawerOpen(true);

    const handleCloseAntecedentsModal = (): void => setAntecedentsConfig({
        isOpen: false
    });
    const handleOpenAntecedentsModal = (): void => setAntecedentsConfig({
        isOpen: true
    });

    const handleReloadHeader = async () => {
        if ((appId ?? appointment?.app_id) && eaccount) {
            if ((appId)) {
                const data = await dispatch(getHeaderSurgery(appId));
                if (data) {
                    setPatientHeader(data);
                }
            }
        }
    };

    const handleSubmitComment = async (text: string): Promise<void> => {
        await dispatch(createComment({
            comment: text,
            entity: "userclient",
            userId: userId as number,
            id: patientId ?? appointment?.patientId as number,
        }));
        const data = await dispatch(getComments({
            entity: "userclient",
            id: patientId ?? appointment?.patientId
        }));
        setComments(data as Comment[]);
    };

    const handleDeleteComment = async (id: string): Promise<void> => {
        await dispatch(deleteComment(id));
        const data = await dispatch(getComments({
            entity: "userclient",
            id: patientId ?? appointment?.patientId
        }));
        setComments(data as Comment[]);
    };

    const handleOpenPatientInfoModal = () => {
        setPatientInfoModal({ ...patientInfoModal, isOpen: true });
    };

    const IMG_EXT_REGEX = /\.(jpeg|jpg|png|gif)$/i;


    const render = () => {
        return (
            <div className="consultation-header align-items-end">
                <div className="align-self-center text-secondary pointer" onClick={() => navigate(-1)}>
                    <IconChevronLeft />
                </div>
                <Avatar
                    src={
                        patientHeader?.patient?.photo
                            ? IMG_EXT_REGEX.test(patientHeader?.patient?.photo)
                                ? patientHeader?.patient?.photo
                                : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                            : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                    }
                    onClick={handleOpenPatientInfoModal} className="pointer align-self-center"
                />
                <div className="d-flex align-items-center ms-3">
                    <div className="ms-3">
                        <small className="text-muted">Paciente</small>
                        <div className="fw-bold text-secondary">{patientHeader?.patient?.fullName}</div>
                    </div>
                </div>
                <div className="ms-4">
                    <small className="text-muted">Identificación</small>
                    <div className="fw-bold text-secondary">{patientHeader?.patient?.documentType} {patientHeader?.patient?.document}</div>
                </div>
                <div className="ms-4">
                    <small className="text-muted">Contrato</small>
                    <div className="fw-bold text-secondary">{patientHeader?.contractName}</div>
                </div>
                <div className="ms-4">
                    <small className="text-muted">Edad</small>
                    <div className="fw-bold text-secondary">{patientHeader?.patient?.age} años</div>
                </div>
                <Badge className="mx-2 align-self-center" style={{
                    backgroundColor: patientHeader?.status.bgColor || "#E8E8EA",
                    color: patientHeader?.status.fontColor || "#6E6F7C"
                }}>
                    {patientHeader?.status.name}
                </Badge>
                <div
                    className="pointer mx-2 text-primary align-self-center"
                    onClick={() => {
                        setConsumptionSheetGeneralModal((state) => ({ ...state, isOpen: true }));
                    }}
                    data-tooltip-id="allergies-tooltip"
                    data-tooltip-content={"Hoja de consumo general"}
                >
                    <IconClipboardData />
                </div>
                <div className="flex-fill"></div>
                <div
                    className="pointer mx-2 text-primary align-self-center"
                    onClick={handleOpenAntecedentsModal}
                    data-tooltip-id="allergies-tooltip"
                    data-tooltip-content={"Antecedentes"}
                >
                    <IconClipboardHeart />
                </div>
                <div
                    className="d-flex align-items-center align-self-center">
                    <div className="message-icon ms-3" onClick={handleOpenCommentsDrawer}>
                        {comments?.length}
                    </div>
                </div>
                {patientHeader?.alertsOcularNotes && (
                    <div
                        className="dynamic-icon dynamic-icon__warning ms-3"
                        data-tooltip-id="allergies-tooltip"
                        data-tooltip-content={patientHeader.alertsOcularNotes}
                    >
                        <IconX />
                    </div>
                )}
                {patientHeader?.allergiesGeneralNotes && (
                    <div
                        className="dynamic-icon dynamic-icon__danger ms-3"
                        data-tooltip-id="allergies-tooltip"
                        data-tooltip-content={patientHeader.allergiesGeneralNotes}
                    >
                        <IconExclamationMark strokeWidth={3} />
                    </div>
                )}
                <GeneralConsumptionSheet
                    isOpen={consumptionSheetGeneralModal.isOpen}
                    appId={appId}
                    stage={patientHeader?.status?.value}
                    onClose={() =>
                        setConsumptionSheetGeneralModal((state) => ({ ...state, isOpen: false }))
                    }
                />

                <Antecedents
                    isOpen={antecedentsConfig.isOpen}
                    onClose={handleCloseAntecedentsModal}
                    isDisabledForm={isDisabledForm}
                    onSuccess={handleReloadHeader}
                    appId={appId ?? appointment?.app_id}
                    data={{ tag: "adx" }}
                    cluId={patientHeader?.cluId}
                />
                <Comments
                    data={comments}
                    isOpen={isCommentsDrawerOpen}
                    onClose={handleCloseCommentsDrawer}
                    width={400}
                    drawer="right"
                    onCreateComment={(value) => handleSubmitComment(value)}
                    onDeleteComment={(id) => handleDeleteComment(id)}
                />
                <Tooltip id="allergies-tooltip" />
            </div >
        );
    };

    return render();
}