import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { HeaderSurgeryResponse } from "@/models/surgery/patientAttention/headerSurgeryAttention";
import { AddLotArticlePayload, AttentionAppParams, AttentionAppointmentSurgeryResponse, ConsumptionCenterParamsSheet, ConsumptionCentersParams, ConsumptionCentersResponse, ConsumptionCentersTableResponse, EvidencePayload, NurseryNotes, NurseryNotesParams, NurseryNotesResponse, QuantityPayload, StartAttentionResponse, deleteSupplyParams, } from "@/models/surgery/patientAttention/patientAttention";
import { setHeaders } from "@/utils";

export async function getAttentionAppointmentService(params: AttentionAppParams): Promise<AttentionAppointmentSurgeryResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AttentionAppointmentSurgeryResponse>(
        "/medical/clinicHistory/surgeryAppointments/", axiosParams
    );
    return serviceData;
}

export async function getHeaderSurgeryService(appId: number): Promise<HeaderSurgeryResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params: { appId } };

    const { data: serviceData } = await axiosInstance.get<HeaderSurgeryResponse>(
        "/medical/clinicHistory/appointmentHeader/", axiosParams
    );
    return serviceData;
}

export async function startSurgeryAttentionService(appId: number): Promise<StartAttentionResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };
    const payload = { appId };

    const { data: serviceData } = await axiosInstance.put<StartAttentionResponse>(
        "/medical/clinicHistory/startSurgeryAttention/", payload, axiosParams
    );
    return serviceData;
}

export async function translateSurgeryAttentionService(appId: number): Promise<StartAttentionResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };
    const payload = { appId };

    const { data: serviceData } = await axiosInstance.put<StartAttentionResponse>(
        "/medical/clinicHistory/transferSurgery/", payload, axiosParams
    );
    return serviceData;
}

export async function getNurseryNotesService(params: NurseryNotesParams): Promise<NurseryNotesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<NurseryNotesResponse>(
        "/medical/clinicHistory/nurseryNotes/", axiosParams
    );
    return serviceData;
}

export async function updateNurseryNotesService(payload: NurseryNotes): Promise<NurseryNotesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<NurseryNotesResponse>(
        "/medical/clinicHistory/nurseryNotes/", payload, axiosParams
    );
    return serviceData;
}


export async function getConsumptionCentersService(params: ConsumptionCentersParams): Promise<ConsumptionCentersResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<ConsumptionCentersResponse>(
        "/medical/clinicHistory/ConsumptionSheet", axiosParams
    );
    return serviceData;
}

export async function addLotArticleService(payload: AddLotArticlePayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/ConsumptionSheetSurgery", payload, axiosParams
    );
    return serviceData;
}

export async function getConsumptionSheetService(params: ConsumptionCenterParamsSheet): Promise<ConsumptionCentersTableResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<ConsumptionCentersTableResponse>(
        "/medical/clinicHistory/ConsumptionSheetSurgery", axiosParams
    );
    return serviceData;
}
export async function deleteSupplyService(payload: deleteSupplyParams): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.patch<IResponse>(
        "/medical/clinicHistory/ConsumptionSheetSurgery", payload, axiosParams
    );
    return serviceData;
}

export async function changeQuantityService(payload: QuantityPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/ConsumptionSheetSurgery", payload, axiosParams
    );
    return serviceData;
}

export async function changeEvidenceService(payload: EvidencePayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/SpecialSupplyEvidence", payload, axiosParams
    );
    return serviceData;
}


export async function deleteEvidenceService(payload: { filId: number; }): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, data: payload };

    const { data: serviceData } = await axiosInstance.delete<IResponse>("/medical/clinicHistory/SpecialSupplyEvidence", axiosParams);
    return serviceData;
}