import { axiosInstance } from "@/config/axios";
import { MeasurementsResponse } from "@/models";
import { setHeaders } from "@/utils";

export async function getMeasurementsServices(accountId: number): Promise<MeasurementsResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params: { id_account: accountId } };
    const { data: serviceData } = await axiosInstance.get<MeasurementsResponse>(
        "/inventory/measurements/", axiosParams
    );
    return serviceData;
}