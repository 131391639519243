import { useEffect, useState } from "react";
import {
    IconCircleFilled,
    IconCirclePlus,
    IconDotsVertical,
    IconEdit,
    IconSearch,
} from "@tabler/icons-react";
//
import {
    Badge,
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Table,
    TablePagination,
    TextField
} from "@/components";
import PredefinedTextConfig from "./Config";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { getPredefinedTexts, updatePredefinedText } from "./predefinedTexts.actions";
import { PredefinedText, PredefinedTextsParams, PredefinedTextsResponse } from "@/models/predefinedTexts";
import { fireCautionAlert } from "@/utils";

interface ConfigParams {
    isOpen: boolean;
    data?: PredefinedText;
}

export default function PredefinedTextsNoIns() {
    const dispatch = useAppDispatch();
    const account = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);

    const [predefinedTextsResult, setPredefinedTextsResult] = useState<PredefinedTextsResponse>();
    const [configModal, setConfigModal] = useState<ConfigParams>({
        isOpen: false,
        data: undefined
    });

    const [filters, setFilters] = useState<PredefinedTextsParams>({
        eaccount: account,
        type: "non-institutional",
        search: "",
        users: [userId as number],
        page: 1,
        perpage: 10
    });

    useEffect(() => {
        async function fetchData() {
            if (filters) {
                const data = await dispatch(getPredefinedTexts(filters, true));
                setPredefinedTextsResult(data as PredefinedTextsResponse);
            }
        }
        fetchData();
    }, [dispatch, filters]);

    const handleNextPage = (value: number): void => {
        setFilters({ ...filters, page: value });
    };
    const handlePrevPage = (value: number): void => {
        setFilters({ ...filters, page: value });
    };

    const handleMaxPage = (value: number): void => {
        setFilters({ ...filters, page: value });
    };
    const handleMinPage = (value: number): void => {
        setFilters({ ...filters, page: value });
    };


    const handleOpenConfigModal = (config: ConfigParams): void => setConfigModal({
        isOpen: config.isOpen,
        data: config.data
    });
    const handleCloseConfigModal = (): void => setConfigModal({
        isOpen: false,
        data: undefined
    });

    const handleSearch = (value: string) => {
        setFilters({ ...filters, search: value });
    };

    const handleChangeState = (item: PredefinedText) => {
        const isEnabled = item.active === 1;
        fireCautionAlert(
            `Este texto predefinido será ${isEnabled ? "deshabilitado" : "habilitado"}.`,
            undefined,
            async () => {
                await dispatch(updatePredefinedText({
                    active: item.active === 0 ? true : false,
                    eaccount: account,
                    id: item.id,
                    updatedBy: userId as number,
                }));
                const data = await dispatch(getPredefinedTexts(filters, true));
                setPredefinedTextsResult(data as PredefinedTextsResponse);
            }
        );
    };

    const renderControls = () => {
        return (
            <div className="w-100 d-flex align-items-center mb-4">
                <TextField
                    className="w-50"
                    variant="filled"
                    placeholder="Escribe aquí para buscar..."
                    onChange={({ target }) => handleSearch(target.value)}
                    endIcon={<IconSearch />}
                />
                <div className="flex-fill"></div>
                <Button
                    endIcon={<IconCirclePlus />}
                    variant="text"
                    size="large"
                    onClick={() => handleOpenConfigModal({ isOpen: true })}
                >
                    Nuevo
                </Button>
            </div>
        );
    };

    const renderRow = (item: PredefinedText) => {
        return (
            <tr key={item.id}>
                <td width={100}>{item.id}</td>
                <td width={300}>{item.description}</td>
                <td width={200}>{item.moduleName}</td>
                <td width={200}>{item.componentName}</td>
                <td width={200}>{item.segName}</td>
                <td width={200}>{item.dtlSegmentName}</td>
                <td width={50}>
                    <Badge
                        color={item.active ? "success" : "danger"}
                        className="px-4"
                    >
                        {item.active ? "Habilitado" : "Deshabilitado"}
                    </Badge>
                </td>
                <td align="right">
                    <Dropdown className="dropdown">
                        <IconDotsVertical
                            size={15}
                            className="pointer text-muted"
                            data-bs-toggle="dropdown"
                        />
                        <DropdownMenu>
                            <DropdownItem onClick={() => setConfigModal({ isOpen: true, data: item })}>
                                <IconEdit />
                                <span className="ms-2">Editar</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => handleChangeState(item)}>
                                <IconCircleFilled className={`${item.active === 1 ? "text-danger" : "text-success"}`} />
                                <span className="ms-2">{item.active === 1 ? "Deshabilitar" : "Habilitar"}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </td>
            </tr>
        );
    };

    const renderTable = () => {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Descripción</th>
                        <th>Módulo</th>
                        <th>Componente</th>
                        <th>Segmento</th>
                        <th>Detalle del segmento</th>
                        <th>Estado</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {predefinedTextsResult?.results?.map(item => renderRow(item))}
                </tbody>
            </Table>
        );
    };

    const render = () => {
        return (
            <section className="container-fluid mt-4">
                <div className="d-flex align-items-center mb-3">
                    <h1 className="fw-bold text-secondary display-5 ms-3">Textos predefinidos</h1>
                </div>
                <div className="w-100 px-4">
                    {renderControls()}
                    {renderTable()}
                    <TablePagination
                        totalPages={predefinedTextsResult?.rowTotal as number}
                        perPage={10}
                        currentPage={filters?.page as number}
                        totalCount={predefinedTextsResult?.rowTotal as number}
                        onNextPage={(value) => handleNextPage(value as number)}
                        onPrevPage={(value) => handlePrevPage(value as number)}
                        onMaxPage={(value) => handleMaxPage(value as number)}
                        onMinPage={(value) => handleMinPage(value as number)}
                    />
                </div>
                <PredefinedTextConfig
                    isOpen={configModal.isOpen}
                    onClose={handleCloseConfigModal}
                    onSuccess={(newData) => {
                        setPredefinedTextsResult(newData as PredefinedTextsResponse);
                        setFilters({
                            ...filters,
                            page: 1,
                        });
                    }}
                    data={configModal.data as PredefinedText}
                    width={500}
                />
            </section>
        );
    };

    return render();
}