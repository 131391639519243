import React, { useEffect, useState } from "react";
//
import { useAppDispatch, useAppSelector } from "@/hooks";
import { IVitalSigns } from "@/models";
import {
    createVitalSigns,
    getVitalSigns,
    updateVitalSigns
} from "../preConsultation.actions";
//
import { SectionCard, TextField } from "@/components";
import { useDebouncedCallback } from "use-debounce";
interface VitalSingProps {
    isDisabledForm: boolean;
}

export function VitalSigns({ isDisabledForm }: VitalSingProps) {
    const dispatch = useAppDispatch();
    const userId = useAppSelector(state => state.auth.user_data.id);
    const accountId = useAppSelector(state => state.workspace.id);
    const medicalFormId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);

    const [vitalSigns, setVitalSigns] = useState<IVitalSigns>({
        bloodPressureDiastolic: "",
        bloodPressureDiastolicUnit: 72,
        bloodPressureSystolic: "",
        bloodPressureSystolicUnit: 72,
        bodyWeight: "",
        bodyWeightUnit: 38,
        breathingFrequency: "",
        breathingFrequencyUnit: 77,
        heartRate: "",
        heartRateUnit: 77,
        height: "",
        heightUnit: 35,
        mcfId: medicalFormId,
        notes: "",
        temperature: "",
        temperatureUnit: 81
    });

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getVitalSigns({
                appId: appointmentId,
                eaccount: accountId,
                userId: userId as number,
                mcfId: medicalFormId,
            }));
            if (data) {
                setVitalSigns(data);
            }
        }
        fetchData();
    }, [dispatch, appointmentId, accountId, userId, medicalFormId]);

    const onSubmitVitalSigns = useDebouncedCallback(() => {
        handleSubmit();
    }, 3000);

    const handleSubmit = async () => {
        if (vitalSigns.id) {
            await dispatch(updateVitalSigns({
                ...vitalSigns,
                appId: appointmentId,
                eaccount: accountId,
                userId: userId as number,
            }));
        } else {
            createVitalFunction();
        }
    };

    const createVitalFunction = async () => {
        const data = await dispatch(createVitalSigns({
            ...vitalSigns,
            appId: appointmentId,
            eaccount: accountId,
            userId: userId as number,
        }));

        if (data) {
            setVitalSigns({ ...vitalSigns, id: data.results as number });

        }
        return data;
    };

    const handleChangeSignalValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        const name = event.target.name;
        const options = {
            ...vitalSigns,
            [name]: value
        };
        setVitalSigns(options);
        onSubmitVitalSigns();
    };

    const renderDilatationRegister = () => {
        return (
            <SectionCard className="mt-3 p-4">
                <h5 className="fw-bold text-secondary">Estado general</h5>
                <hr />
                <div className="row">
                    <div className="col overflow-hidden">
                        <div className="row align-items-end g-0 mb-3">
                            <div className="fw-bold nowrap col-lg-4">Tensión arterial</div>
                            <div className="col-lg-6 d-flex">
                                <TextField
                                    variant="plain"
                                    type="number"
                                    className="me-3 flex-grow-1"
                                    placeholder="Sistólica"
                                    value={vitalSigns.bloodPressureSystolic}
                                    name="bloodPressureSystolic"
                                    onChange={handleChangeSignalValue}
                                    disabled={isDisabledForm}
                                />
                                <TextField
                                    variant="plain"
                                    type="number"
                                    placeholder="Diastólica"
                                    className="flex-grow-1"
                                    value={vitalSigns.bloodPressureDiastolic}
                                    name="bloodPressureDiastolic"
                                    onChange={handleChangeSignalValue}
                                    disabled={isDisabledForm}
                                />
                            </div>
                            <div className="col-lg-2 nowrap ps-2">
                                mmHG
                            </div>
                        </div>
                        <div className="row align-items-end g-0 mb-3">
                            <div className="fw-bold nowrap col-lg-4">Frecuencia cardíaca</div>
                            <div className="col-lg-6">
                                <TextField
                                    variant="plain"
                                    type="number"
                                    placeholder="Valor"
                                    value={vitalSigns.heartRate}
                                    name="heartRate"
                                    onChange={handleChangeSignalValue}
                                    disabled={isDisabledForm}
                                />
                            </div>
                            <div className="col-lg-2 nowrap ps-2">
                                LPM
                            </div>
                        </div>
                        <div className="row align-items-end g-0 mb-3">
                            <div className="fw-bold nowrap col-lg-4">Frecuencia respiratoria</div>
                            <div className="col-lg-6">
                                <TextField
                                    variant="plain"
                                    type="number"
                                    placeholder="Valor"
                                    value={vitalSigns.breathingFrequency}
                                    name="breathingFrequency"
                                    onChange={handleChangeSignalValue}
                                    disabled={isDisabledForm}
                                />
                            </div>
                            <div className="col-lg-2 nowrap ps-2">
                                RPM
                            </div>
                        </div>
                        <div className="row align-items-end g-0 mb-3">
                            <div className="fw-bold nowrap col-lg-4">Temperatura</div>
                            <div className="col-lg-6">
                                <TextField
                                    variant="plain"
                                    type="number"
                                    placeholder="Valor"
                                    value={vitalSigns.temperature}
                                    name="temperature"
                                    onChange={handleChangeSignalValue}
                                    disabled={isDisabledForm}
                                />
                            </div>
                            <div className="col-lg-2 nowrap ps-2">
                                grados °C
                            </div>
                        </div>
                        <div className="row align-items-end g-0 mb-3">
                            <div className="col-lg-4 fw-bold nowrap">Peso corporal</div>
                            <div className="col-lg-6">
                                <TextField
                                    variant="plain"
                                    type="number"
                                    placeholder="Valor"
                                    value={vitalSigns.bodyWeight}
                                    name="bodyWeight"
                                    onChange={handleChangeSignalValue}
                                    disabled={isDisabledForm}
                                />
                            </div>
                            <div className="col-lg-2 nowrap ps-2">
                                KG
                            </div>
                        </div>
                        <div className="row align-items-end g-0 mb-3">
                            <div className="col-lg-4 fw-bold nowrap">Estatura</div>
                            <div className="col-lg-6">
                                <TextField
                                    variant="plain"
                                    type="number"
                                    placeholder="Valor"
                                    value={vitalSigns.height}
                                    name="height"
                                    onChange={handleChangeSignalValue}
                                    disabled={isDisabledForm}
                                />
                            </div>
                            <div className="col-lg-2 nowrap ps-2">
                                CM
                            </div>
                        </div>
                    </div>
                    <div className="col-5 d-flex flex-column">
                        <span className="text-label">Notas</span>
                        <textarea
                            className="form-control no-resize h-100"
                            value={vitalSigns.notes}
                            name="notes"
                            onChange={handleChangeSignalValue}
                            placeholder="Escribir..."
                            disabled={isDisabledForm}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    const render = () => {
        return (
            <>
                {renderDilatationRegister()}
            </>
        );
    };

    return render();
}