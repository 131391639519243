import { AdmissionNotes } from "@/models/surgery/patientAttention/patientAttention";

const Admission = ({data, setAdmissionNotes, onSubmitNotes} : {data?: AdmissionNotes, setAdmissionNotes: (admissionNotes: AdmissionNotes)=> void,
    onSubmitNotes: (section: string, payload: AdmissionNotes) => void
}) => {
    console.log("onSubmitNotes: ", onSubmitNotes);
    console.log("setAdmissionNotes: ", setAdmissionNotes);
    console.log("data: ", data);
    
    return (
        <div>Admission</div>
    );
};

export default Admission;