import { useEffect, useState } from "react";
//
import { Vision } from "@/models/select/vision";
import { VisualAcuityParams } from "../VisualAcuity/VisualAcuity";
import { getFarVisionList } from "@/services";
//
import { SectionCard, Select } from "@/components";

export default function AvPh({ externalPayload, setExternalPayload, onSubmit }: VisualAcuityParams) {
    const [avph, setAvph] = useState<Vision[]>([]);

    useEffect(() => {
        async function fetch() {
            const optotypesResponse = await getFarVisionList();
            setAvph(optotypesResponse.results);
        }
        fetch();
    }, []);

    const onChangePH = (tableType: {
        eye: "oi" | "od";
        value?: number | string;
    }) => {
        const options: typeof externalPayload = {
            ...externalPayload,
            visualAcuity: {
                ...externalPayload.visualAcuity,
                ph: {
                    ...externalPayload.visualAcuity?.ph,
                    [tableType.eye]: tableType.value
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const farVisionFormatted = avph?.map(item => ({ label: item.value, value: item.id }));

    const render = () => {
        const phOdValue = farVisionFormatted.find(vs => vs.value === externalPayload.visualAcuity?.ph?.od);
        const phOiValue = farVisionFormatted.find(vs => vs.value === externalPayload.visualAcuity?.ph?.oi);

        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold">AV PH</h5>
                <hr className="text-primary mt-2" />
                <table className="mt-3">
                    <tbody>
                        <tr>
                            <td className="text-secondary ps-2 bg-od" style={{ borderTopLeftRadius: "0.5rem" }}>OD</td>
                            <td className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }}>
                                <Select
                                    options={farVisionFormatted}
                                    style={{ width: 80 }}
                                    onChange={({ option }) => onChangePH({ eye: "od", value: option.value })}
                                    value={phOdValue}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="text-secondary ps-2 bg-oi" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                            <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }}>
                                <Select
                                    options={farVisionFormatted}
                                    style={{ width: 80 }}
                                    onChange={({ option }) => onChangePH({ eye: "oi", value: option.value })}
                                    value={phOiValue}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </SectionCard>
        );
    };

    return render();
}