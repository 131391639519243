import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useLocation, useNavigate } from "react-router-dom";
//
import { useAppDispatch, useAppSelector } from "@/hooks";
import { getPreoperativeEvaluation, savePreoperativeEvaluation } from "./preoperative.actions";
import { PreoperativePayload } from "@/models/sheets/preoperative";
//
import ConsultationControls from "../Controls/Controls";
import ConsultationHeader from "../Header/Header";
import GeneralCondition from "./GeneralCondition/GeneralCondition";
import History from "../History/History";
//
import "./Preoperative.scss";
import Antecedents from "./Antecedents/Antecedents";
import MedicalExams from "./MedicalExams/MedicalExams";
import Approval from "./Approval/Approval";
import DiagnosticImpression from "../DiagnosticImpression/DiagnosticImpression";
import { setAttentionEnd } from "@/config/slices/canAttentionEnd";
import { getCanAttentionEnd } from "../consultation.actions";

type PreoperativeTabs =
    "actualCondition"
    | "background"
    | "medicalExams"
    | "suitableForSurgery";

export default function Preoperative() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const medicalFormId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const currentSheet = useAppSelector(state => state.patientAttention.patientStatus.sheet);
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const accountId = useAppSelector(state => state.workspace.id);
    const isDisabledForm = false;

    const [preoperativePayload, setPreoperativePayload] = useState<PreoperativePayload>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number
    });
    // const [showHistory, setShowHistory] = useState(false);
    const [currentTab, setCurrentTab] = useState<PreoperativeTabs>("actualCondition");
    const [isLoading, setIsLoading] = useState(false);

    const tabItems: { tag: PreoperativeTabs; label: string; }[] = [
        { tag: "actualCondition", label: "Condición general" },
        { tag: "background", label: "Antecedentes" },
        { tag: "medicalExams", label: "Exámenes médicos" },
        { tag: "suitableForSurgery", label: "Aprobación" },
    ];

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getCanAttentionEnd(medicalFormId));
            dispatch(setAttentionEnd(data.results ?? false));
        }
        fetchData();
    }, [dispatch, medicalFormId]);

    useEffect(() => {
        const splittedPath = location.pathname.split("/");
        const sheet = splittedPath[splittedPath.length - 1];

        if (sheet !== currentSheet) {
            navigate(-1);
        }
    }, [currentSheet, navigate, location.pathname]);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getPreoperativeEvaluation({
                appId: appointmentId,
                eaccount: accountId,
                userId: userId as number,
            }));
            if (data) setPreoperativePayload(prev => ({ ...prev, ...data }));
        }
        fetchData();
    }, [appointmentId, accountId, userId, dispatch]);

    const onChangeTab = (tab: PreoperativeTabs) => setCurrentTab(tab);

    const onSavePreoperativeEvolution = useDebouncedCallback(async (reload?: boolean) => {
        setIsLoading(true);
        if (preoperativePayload) {
            const options: PreoperativePayload = { ...preoperativePayload, tag: currentTab };

            setPreoperativePayload(options);
            const data = await dispatch(savePreoperativeEvaluation(options));

            if (data && reload) {
                setPreoperativePayload(prev => ({ ...prev, ...data }));
            }
        }
        setIsLoading(false);

    }, 1000);

    const render = () => {
        return (
            <section className="preoperative">
                <ConsultationHeader isDisabledForm={isDisabledForm} currentSection="Val. preoperatoria" showHistory={true}  />
                <div className="d-flex flex-grow-1 overflow-hidden">
                    <div className="consultation-section">
                        <ul className="nav nav-tabs">
                            {tabItems.map(tab => (
                                <li key={tab.tag} className="nav-item pointer" onClick={() => onChangeTab(tab.tag)}>
                                    <div className={`nav-link ${tab.tag === currentTab ? "active" : ""}`}>{tab.label}</div>
                                </li>
                            ))}
                        </ul>
                        <div className="tabs-content">
                            {currentTab === "actualCondition" &&
                                <GeneralCondition
                                    isDisabledForm={isDisabledForm}
                                    preoperativePayload={preoperativePayload}
                                    setPreoperativePayload={setPreoperativePayload}
                                    onSubmit={onSavePreoperativeEvolution}
                                />}
                            {currentTab === "background" &&
                                <Antecedents
                                    isDisabledForm={isDisabledForm}
                                    preoperativePayload={preoperativePayload}
                                    setPreoperativePayload={setPreoperativePayload}
                                    onSubmit={onSavePreoperativeEvolution}
                                    isLoading={isLoading}
                                />}
                            {currentTab === "medicalExams" &&
                                <MedicalExams
                                    isDisabledForm={isDisabledForm}
                                    preoperativePayload={preoperativePayload}
                                    setPreoperativePayload={setPreoperativePayload}
                                    onSubmit={onSavePreoperativeEvolution}
                                />}
                            {currentTab === "suitableForSurgery" &&
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Approval
                                            isDisabledForm={isDisabledForm}
                                            preoperativePayload={preoperativePayload}
                                            setPreoperativePayload={setPreoperativePayload}
                                            onSubmit={onSavePreoperativeEvolution}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <DiagnosticImpression isDisabledForm={isDisabledForm} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <History showClose={false} />
                </div>
                <ConsultationControls preoperativePayload={preoperativePayload} isDisabledForm={isDisabledForm} />
            </section >
        );
    };

    return render();
}