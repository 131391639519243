import { ICheckbox } from "./checkbox";
import "./Checkbox.scss";

export function Checkbox({
    className = "",
    label,
    labelPosition = "right",
    id = "",
    withShadow,
    checked,
    ...rest
}: ICheckbox) {

    return (
        <div className={`checkbox ${className} ${(withShadow && checked) ? "with-shadow" : ""}`}>
            {labelPosition === "left" && (
                <div>
                    <label htmlFor={id} className="form-check-label me-2">{label}</label>
                </div>
            )}
            <input className="form-check-input m-0" type="checkbox" id={id} checked={checked} {...rest} />
            {labelPosition === "right" && (
                <div>
                    <label htmlFor={id} className="ms-2 pointer">{label}</label>
                </div>
            )}
        </div>
    );
}