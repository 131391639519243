import { useLocation } from "react-router";

export const useGetLastPathName = (separador:string) => {
    const location = useLocation();
    const split = location.pathname.split(separador);
    const lastPathname = split[split.length - 1];

    return{
        pathname: location.pathname,
        lastPathname: lastPathname
    };
};