import { axiosInstance } from "@/config/axios";
import { setHeaders } from "@/utils";
//
import { IGetCorrectedIntraocularPressureResponse, ICorrectedIntraocularPressureParams } from "@/models";

export const getCorrectedIntraocularPressureService = async (params?: ICorrectedIntraocularPressureParams): Promise<IGetCorrectedIntraocularPressureResponse> => {
    const headers = setHeaders();
    const axiosParams = { headers, params };
    const { data } = await axiosInstance.get<IGetCorrectedIntraocularPressureResponse>(
        "/medical/clinicHistory/calculateCorrectedIntraocularPressure/", axiosParams
    );
    return data;
};

