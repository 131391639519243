import { AttentionOrigin, Dilatation, OcularSifting, PreconsultingDetail, VitalSigns } from "@/models/historyDetails/preconsulting";
import { formatHistoryDetails } from "@/utils";
import "../Details.scss";

export default function PreconsultDetails({ preconsultingDetail }: { preconsultingDetail: PreconsultingDetail; }) {

    const renderAttentionOrigin = (attentionOrigin: AttentionOrigin | null) => {

        if (attentionOrigin) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Origen de atención
                    </h4>
                    {attentionOrigin.attentionOrigin !== null ? (
                        <h5>
                            <span className="fw-bold">Origen de la atención: </span>
                            {attentionOrigin.attentionOrigin}
                        </h5>
                    ) : ""}
                    {attentionOrigin.abserveEvent !== null ? (
                        <h5>
                            <span className="fw-bold">Evento adverso: </span>
                            {attentionOrigin.abserveEvent === 1 ? "Sí" : "No"}
                        </h5>
                    ) : ""}
                    {(Object.values(attentionOrigin.attendingReason || {}).length || attentionOrigin.attendingReasonJst) ? (
                        <div>
                            {Object.values(attentionOrigin.attendingReason || {}).length ? (
                                <h5 className="fw-bold">
                                    <span>Motivo consulta: </span>
                                    {Object.values(attentionOrigin.attendingReason || {}).join(" - ")}
                                </h5>
                            ) : ""}
                            {attentionOrigin.attendingReasonJst ? (
                                <h5 style={{ whiteSpace: "pre-line" }}>
                                    {attentionOrigin.attendingReasonJst}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}
                    {(Object.values(attentionOrigin.currentIllness || {}).length || attentionOrigin.currentIllnessJst) ? (
                        <div className="mt-3">
                            {Object.values(attentionOrigin.currentIllness || {}).length ? (
                                <h5 className="fw-bold">
                                    <span>Enfermedad actual: </span>
                                    {Object.values(attentionOrigin.currentIllness || {}).join(" - ")}
                                </h5>
                            ) : ""}
                            {attentionOrigin.currentIllnessJst ? (
                                <h5 style={{ whiteSpace: "pre-line" }}>
                                    {attentionOrigin.currentIllnessJst}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}
                    {attentionOrigin.evolutionTime !== null ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>{" "}
                            {attentionOrigin.evolutionTime}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderDilation = (dilation: Dilatation[] | null) => {
        if (dilation?.length) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Dilatación
                    </h4>

                    <h5 className=" fw-bold mt-4">
                        Registro de dilatación
                    </h5>
                    <table className="table table-bordered mt-2 mb-4" style={{ maxWidth: 300 }}>
                        <thead>
                            <tr className="text-secondary">
                                <th>Medicamentos</th>
                                <th>Gotas</th>
                                <th>Hora</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dilation?.map(dil => (
                                <tr>
                                    <td>{dil.medication}</td>
                                    <td>{dil.drops}</td>
                                    <td>{dil.time}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            );
        } else return "";
    };

    const renderVitalSigns = (vitalSigns: VitalSigns) => {
        if (vitalSigns) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Signos vitales
                    </h4>
                    {vitalSigns.bloodPressureSystolic ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Tensión arterial sistólica: </span>{" "}
                            {vitalSigns.bloodPressureSystolic}
                        </h5>
                    ) : ""}
                    {vitalSigns.bloodPressureDiastolic ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Tensión arterial diastólica: </span>{" "}
                            {vitalSigns.bloodPressureDiastolic}
                        </h5>
                    ) : ""}
                    {vitalSigns.heartRate ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Frecuencia cardiaca: </span>{" "}
                            {vitalSigns.heartRate}
                        </h5>
                    ) : ""}
                    {vitalSigns.breathingFrequency ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Frecuencia respiratoria: </span>{" "}
                            {vitalSigns.breathingFrequency}
                        </h5>
                    ) : ""}
                    {vitalSigns.temperature ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Temperatura: </span>{" "}
                            {vitalSigns.temperature}
                        </h5>
                    ) : ""}
                    {vitalSigns.bodyWeight ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Peso corporal: </span>{" "}
                            {vitalSigns.bodyWeight}
                        </h5>
                    ) : ""}
                    {vitalSigns.height ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Estatura: </span>{" "}
                            {vitalSigns.height}
                        </h5>
                    ) : ""}
                    {vitalSigns.notes ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Notas: </span>{" "}
                            {vitalSigns.notes}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderOcularSiftning = (ocularSiftning: OcularSifting | null) => {
        if (ocularSiftning) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Tamizaje ocular
                    </h4>
                    {ocularSiftning.visionShoot ? (
                        <div className="mb-2">
                            <h5 className="fw-bold">Toma de visión</h5>
                            <h5>
                                {ocularSiftning.visionShoot.od ? (
                                    <>
                                        <span className="fw-bold">OD: </span>
                                        {ocularSiftning.visionShoot.od}
                                    </>
                                ) : ""}
                                {(ocularSiftning.visionShoot.od && ocularSiftning.visionShoot.oi) ? " / " : ""}
                                {ocularSiftning.visionShoot.oi ? (
                                    <>
                                        <span className="fw-bold">OI: </span>
                                        {ocularSiftning.visionShoot.oi}
                                    </>
                                ) : ""}
                            </h5>
                        </div>
                    ) : ""}
                    {ocularSiftning.pachymetry ? (
                        <div className="mb-3">
                            <h5 className=" fw-bold">Paquimetría</h5>
                            <h5>
                                {ocularSiftning.pachymetry.oi ? (
                                    <>
                                        <span className="fw-bold">OI: </span>
                                        {ocularSiftning.pachymetry.oi.value}
                                    </>
                                ) : ""}
                                {(ocularSiftning.pachymetry.oi && ocularSiftning.pachymetry.od) ? " / " : ""}
                                {ocularSiftning.pachymetry.od ? (
                                    <>
                                        <span className="fw-bold">OD: </span>
                                        {ocularSiftning.pachymetry.od.value}
                                    </>
                                ) : ""}
                            </h5>
                            <h5>
                                {ocularSiftning.pachymetry.oi ? (
                                    <>
                                        <span className="fw-bold">OI Corrección: </span>
                                        {ocularSiftning.pachymetry.oi.correction}
                                    </>
                                ) : ""}
                                {(ocularSiftning.pachymetry.oi && ocularSiftning.pachymetry.od) ? " / " : ""}
                                {ocularSiftning.pachymetry.od ? (
                                    <>
                                        <span className="fw-bold">OD Corrección: </span>
                                        {ocularSiftning.pachymetry.od.correction}
                                    </>
                                ) : ""}
                            </h5>
                        </div>
                    ) : ""}

                    {ocularSiftning.intraocularPressure ? (
                        <div>
                            <h5 className="fw-bold">Presión intraocular</h5>
                            {ocularSiftning.intraocularPressure.uncorrected ? (
                                <h5 className="mb-2">
                                    {ocularSiftning.intraocularPressure.uncorrected.oi ? (
                                        <>
                                            <span className="fw-bold">PIO OI: </span>
                                            {ocularSiftning.intraocularPressure.uncorrected.oi}
                                        </>
                                    ) : ""}
                                    {(ocularSiftning.intraocularPressure.uncorrected.od && ocularSiftning.intraocularPressure.uncorrected.oi) ? " / " : ""}
                                    {ocularSiftning.intraocularPressure.uncorrected.od ? (
                                        <>
                                            <span className="fw-bold">PIO OD: </span>
                                            {ocularSiftning.intraocularPressure.uncorrected.od}
                                        </>
                                    ) : ""}
                                </h5>
                            ) : ""}
                            {ocularSiftning.intraocularPressure.corrected ? (
                                <h5>
                                    {ocularSiftning.intraocularPressure.corrected.oi ? (
                                        <>
                                            <span className="fw-bold">PIO Ajustada OI: </span>
                                            {ocularSiftning.intraocularPressure.corrected.oi}
                                        </>
                                    ) : ""}
                                    {(ocularSiftning.intraocularPressure.corrected.oi && ocularSiftning.intraocularPressure.corrected.od) ? " / " : ""}
                                    {ocularSiftning.intraocularPressure.corrected.od ? (
                                        <>
                                            <span className="fw-bold">PIO Ajustada OD: </span>
                                            {ocularSiftning.intraocularPressure.corrected.od}
                                        </>
                                    ) : ""}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}

                </div>
            );
        } else return "";
    };

    const renderSiftningPictures = (ocularSiftning: OcularSifting | null) => {
        if (ocularSiftning?.backgroundEye || ocularSiftning?.octOpticNerve || ocularSiftning?.octRetina) {
            return (
                <div className="row text-muted">
                    <div className="col-12 mb-3">
                        <hr className=" mb-3" />
                        <h4 className="text-primary fw-bold mb-3">
                            Fotos tamizaje
                        </h4>
                        <div className="row">
                            {ocularSiftning.backgroundEye ? (
                                <div className="col">
                                    <div className="border p-3">
                                        <h5 className=" fw-bold">
                                            Foto fondo ojo
                                        </h5>
                                        <div className="row">
                                            {ocularSiftning.backgroundEye.oi ? (
                                                <div className="col">
                                                    <div className="fw-bold ">OI</div>
                                                    <img
                                                        src={ocularSiftning.backgroundEye.oi}
                                                        className="img-fluid"
                                                        width={250}
                                                        style={{ objectFit: "contain" }}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : ""}
                                            {ocularSiftning.backgroundEye.od ? (
                                                <div className="col">
                                                    <div className="fw-bold ">OD</div>
                                                    <img
                                                        src={ocularSiftning.backgroundEye.od}
                                                        className="img-fluid"
                                                        width={250}
                                                        style={{ objectFit: "contain" }}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : ""}
                                        </div>
                                    </div>
                                </div>
                            ) : ""}
                            {ocularSiftning.octRetina ? (
                                <div className="col">
                                    <div className="border p-3">
                                        <h5 className=" fw-bold">
                                            Tomografía óptica coherente de retina
                                        </h5>
                                        <div className="row">
                                            {ocularSiftning.octRetina.oi ? (
                                                <div className="col">
                                                    <div className="fw-bold ">OI</div>
                                                    <img
                                                        src={ocularSiftning.octRetina.oi}
                                                        className="img-fluid"
                                                        width={250}
                                                        style={{ objectFit: "contain" }}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : ""}
                                            {ocularSiftning.octRetina.od ? (
                                                <div className="col">
                                                    <div className="fw-bold ">OD</div>
                                                    <img
                                                        src={ocularSiftning.octRetina.od}
                                                        className="img-fluid"
                                                        width={250}
                                                        style={{ objectFit: "contain" }}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : ""}
                                        </div>
                                    </div>
                                </div>
                            ) : ""}
                            {ocularSiftning.octOpticNerve ? (
                                <div className="col">
                                    <div className="border p-3">
                                        <h5 className=" fw-bold">
                                            Tomografía óptica coherente nervio óptico
                                        </h5>
                                        <div className="row">
                                            {ocularSiftning.octOpticNerve.oi ? (
                                                <div className="col">
                                                    <div className="fw-bold ">OI</div>
                                                    <img
                                                        src={ocularSiftning.octOpticNerve.oi}
                                                        className="img-fluid"
                                                        width={250}
                                                        style={{ objectFit: "contain" }}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : ""}
                                            {ocularSiftning.octOpticNerve.od ? (
                                                <div className="col">
                                                    <div className="fw-bold ">OD</div>
                                                    <img
                                                        src={ocularSiftning.octOpticNerve.od}
                                                        className="img-fluid"
                                                        width={250}
                                                        style={{ objectFit: "contain" }}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : ""}
                                        </div>
                                    </div>
                                </div>
                            ) : ""}
                        </div>
                    </div>
                </div>
            );
        } else return "";
    };

    const renderMedication = (medication: string[] | null) => {
        if (medication && medication.length) {
            return (
                <div className="row text-muted">
                    <div className="col-12 mb-3">
                        <hr className=" mb-3" />
                        <h4 className="text-primary fw-bold mb-3">
                            Reconciliación medicamentosa
                        </h4>
                        <h5 className=" mb-3">
                            <span className="fw-bold">En uso: </span>{" "}
                            {medication.join(", ")}
                        </h5>
                        {/* <h5 className=" mb-3">
                            <span className="fw-bold">Suspendidos: </span>{" "}
                            Dolex forte, Acetaminofén
                        </h5> */}
                    </div>
                </div>
            );
        } else return "";
    };

    const render = () => {
        const attentionOrigin = formatHistoryDetails(preconsultingDetail.attentionOrigin);
        const dilatation = formatHistoryDetails(preconsultingDetail.dilatation) as Record<string, Dilatation>;
        const vitalSigns = formatHistoryDetails(preconsultingDetail.vitalSigns);
        const ocularSifting = formatHistoryDetails(preconsultingDetail.ocularSifting);
        const medication = formatHistoryDetails(preconsultingDetail.medication);

        return (
            <div className="row overflow-auto">
                <div className="detail-container">
                    <div className="columns">
                        {renderAttentionOrigin(attentionOrigin)}
                        {renderDilation(Object.values(dilatation || {}))}
                        {renderVitalSigns(vitalSigns)}
                        {renderOcularSiftning(ocularSifting)}
                    </div>
                </div>
                {renderSiftningPictures(ocularSifting)}
                {renderMedication(Object.values(medication || {}))}
            </div>
        );
    };

    return render();
}