import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { SitesParams } from "@/models";
import { AddLotArticlePayload, AttentionAppParams, ConsumptionCenterParamsSheet, ConsumptionCentersParams, EvidencePayload, NurseryNotes, NurseryNotesParams, QuantityPayload, deleteSupplyParams } from "@/models/surgery/patientAttention/patientAttention";
import { getSitesListService, getSurgeonsService, getSurgeriesRoomService } from "@/services";
import { addLotArticleService, changeEvidenceService, changeQuantityService, deleteEvidenceService, deleteSupplyService, getAttentionAppointmentService, getConsumptionCentersService, getConsumptionSheetService, getHeaderSurgeryService, getNurseryNotesService, startSurgeryAttentionService, translateSurgeryAttentionService, updateNurseryNotesService } from "@/services/surgery/patientAttention";


export function getAttentionAppointment(params: AttentionAppParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAttentionAppointmentService(params);
            if (response.success) return response;

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function getHeaderSurgery(appId: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getHeaderSurgeryService(appId);
            if (response.success) return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getSitesSurgery(params: SitesParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getSitesListService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function getSurgeryRooms() {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getSurgeriesRoomService();
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function getSurgeons() {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getSurgeonsService();
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function startSurgeryAttention(appId: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await startSurgeryAttentionService(appId);
            return response;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function translateSurgeryAttention(appId: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await translateSurgeryAttentionService(appId);
            return response;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getNurseryNotes(params: NurseryNotesParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getNurseryNotesService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function updateNurseryNotes(payload: NurseryNotes) {
    return async function (dispatch: AppDispatch) {
        const response = await updateNurseryNotesService(payload);
        return response;
    };
}

export function getConsumptionCenters(params: ConsumptionCentersParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getConsumptionCentersService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function addLotArticle(payload: AddLotArticlePayload) {
    return async function (dispatch: AppDispatch) {
        const response = await addLotArticleService(payload);
        return response;
    };
}

export function getConsumptionCentersSheet(params: ConsumptionCenterParamsSheet) {
    return async function (dispatch: AppDispatch) {
        const response = await getConsumptionSheetService(params);
        return response.results;
    };
}

export function deleteSupply(params: deleteSupplyParams) {
    return async function (dispatch: AppDispatch) {
        const response = await deleteSupplyService(params);
        return response;
    };
}


export function changeQuantity(params: QuantityPayload) {
    return async function (dispatch: AppDispatch) {
        const response = await changeQuantityService(params);
        return response;
    };
}
export function changeEvidence(params: EvidencePayload) {
    return async function (dispatch: AppDispatch) {
        const response = await changeEvidenceService(params);
        return response;
    };
}

export function deleteEvidence(params: { filId: number; }) {
    return async function (dispatch: AppDispatch) {
        const response = await deleteEvidenceService(params);
        return response;
    };
}
