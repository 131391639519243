import { useEffect, useState } from "react";
import { Checkbox, SectionCard, Select } from "../../../../../components";
import { useLocation, useNavigate } from "react-router-dom";
//
import { useAppDispatch, useAppSelector } from "@/hooks";
//
import AttentionOrigin from "../AttentionOrigin/AttentionOrigin";
import ConsultationHeader from "../Header/Header";
import ConsultationControls from "../Controls/Controls";
import DiagnosticImpression from "../DiagnosticImpression/DiagnosticImpression";
import AnalysisAndPlan from "../AttentionOrigin/AnalysisAndPlan";
import History from "../History/History";
import { getVisionShoot } from "../PreConsultation/preConsultation.actions";
import { AttentionOriginFields } from "@/models/attentionOrigin";
import { AdaptationInter, AdaptationPayload, ContactLenses, GeneralSelectsAdaptation, GlassesToOrder, ReusableEyeConfig, ScleralLens, ScleralLenses } from "@/models/sheets/adaptation";
import { IVisionShoot } from "@/models";
import { getAdaptation, getAdaptationSelects, updateAdaptation } from "./adaptation.actions";
import { useDebouncedCallback } from "use-debounce";
import { Vision } from "@/models/select/vision";
import { MaterialsLens } from "@/models/select/materialsLens";
import { SpecialLens } from "@/models/select/specialLens";
import { SoftLens } from "@/models/select/softLens";
import { DesignLens } from "@/models/select/designLens";
import CentralClearance from "./CentralClearance/CentralClearance";
import "./Adaptation.scss";

export default function Adaptation() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const currentSheet = useAppSelector(state => state.patientAttention.patientStatus.sheet);
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const isDisabledForm = false;
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const mcfId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);

    const [attentionOriginOptions, setAttentionOriginOptions] = useState<AttentionOriginFields>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number,
        generalFields: {
            attentionOriginId: undefined,
            abserveEvent: undefined,
            evolutionTime: undefined,
            evolutionTimeUnitId: undefined
        },
        reasonForConsulting: { predefinedTxts: [], justification: "" },
        currentDisease: { predefinedTxts: [], justification: "" },
        analysisPlan: ""
    });
    const [adaptationPayload, setAdaptationPayload] = useState<AdaptationPayload>({
        appId: appointmentId,
        eaccount: accountId,
        mcfId: mcfId,
        userId: userId as number,
        clearenceCentral: []
    });
    const [generalSelects, setGeneralSelects] = useState<GeneralSelectsAdaptation>({
        visualAcuity: [],
        lensAdd: [],
        lensType: [],
        designLens: [],
        materialsLens: [],
        softLens: [],
        specialLens: [],
    });

    useEffect(() => {
        const splittedPath = location.pathname.split("/");
        const sheet = splittedPath[splittedPath.length - 1];

        if (sheet !== currentSheet) {
            navigate(-1);
        }
    }, [currentSheet, navigate, location.pathname]);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getAdaptation({
                appId: appointmentId,
                eaccount: accountId,
                mcfId: mcfId,
                userId: userId as number
            }));
            if (data) {
                setAdaptationPayload({
                    ...data,
                    mcfId: mcfId,
                    eaccount: accountId,
                    userId: userId as number,
                    appId: appointmentId,
                });
            }
        }
        fetchData();
    }, [dispatch, appointmentId, accountId, mcfId, userId]);

    useEffect(() => {
        async function fetchData() {
            const resultVisionShoot = await dispatch(getVisionShoot());
            const resultsSelects = await dispatch(getAdaptationSelects());
            setGeneralSelects({
                visualAcuity: resultVisionShoot as IVisionShoot[],
                lensAdd: resultsSelects?.lensAddResponse as Vision[],
                lensType: resultsSelects?.lensTypeResponse as Vision[],
                materialsLens: resultsSelects?.materialLensResponse as MaterialsLens[],
                specialLens: resultsSelects?.specialLensResponse as SpecialLens[],
                softLens: resultsSelects?.softLensResponse as SoftLens[],
                designLens: resultsSelects?.designLensResponse as DesignLens[],
            });
        }
        fetchData();
    }, [dispatch]);

    const onSubmitAdaptation = useDebouncedCallback((options: AdaptationPayload) => {
        dispatch(updateAdaptation(options));
    }, 1000);

    const OnChangeScleralLensesRadioButtons = (label: keyof ScleralLenses, value: boolean) => {
        let options: AdaptationPayload = {
            ...adaptationPayload,
        };
        if (label === "test" && value) {
            options = {
                ...adaptationPayload,
                scleralLenses: {
                    ...adaptationPayload?.scleralLenses,
                    [label]: value,
                    definitive: false
                }
            };
        } else {
            options = {
                ...adaptationPayload,
                scleralLenses: {
                    ...adaptationPayload?.scleralLenses,
                    [label]: value,
                    test: false
                }
            };
        }
        setAdaptationPayload(options);
        onSubmitAdaptation(options);
    };

    const onChangeScleralLensesEyes = (eye: "od" | "oi", label: keyof ScleralLens, value: string | number) => {
        const options: AdaptationPayload = {
            ...adaptationPayload,
            scleralLenses: {
                ...adaptationPayload?.scleralLenses,
                [eye]: {
                    ...adaptationPayload?.scleralLenses?.[eye],
                    [label]: value
                }
            }
        };
        setAdaptationPayload(options);
        onSubmitAdaptation(options);
    };
    const onChangeReusableSection = (section: "glassesToOrder" | "overfraction", eye: "od" | "oi", label: keyof ReusableEyeConfig, value: string | number) => {
        const options: AdaptationPayload = {
            ...adaptationPayload,
            [section]: {
                ...adaptationPayload?.[section],
                [eye]: {
                    ...adaptationPayload?.[section]?.[eye],
                    [label]: value
                }
            }
        };
        setAdaptationPayload(options);
        onSubmitAdaptation(options);
    };
    const onChangeGlassToOrderOtherFiel = (label: keyof GlassesToOrder, value: string | number) => {
        const options: AdaptationPayload = {
            ...adaptationPayload,
            glassesToOrder: {
                ...adaptationPayload?.glassesToOrder,
                [label]: value
            }
        };
        setAdaptationPayload(options);
        onSubmitAdaptation(options);
    };
    const onChangeAdaptation = (label: keyof AdaptationInter, value: string | number) => {
        const options: AdaptationPayload = {
            ...adaptationPayload,
            adaptation: {
                ...adaptationPayload?.adaptation,
                [label]: value
            }
        };
        setAdaptationPayload(options);
        onSubmitAdaptation(options);
    };

    const onChangeLensType = (value: string | number) => {
        const options: AdaptationPayload = {
            ...adaptationPayload,
            contactLenses: {
                ...adaptationPayload?.contactLenses,
                lensSelected: undefined,
                lensType: +value === 0 ? null : +value
            }
        };
        setAdaptationPayload(options);
        onSubmitAdaptation(options);
    };

    const onChangeContactLens = (label: keyof ContactLenses, value: string | number) => {
        const options: AdaptationPayload = {
            ...adaptationPayload,
            contactLenses: {
                ...adaptationPayload?.contactLenses,
                [label]: value
            }
        };
        setAdaptationPayload(options);
        onSubmitAdaptation(options);
    };

    const onChangeLensSelected = (label: keyof ContactLenses, value: string | number) => {
        const options: AdaptationPayload = {
            ...adaptationPayload,
            contactLenses: {
                ...adaptationPayload?.contactLenses,
                materials: undefined,
                design: undefined,
                lensSelected: +value
            }
        };
        setAdaptationPayload(options);
        onSubmitAdaptation(options);
    };

    const renderScleralLenses = () => {
        const formattedVisualAcuitySelect = generalSelects.visualAcuity.map(sel => ({
            label: sel.name,
            value: sel.id
        }));
        const odVisualAcuity = formattedVisualAcuitySelect.find(fob => fob.value === adaptationPayload?.scleralLenses?.od?.visionShoot);
        const oiVisualAcuity = formattedVisualAcuitySelect.find(fob => fob.value === adaptationPayload?.scleralLenses?.oi?.visionShoot);

        return (
            <SectionCard>
                <div className="d-flex justify-content-between">
                    <div>
                        <h5 className="text-secondary fw-bold">Lentes esclerales</h5>
                    </div>
                    <div className="mt-3 d-flex">
                        <Checkbox
                            disabled={isDisabledForm}
                            label="Prueba"
                            className="text-muted fw-bold mx-2"
                            value="test"
                            name="test"
                            id="test"
                            checked={adaptationPayload?.scleralLenses?.test}
                            onChange={({ target }) => OnChangeScleralLensesRadioButtons("test", target.checked)}
                        />
                        <Checkbox
                            label="Definitivos"
                            disabled={isDisabledForm}
                            className="text-muted fw-bold"
                            value="definitive"
                            name="definitive"
                            id="definitive"
                            checked={adaptationPayload?.scleralLenses?.definitive}
                            onChange={({ target }) => OnChangeScleralLensesRadioButtons("definitive", target.checked)}
                        />

                    </div>
                </div>
                <hr className="text-primary mb-0 mt-2" />


                <table className="mt-3">
                    <thead>
                        <tr>
                            <td className="p-2"></td>
                            <td className="text-secondary" align="center">Diseño</td>
                            <td className="text-secondary" align="center">Power</td>
                            <td className="text-secondary" align="center">SAG</td>
                            <td align="center" className="fs-3 text-secondary">⌀</td>
                            <td className="text-secondary" align="center">AV</td>
                            <td className="text-secondary" align="center">Clearance</td>
                            <td align="left" className="ps-5 text-secondary">Otros</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="p-2 text-secondary elemEyeOD "
                                style={{ borderRadius: "6px 0px 0px 0px" }}

                                width={30}>OD</td>
                            <td className="p-2 elemEyeOD" width={120}>
                                <input
                                    type="text"
                                    disabled={isDisabledForm}
                                    className="form-control"
                                    name="design"
                                    value={adaptationPayload?.scleralLenses?.od?.design}
                                    onChange={(e) => {
                                        onChangeScleralLensesEyes("od", "design", e?.target?.value);
                                    }}
                                />
                            </td>
                            <td className="p-2 elemEyeOD" width={120}>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="power"
                                    disabled={isDisabledForm}
                                    value={adaptationPayload?.scleralLenses?.od?.power}
                                    onChange={(e) => {
                                        const value = e.target.value.split(".")[1];
                                        if (value && value.length > 2) {
                                            e.preventDefault();
                                        } else {
                                            onChangeScleralLensesEyes("od", "power", e?.target?.value);
                                        }
                                    }}
                                    onKeyDown={(e) => (e.key === "+") && e.preventDefault()}
                                />
                            </td>
                            <td className="p-2 elemEyeOD" width={120}>
                                <input type="number" className="form-control" name="sag"
                                    value={adaptationPayload?.scleralLenses?.od?.sag}
                                    disabled={isDisabledForm}
                                    onChange={(e) => {
                                        const values = e.target.value.split(".");
                                        if (values[1] && values[1].length > 2) {
                                            e.preventDefault();
                                        } else {
                                            onChangeScleralLensesEyes("od", "sag", e.target.value);
                                        }
                                    }}
                                />
                            </td>
                            <td className="p-2 elemEyeOD" width={120}>
                                <input type="number" className="form-control" name="diameter"
                                    value={adaptationPayload?.scleralLenses?.od?.diameter}
                                    disabled={isDisabledForm}
                                    onChange={(e) => {
                                        const values = e.target.value.split(".");
                                        if (values[1] && values[1].length > 2) {
                                            e.preventDefault();
                                        } else {
                                            onChangeScleralLensesEyes("od", "diameter", e.target.value);
                                        }
                                    }}
                                />
                            </td>
                            <td className="p-2 elemEyeOD" width={120}>
                                <Select
                                    width={120}
                                    name="visionShoot"
                                    options={formattedVisualAcuitySelect}
                                    value={odVisualAcuity}
                                    disabled={isDisabledForm}
                                    onChange={(e) => {
                                        onChangeScleralLensesEyes("od", "visionShoot", e?.option.value);
                                    }}
                                />
                            </td>
                            <td className="p-2 elemEyeOD" width={120}>
                                <input type="number" className="form-control" name="clearance"
                                    value={adaptationPayload?.scleralLenses?.od?.clearance}
                                    disabled={isDisabledForm}
                                    onChange={(e) => {
                                        const values = e.target.value.split(".");
                                        if (values[1] && values[1].length > 2) {
                                            e.preventDefault();
                                        } else {
                                            onChangeScleralLensesEyes("od", "clearance", e.target.value);
                                        }
                                    }}
                                />
                            </td>
                            <td className="p-2 elemEyeOD"
                                style={{ borderRadius: "0px 6px 0px 0px" }}

                            >
                                <input type="text" className="form-control w-100" name="others"
                                    value={adaptationPayload?.scleralLenses?.od?.others}
                                    disabled={isDisabledForm}
                                    onChange={(e) => {
                                        onChangeScleralLensesEyes("od", "others", e?.target?.value);
                                    }} />
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 text-secondary elemEyeOI"
                                style={{ borderRadius: "0px 0px 0px 6px" }}
                            >OI</td>
                            <td className="p-2 elemEyeOI" width={120}>
                                <input type="text" className="form-control"
                                    name="design"
                                    value={adaptationPayload?.scleralLenses?.oi?.design}
                                    disabled={isDisabledForm}
                                    onChange={(e) => {
                                        onChangeScleralLensesEyes("oi", "design", e?.target?.value);
                                    }}
                                />
                            </td>
                            <td className="p-2 elemEyeOI" width={120}>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="power"
                                    disabled={isDisabledForm}
                                    value={adaptationPayload?.scleralLenses?.oi?.power}
                                    onChange={(e) => {
                                        const value = e.target.value.split(".")[1];
                                        if (value && value.length > 2) {
                                            e.preventDefault();
                                        } else {
                                            onChangeScleralLensesEyes("oi", "power", e?.target?.value);
                                        }
                                    }}
                                    onKeyDown={(e) => (e.key === "+") && e.preventDefault()}
                                />
                            </td>
                            <td className="p-2 elemEyeOI" width={120}>
                                <input type="number" className="form-control" name="sag"
                                    value={adaptationPayload?.scleralLenses?.oi?.sag}
                                    disabled={isDisabledForm}
                                    onChange={(e) => {
                                        const values = e.target.value.split(".");
                                        if (values[1] && values[1].length > 2) {
                                            e.preventDefault();
                                        } else {
                                            onChangeScleralLensesEyes("oi", "sag", e.target.value);
                                        }
                                    }} />
                            </td>
                            <td className="p-2 elemEyeOI" width={120}>
                                <input type="number" className="form-control" name="diameter"
                                    value={adaptationPayload?.scleralLenses?.oi?.diameter}
                                    disabled={isDisabledForm}
                                    onChange={(e) => {
                                        const values = e.target.value.split(".");
                                        if (values[1] && values[1].length > 2) {
                                            e.preventDefault();
                                        } else {
                                            onChangeScleralLensesEyes("oi", "diameter", e.target.value);
                                        }
                                    }} />
                            </td>
                            <td className="p-2 elemEyeOI" width={120}>
                                <Select
                                    width={120}
                                    options={formattedVisualAcuitySelect}
                                    value={oiVisualAcuity}
                                    disabled={isDisabledForm}
                                    name="visionShoot"
                                    onChange={(e) => {
                                        onChangeScleralLensesEyes("oi", "visionShoot", e?.option.value);
                                    }}
                                />
                            </td>
                            <td className="p-2 elemEyeOI" width={120}>
                                <input type="number" className="form-control" name="clearance"
                                    value={adaptationPayload?.scleralLenses?.oi?.clearance}
                                    disabled={isDisabledForm}
                                    onChange={(e) => {
                                        const values = e.target.value.split(".");
                                        if (values[1] && values[1].length > 2) {
                                            e.preventDefault();
                                        } else {
                                            onChangeScleralLensesEyes("oi", "clearance", e.target.value);
                                        }
                                    }} />
                            </td>
                            <td className="p-2 elemEyeOI"
                                style={{ borderRadius: "0px 0px 6px 0px" }}
                            >
                                <input type="text" className="form-control w-100" name="others"
                                    value={adaptationPayload?.scleralLenses?.oi?.others}
                                    disabled={isDisabledForm}
                                    onChange={(e) => {
                                        onChangeScleralLensesEyes("oi", "others", e?.target?.value);
                                    }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="text-secondary fw-bold mt-5">Clearance central</h5>
                <CentralClearance
                    isDisabledForm={isDisabledForm}
                    key={adaptationPayload.clearenceCentral.length}
                    adaptationPayload={adaptationPayload}
                />
            </SectionCard >
        );
    };

    const renderReusableSection = (section: "glassesToOrder" | "overfraction") => {
        const formattedVisualAcuitySelect = generalSelects.visualAcuity.map(sel => ({
            label: sel.name,
            value: sel.id
        }));
        const odVisualAcuity = formattedVisualAcuitySelect.find(fob => fob.value === adaptationPayload?.[section]?.od?.visionShoot);
        const oiVisualAcuity = formattedVisualAcuitySelect.find(fob => fob.value === adaptationPayload?.[section]?.oi?.visionShoot);

        const formattedLensAddSelect = generalSelects.lensAdd.map(sel => ({
            label: sel.value,
            value: sel.id
        }));
        const odLensAdd = formattedLensAddSelect.find(fob => fob.value === adaptationPayload?.[section]?.od?.add);
        const oiLensAdd = formattedLensAddSelect.find(fob => fob.value === adaptationPayload?.[section]?.oi?.add);

        return (
            <table>
                <thead>
                    <tr>
                        <td className="p-2"></td>
                        <td className="text-secondary" align="center">Esfera</td>
                        <td className="text-secondary" align="center">Cilindro</td>
                        <td className="text-secondary" align="center">Eje°</td>
                        <td className="text-secondary" align="center">ADD</td>
                        <td className="text-secondary" align="center">AV</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="p-2 text-secondary elemEyeOD" width={30}
                            style={{ borderRadius: "6px 0px 0px 0px" }}

                        >OD</td>
                        <td className="p-2 elemEyeOD" width="100px">
                            <input type="number " className="form-control"
                                disabled={isDisabledForm}
                                name="sphere"
                                value={adaptationPayload?.[section]?.od?.sphere}
                                onChange={(e) => {
                                    const values = e.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        e.preventDefault();
                                    } else {
                                        onChangeReusableSection(section, "od", "sphere", e.target.value);
                                    }
                                }}
                            />
                        </td>
                        <td className="p-2 elemEyeOD" width="100px">
                            <input type="number" className="form-control"
                                name="cylinder"
                                disabled={isDisabledForm}
                                value={adaptationPayload?.[section]?.od?.cylinder}
                                onChange={(e) => {
                                    const values = e.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        e.preventDefault();
                                    } else {
                                        onChangeReusableSection(section, "od", "cylinder", e.target.value);
                                    }
                                }} />
                        </td>
                        <td className="p-2 elemEyeOD" width="100px">
                            <input type="number" className="form-control" name="axis"
                                value={adaptationPayload?.[section]?.od?.axis}
                                disabled={isDisabledForm}
                                onChange={(e) => {
                                    onChangeReusableSection(section, "od", "axis", e?.target?.value);
                                }} />
                        </td>
                        <td className="p-2 elemEyeOD">
                            <Select
                                style={{ width: "100%" }}
                                options={formattedLensAddSelect}
                                value={odLensAdd}
                                name="add"
                                disabled={isDisabledForm}
                                onChange={(e) => {
                                    onChangeReusableSection(section, "od", "add", e?.option.value);
                                }}
                            />
                        </td>
                        <td className="p-2 elemEyeOD"
                            style={{ borderRadius: "0px 6px 0px 0px" }}

                        >
                            <Select
                                disabled={isDisabledForm}
                                style={{ width: "100%" }}
                                options={formattedVisualAcuitySelect}
                                value={odVisualAcuity}
                                name="visionShoot"
                                onChange={(e) => {
                                    onChangeReusableSection(section, "od", "visionShoot", e?.option.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 text-secondary elemEyeOI" width={30}
                            style={{ borderRadius: "0px 0px 0px 6px" }}
                        >OI</td>
                        <td className="p-2 elemEyeOI" width="100px">
                            <input type="number" className="form-control"
                                name="sphere"
                                disabled={isDisabledForm}
                                value={adaptationPayload?.[section]?.oi?.sphere}
                                onChange={(e) => {
                                    const values = e.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        e.preventDefault();
                                    } else {
                                        onChangeReusableSection(section, "oi", "sphere", e.target.value);
                                    }
                                }} />
                        </td>
                        <td className="p-2 elemEyeOI" width="100px">
                            <input type="number" className="form-control"
                                name="cylinder"
                                disabled={isDisabledForm}
                                value={adaptationPayload?.[section]?.oi?.cylinder}
                                onChange={(e) => {
                                    const values = e.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        e.preventDefault();
                                    } else {
                                        onChangeReusableSection(section, "oi", "cylinder", e.target.value);
                                    }
                                }} />
                        </td>
                        <td className="p-2 elemEyeOI" width="100px">
                            <input type="number" className="form-control"
                                name="axis"
                                disabled={isDisabledForm}
                                value={adaptationPayload?.[section]?.oi?.axis}
                                onChange={(e) => {
                                    onChangeReusableSection(section, "oi", "axis", e?.target?.value);
                                }} />
                        </td>
                        <td className="p-2 elemEyeOI">
                            <Select
                                disabled={isDisabledForm}
                                style={{ width: "100%" }}
                                options={formattedLensAddSelect}
                                value={oiLensAdd}
                                name="add"
                                onChange={(e) => {
                                    onChangeReusableSection(section, "oi", "add", e?.option.value);
                                }}
                            />
                        </td>
                        <td className="p-2 elemEyeOI"
                            style={{ borderRadius: "0px 0px 6px 0px" }}
                        >
                            <Select
                                style={{ width: "100%" }}
                                options={formattedVisualAcuitySelect}
                                value={oiVisualAcuity}
                                disabled={isDisabledForm}
                                name="visionShoot"
                                onChange={(e) => {
                                    onChangeReusableSection(section, "oi", "visionShoot", e?.option.value);
                                }}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const renderGlassesToOrder = () => {
        const formattedLensTypeSelect = generalSelects.lensType.map(sel => ({
            label: sel.value,
            value: sel.id
        }));
        const LensType = formattedLensTypeSelect.find(fob => fob.value === adaptationPayload?.glassesToOrder?.lensType);

        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold">Lentes a pedir</h5>
                <hr className="text-primary mb-3 mt-2" />
                {renderReusableSection("glassesToOrder")}

                <div className="d-flex align-items-center mt-3">
                    <div className="me-3 w-25">
                        <label className="text-label">Tipo de lente</label>
                        <Select
                            options={formattedLensTypeSelect}
                            value={LensType}
                            disabled={isDisabledForm}
                            name="visionShoot"
                            onChange={(e) => {
                                onChangeGlassToOrderOtherFiel("lensType", e?.option.value);
                            }}
                        />
                    </div>
                    <div className="me-3 w-100">
                        <label className="text-label">Recomendaciones de uso</label>
                        <input
                            type="text"
                            disabled={isDisabledForm}
                            className="form-control w-100"
                            name="recommendationsForUse"
                            value={adaptationPayload?.glassesToOrder?.recommendationsForUse}
                            onChange={(e) => {
                                onChangeGlassToOrderOtherFiel("recommendationsForUse", e?.target?.value);
                            }}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    const renderAdaptation = () => {
        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold">Adaptación</h5>
                <hr className="text-primary mb-0 mt-2" />

                <div className="d-flex align-items-center mt-3">
                    <span className="text-label w-25">Tolerancia / Confort</span>
                    <input type="text" className="form-control" name="tolerance"
                        value={adaptationPayload.adaptation?.tolerance}
                        disabled={isDisabledForm}
                        onChange={(e) => {
                            onChangeAdaptation("tolerance", e?.target.value);
                        }}
                    />
                </div>
                <div className="d-flex align-items-center mt-3">
                    <span className="text-label w-25">Centrado</span>
                    <input type="text" className="form-control" name="centered"
                        value={adaptationPayload.adaptation?.centered}
                        disabled={isDisabledForm}
                        onChange={(e) => {
                            onChangeAdaptation("centered", e?.target.value);
                        }} />
                </div>
                <div className="d-flex align-items-center mt-3">
                    <span className="text-label w-25">Movimiento</span>
                    <input type="text" className="form-control" name="motion"
                        value={adaptationPayload.adaptation?.motion}
                        disabled={isDisabledForm}
                        onChange={(e) => {
                            onChangeAdaptation("motion", e?.target.value);
                        }} />
                </div>
            </SectionCard>
        );
    };

    const renderContactLenses = () => {
        const lensTypes = [
            { label: "Lentes blandos", value: 1 },
            { label: "Lentes especiales", value: 2 }
        ];
        const selectedLensType = lensTypes.find(item => adaptationPayload.contactLenses?.lensType === item.value);

        const formattedSoftLensSelect = generalSelects.softLens.map(sel => ({
            label: sel.description,
            value: sel.id
        }));
        const softLens = formattedSoftLensSelect.find(fob => fob.value === adaptationPayload?.contactLenses?.lensSelected);

        const formattedMaterialsLensSelect = generalSelects.materialsLens.map(sel => ({
            label: sel.description,
            value: sel.id
        }));
        const materialsLens = formattedMaterialsLensSelect.find(fob => fob.value === adaptationPayload?.contactLenses?.materials);

        const formattedSpecialLensSelect = generalSelects.specialLens.map(sel => ({
            label: sel.description,
            value: sel.id
        }));
        const specialLens = formattedSpecialLensSelect.find(fob => fob.value === adaptationPayload?.contactLenses?.lensSelected);

        const formattedDesignLensSelect = generalSelects.designLens.map(sel => ({
            label: sel.description,
            value: sel.id
        }));
        const designLens = formattedDesignLensSelect.find(fob => fob.value === adaptationPayload?.contactLenses?.design);

        let lensList = undefined;
        if (adaptationPayload.contactLenses?.lensType === 1) lensList = formattedSoftLensSelect;
        if (adaptationPayload.contactLenses?.lensType === 2) lensList = formattedSpecialLensSelect;

        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold">Lentes de contacto de prueba</h5>
                <hr className="text-primary mb-0 mt-2" />
                <div className="d-flex align-items-center mt-3">
                    <div className="me-3 w-50">
                        <label className="text-label">Tipo de lentes</label>
                        <Select
                            disabled={isDisabledForm}
                            options={lensTypes}
                            name="soft"
                            value={selectedLensType}
                            onChange={(e) => {
                                onChangeLensType(e?.option.value);
                            }}
                        />
                    </div>
                    <div className="me-3 w-50">
                        <label className="text-label">Lista de lentes</label>
                        <Select
                            options={lensList}
                            value={adaptationPayload.contactLenses?.lensType === 1 ? softLens : specialLens}
                            onChange={(e) => {
                                onChangeLensSelected("lensSelected", e?.option.value);
                            }}
                            disabled={!lensList || isDisabledForm}
                        />
                    </div>
                    <div className="flex-fill"></div>
                </div>
                {(adaptationPayload.contactLenses?.lensType === 2 && specialLens?.value === 1) && (
                    <div className="d-flex align-items-center mt-3">
                        <div className="me-3 w-50">
                            <label className="text-label">Materiales</label>
                            <Select
                                disabled={isDisabledForm}
                                options={formattedMaterialsLensSelect}
                                value={materialsLens}
                                name="materials"
                                onChange={(e) => {
                                    onChangeContactLens("materials", e?.option.value);
                                }}
                            />
                        </div>
                        <div className="me-3 w-50">
                            <label className="text-label">Diseño</label>
                            <Select
                                disabled={isDisabledForm}
                                options={formattedDesignLensSelect}
                                value={designLens}
                                name="design"
                                onChange={(e) => {
                                    onChangeContactLens("design", e?.option.value);
                                }}
                            />
                        </div>
                        <div className="flex-fill"></div>
                    </div>
                )}
            </SectionCard>
        );
    };

    const render = () => {
        return (
            <div className="d-flex flex-column h-100 overflow-hidden">
                <ConsultationHeader
                    isDisabledForm={isDisabledForm}
                    currentSection="Adaptación LC" showHistory={false} />
                <div className="d-flex overflow-hidden h-100">
                    <section className="consultation-section">
                        <div className="row g-3">
                            <div className="col-12">
                                <AttentionOrigin
                                    isDisabledForm={isDisabledForm}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                />
                            </div>
                            <div className="col-6">
                                {renderContactLenses()}
                            </div>

                            <div className="col-6">
                                <SectionCard className="h-100">
                                    <h5 className="text-secondary fw-bold">Sobre-refracción</h5>
                                    <hr className="text-primary mb-3 mt-2" />
                                    {renderReusableSection("overfraction")}
                                </SectionCard>
                            </div>


                            <div className="col-6">
                                {renderAdaptation()}
                            </div>


                            <div className="col-6">
                                {renderGlassesToOrder()}
                            </div>
                            <div className="col-12">
                                {renderScleralLenses()}
                            </div>

                            <div className="col-6">
                                <DiagnosticImpression isDisabledForm={isDisabledForm}
                                />
                            </div>
                            <div className="col-6">
                                <AnalysisAndPlan
                                    isDisabledForm={isDisabledForm}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                    height={260}
                                />
                            </div>
                        </div>
                    </section >
                    <History showClose={false} />
                </div>
                <ConsultationControls isDisabledForm={isDisabledForm} />
            </div>
        );
    };

    return render();
}