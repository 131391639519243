import { useEffect, useState } from "react";
import { SectionCard, Select } from "@/components";
import { CommonSelect, OrthopticPayload, SensoryCorrespondenceVision } from "@/models/sheets/orthoptic";
import { getSensoryCorrespondenceService } from "@/services";

interface SensorycParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
}

export default function Sensory({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm }: SensorycParams) {
    const [sensors, setSensors] = useState<CommonSelect[]>([]);

    useEffect(() => {
        async function fetchData() {
            const response = await getSensoryCorrespondenceService();
            if (response.results) setSensors(response.results);
        }
        fetchData();
    }, []);

    const onChangeSensor = (vision: "vl" | "vp", segment: keyof SensoryCorrespondenceVision, value: number) => {
        const options: typeof orthopticPayload = {
            ...orthopticPayload,
            sensoryCorrespondence: {
                ...orthopticPayload.sensoryCorrespondence,
                [vision]: {
                    ...orthopticPayload.sensoryCorrespondence?.[vision],
                    [segment]: value
                }
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const getSensorValue = (vision: "vl" | "vp", segment: keyof SensoryCorrespondenceVision) => {
        const formattedSensors = sensors.map(item => ({ label: item.description, value: item.id }));

        return formattedSensors.find(item => item.value === orthopticPayload.sensoryCorrespondence?.[vision]?.[segment]);
    };

    const render = () => {
        const formattedSensors = sensors.map(item => ({ label: item.description, value: item.id }));


        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold align-self-start">
                    Correspondencia sensorial
                </h5>
                <hr className="text-primary mt-2 mb-4" />
                <div className="row">
                    <div className="col-lg-12">
                        <table>
                            <tbody>
                                <tr>
                                    <td align="center"></td>
                                    <td align="center" className="text-secondary">FR</td>
                                    <td align="center" className="text-secondary">BAG</td>
                                    <td align="center" className="text-secondary">LW</td>
                                    <td align="center" className="text-secondary">AMB</td>
                                </tr>
                                <tr>
                                    <td className="p-2 text-secondary">VL</td>
                                    <td className="p-2">
                                        <Select
                                            disabled={isDisabledForm}
                                            style={{ width: "100%" }}
                                            options={formattedSensors}
                                            onChange={({ option }) => onChangeSensor("vl", "fr", option.value)}
                                            value={getSensorValue("vl", "fr")}
                                        />
                                    </td>
                                    <td className="p-2">
                                        <Select
                                            disabled={isDisabledForm}
                                            style={{ width: "100%" }}
                                            options={formattedSensors}
                                            onChange={({ option }) => onChangeSensor("vl", "bag", option.value)}
                                            value={getSensorValue("vl", "bag")}
                                        />
                                    </td>
                                    <td className="p-2">
                                        <Select
                                            disabled={isDisabledForm}
                                            style={{ width: "100%" }}
                                            options={formattedSensors}
                                            onChange={({ option }) => onChangeSensor("vl", "lw", option.value)}
                                            value={getSensorValue("vl", "lw")}
                                        />
                                    </td>
                                    <td className="p-2">
                                        <Select
                                            disabled={isDisabledForm}
                                            style={{ width: "100%" }}
                                            options={formattedSensors}
                                            onChange={({ option }) => onChangeSensor("vl", "amd", option.value)}
                                            value={getSensorValue("vl", "amd")}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-2 text-secondary">VP</td>
                                    <td className="p-2">
                                        <Select
                                            disabled={isDisabledForm}
                                            style={{ width: "100%" }}
                                            options={formattedSensors}
                                            onChange={({ option }) => onChangeSensor("vp", "fr", option.value)}
                                            value={getSensorValue("vp", "fr")}
                                        />
                                    </td>
                                    <td className="p-2">
                                        <Select
                                            disabled={isDisabledForm}
                                            style={{ width: "100%" }}
                                            options={formattedSensors}
                                            onChange={({ option }) => onChangeSensor("vp", "bag", option.value)}
                                            value={getSensorValue("vp", "bag")}
                                        />
                                    </td>
                                    <td className="p-2">
                                        <Select
                                            disabled={isDisabledForm}
                                            style={{ width: "100%" }}
                                            options={formattedSensors}
                                            onChange={({ option }) => onChangeSensor("vp", "lw", option.value)}
                                            value={getSensorValue("vp", "lw")}
                                        />
                                    </td>
                                    <td className="p-2">
                                        <Select
                                            disabled={isDisabledForm}
                                            style={{ width: "100%" }}
                                            options={formattedSensors}
                                            onChange={({ option }) => onChangeSensor("vp", "amd", option.value)}
                                            value={getSensorValue("vp", "amd")}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-12 d-flex align-items-end mt-3">
                        <textarea
                            disabled={isDisabledForm}
                            className="form-control no-resize mb-2 "
                            style={{ height: "80px" }}
                            value={orthopticPayload.sensoryCorrespondence?.observations}
                            onChange={({ target }) => {
                                setOrthopticPayload({
                                    ...orthopticPayload,
                                    sensoryCorrespondence: {
                                        ...orthopticPayload.sensoryCorrespondence,
                                        observations: target.value
                                    }
                                });
                                onSubmit();
                            }}
                            placeholder="Observaciones..."
                        />
                    </div>
                </div>

            </SectionCard>
        );
    };

    return render();
}