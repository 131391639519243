import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { useLocation, useNavigate } from "react-router-dom";
//
import { AttentionOriginFields } from "@/models/attentionOrigin";
import { OrthopticPayload } from "@/models/sheets/orthoptic";
import { getOrthoptic, saveOrthoptic } from "./orthoptic.action";
//
import AttentionOrigin from "../AttentionOrigin/AttentionOrigin";
import History from "../History/History";
import ConsultationControls from "../Controls/Controls";
import ConsultationHeader from "../Header/Header";
// import VisualAcuity from "../shared/VisualAcuity/VisualAcuity";
import BielschowskyTest from "./BielschowskyTest/BielschowskyTest";
import ChromaticVision from "./ChromaticVision/ChromaticVision";
import FinalFormula from "./FinalFormula/FinalFormula";
import Laterality from "./Laterality/Laterality";
//
import KappaAngle from "./KappaAngle/KappaAngle";
import NextConvergencePoint from "./NextConvergencePoint/NextConvergencePoint";
import Flexibility from "./Flexibility/Flexibility";
import Fixation from "./Fixation/Fixation";
import PrismCoverTest from "./PrismCoverTest/PrismCoverTest";
import WhiteTest from "./WhiteTest/WhiteTest";
import HirschbergReflex from "./HirschbergReflex/HirschbergReflex";
import KrimskyTest from "./KrimskyTest/KrimskyTest";
import Accomodation from "./Accomodation/Accomodation";
import Versions from "./Versions/Versions";
import Sensory from "./Sensory/Sensory";
import FusionalBreadths from "./FusionalBreadths/FusionalBreadths";
import Stereopsis from "./Stereopsis/Stereopsis";
import DiagnosticImpression from "../DiagnosticImpression/DiagnosticImpression";
import AnalysisAndPlan from "../AttentionOrigin/AnalysisAndPlan";
import "../Consultation.scss";

export default function Orthoptic() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const currentSheet = useAppSelector(state => state.patientAttention.patientStatus.sheet);
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const mcfId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const isDisabledForm = false;
    const [attentionOriginOptions, setAttentionOriginOptions] = useState<AttentionOriginFields>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number,
        generalFields: {
            attentionOriginId: undefined,
            abserveEvent: undefined,
            evolutionTime: undefined,
            evolutionTimeUnitId: undefined
        },
        reasonForConsulting: { predefinedTxts: [], justification: "" },
        currentDisease: { predefinedTxts: [], justification: "" },
        analysisPlan: ""
    });
    const [orthopticPayload, setOrthopticPayload] = useState<OrthopticPayload>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number
    });

    useEffect(() => {
        const splittedPath = location.pathname.split("/");
        const sheet = splittedPath[splittedPath.length - 1];

        if (sheet !== currentSheet) {
            navigate(-1);
        }
    }, [currentSheet, navigate, location.pathname]);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getOrthoptic({
                appId: appointmentId,
                eaccount: accountId,
                userId: userId as number
            }));
            if (data) {
                setOrthopticPayload({
                    ...data,
                    eaccount: accountId,
                    userId: userId as number,
                    appId: appointmentId,
                });
            }
        }
        fetchData();
    }, [dispatch, appointmentId, accountId, mcfId, userId]);

    const onSubmitOrthoptic = useDebouncedCallback(() => {
        if (orthopticPayload) {
            const options = { ...orthopticPayload };

            setOrthopticPayload(options);
            dispatch(saveOrthoptic(options));
        }
    }, 1000);

    const render = () => {
        return (
            <div className="d-flex flex-column h-100 overflow-hidden">
                <ConsultationHeader isDisabledForm={isDisabledForm} currentSection="Ortóptica" showHistory={false} />
                <div className="d-flex overflow-hidden h-100">
                    <section className="consultation-section">
                        <div className="row g-3">
                            <div className="col-12">
                                <AttentionOrigin
                                    isDisabledForm={isDisabledForm}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                />
                            </div>
                            <div className="col-5">
                                <FinalFormula
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-5">
                                <FusionalBreadths
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-2">
                                <Fixation
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-6">
                                <HirschbergReflex
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-6">
                                <KrimskyTest
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-6">
                                <PrismCoverTest
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-6">
                                <WhiteTest
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-6">
                                <Versions
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                    showHistory
                                // showHistory={showHistory}
                                />
                            </div>
                            <div className="col-6">
                                <div className=" ">
                                    <BielschowskyTest
                                        isDisabledForm={isDisabledForm}
                                        orthopticPayload={orthopticPayload}
                                        setBielschowskyPayload={setOrthopticPayload}
                                        onSubmit={() => onSubmitOrthoptic()}
                                    />
                                </div>
                                <div className="d-flex  mt-3">
                                    <div className="col-6 pr-1" style={{ paddingRight: 5 }}>
                                        <NextConvergencePoint
                                            isDisabledForm={isDisabledForm}
                                            orthopticPayload={orthopticPayload}
                                            setOrthopticPayload={setOrthopticPayload}
                                            onSubmit={() => onSubmitOrthoptic()}
                                        />
                                    </div>
                                    <div className="col-6 pl-1" style={{ paddingLeft: 5 }}>
                                        <Flexibility
                                            isDisabledForm={isDisabledForm}
                                            orthopticPayload={orthopticPayload}
                                            setOrthopticPayload={setOrthopticPayload}
                                            onSubmit={() => onSubmitOrthoptic()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Laterality
                                            isDisabledForm={isDisabledForm}
                                            orthopticPayload={orthopticPayload}
                                            setOrthopticPayload={setOrthopticPayload}
                                            onSubmit={() => onSubmitOrthoptic()}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <KappaAngle
                                            isDisabledForm={isDisabledForm}
                                            orthopticPayload={orthopticPayload}
                                            setOrthopticPayload={setOrthopticPayload}
                                            onSubmit={() => onSubmitOrthoptic()}
                                        />
                                    </div>

                                </div>
                            </div>





                            <div className="col-6">
                                <Accomodation
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-4">
                                <ChromaticVision
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={onSubmitOrthoptic}
                                />
                            </div>

                            <div className="col-5">
                                <Sensory
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>


                            <div className="col-3">
                                <Stereopsis
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-6">
                                <DiagnosticImpression isDisabledForm={isDisabledForm} />
                            </div>
                            <div className="col-lg-6">
                                <AnalysisAndPlan
                                    isDisabledForm={isDisabledForm}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                    height={220}
                                />
                            </div>
                        </div>
                    </section>
                    <History showClose={false} />
                </div>
                <ConsultationControls isDisabledForm={isDisabledForm} />
            </div>
        );
    };


    return render();
}