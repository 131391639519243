import { useEffect, useState } from "react";
import { SectionCard, Select } from "@/components";
import { CommonSelect, OrthopticPayload } from "@/models/sheets/orthoptic";
import { getKappaAngleService } from "@/services";

interface ChromaticVisionParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
}

export default function KappaAngle({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm }: ChromaticVisionParams) {
    const [kappaAngles, setKappaAngles] = useState<CommonSelect[]>([]);

    useEffect(() => {
        async function fetchData() {
            const data = await getKappaAngleService();
            setKappaAngles(data.results);
        }
        fetchData();
    }, []);

    const onChangeAngle = (kappa: "kapIdOd" | "kapIdOi", value: string) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            kappaAngle: {
                ...orthopticPayload.kappaAngle,
                [kappa]: value
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const render = () => {
        const kappaAngleFormatted = kappaAngles.map(item => ({ value: item.id, label: item.description }));
        const kappaAngleOD = kappaAngleFormatted.find(item => item.value === orthopticPayload.kappaAngle?.kapIdOd);
        const kappaAngleOI = kappaAngleFormatted.find(item => item.value === orthopticPayload.kappaAngle?.kapIdOi);

        return (
            <SectionCard>
                <h5 className="text-label fw-bold">Angulo Kappa</h5>
                <hr className="text-primary mt-2 mb-2" />
                <div className="d-flex align-items-center mt-3 p-2  bg-od" style={{borderRadius: "0.5rem 0.5rem 0rem 0rem"}}>
                    <span style={{ width: 30 }} className="text-secondary align-self-center">OD</span>
                    <Select
                        disabled={isDisabledForm}
                        width="100%"
                        style={{ width: "100%" }}
                        options={kappaAngleFormatted}
                        value={kappaAngleOD}
                        onChange={({ option }) => onChangeAngle("kapIdOd", option.value)}
                    />
                </div>
                <div className="d-flex bg-oi p-2" style={{borderRadius: "0rem 0rem 0.5rem 0.5rem"}}>
                    <span style={{ width: 30 }} className="text-secondary align-self-center">OI</span>
                    <Select
                        disabled={isDisabledForm}
                        width="100%"
                        style={{ width: "100%" }}
                        options={kappaAngleFormatted}
                        value={kappaAngleOI}
                        onChange={({ option }) => onChangeAngle("kapIdOi", option.value)}
                    />
                </div>
            </SectionCard>
        );
    };

    return render();
}