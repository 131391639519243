import { Modal, Select, TextField } from "@/components";
import ScrollableTable from "@/components/ScrollableTable/ScrollableTable";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { BodyCenterList, ConsumptionCenters, ConsumptionCentersListByInsurance, ITabs, deleteSupplyParams } from "@/models/surgery/patientAttention/patientAttention";
import { fireCautionAlert } from "@/utils";
import { IconChecklist, IconCircleX, IconEditCircle, IconPhotoUp, IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { changeQuantity, deleteSupply, getConsumptionCenters, getConsumptionCentersSheet } from "../patientAttentionSurgery.action";
import BatchRecord from "./BatchRecord";
import EvidenceModal from "./EvidenceModal";

const ConsumptionSheet = ({ stage, isOpen, onClose, appId }: {
    stage?: ITabs; isOpen: boolean, onClose: () => void, appId?: number | null;
}) => {

    const eaccount = useAppSelector(state => state.workspace).id;
    const dispatch = useAppDispatch();
    const [consumptionList, setConsumptionList] = useState<ConsumptionCenters[]>([]);
    const [trigger, setTrigger] = useState(0);
    const [consumptionListSheet, setConsumptionListSheet] = useState<ConsumptionCentersListByInsurance[]>([]);
    const [lotsList, setLotsList] = useState<{ medicineName: string; data: ConsumptionCenters; }>(
        {
            medicineName: "",
            data: {
                article: {
                    barCode: "",
                    id: NaN,
                    name: "",
                },
                inventoryFamily: "",
                lots: [],
                supplieId: NaN,
                supplieName: ""
            },
        }
    );
    const [lotsModal, setLotsModal] = useState<boolean>(false);
    const [reloadDataEvidence, setReloadDataEvidence] = useState(0);

    const handleOpenLotsModal = () => setLotsModal(true);
    const handleCloseLotsModal = (update: boolean) => {
        setLotsModal(false);
        if (update) {
            setTrigger(trigger + 1);
        }
    };
    const handleCloseModal = () => {
        onClose();
    };

    const renderTable = () => {
        return (

            <ScrollableTable maxHeight={600} key={trigger}>
                <ScrollableTable.Head>
                    <ScrollableTable.Cell className="fs-6" col={1}>
                        Código
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6 nowrap" col={3}>
                        Articulo
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6 nowrap text-ellipsis overflow-hidden" col={1}>
                        Lote
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell align="center" className="fs-6 text-ellipsis overflow-hidden" col={2}>
                        Fecha de vencimiento
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell align="center" className="fs-6" col={2}>
                        Cant. teórica
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6" col={2}>
                        Cant. corregida
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6" >
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6" >
                    </ScrollableTable.Cell>
                </ScrollableTable.Head>

                <ScrollableTable.Body>
                    {consumptionListSheet?.length > 0 ? consumptionListSheet?.map((article, ind) => {
                        return (
                            <div key={ind}>
                                <ScrollableTable.Row >
                                    <ScrollableTable.Cell col={12} align="center" className="subTitleTable fw-bold">
                                        {article.supName}
                                    </ScrollableTable.Cell>
                                </ScrollableTable.Row>
                                {article?.articles.length > 0 && article?.articles.map((supply, index) => {
                                    return (
                                        <RenderRow
                                            reloadDataEvidence={reloadDataEvidence} setReloadDataEvidence={setReloadDataEvidence}
                                            key={index} row={supply} deleteSupplyFunction={deleteSupplyFunction} setTrigger={setTrigger} trigger={trigger} showImage={article.supPrefix === "specials"}
                                        />
                                    );
                                })
                                }
                            </div>
                        );
                    }) :
                        <ScrollableTable.Row >
                            <ScrollableTable.Cell col={12} align="center" className=" justify-content-center fw-bold py-4">
                                <span>
                                    No hay insumos disponibles
                                </span>
                            </ScrollableTable.Cell>
                        </ScrollableTable.Row>
                    }
                </ScrollableTable.Body>
            </ScrollableTable>

        );
    };

    const deleteSupplyFunction = async (params: deleteSupplyParams) => {
        fireCautionAlert("Se eliminará de la hoja de consumo", "¿Está seguro?", async () => {
            const data = await dispatch(deleteSupply(params));
            if (data.success) {
                setTrigger(trigger + 1);
            }
        });

    };

    useEffect(() => {
        async function fetchData() {
            const dataArticles = await dispatch(getConsumptionCenters({ eaccount }));
            if (appId && stage?.value) {
                const dataSheet = await dispatch(getConsumptionCentersSheet({ appId, stagePrefix: stage?.value, eaccount }));
                if (dataSheet) {
                    setConsumptionListSheet(dataSheet);
                }
            }
            if (dataArticles) {
                setConsumptionList(dataArticles);
            }
        }
        fetchData();
    }, [appId, eaccount, dispatch, trigger, stage?.value, reloadDataEvidence]);


    const renderModalLots = (fullData: ConsumptionCenters, name: string) => {
        setLotsList({ medicineName: name, data: fullData });
        handleOpenLotsModal();
    };

    const render = () => {
        const options = consumptionList.map(center => ({
            value: center.article.id,
            label: center.article.name,
            extraData: center,
        }));
        return (
            <>
                <BatchRecord stage={stage} isOpen={lotsModal} onClose={handleCloseLotsModal} data={{ medicineName: lotsList.medicineName, data: lotsList.data }} appId={appId} />
                <Modal
                    positionModal="center"
                    width={1200}
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                >
                    <h1 className="text-secondary fw-bold text-center">Hoja de consumo - {stage?.label}</h1>
                    <div className="row d-flex pb-4">

                        <div>
                            <b className="text-muted text-center align-self-center"> Buscar</b>
                        </div>
                        <div className="">

                            <Select
                                className="textGray100 w-100"
                                isSearchable
                                placeholder="Código o nombre del articulo..."
                                variant="plain"
                                showDefaultOption={false}
                                options={options}
                                style={{ width: "100%" }}
                                onChange={({ option }) => {
                                    if (option.extraData) {
                                        renderModalLots(option.extraData, option.label);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {renderTable()}
                </Modal>
            </>
        );
    };

    return render();
};

export default ConsumptionSheet;


const RenderRow = ({ row, showImage, trigger, deleteSupplyFunction, setTrigger, setReloadDataEvidence, reloadDataEvidence }:
    {
        row: BodyCenterList, showImage?: boolean,
        reloadDataEvidence: number, setReloadDataEvidence: (data: number) => void;
        trigger: number, setTrigger: (data: number) => void, deleteSupplyFunction: (payload: deleteSupplyParams) => void;
    }) => {

    const dispatch = useAppDispatch();
    const evidenceMandatory = (row.evidence === 1 && (row?.files?.length === 0 || row.files === null)) ? "#ffa73320" : "";
    const [isEditing, setIsEditing] = useState(false);
    const [quantityValue, setQuantityValue] = useState<string | number>("");
    let triggerValue = 0;
    const handleChangedQuantity = async () => {
        const data = await dispatch(changeQuantity({
            "artId": row.articleId,
            "quantity": quantityValue !== "" ? Number(quantityValue) : row.quantity,
            "cssId": row.cssId,
        }));
        if (data.success) {
            setIsEditing(false);
            setTrigger(trigger + 1);
        }
    };
    const cancelQuantity = () => {
        setIsEditing(false);
    };

    const [imagesModal, setImagesModal] = useState<{ isOpen: boolean, elem?: BodyCenterList; }>({
        isOpen: false
    });
    return (
        <>
            <EvidenceModal
                data={row}
                isOpen={imagesModal.isOpen}
                reloadDataEvidence={reloadDataEvidence} setReloadDataEvidence={setReloadDataEvidence}
                onClose={() => {
                    setImagesModal((state) => ({ ...state, isOpen: false }));
                }} />
            <ScrollableTable.Row key={row.articleId + trigger + row.cssId}
                style={{ backgroundColor: evidenceMandatory }}
            >
                <ScrollableTable.Cell col={1}
                > <span className="text-muted" >{row.articleBarCode ?? "-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={3}><span className="text-muted">{row.articleName ?? "-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={1}><span className="text-muted">{row.lotName ?? "-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={2} className="justify-content-center"><span className="text-muted">{row.expirationDate ?? "-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={2} className="justify-content-center"><span className="text-muted ">{"-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={2} key={triggerValue} className="d-flex">
                    <div className="d-flex justify-content-between" key={triggerValue}>
                        <div style={{ width: 80 }}>
                            {isEditing ?
                                <TextField
                                    type="number"
                                    defaultValue={row.quantity}
                                    onChange={(e) => { setQuantityValue(e.target.value); }}
                                    name="appDate"
                                    style={{ color: "#999", width: "80px" }}
                                    onKeyDown={e => {
                                        if (e.key === "Enter") {
                                            handleChangedQuantity();
                                        }
                                    }}
                                />
                                :
                                <span className="text-muted text-center">{row.quantity}</span>
                            }
                        </div>
                        {!isEditing &&
                            <div className="px-2 align-self-center pointer" onClick={() => {
                                setIsEditing(true);
                                triggerValue = triggerValue + 1;
                            }}>
                                <IconEditCircle />
                            </div>
                        }
                        {
                            isEditing &&
                            <>
                                <div className="pointer px-2 text-success align-self-center" onClick={() => {
                                    handleChangedQuantity();
                                }} >
                                    <IconChecklist />
                                </div>
                                <div className="px-2 text-danger pointer align-self-center"
                                    onClick={() => cancelQuantity()}
                                >
                                    <IconCircleX />
                                </div>
                            </>

                        }

                    </div>
                </ScrollableTable.Cell>
                <ScrollableTable.Cell >
                    <div id="evidence" onClick={() => {
                        setImagesModal((state) => ({ ...state, isOpen: true }));
                    }} className="pointer">
                        <span className=" text-primary">{showImage && <IconPhotoUp />}</span>
                    </div>

                </ScrollableTable.Cell>
                <ScrollableTable.Cell ><span className=" text-primary">
                    <div className="pointer"
                        onClick={() => {
                            deleteSupplyFunction({
                                artId: row.articleId,
                                cssId: row.cssId,
                                enabled: 0
                            });
                        }}
                    >
                        <IconTrash />
                    </div>
                </span></ScrollableTable.Cell>
            </ScrollableTable.Row >
        </>
    );
};